<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- 検索フォーム -->
    <v-container>
      <!-- 開閉パネル -->
      <v-expansion-panels>
        <v-expansion-panel>
          <!-- パネルを閉じているときの表示内容 -->
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4">
                <h4>検索入力フォーム</h4>
              </v-col>
              <v-col class="text--secondary" cols="8">
                <v-fade-transition leave-absolute>
                  <!-- 検索フォームを開いているときのメッセージ -->
                  <span v-if="open">検索条件を入力してください</span>
                  <!-- 検索フォームを閉じているときのメッセージ -->
                  <v-row v-else no-gutters style="width: 100%">
                    <!-- 設定された検索条件を表示 -->
                    <ClosedSearchFormDisp
                        :value="searchCondition.name"
                        label="名称"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.caseLogName"
                        label="応対履歴データ名"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.faqName"
                        label="FAQデータ名"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.selectedStatus"
                        label="状態"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.clusterCountFrom"
                        :toValue="searchCondition.clusterCountTo"
                        label="クラスタ数"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.startDatetimeFrom"
                        :toValue="searchCondition.startDatetimeTo"
                        label="ジョブ開始日時"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.endDatetimeFrom"
                        :toValue="searchCondition.endDatetimeTo"
                        label="ジョブ終了日時"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.faqCoverRateFrom"
                        :toValue="searchCondition.faqCoverRateTo"
                        label="FAQカバー率(%)"
                    />
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <!-- パネルを開いているときの表示内容（検索入力フォーム） -->
          <v-form ref="search_form">
            <v-expansion-panel-content>
              <!-- 名前 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">名称 :</v-col>
                <v-col cols="6">
                  <NormalTextArea
                      :value.sync="searchCondition.name"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- 応対履歴 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">応対履歴データ名 :</v-col>
                <v-col cols="6">
                  <NormalTextArea
                      :value.sync="searchCondition.caseLogName"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- FAQ -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">FAQデータ名 :</v-col>
                <v-col cols="6">
                  <NormalTextArea
                      :value.sync="searchCondition.faqName"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- クラスタ数 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">クラスタ数 :</v-col>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.clusterCountFrom"
                      label=""
                  ></NumberTextArea>
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.clusterCountTo"
                      label=""
                  ></NumberTextArea>
                </v-col>
              </v-row>

              <!-- 状態 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">状態 :</v-col>
                <v-col cols="6">
                  <SelectField
                      :status="processingSelects"
                      :value.sync="searchCondition.selectedStatus"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- ジョブ開始日時 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ジョブ開始日時 :</v-col>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.startDatetimeFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.startDatetimeTo"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- ジョブ終了日時 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ジョブ終了日時 :</v-col>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.endDatetimeFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.endDatetimeTo"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- FAQカバー率(%) -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">FAQカバー率(%) :</v-col>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.faqCoverRateFrom"
                      label=""
                  ></NumberTextArea>
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.faqCoverRateTo"
                      label=""
                  ></NumberTextArea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-form>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ボタン２つ -->
      <br/>
      <v-row>
        <NewFAQCandidateBtnForResHisScreen
            :buttonClass="'ma-3'"
            :isFAQCandidateScreen="true"
            :isResHisScreen="false"
            :isSmall="false"
            :selectResHis="null"
            @search="search"
            @showError="showError"
            @showSuccess="showSuccess"
            @showWarning="showWarning"
            @alertsInitByEmit="alertsInitByEmit"
        />
        <v-spacer></v-spacer>
        <v-btn
            :loading="createFAQCandidateDataListLoading"
            id="button_get_create_faq_candidate_list"
            class="ma-3"
            color="primary"
            @click="
              createFAQCandidateDataListOptions.page = 1;
              search();
            "
        >
          <v-icon>mdi-magnify</v-icon>
          検索
        </v-btn>
        <v-btn
            id="button_search_condition_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>
      </v-row>
    </v-container>

    <!-- テーブル内 -->
    <v-container>
      <v-data-table
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
          }"
          :headers="createFAQCandidateHeaders"
          :items="createFAQCandidateDataList"
          :loading="createFAQCandidateDataListLoading"
          :options.sync="createFAQCandidateDataListOptions"
          :server-items-length="total"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          @update:items-per-page="search()"
          @update:page="search()"
      >
        <template v-slot:[`item.start_datetime`]="{ item }">
          {{ dateReplace(item.start_datetime) }}
        </template>
        <template v-slot:[`item.end_datetime`]="{ item }">
          {{ dateReplace(item.end_datetime) }}
        </template>
        <template v-slot:[`item.faq_cover_rate`]="{ item }">
          {{ faqReplace(item.faq_cover_rate) }}
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <template v-if="item.status === '処理完了'">
            <v-tooltip color="tooltip" top>
              <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    color="primary"
                    small
                    @click="
                      jobId = item.id;
                      createFaqName = item.name;
                      caseLogName = item.case_log_dataset_group_name;
                      faqName = item.faq_group_name;
                      groupId = item.id;
                      datasetGroupId = item.case_log_dataset_group_id;
                      faqGroupId = item.faq_group_id;
                      CreateFAQCandidateResultDialog = true;
                    "
                >
                  <v-icon>mdi-file-find</v-icon>

                </v-btn>
              </template>
              <span>結果確認</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
      <v-dialog v-model="CreateFAQCandidateResultDialog">
        <CreateFAQCandidateResult
            :dialog.sync="CreateFAQCandidateResultDialog"
            :jobId="jobId"
            :caseLogName="caseLogName"
            :faqName="faqName"
            :datasetGroupId="datasetGroupId"
            :faqGroupId="faqGroupId"
            :groupId="groupId"
            :createFaqName="createFaqName"
        />
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import NormalTextArea from "../../parts/NormalTextArea.vue";
import NumberTextArea from "../../parts/NumberTextArea.vue";
import CalendarYYYYMMDD from "../../parts/CalendarYYYYMMDD.vue";
import SelectField from "../../parts/SelectField.vue";
import ClosedSearchFormDisp from "../../parts/ClosedSearchFormDisp.vue";
import ClosedSearchFormFromToDisp from "../../parts/ClosedSearchFormFromToDisp.vue";
import CreateFAQCandidateResult from "./CreateFAQCandidateResult.vue";
import ApiUtils from "../../js/ApiUtils";
import StringUtils from "../../js/StringUtils";
import ObjectUtils from "../../js/ObjectUtils";
import AlertArea from "../../parts/AlertArea.vue";
import Messages from "../../js/Messages";
import NewFAQCandidateBtnForResHisScreen from "@/components/parts/NewFAQCandidateBtnForResHisScreen";
import ValidationUtils from "@/components/js/ValidationUtils";

export default {
  name: "CreateFAQCandidateMgt",
  components: {
    NewFAQCandidateBtnForResHisScreen,
    AlertArea,
    NormalTextArea,
    NumberTextArea,
    CalendarYYYYMMDD,
    SelectField,
    ClosedSearchFormDisp,
    ClosedSearchFormFromToDisp,
    CreateFAQCandidateResult,
  },
  data: () => ({
    caseLogName: null,
    faqName: null,
    createFaqName: null,
    groupId: null,
    datasetGroupId: null,
    faqGroupId: null,
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    CreateFAQCandidateResultDialog: false,
    createFAQCandidateDataListLoading: false,
    createFAQCandidateDataListOptions: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ["id"],
      sortDesc: [false],
    },
    total: 0,
    processingSelects: ["未処理", "処理中", "処理完了"],
    searchCondition: {
      name: null,
      caseLogName: null,
      faqName: null,
      clusterCountFrom: null,
      clusterCountTo: null,
      selectedStatus: null,
      startDatetimeFrom: null,
      startDatetimeTo: null,
      endDatetimeFrom: null,
      endDatetimeTo: null,
      faqCoverRateFrom: null,
      faqCoverRateTo: null,
    },
    jobId: null,
    createFAQCandidateDataList: [],
    createFAQCandidateHeaders: [
      {align: "start", text: "名称", value: "name"},
      {align: "start", text: "応対履歴データ名", value: "case_log_dataset_group_name"},
      {align: "start", text: "FAQデータ名", value: "faq_group_name"},
      {align: "end", text: "クラスタ数", value: "cluster_count"},
      {align: "start", text: "状態", value: "status"},
      {align: "start", text: "ジョブ開始日時", value: "start_datetime"},
      {align: "start", text: "ジョブ終了日時", value: "end_datetime"},
      {align: "end", text: "FAQカバー率", value: "faq_cover_rate"},
      {align: "start", text: "操作", value: "operation", width: "80"},
    ],
  }),
  methods: {

    /**
     * FAQカバー率書式を変更する。
     */
    faqReplace: function (item) {
      if (item === 0) {
        return item + ".0%"
      }
      if (!item) {
        return item
      }
      const round = Math.round(item * 1000) / 10; // 例:0.9955 -> 99.6%
      return round.toFixed(1) + "%";
    },

    /**
     * 日付書式を変更する。
     */
    dateReplace: function (item) {
      return StringUtils.dateReplace(item);
    },

    /**
     * 検索フォームをすべてリセットする。
     */
    searchConditionClear() {
      this.searchCondition.name = null;
      this.searchCondition.caseLogName = null;
      this.searchCondition.faqName = null;
      this.searchCondition.clusterCountFrom = null;
      this.searchCondition.clusterCountTo = null;
      this.searchCondition.selectedStatus = null;
      this.searchCondition.startDatetimeFrom = null;
      this.searchCondition.startDatetimeTo = null;
      this.searchCondition.endDatetimeFrom = null;
      this.searchCondition.endDatetimeTo = null;
      this.searchCondition.faqCoverRateFrom = null;
      this.searchCondition.faqCoverRateTo = null;
    },

    /**
     * Faq 候補生成ジョブのリストを取得。
     **/
    async search() {
      this.logDebug("### search() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["search_form"])) {
        this.logDebug("### バリデーションNG search() 終了");
        return;
      }
      try {
        // ローディング開始
        this.createFAQCandidateDataListLoading = true;

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "name", this.searchCondition.name);
        ObjectUtils.objectPush(qParams, "case_log_name", this.searchCondition.caseLogName);
        ObjectUtils.objectPush(qParams, "faq_name", this.searchCondition.faqName);
        ObjectUtils.objectPush(qParams, "cluster_count_from", this.searchCondition.clusterCountFrom);
        ObjectUtils.objectPush(qParams, "cluster_count_to", this.searchCondition.clusterCountTo);
        ObjectUtils.objectPush(qParams, "status", this.searchCondition.selectedStatus);
        ObjectUtils.dateObjectPush(qParams, "start_datetime_from", this.searchCondition.startDatetimeFrom);
        ObjectUtils.dateObjectPush(qParams, "start_datetime_to", this.searchCondition.startDatetimeTo);
        ObjectUtils.dateObjectPush(qParams, "end_datetime_from", this.searchCondition.endDatetimeFrom);
        ObjectUtils.dateObjectPush(qParams, "end_datetime_to", this.searchCondition.endDatetimeTo);
        ObjectUtils.percentObjectPush(qParams, "faq_cover_rate_from", this.searchCondition.faqCoverRateFrom);
        ObjectUtils.percentObjectPush(qParams, "faq_cover_rate_to", this.searchCondition.faqCoverRateTo);
        ObjectUtils.objectPush(qParams, "limits", parseInt(this.createFAQCandidateDataListOptions.itemsPerPage));
        ObjectUtils.objectPush(qParams, "page", parseInt(this.createFAQCandidateDataListOptions.page));

        // APIリクエスト（R36）
        this.logDebug("#### R36 リクエスト");
        const response = await ApiUtils.get(
            "/faq_from_case_log/job",
            qParams
        );
        if (200 === response.status) {
          this.logDebug("#### R36 成功");
          this.createFAQCandidateDataList = response.data.data;
          this.total = response.data.counts;
        } else {
          this.logDebug("#### R36 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r36.warning;
        }
      } catch (error) {
        this.logDebug("#### search() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r36.error;
      } finally {
        this.createFAQCandidateDataListLoading = false;
      }
      this.logDebug("### search() 終了");
    },

    /**
     * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
     * @param message サクセスメッセージ
     */
    showSuccess: ObjectUtils.showSuccess,

    /**
     * 子コンポーネントからワーニングを表示したいときに呼び出す。
     * @param message ワーニングメッセージ
     */
    showWarning: ObjectUtils.showWarning,

    /**
     * 子コンポーネントからエラーを表示したいときに呼び出す。
     * @param message エラーメッセージ
     */
    showError: ObjectUtils.showError,

    /**
     * alerts を初期化する。
     */
    alertsInitByEmit: ObjectUtils.alertsInitByEmit,
  },
  mounted: function () {
    // 画面表示のタイミングで検索を実行
    this.logDebug("# CreateFAQCandidateMgt.vue mounted");
    this.search();
  },
};
</script>
