<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>
    <!-- 検索フォーム -->
    <v-container>
      <!-- 開閉パネル -->
      <v-expansion-panels>
        <v-expansion-panel>
          <!-- 開閉パネルヘッダー -->
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4"><h4>検索入力フォーム</h4></v-col>
              <v-col class="text--secondary" cols="8">
                <v-fade-transition leave-absolute>
                  <!-- 検索フォームを開いているときのメッセージ -->
                  <span v-if="open">検索条件を入力してください</span>
                  <!-- 検索フォームを閉じているときのメッセージ -->
                  <v-row v-else no-gutters style="width: 100%">
                    <!-- 設定された検索条件を表示 -->
                    <ClosedSearchFormDisp
                        :value="searchCondition.name"
                        label="名称"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.documentFile"
                        label="文書データ名"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.selectedStatus"
                        label="状態"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.jobStartDateFrom"
                        :toValue="searchCondition.jobStartDateTo"
                        label="ジョブ登録日時"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.jobEndDateFrom"
                        :toValue="searchCondition.jobEndDateTo"
                        label="ジョブ終了日時"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.numberFrom"
                        :toValue="searchCondition.numberTo"
                        label="件数"
                    />
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-form ref="search_form">
            <!-- 開閉パネルコンテンツ -->
            <v-expansion-panel-content>
              <!-- 名前 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">名称 :</v-col>
                <v-col cols="6">
                  <NormalTextArea
                      :value.sync="searchCondition.name"
                      label=""
                  />
                </v-col>
              </v-row>
              <!-- ドキュメント -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">文書データ名 :</v-col>
                <v-col cols="6">
                  <NormalTextArea
                      :value.sync="searchCondition.documentFile"
                      label=""
                  />
                </v-col>
              </v-row>
              <!-- 状態 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">状態 :</v-col>
                <v-col cols="6">
                  <SelectField
                      :status="processingSelects"
                      :value.sync="searchCondition.selectedStatus"
                      label=""
                  />
                </v-col>
              </v-row>
              <!-- ジョブ登録日時 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ジョブ登録日時 :</v-col>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobStartDateFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobStartDateTo"
                      label=""
                  />
                </v-col>
              </v-row>
              <!-- ジョブ終了日時 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ジョブ終了日時 :</v-col>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobEndDateFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobEndDateTo"
                      label=""
                  />
                </v-col>
              </v-row>
              <!-- 件数 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">件数 :</v-col>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.numberFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.numberTo"
                      label=""
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-form>
        </v-expansion-panel>
      </v-expansion-panels>

      <br/>
      <v-row>
        <NewFAQCandidateBtnForDocScreen
            :buttonClass="'ma-3'"
            :isCreateFAQResultScreen="true"
            :isDocumentScreen="false"
            :isSmall="false"
            :selectDocument="null"
            @search="search"
            @showError="showError"
            @showSuccess="showSuccess"
            @showWarning="showWarning"
            @alertsInitByEmit="alertsInitByEmit"
        />
        <v-spacer></v-spacer>
        <v-btn
            :loading="searchLoading"
            id="button_get_faq_data_list"
            class="ma-3"
            color="primary"
            @click="faqResultOptions.page = 1; search()"
        >
          <v-icon>mdi-magnify</v-icon>
          検索
        </v-btn>
        <v-btn
            id="button_search_condition_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
          :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
        }"
          :headers="faqDataHeaders"
          :items="faqDataList"
          :loading="searchLoading"
          :options.sync="faqResultOptions"
          :server-items-length="total"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          @update:items-per-page="search()"
          @update:page="search()"
      >
        <template v-slot:[`item.status`]="{ item }">
          <!-- 処理状況を伝えるUI検討中 -->
          <!--
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-progress-circular
                  v-on="on"
                  :rotate="-90"
                  :size="30"
                  :width="2"
                  :value="statusPercent(item.status)"
                  color="secondary"
              >
                {{ statusPercent(item.status) }}
              </v-progress-circular>
            </template>
            <span>
              <v-container style="font-size:0.8em;width: 300px;">
                <v-stepper v-model="item.status" alt-labels style="background-color:#222222;" dark>
                  <v-stepper-header>
                    <v-stepper-step class="mt-1 mb-1 pt-0 pb-0" :complete="statusPercent(item.status) >= 0" step="1">
                      <div>未処理</div>
                    </v-stepper-step>
                    <v-divider class="mt-4" color="accent"></v-divider>
                    <v-stepper-step class="mt-1 mb-1 pt-0 pb-0" :complete="statusPercent(item.status) >= 50" step="2">
                      <div>処理中</div>
                    </v-stepper-step>
                    <v-divider class="mt-4" color="accent"></v-divider>
                    <v-stepper-step class="mt-1 mb-1 pt-0 pb-0" :complete="statusPercent(item.status) >= 100" step="3">
                      <div>処理完了</div>
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </v-container>
              <v-row dense>
                <v-col cols="6">状態 :</v-col>
                <v-col>{{ item.status }}</v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">処理件数 :</v-col>
                <v-col>{{ "53,578" }}</v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">ジョブ開始日時 :</v-col>
                <v-col>{{ dateReplace(item.start_datetime) }}</v-col>
              </v-row>
              <v-row v-if="item.status === '処理完了'" dense>
                <v-col cols="6">ジョブ終了日時 :</v-col>
                <v-col>{{ dateReplace(item.end_datetime) }}</v-col>
              </v-row>
              <v-row v-if="item.status !== '処理完了'" dense>
                <v-col cols="6">予定ジョブ終了日時 :</v-col>
                <v-col>2021-05-21 18:01:37</v-col>
              </v-row>
              <v-row v-if="item.status === '処理完了'" dense>
                <v-col cols="6">処理時間 :</v-col>
                <v-col>{{ "00:41:22" }}</v-col>
              </v-row>
              <v-row v-if="item.status !== '処理完了'" dense>
                <v-col cols="6">予定処理時間 :</v-col>
                <v-col>00:50:24</v-col>
              </v-row>
            </span>
          </v-tooltip>
          -->
          {{ item.status }}
        </template>
        <template v-slot:[`item.start_datetime`]="{ item }">
          {{ dateReplace(item.start_datetime) }}
        </template>
        <template v-slot:[`item.end_datetime`]="{ item }">
          {{ dateReplace(item.end_datetime) }}
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <template v-if="item.status === '処理完了'">
            <v-tooltip color="tooltip" top>
              <template v-slot:activator="{ on }">
                <v-btn
                    id="button_faq_result"
                    v-on="on"
                    color="primary"
                    small
                    @click="
                      createFaqName = item.name;
                      documentId = item.document_id;
                      filename = item.document_name;
                      groupId = item.id;
                      faqResultDialog = true;
                    "
                >
                  <v-icon>mdi-file-find</v-icon>
                </v-btn>
              </template>
              <span>結果確認</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
      <v-dialog v-model="faqResultDialog">
        <FAQResult :dialog.sync="faqResultDialog" :groupId="groupId" :documentName="filename" :documentId="documentId" :createFaqName="createFaqName"/>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import AlertArea from "../../parts/AlertArea.vue";
import NormalTextArea from "../../parts/NormalTextArea.vue";
import NumberTextArea from "../../parts/NumberTextArea.vue";
import CalendarYYYYMMDD from "../../parts/CalendarYYYYMMDD.vue";
import SelectField from "../../parts/SelectField.vue";
import ClosedSearchFormDisp from "../../parts/ClosedSearchFormDisp.vue";
import ClosedSearchFormFromToDisp from "../../parts/ClosedSearchFormFromToDisp.vue";
import NewFAQCandidateBtnForDocScreen from "../../parts/NewFAQCandidateBtnForDocScreen";
import FAQResult from "./FAQResult.vue"
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import StringUtils from "../../js/StringUtils";
import ValidationUtils from "../../js/ValidationUtils";
import Messages from "../../js/Messages";

export default {
  name: "FAQCreateResultMgt",
  components: {
    AlertArea,
    NormalTextArea,
    NumberTextArea,
    CalendarYYYYMMDD,
    SelectField,
    ClosedSearchFormDisp,
    ClosedSearchFormFromToDisp,
    FAQResult,
    NewFAQCandidateBtnForDocScreen
  },
  methods: {

    /**
     * 日付書式を変更する。
     */
    dateReplace: function (item) {
      return StringUtils.dateReplace(item);
    },

    // TODO 処理状況を伝えるUIに使うファンクション
    /**
     * status を数値に変換する。
     */
    // statusPercent: function (status) {
    //   let ret = 0;
    //   if (status == null) {
    //     return ret;
    //   }
    //   switch (status) {
    //     case '未処理':
    //       ret = 0;
    //       break;
    //     case '処理中':
    //       ret = 50;
    //       break;
    //     case '処理完了':
    //       ret = 100;
    //       break;
    //     default:
    //       ret = 0;
    //       break;
    //   }
    //   return ret;
    // },

    /**
     * 検索フォームをすべてリセットする。
     */
    searchConditionClear() {
      this.searchCondition.name = null;
      this.searchCondition.faq = null;
      this.searchCondition.documentFile = null;
      this.searchCondition.selectedStatus = null;
      this.searchCondition.jobStartDateFrom = null;
      this.searchCondition.jobStartDateTo = null;
      this.searchCondition.jobEndDateFrom = null;
      this.searchCondition.jobEndDateTo = null;
      this.searchCondition.numberFrom = null;
      this.searchCondition.numberTo = null;
    },

    /**
     * 解析結果（Faq生成結果）のリストを取得、セットする。
     */
    async search() {
      this.logDebug("### search() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["search_form"])) {
        this.logDebug("### バリデーションNG search() 終了");
        return;
      }

      // ローディング開始
      this.searchLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "name", this.searchCondition.name);
        ObjectUtils.objectPush(qParams, "document_name", this.searchCondition.documentFile);
        ObjectUtils.objectPush(qParams, "status", this.searchCondition.selectedStatus);
        ObjectUtils.dateObjectPush(qParams, "start_datetime_from", this.searchCondition.jobStartDateFrom);
        ObjectUtils.dateObjectPush(qParams, "start_datetime_to", this.searchCondition.jobStartDateTo);
        ObjectUtils.dateObjectPush(qParams, "end_datetime_from", this.searchCondition.jobEndDateFrom);
        ObjectUtils.dateObjectPush(qParams, "end_datetime_to", this.searchCondition.jobEndDateTo);
        ObjectUtils.objectPush(qParams, "faq_count_min", this.searchCondition.numberFrom);
        ObjectUtils.objectPush(qParams, "faq_count_max", this.searchCondition.numberTo);
        ObjectUtils.objectPush(qParams, "limits", parseInt(this.faqResultOptions.itemsPerPage));
        ObjectUtils.objectPush(qParams, "page", parseInt(this.faqResultOptions.page));

        // APIリクエスト（R27）
        this.logDebug("#### R27 リクエスト");
        const response = await ApiUtils.get("/faq_from_document/faq", qParams);

        if (200 === response.status) {
          this.logDebug("#### R27 成功");
          this.faqDataList = response.data.data;
          this.total = response.data.counts;
        } else {
          this.logDebug("#### R27 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r27.warning;
        }
      } catch (error) {
        this.logDebug("#### search() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r27.error;
      } finally {
        // ローディング終了
        this.searchLoading = false;
      }
      this.logDebug("### search() 終了");
    },

    /**
     * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
     * @param message サクセスメッセージ
     */
    showSuccess: ObjectUtils.showSuccess,

    /**
     * 子コンポーネントからワーニングを表示したいときに呼び出す。
     * @param message ワーニングメッセージ
     */
    showWarning: ObjectUtils.showWarning,

    /**
     * 子コンポーネントからエラーを表示したいときに呼び出す。
     * @param message エラーメッセージ
     */
    showError: ObjectUtils.showError,

    /**
     * alerts を初期化する。
     */
    alertsInitByEmit: ObjectUtils.alertsInitByEmit,
  },
  data: () => ({
    documentId: null,
    filename: null,
    createFaqName: null,
    groupId: null,
    faqResultDialog: false,
    searchLoading: false,
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    processingSelects: ["処理待ち", "処理中", "完了"],
    searchCondition: {
      name: null,
      documentFile: null,
      faq: null,
      selectedStatus: null,
      jobStartDateFrom: null,
      jobStartDateTo: null,
      jobEndDateFrom: null,
      jobEndDateTo: null,
      numberFrom: null,
      numberTo: null,
    },
    faqResultOptions: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ["id"],
      sortDesc: [false],
    },
    total: 0,
    faqDataHeaders: [
      {align: "start", text: "名称", value: "name"},
      {align: "start", text: "文書データ名", value: "document_name"},
      {align: "start", text: "状態", value: "status", width: "90"},
      {align: "start", text: "ジョブ開始日時", value: "start_datetime"},
      {align: "start", text: "ジョブ終了日時", value: "end_datetime"},
      {align: "end", text: "件数", value: "faq_count"},
      {align: "start", text: "操作", value: "operation", width: "80"},
    ],
    faqDataList: [],
  }),
  mounted: function () {
    // 画面表示のタイミングで検索を実行
    this.search();
  },
};
</script>
