<template>
  <v-text-field
      v-model="inputData"
      :label="label"
      :value="value"
      auto-grow
      clearable
      row-height="15"
      rows="1"
  ></v-text-field>
</template>

<script>
export default {
  name: 'NormalTextArea',
  props: ['value', 'label'],
  // 変更を検知したら親に値の更新を依頼する
  computed: {
    inputData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      }
    },
  }
};
</script>