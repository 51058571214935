/**
 * vuetifyのvalidate()を呼び出す。
 * @param refs v-form の ref
 * @return true:バリデーションOK, false:バリデーションNG
 **/
function valid(refs) {
    return refs.validate();
}

/**
 * vuetifyのreset()を呼び出す。
 * v-form内すべての入力を消去し、バリデーション・エラーの状態をリセットする。
 * @param refs v-form の ref
 **/
function reset(refs) {
    refs.reset();
}

export default {
    valid, reset
}