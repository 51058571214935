import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './route'
import store from './store/store'
import cognito from './cognito'
import filters from './components/js/Filters.js'
import logger from "@/mixins/logger";
import polling from "@/mixins/polling";
import pdf from './plugins/vue-pdf.js'

Vue.config.productionTip = false

// mixinに登録
Vue.mixin(logger)
Vue.mixin(polling)

// filters.jsをすべてインポートして、すべてのコンポーネントで使用可能にする
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

// Webストレージにユーザ情報がある場合読み込む
if (sessionStorage.getItem('KnowledgeGenerationApp')) {
    const storage = JSON.parse(sessionStorage.getItem('KnowledgeGenerationApp'));
    if (storage.user.name) {
        store.dispatch('user/setUsername', storage.user.username)
        store.dispatch('user/setName', storage.user.name)
        store.dispatch('user/setToken', storage.user.token)
        store.dispatch('user/setRefreshToken', storage.user.refreshToken)
        store.dispatch('user/setTenant', storage.user.tenant)
        store.dispatch('user/setIsLogin', storage.user.isLogin)
    }
}

// 定期的なセッション更新処理を稼働
polling.methods.refreshSession()

new Vue({
    router,
    vuetify,
    pdf,
    store,
    cognito,
    render: h => h(App)
}).$mount('#app')
