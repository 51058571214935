<template>
  <v-row
      v-show="(fromValue !== null && fromValue !== '') || (toValue !== null && toValue !== '') "
      no-gutters
      style="width: 100%">
    <v-col cols="12">
      <div>
        {{ label }} : {{ fromValue || '未設定' }} ～ {{ toValue || '未設定' }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['fromValue', 'toValue', 'label']
};
</script>