/**
 * NODE_ENV にセットされた値を返す。
 */
function getNodeEnv() {
    return process.env.NODE_ENV;
}

/**
 * VUE_APP_API_BASE_URL にセットされた値を返す。
 */
function getApiBaseUrl() {
    return process.env.VUE_APP_API_BASE_URL;
}

/**
 * VUE_APP_API_LOG_LEVEL にセットされた値を返す。
 * @return 'DEBUG','INFO','WARNING','SEVERE', 数値 を想定
 */
function getEnvLogLevel() {
    return process.env.VUE_APP_API_LOG_LEVEL;
}

export default {
    getNodeEnv,
    getApiBaseUrl,
    getEnvLogLevel,
}