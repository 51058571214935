<template>

  <!-- フッター -->
  <v-footer app
            color="indigo darken-2"
            dark
            dense>
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="6">
          <div class="caption text-center">
            <a href="https://www.nri.com/jp/" target="_blank">NRI トップページ</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.nri.com/jp/site/security" target="_blank">個人情報保護方針</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.nri.com/jp/site/privacy" target="_blank">個人情報の取り扱いについて</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.nri.com/jp/site/right" target="_blank">利用規約</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.nri.com/jp/site/feedback" target="_blank">お問い合わせ</a>
          </div>
          <div class="caption text-center">
            Copyright(c) Nomura Research Institute, Ltd. All rights reserved.
          </div>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </v-footer>

</template>

<script>
export default {
  data: () => ({}),
};
</script>
