<template>
  <v-card>
    <v-card-title>同義語生成</v-card-title>

    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- 同義語のテーブル -->
    <v-container>
      <v-data-table
          v-model="synonymSelected"
          :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
        }"
          :headers="synonymHeaders"
          :items="synonymItems"
          :loading="synonymLoading"
          :options.sync="options"
          calculate-widths
          class="elevation-1 row-pointer"
          dense
          disable-sort
          item-key="rank"
          show-select
          @click:row="rowClickEvent"
      >
        <template v-slot:[`item.synonyms`]="{ item }">
          {{ showSynonyms(item.synonyms) }}
        </template>
      </v-data-table>
    </v-container>

    <!-- レコードを押下した時に開かれる、同義語を選択するダイアログ -->
    <v-dialog v-model="similarWordsDialog" width="1000">
      <v-card>
        <v-container>
          <v-card-title>単語：{{ similarWordsTitle }}</v-card-title>
          <v-container>
            <v-data-table
                v-model="similarWordsSelected"
                :footer-props="{
                'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
              }"
                :headers="similarWordsHeaders"
                :items="similarWords"
                :loading="similarWordsLoading"
                :options.sync="optionsDialog"
                calculate-widths
                class="elevation-1"
                dense
                disable-sort
                item-key="spelling"
                show-select
            ></v-data-table>
          </v-container>
          <v-row justify="center">
            <v-card-actions class="mb-1">
              <v-btn
                  class="ma-6"
                  color="primary"
                  @click="similarWordsDialog = false"
              >キャンセル
              </v-btn>
              <v-btn
                  id="synonym_edit_button_similar_words_dialog_ok"
                  class="ma-6"
                  color="secondary"
                  @click="similarWordsDialogOK()"
              >OK
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- 閉じるボタン、ファイル出力ボタン -->
    <v-container>
      <v-row justify="center">
        <v-card-actions class="mt-n4">
          <v-btn
              id="synonym_edit_button_close"
              class="ma-6"
              color="primary"
              @click="closeDialog()"
          >
            閉じる
          </v-btn>
          <v-btn
              id="synonym_edit_button_file_output"
              class="ma-6"
              color="primary"
              @click="downloadCSV()"
          >
            <v-icon>mdi-file-export-outline</v-icon>
            ファイル出力
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import StringUtils from "../../js/StringUtils";
import AlertArea from "../../parts/AlertArea.vue";
import Messages from "../../js/Messages";
import FileUtils from "@/components/js/FileUtils";

export default {
  name: "SynonymEdit",
  components: {
    AlertArea,
  },
  props: {
    project: Object, // プロジェクトデータに単語分散表現データを紐付けたオブジェクト
    dialog: Boolean, // 親のダイアログフラグ
  },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 20,
    },
    optionsDialog: {
      page: 1,
      itemsPerPage: 20,
    },
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    searchConditions: {
      word: null,
      countFrom: null,
      countTo: null,
    },
    synonymSelected: [],
    synonymHeaders: [
      {align: "start", text: "単語", value: "spelling", width:"30%"},
      {align: "end", text: "出現数", value: "count", width:"120"},
      {align: "start", text: "同義語", value: "synonyms"},
    ],
    synonymItems: [],
    synonymLoading: false,
    selectingSynonymItem: null,
    similarWordsDialog: false,
    similarWordsSelected: [],
    similarWordsTitle: null,
    similarWordsHeaders: [
      {align: "start", text: "単語", value: "spelling"},
      {align: "end", text: "類似度スコア", value: "score_for_view"},
    ],
    similarWords: [],
    similarWordsLoading: false,
  }),
  methods: {
    /**
     * 選択した単語で類似単語検索をし、同義語編集のダイアログを表示する。
     */
    async rowClickEvent(item) {
      this.logDebug("## rowClickEvent() 開始");

      // ローディング開始
      this.similarWordsLoading = true;

      // データリセット
      this.similarWords = [];

      // 類似単語画面のタイトルを設定
      this.similarWordsTitle = item.spelling;

      // 選択した同義語の情報を類語画面に引き継ぐ
      this.selectingSynonymItem = item;

      // ダイアログを表示
      this.similarWordsDialog = true;

      // 類似単語を取得・設定する
      await this.getSimilarWords(item);

      // 類似単語ですでにチェックを入れているものがあれば選択状態にしておく
      this.similarWordsSelected = item.synonyms;

      // ローディング終了
      this.similarWordsLoading = false;
      this.logDebug("## rowClickEvent() 終了");
    },

    /**
     * 親にダイアログをクローズしてもらう。
     */
    closeDialog() {
      this.$emit("update:dialog", false);
    },

    /**
     * 単語ランキングの分析結果を取得する。
     **/
    async getWordRanking() {
      this.logDebug("### getWordRanking() 開始");
      try {

        // クエリパラメータを設定
        let body = {};
        ObjectUtils.objectPush(body, "limits", 1000);

        // APIリクエスト（R541）
        this.logDebug("#### R541 リクエスト");
        let response = await ApiUtils.post(
            "/synonym/words/" + this.project.project_key,
            body
        );

        if (200 === response.status) {
          this.logDebug("#### R541 成功");
          let tempItem = [];
          response.data.data.forEach((item) => {
            tempItem.push(item);
          });
          this.synonymItems = tempItem;
        } else {
          this.logDebug("#### R541 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r541.warning;
        }
      } catch (error) {
        this.logDebug("#### getWordRanking() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r541.error;
      }
      this.logDebug("### getWordRanking() 終了");
    },

    /**
     * 類似単語を取得する。
     **/
    async getSimilarWords(item) {
      this.logDebug("### getSimilarWords() 開始");
      try {

        // クエリパラメータを設定
        let body = {};
        ObjectUtils.objectPush(body, "spelling", item.spelling);
        ObjectUtils.objectPush(body, "limits", 1000);

        // APIリクエスト（R556）
        this.logDebug("#### R556 リクエスト");
        let response = await ApiUtils.post(
            "/synonym/word_embeddings/" +
            this.project.word_embedding.word_embedding_key +
            "/search",
            body
        );

        if (200 === response.status) {
          this.logDebug("#### R556 成功");
          let wordEmbeddings = response.data.data;
          for (let i = 0; i < wordEmbeddings.length; i++) {

            // スコアの値は、表示用に小数点以下桁数を揃えて、文字列として持たせる
            wordEmbeddings[i].score_for_view = StringUtils.formatNumber(wordEmbeddings[i].score)
          }
          this.similarWords = wordEmbeddings;
        } else if (400 === response.status) {
          this.logDebug("#### R556 データなし");
          this.similarWords = [];
          this.similarWordsSelected = [];
        } else {
          this.logDebug("#### R556 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r556.warning;
        }
      } catch (error) {
        this.logDebug("#### getSimilarWords() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r556.error;
      }
      this.logDebug("### getSimilarWords() 終了");
    },

    /**
     * CSVファイルを出力してダウンロードする。
     * CSVはファイルの先頭にBOMとしてを'\ufeff'を追加している。
     * 出力対象はsynonymSelectedとしている。
     */
    downloadCSV() {
      this.logDebug("### downloadCSV() 開始");
      if (this.synonymSelected.length === 0) {
        this.logDebug("### 未選択 downloadCSV() 終了");
        alert(Messages.synonymFileOutput.error);
        return;
      }

      // FileUtils.downLoadCSV()で適切に出力されるようにデータを複製
      FileUtils.duplicateJsonKey(this.synonymSelected, "spelling", "単語");
      FileUtils.duplicateJsonKey(this.synonymSelected, "count", "出現数");

      // 同義語は画面に表示している値でCSV出力したいため独自実装
      this.synonymSelected.forEach((synonym) => {
        synonym["同義語"] = this.showSynonyms(synonym["synonyms"]);
      });

      // CSVに出力するカラムを定義
      const fields = ["単語", "出現数", "同義語"];
      const filename = "同義語データ.csv";
      FileUtils.downLoadCSV(fields, this.synonymSelected, filename);
      this.logDebug("### downloadCSV() 終了");
    },

    /**
     * 画面を描画する際の初期処理。
     */
    async init() {
      this.logDebug("## init() 開始");

      // ローディング開始
      this.synonymLoading = true;

      // データリセット
      this.synonymItems = [];

      // アラートエリアを非表示にする
      ObjectUtils.alertsInit(this.alerts);

      // 単語ランキングを取得
      await this.getWordRanking();

      // ローディング終了
      this.synonymLoading = false;
      this.logDebug("## init() 終了");
    },

    /**
     * 引数の配列(synonyms)のspellingを"，"で連結した文字列を返却する。
     */
    showSynonyms: function (synonyms) {
      let result = "";

      // 選択されていない場合は空文字を返却
      if (synonyms == null || synonyms.length === 0) {
        return result;
      }

      // ， で同義語を連結
      synonyms.forEach((synonym) => {
        result += synonym.spelling;
        result += "，";
      });

      // 最後の ， を削除
      result = result.slice(0, -1);
      return result;
    },

    /**
     * 類似単語選択画面でOKボタンが押下された際の処理。
     * synonymItemsに選択された類似単語を追加する。
     */
    similarWordsDialogOK() {
      let addWords = [];

      // 何も選択されていなければ初期化
      if (this.similarWordsSelected == null) {
        this.selectingSynonymItem["synonyms"] = addWords;
        this.similarWordsDialog = false;
        return;
      }

      this.similarWordsSelected.forEach((similarWord) => {
        addWords.push(similarWord);
      });
      this.selectingSynonymItem["synonyms"] = addWords;
      this.similarWordsDialog = false;
    },
  },
  mounted: function () {
    this.logDebug("# SynonymEdit.vue mounted");
    this.init();
  },
  watch: {
    project: function () {
      this.logDebug("# watch project：" + JSON.stringify(this.project));

      // projectを監視。projectに変更があったら初期処理を呼び出す
      this.init();
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>