<template>
  <span>
    <v-tooltip :disabled="isCreateFAQResultScreen" color="tooltip" top>
      <template v-slot:activator="{ on }">
        <v-btn
            id="button_get_select_fields"
            v-on="on"
            :class="buttonClass"
            :loading="selectFieldsLoading"
            :small="isSmall"
            color="secondary"
            @click="getSelectFields()"
        >
          <v-icon>mdi-chart-box-plus-outline</v-icon>
          <div v-if="isCreateFAQResultScreen">
            新規FAQ候補生成
          </div>
        </v-btn>
      </template>
      <span>新規FAQ候補生成</span>
    </v-tooltip>

    <!-- 新規FAQ候補生成ダイアログ -->
    <v-dialog v-model="createFaqDialog" max-width="600">
      <v-card>
        <v-form ref="create_form" @submit.prevent="createFaqRequest()">
          <v-card-title>新規FAQ候補生成</v-card-title>
          <v-container>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredTextArea
                    :disabled="createFaqLoading"
                    :value.sync="createFaqData.name"
                    label="名称"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredSelectField
                    :disabled="isDocumentScreen || createFaqLoading"
                    :status="documentFileSelects"
                    :value.sync="createFaqData.documentId"
                    label="文書データ"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-6" color="primary" @click="createFaqDialog = false"
            >キャンセル</v-btn
            >
            <v-btn
                id="button_create_request"
                :loading="createFaqLoading"
                class="ma-6"
                color="secondary"
                @click="createFaqRequest()"
            >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import ObjectUtils from "@/components/js/ObjectUtils";
import ApiUtils from "@/components/js/ApiUtils";
import Messages from "@/components/js/Messages";
import RequiredTextArea from "@/components/parts/RequiredTextArea.vue";
import RequiredSelectField from "@/components/parts/RequiredSelectField.vue";
import ValidationUtils from "@/components/js/ValidationUtils";
import StringUtils from "@/components/js/StringUtils";

export default {
  name: 'NewFAQCandidateBtnForDocScreen',
  props: [
    'selectDocument', // 選択されたドキュメントのデータ
    'isDocumentScreen', // このコンポーネントを表示する画面が文書データ登録画面ならtrue
    'isCreateFAQResultScreen', // このコンポーネントを表示する画面がFAQ候補生成画面ならtrue
    'isSmall', // ボタンのサイズを小さくする場合はtrue
    'buttonClass', // ボタンのclass
  ],
  components: {
    RequiredTextArea,
    RequiredSelectField,
  },
  methods: {

    /**
     * プルダウン項目を取得するファンクション。
     * FAQ候補生成画面で表示する場合、ドキュメントのリストはAPIから取得する。
     * 文書データ登録画面で表示する場合、ドキュメントはpropsの値から取得する。
     */
    async getSelectFields() {
      this.logDebug("### getSelectFields() 開始");
      try {
        this.selectFieldsLoading = true;

        // 名称を入力
        const date = StringUtils.getNowDate();
        this.createFaqData.name = "FAQ候補 " + date;

        // 文書データ登録画面で表示する場合は、ドキュメントプルダウンにpropsの値をセット
        if (this.selectDocument) {

          // 選択された値としてセット
          let tempObj = {};
          tempObj["label"] = this.selectDocument.name;
          tempObj["value"] = this.selectDocument.id;
          this.createFaqData.documentId = tempObj;

          // プルダウン項目をセット
          let tempSelects = [];
          tempSelects.push(tempObj);
          this.documentFileSelects = tempSelects;
          this.createFaqDialog = true;
        }

        // FAQ候補生成画面で表示する場合はAPIで応対履歴プルダウンにの値をAPIから取得する
        if (this.isCreateFAQResultScreen) {

          // APIを呼び出して応対履歴プルダウンをセット
          // クエリパラメータを設定
          let qParams = {};
          ObjectUtils.objectPush(qParams, "limits", 10000);
          ObjectUtils.objectPush(qParams, "page", 1);

          // APIをリクエスト（R16）
          // プルダウンに表示するために取得するため絞り込み条件なし
          this.logDebug("#### R16 リクエスト");
          const response = await ApiUtils.get(
              "/faq_from_document/document",
              qParams
          );

          // レスポンス処理
          if (200 === response.status) {
            this.logDebug("#### R16 成功");

            // プルダウン初期化
            let tempDocumentFileSelects = [];

            // レスポンスのデータをプルダウンにセット
            response.data.data.forEach(function (val) {

              // 準備完了になっているレコードのみプルダウンにセット
              if (val.status === "準備完了") {
                let tempObj = {};
                tempObj["label"] = val.name;
                tempObj["value"] = val.id;
                tempDocumentFileSelects.push(tempObj);
              }
            });
            this.documentFileSelects = tempDocumentFileSelects;
            this.createFaqDialog = true;
          } else {
            this.logDebug("#### R16 失敗 レスポンス：");
            this.logDebug(response);
            this.$emit("alertsInitByEmit");
            this.$emit("showWarning", Messages.r16.warning);
            this.createFaqDialog = false;
          }
        }
        this.logDebug("#### ドキュメントプルダウン：" + JSON.stringify(this.documentFileSelects))
      } catch (error) {
        this.logDebug("#### getSelectFields() 例外発生");
        this.logDebug(error);
        this.$emit("alertsInitByEmit");
        this.$emit("showError", Messages.r16.error);
        this.createFaqDialog = false;
      } finally {
        this.selectFieldsLoading = false;
      }
      this.logDebug("### getSelectFields() 終了");
    },

    /**
     * 解析ジョブ情報（選択したドキュメント情報）を
     * 登録、解析ジョブを開始するAPIにリクエストする。
     **/
    async createFaqRequest() {
      this.logDebug("### createFaqRequest() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["create_form"])) {
        this.logDebug("### バリデーションNG createFaqRequest() 終了");
        return;
      }

      this.$emit("alertsInitByEmit");
      this.createFaqLoading = true;
      try {

        // bodyを設定
        let body = {};
        ObjectUtils.objectPush(body, "name", this.createFaqData.name);
        ObjectUtils.objectPush(body, "document_id", this.createFaqData.documentId.value);

        // APIリクエスト（R25）
        const response = await ApiUtils.post(
            "/faq_from_document/document/" + this.createFaqData.documentId.value + "/start",
            body,
        );
        if (200 === response.status) {
          this.logDebug("#### R25 成功");

          // 成功メッセージを表示
          let reps = [this.createFaqData.name, this.createFaqData.documentId.label];
          this.$emit("showSuccess", StringUtils.messageReplace(Messages.r25.success, reps));

          // 入力フォームをリセット
          ValidationUtils.reset(this.$refs["create_form"])

          // 検索処理を呼び出し
          this.$emit("search")
        } else {
          this.logDebug("#### R25 失敗 レスポンス：");
          this.logDebug(response);
          this.$emit("showWarning", Messages.r25.warning);
        }
      } catch (error) {
        this.logDebug("#### createFaqRequest() 例外発生");
        this.logDebug(error);
        this.$emit("showError", Messages.r25.error);
      } finally {
        this.createFaqLoading = false;
        this.createFaqDialog = false;
      }
      this.logDebug("### createFaqRequest() 終了");
    },
  },
  data: () => ({
    createFaqDialog: false,
    selectFieldsLoading: false,
    createFaqData: {
      name: null,
      documentId: null,
    },
    documentFileSelects: [],
    faqSelects: [],
    createFaqLoading: false,
  }),
};
</script>