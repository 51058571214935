<template>
  <v-main>
    <!-- タイトル -->
    <Title title="AI 教師データ生成"/>

    <!-- タブ -->
    <CreateAiTeacherDataTab/>

  </v-main>
</template>

<script>
import Title from '../parts/Title.vue';
import CreateAiTeacherDataTab from '../tabs/CreateAiTeacherDataTab.vue';

export default {
  name: 'CreateAiTeacherData',
  components: {
    Title,
    CreateAiTeacherDataTab
  },
  data: () => ({}),
};
</script>

