/**
 * objに対してkey&valueを追加する。
 * valueがnullの場合は何もしない。
 **/
function objectPush(obj, key, value) {
    if (value === null || value === "") {
        return;
    }
    obj[key] = value;
}

/**
 * objに対してkey&dateを追加する。
 * dateがnullの場合は何もしない。
 * dateを整形する場合がある場合、コメントアウトしている箇所で整形する。
 * 現在の実装はobjectPush()と完全に同じだが、将来的に日付の書式が変わった際に
 * 対応しやすいようにこのメソッドを残している。
 **/
function dateObjectPush(obj, key, date) {
    if (date === null || date === "") {
        return;
    }
    // const dt = new Date(date);
    // const isoStr = dt.toISOString().split('Z')[0] + '+09:00';
    obj[key] = date;
}

/**
 * objに対してkey&valueを追加する。
 * valueがnullまたは数値型以外の場合は何もしない。
 * valueは100で割った値をセットする。
 **/
function percentObjectPush(obj, key, value) {
    if (value === null || value === "" || isNaN(value)) {
        return;
    }
    obj[key] = value / 100;
}

/**
 * 引数の alerts を初期化する。
 * $emit()で使いたい場合は alertsInitByEmit() を使用する。
 */
function alertsInit(alerts) {
    if (alerts == null) {
        return;
    }
    if (alerts.info != null) {
        alerts.info.isShow = false;
        alerts.info.message = "";
    }
    if (alerts.success != null) {
        alerts.success.isShow = false;
        alerts.success.message = "";
    }
    if (alerts.warning != null) {
        alerts.warning.isShow = false;
        alerts.warning.message = "";
    }
    if (alerts.error != null) {
        alerts.error.isShow = false;
        alerts.error.message = "";
    }
}

/**
 * alerts を初期化する。$emit()で呼び出す用。
 */
function alertsInitByEmit() {
    if (this.alerts == null) {
        return;
    }
    if (this.alerts.info != null) {
        this.alerts.info.isShow = false;
        this.alerts.info.message = "";
    }
    if (this.alerts.success != null) {
        this.alerts.success.isShow = false;
        this.alerts.success.message = "";
    }
    if (this.alerts.warning != null) {
        this.alerts.warning.isShow = false;
        this.alerts.warning.message = "";
    }
    if (this.alerts.error != null) {
        this.alerts.error.isShow = false;
        this.alerts.error.message = "";
    }
}

/**
 * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
 * @param message サクセスメッセージ
 */
function showSuccess(message) {
    this.alerts.success.isShow = true;
    this.alerts.success.message = message;
}

/**
 * 子コンポーネントからワーニングを表示したいときに呼び出す。
 * @param message ワーニングメッセージ
 */
function showWarning(message) {
    this.alerts.warning.isShow = true;
    this.alerts.warning.message = message;
}

/**
 * 子コンポーネントからエラーを表示したいときに呼び出す。
 * @param message エラーメッセージ
 */
function showError(message) {
    this.alerts.error.isShow = true;
    this.alerts.error.message = message;
}

export default {
    objectPush,
    dateObjectPush,
    percentObjectPush,
    alertsInit,
    alertsInitByEmit,
    showSuccess,
    showWarning,
    showError,
}