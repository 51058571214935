<template>
  <v-container>
    <v-row justify="center">
      <h1>{{ title }}</h1>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["title"],
};
</script>