<template>
  <v-card>

    <!-- 閉じるボタン -->
    <v-btn id="button_close" icon
           @click="closeDialog()">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <!-- アラートエリア -->
    <v-container class="mt-n5 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- タイトル -->
    <v-container class="mt-n5 mb-n4">
      <v-row>
        <v-card-title>名称：{{ createFaqName }}</v-card-title>
      </v-row>
      <!-- 応対履歴データセットダウンロードダイアログボタン -->
      <v-row class="ml-1" dense style="color:rgba(0, 0, 0, 0.7);">
        ・応対履歴データ：{{ caseLogName }}
        <v-tooltip color="tooltip" top>
          <template v-slot:activator="{ on }">
            <v-btn
                id="case_log_dialog_button"
                v-on="on"
                class="mt-n1 ma-3"
                color="primary"
                small
                @click="caseLogDialog=true"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>ダウンロード</span>
        </v-tooltip>
        <v-card-actions class="mt-n7">
          <!-- 応対履歴選択ダウンロードダイアログ -->
          <v-dialog v-model="caseLogDialog" width="700">
            <v-card>
              <v-container>
                <v-card-title>応対履歴データの選択</v-card-title>
                <v-flex>
                  <DownloadResponseHistory :datasetGroupId="datasetGroupId"/>
                  <v-row justify="center" class="mt-7">
                    <v-btn
                        id="case_log_dialog_ok_button"
                        class="ma-3"
                        color="primary"
                        @click="caseLogDialog=false"
                    >閉じる
                    </v-btn>
                  </v-row>
                  <br/>
                </v-flex>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-row>
      <!-- FAQダウンロードボタン -->
      <v-row class="ml-1" dense style="color:rgba(0, 0, 0, 0.7);">
        ・FAQデータ：{{ faqName }}
        <v-tooltip color="tooltip" top>
          <template v-slot:activator="{ on }">
            <v-btn
                id="faq_download_button"
                v-on="on"
                :loading="downloadLoading"
                class="mt-n1 ma-3"
                color="primary"
                small
                @click="download()"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>ダウンロード</span>
        </v-tooltip>
      </v-row>
    </v-container>

    <!-- 教師データリスト -->
    <v-container>
      <v-data-table
          v-model="outputTargets"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500, 1000],
          }"
          :headers="createFAQCandidateListHeaders"
          :items="createFAQCandidateList"
          :loading="getCreateFAQCandidateListLoading"
          :options.sync="options"
          :server-items-length="total"
          calculate-widths
          class="elevation-1 row-pointer mb-6"
          dense
          disable-sort
          item-key="cluster_no"
          show-select
          @click:row="rowClickEvent"
          @update:items-per-page="getCreateFAQCandidateList()"
          @update:page="getCreateFAQCandidateList()">
      </v-data-table>
    </v-container>

    <!-- ファイル出力ボタン -->
    <v-container class="mt-n9">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn id="button_download" class="ma-3" color="primary" @click="downloadCSV">
          <v-icon>mdi-file-export-outline</v-icon>
          ファイル出力
        </v-btn>
      </v-row>
    </v-container>
    <v-dialog v-model="createFAQCandidateDetail">
      <CreateFAQCandidateResultDetail
          :cluster_no="detailDialogData.cluster_no"
          :cluster_size="detailDialogData.cluster_size"
          :dialog.sync="createFAQCandidateDetail"
          :jobId="jobId"
          :question="detailDialogData.question"/>
    </v-dialog>
  </v-card>
</template>

<script>

import AlertArea from "../../parts/AlertArea.vue";
import CreateFAQCandidateResultDetail from "./CreateFAQCandidateResultDetail.vue";
import DownloadResponseHistory from "./DownloadResponseHistory.vue";
import ObjectUtils from "../../js/ObjectUtils";
import ApiUtils from "../../js/ApiUtils";
import Messages from "../../js/Messages";
import FileUtils from "../../js/FileUtils";

export default {
  name: "CreateFAQCandidateResult",
  components: {
    AlertArea,
    CreateFAQCandidateResultDetail,
    DownloadResponseHistory
  },
  props: {
    jobId: Number, //ジョブの ID
    dialog: Boolean, // 大元のダイアログフラグ
    groupId: Number, // AI 教師データ生成ジョブの ID
    datasetGroupId: Number, // 応対履歴データセットの ID
    faqGroupId: Number, // FAQ ファイルの ID
    caseLogName: String, // 応対履歴の名称
    faqName: String, // FAQの名称
    createFaqName: String, // FAQ候補の名称
  },
  data: () => ({
    caseLogDialog: false,
    downloadLoading: false,
    createFAQCandidateDetail: false,
    getCreateFAQCandidateListLoading: false,
    total: 0,
    options: {
      page: 1,
      itemsPerPage: 20,
    },
    detailDialogData: {
      job_id: null,
      cluster_no: null,
      question: "",
      cluster_size: null
    },
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    createFAQCandidateListHeaders: [
      {align: "end", text: "クラスタ番号", value: "cluster_no"},
      {align: "end", text: "代表応対履歴番号", value: "case_no"},
      {align: "start", text: "代表質問文", value: "question"},
      {align: "start", text: "代表回答文", value: "answer"},
      {align: "end", text: "クラスタサイズ", value: "cluster_size"},
    ],
    createFAQCandidateList: [],
    outputTargets: []
  }),

  methods: {

    /**
     * 行をクリックした際の処理。
     * 選択された行のデータを引き継ぎ、ダイアログを表示する。
     **/
    rowClickEvent(item) {
      this.detailDialogData = item;
      this.createFAQCandidateDetail = true;
    },

    /**
     * FAQ候補生成の詳細リストを取得。
     **/
    async getCreateFAQCandidateList() {
      this.logDebug("### getCreateFAQCandidateList() 開始");

      // ローディング開始
      this.getCreateFAQCandidateListLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "limits", this.options.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.options.page);

        // APIリクエスト（R37）
        this.logDebug("#### R37 リクエスト");
        const response = await ApiUtils.get(
            "/faq_from_case_log/job/" + this.jobId + "/summary",
            qParams
        );
        if (200 === response.status) {
          this.logDebug("#### R37 成功");
          this.createFAQCandidateList = response.data.data;
          this.total = response.data.counts;
        } else {
          this.logDebug("#### R37 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r37.warning;
        }
      } catch (error) {
        this.logDebug("#### getCreateFAQCandidateList() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r37.error;
      } finally {

        // ローディング終了
        this.getCreateFAQCandidateListLoading = false;
      }
      this.logDebug("### getCreateFAQCandidateList() 終了");
    },

    /**
     * CSVファイルを出力してダウンロードする。
     * 出力対象はoutputTargets。
     */
    downloadCSV() {
      this.logDebug("### downloadCSV() 開始");
      if (this.outputTargets[0] == null) {
        this.logDebug("### 未選択 downloadCSV() 終了");
        alert(Messages.faqCandidateResultFileOutput.error);
        return;
      }

      // FileUtils.downLoadCSV()で適切に出力されるようにデータを複製
      FileUtils.duplicateJsonKey(this.outputTargets, "cluster_no", "クラスタ番号");
      FileUtils.duplicateJsonKey(this.outputTargets, "case_no", "代表応対履歴番号");
      FileUtils.duplicateJsonKey(this.outputTargets, "question", "代表質問文");
      FileUtils.duplicateJsonKey(this.outputTargets, "answer", "代表回答文");
      FileUtils.duplicateJsonKey(this.outputTargets, "cluster_size", "クラスタサイズ");

      // CSVに出力するカラムを定義
      const fields = ["クラスタ番号", "代表応対履歴番号", "代表質問文", "代表回答文", "クラスタサイズ"];
      const filename = "FAQ候補生成結果.csv";
      FileUtils.downLoadCSV(fields, this.outputTargets, filename);
      this.logDebug("### downloadCSV() 終了");
    },

    /**
     * 親にダイアログをクローズしてもらう。
     */
    closeDialog() {
      this.$emit("update:dialog", false);
    },

    /**
     * 画面を描画する際の初期処理。
     * （初回描画、jobIdが切り替わったタイミングなど）
     */
    async init() {
      this.logDebug("## init() 開始");

      // ローディング開始
      this.getCreateFAQCandidateListLoading = true;

      // 変数初期化
      this.outputTargets = [];
      this.createFAQCandidateList = [];
      this.total = 0;
      this.options.itemsPerPage = 20;
      this.options.page = 1;
      ObjectUtils.alertsInit(this.alerts);

      // FAQ候補生成詳細を取得
      await this.getCreateFAQCandidateList();

      //ローディング終了
      this.getCreateFAQCandidateListLoading = false;
      this.logDebug("## init() 終了");
    },

    /**
     * FAQダウンロードボタン押下時の処理。
     * 生成結果の元ファイルをダウンロードする。
     * 一度検索してレスポンスからs3_pathを取得している。
     */
    async download() {
      this.logDebug("### download() 開始");
      try {
        this.downloadLoading = true;

        // リスト情報を取得して、s3_pathを取得する
        let searchResponse = null;
        // APIリクエスト（R6）
        searchResponse = await this.getFaq();
        if (!searchResponse.is_success) {
          return
        }

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "s3_path", searchResponse.path);

        // APIリクエスト（R2）
        this.logDebug("#### R2 リクエスト");
        const response = await ApiUtils.get("/common/download_url", qParams);

        if (200 === response.status) {
          window.location.href = response.data.download_url;
        } else {
          this.logDebug("#### R2 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r2.warning;
        }
      } catch (error) {
        this.logDebug("#### download() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r2.error;
      } finally {
        this.downloadLoading = false;
        this.logDebug("### download() 終了");
      }
    },

    /**
     * R6をリクエストして、FAQの情報を取得する。
     * 取得結果をそのまま呼び出し元に返却する。
     **/
    async getFaq() {
      this.logDebug("### getFaq() 開始");
      let ret = {
        is_success: false,
        path: null,
      };

      // クエリパラメータを設定
      let qParams = {};
      ObjectUtils.objectPush(qParams, "name", this.faqName);

      try {
        // APIリクエスト（R6）
        this.logDebug("#### R6 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/faq",
            qParams
        );
        if (200 === response.status) {
          const data = response.data.data.find(data => data.id === this.faqGroupId);
          if (!data) {
            this.logDebug("#### getFaq():元ファイル削除済み");
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.download.warning;
            return ret;
          }
          ret = {
            is_success: true,
            path: data.s3_path,
          }
          return ret;
        } else {
          this.logDebug("#### R6 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r6.warning;
          return ret;
        }
      } catch (error) {
        this.logDebug("#### getFaq() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r6.error;
        return ret;
      } finally {
        this.logDebug("### getFaq() 終了");
      }
    },
  },
  mounted: function () {
    this.logDebug("# CreateFAQCandidateResult.vue mounted");
    this.init();
  },

  watch: {
    jobId: function () {
      this.logDebug("# watch jobId：" + this.jobId);

      // jobIdを監視。jobIdに変更があったら初期処理を呼び出す
      this.init();
    }
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
