<template>
  <v-text-field
      v-model="inputData"
      :label="label"
      :rules="[required]"
      :value="value"
      auto-grow
      clearable
      row-height="15"
      rows="1"
      v-bind:disabled="disabled"
  ></v-text-field>
</template>

<script>
export default {
  name: 'RequiredTextArea',
  props: ['value', 'label', 'disabled'],
  methods: {
    required: value => !!value || ""
  },
  // 変更を検知したら親に値の更新を依頼する
  computed: {
    inputData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      }
    },
  }
};
</script>