import {AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoRefreshToken} from 'amazon-cognito-identity-js'
import {Config} from 'aws-sdk'
import logger from '@/mixins/logger';

/**
 * Amazon Cognito ユーティリティクラス
 * 参考： https://qiita.com/daikiojm/items/b02c19cfea6766c308ca
 */
export default class Cognito {
    static install = (Vue, options) => {
        Object.defineProperty(Vue.prototype, '$cognito', {
            get() {
                return this.$root._cognito
            }
        })

        Vue.mixin({
            beforeCreate() {
                if (this.$options.cognito) {
                    this._cognito = this.$options.cognito
                    this._cognito.configure(options)
                }
            }
        })
    }

    configure(config) {
        if (config.userPool) {
            this.userPool = config.userPool
        } else {
            this.userPool = new CognitoUserPool({
                UserPoolId: config.UserPoolId,
                ClientId: config.ClientId
            })
        }
        Config.region = config.region
        //Config.credentials = new CognitoIdentityCredentials({
        //  IdentityPoolId: config.IdentityPoolId
        //})
        this.options = config
    }

    /**
     * ログイン
     * @param username ユーザID
     * @param password パスワード
     * @returns authenticateUser function のレスポンスデータ
     */
    login(username, password) {
        const userData = {Username: username, Pool: this.userPool}
        this.cognitoUser = new CognitoUser(userData)
        const authenticationData = {Username: username, Password: password}
        const authenticationDetails = new AuthenticationDetails(authenticationData)
        return new Promise((resolve, reject) => {
            this.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => { // ログイン成功
                    logger.methods.logDebug('#### login onSuccess result')
                    logger.methods.logDebug(result)

                    const response = result
                    response.status = "CONFIRMED" // 初期パスワード更新済みであることを示す値を追加で設定
                    resolve(response)
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => { // ログイン成功したが、初期パスワードの変更が必要
                    if (userAttributes) {
                        logger.methods.logDebug('#### login newPasswordRequired userAttributes')
                        logger.methods.logDebug(userAttributes)
                    }
                    if (requiredAttributes) {
                        logger.methods.logDebug('#### login newPasswordRequired requiredAttributes')
                        logger.methods.logDebug(requiredAttributes)
                    }

                    const response = userAttributes
                    response.username = authenticationData.Username // username もレスポンスに設定
                    response.status = "FORCE_CHANGE_PASSWORD" // 初期パスワード更新が必要であることを示す値を追加で設定
                    resolve(response)
                },
                onFailure: (err) => { // ログイン失敗
                    logger.methods.logDebug('#### login onFailure err')
                    logger.methods.logDebug(err)
                    reject(err)
                }
            })
        })
    }

    /**
     * ログアウト
     */
    logout() {
        if (this.cognitoUser) {
            this.cognitoUser.signOut()
        }
    }

    /**
     * ログインしているかどうかを判定する
     * @returns ログイン状態
     */
    isAuthenticated() {
        return new Promise((resolve, reject) => {
            if (!this.cognitoUser) {
                reject({"status": "NOT_AUTHENTECATED"})
            }

            this.cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err)
                } else {
                    if (!session.isValid()) {
                        reject(session)
                    } else {
                        resolve(session)
                    }
                }
            })
        })
    }

    /**
     * パスワードのリセットをリクエストする
     * @param username ユーザID
     * @returns forgotPassword function のレスポンスデータ
     */
    forgotPassword(username) {
        const userData = {Username: username, Pool: this.userPool}
        const cognitoUser = new CognitoUser(userData)
        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: (result) => { // forgotPassword 成功
                    logger.methods.logDebug('#### forgotPassword onSuccess result')
                    logger.methods.logDebug(result)
                    resolve(result)
                },
                onFailure: (err) => { // forgotPassword 失敗
                    logger.methods.logDebug('#### forgotPassword onFailure err')
                    logger.methods.logDebug(err)
                    reject(err)
                }
            })
        })
    }

    /**
     * パスワードリセット用の確認コードとあわせて、パスワードをリセット（変更）する
     * @param username ユーザID
     * @param newPassword 新しいパスワード
     * @param confirmationCode パスワードリセット用確認コード
     * @returns confirmPassword function のレスポンスデータ
     */
    confirmForgotPassword(username, newPassword, confirmationCode) {
        const userData = {Username: username, Pool: this.userPool}
        const cognitoUser = new CognitoUser(userData)
        return new Promise((resolve, reject) => {

            if (this.is_similar_to_username(username, newPassword)) {
                reject({"status": "PASSWORD_IS_SIMILAR_TO_USERNAME"}) // ID とパスワードが似ている
                return
            }

            cognitoUser.confirmPassword(confirmationCode, newPassword, {
                onSuccess: (result) => { // confirmPassword 成功
                    logger.methods.logDebug('#### confirmPassword onSuccess result')
                    logger.methods.logDebug(result)
                    resolve(result)
                },
                onFailure: (err) => { // confirmPassword 失敗
                    logger.methods.logDebug('#### confirmPassword onFailure err')
                    logger.methods.logDebug(err)
                    reject(err)
                }
            })
        })
    }

    /**
     * 初回ログイン後に、初期パスワードの更新を行う
     * @param newPassword 新しいパスワード
     * @returns completeNewPasswordChallenge function のレスポンスデータ
     */
    updateNewPassword(newPassword) {
        return new Promise((resolve, reject) => {
            if (!this.cognitoUser) {
                reject({"status": "NOT_AUTHENTECATED"}) // 認証の有効期限切れなど
                return
            }

            if (this.is_similar_to_username(this.cognitoUser.username, newPassword)) {
                reject({"status": "PASSWORD_IS_SIMILAR_TO_USERNAME"}) // ID とパスワードが似ている
                return
            }

            this.cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: (result) => { // completeNewPasswordChallenge 成功
                    logger.methods.logDebug('#### completeNewPasswordChallenge onSuccess result')
                    logger.methods.logDebug(result)
                    resolve(result)
                },
                onFailure: (err) => { // completeNewPasswordChallenge 失敗
                    logger.methods.logDebug('#### completeNewPasswordChallenge onFailure err')
                    logger.methods.logDebug(err)
                    reject(err)
                }
            }, {})
        })
    }

    /**
     * 自身のパスワードを変更する
     * @param oldPassword 古いパスワード
     * @param newPassword 新しいパスワード
     * @returns changePassword function のレスポンスデータ
     */
    changePassword(oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            if (!this.cognitoUser) {
                reject({"status": "NOT_AUTHENTECATED"}) // 認証の有効期限切れなど
                return
            }

            if (this.is_similar_to_username(this.cognitoUser.username, newPassword)) {
                reject({"status": "PASSWORD_IS_SIMILAR_TO_USERNAME"}) // ID とパスワードが似ている
                return
            }

            this.cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
                if (!err) { // changePassword 成功
                    logger.methods.logDebug('#### changePassword onSuccess result')
                    resolve(result)
                } else { // changePassword 失敗
                    logger.methods.logDebug('#### changePassword onFailure err')
                    logger.methods.logDebug(err)
                    reject(err)
                }
            })
        })
    }

    /**
     * ID とパスワードが似ているかどうかをチェックする
     * @param {*} username 
     * @param {*} password 
     */
    is_similar_to_username(username, password) {
        let username_lower = username.toLowerCase()
        let password_lower = password.toLowerCase()

        if (username_lower.includes(password_lower)) {
            return true
        }
        if (password_lower.includes(username_lower)) {
            return true
        }

        return false
    }

    /**
     * セッションを更新する
     * @param username ユーザID
     * @param refreshToken リフレッシュトークン
     * @returns 新しいセッションから取得した ID トークン、リフレッシュトークン
     */
    refreshSession(username, refreshToken) {
        return new Promise((resolve, reject) => {
            logger.methods.logDebug('#### refreshSession username: ' + username)
            const userData = {Username: username, Pool: this.userPool}
            this.cognitoUser = new CognitoUser(userData)

            const token = new CognitoRefreshToken({ RefreshToken: refreshToken })
            this.cognitoUser.refreshSession(token, (err, session) => {
                if (!err) { // リフレッシュ成功
                    resolve({"status": "SUCCESS", "idToken": session.getIdToken().getJwtToken(), "refreshToken": session.getRefreshToken().getToken()})
                } else {
                    logger.methods.logDebug('#### refreshSession. NOT_AUTHENTECATED')
                    logger.methods.logDebug(err)
                    reject({"status": "NOT_AUTHENTECATED"}) // 認証の有効期限切れなど          
                }
            })
        })
    }
}