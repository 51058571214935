<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- 検索フォーム -->
    <v-container>
      <!-- 開閉パネル -->
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4"><h4>検索入力フォーム</h4></v-col>
              <v-col class="text--secondary" cols="8">
                <v-fade-transition leave-absolute>
                  <!-- 検索フォームを開いているときのメッセージ -->
                  <span v-if="open">検索条件を入力してください</span>
                  <!-- 検索フォームを閉じているときのメッセージ -->
                  <v-row v-else no-gutters style="width: 100%">
                    <!-- 設定された検索条件を表示 -->
                    <ClosedSearchFormDisp
                        :value="searchCondition.name"
                        label="文書データ名"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.filename"
                        label="ファイル名"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.registerDateFrom"
                        :toValue="searchCondition.registerDateTo"
                        label="登録日時"
                    />
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- 名称 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">文書データ名 :</v-col>
              <v-col cols="6">
                <NormalTextArea
                    :value.sync="searchCondition.name"
                    label=""
                />
              </v-col>
            </v-row>

            <!-- ファイル名 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ファイル名 :</v-col>
              <v-col cols="6">
                <NormalTextArea
                    :value.sync="searchCondition.filename"
                    label=""
                />
              </v-col>
            </v-row>

            <!-- 登録日時 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0,0,0,0.6);">登録日時 :</v-col>
              <v-col cols="3">
                <CalendarYYYYMMDD
                    v-model="searchCondition.registerDateFrom"
                    label=""
                />
              </v-col>
              <div class="mt-6">～</div>
              <v-col cols="3">
                <CalendarYYYYMMDD
                    v-model="searchCondition.registerDateTo"
                    label=""
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <br/>
      <v-row>
        <v-btn
            id="new_register"
            :loading="getRootDocumentsLoading"
            class="ma-3"
            color="secondary"
            @click.stop="
              parentDocumentId = null;
              getRootDocuments();
            "
        >
          <v-icon>mdi-text-box-plus-outline</v-icon>
          文書データの新規登録
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            id="button_get_document_data_list"
            :loading="searchLoading"
            class="ma-3"
            color="primary"
            @click="options.page = 1; search()"
        >
          <v-icon>mdi-magnify</v-icon>
          検索
        </v-btn>
        <v-btn
            id="button_search_condition_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>
        <v-dialog v-model="registerDialog" max-width="600">
          <v-card>
            <v-form ref="register_form" @submit.prevent="newRegister()">
              <v-card-title>文書データの新規登録</v-card-title>
              <v-container>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="newRegisterLoading"
                        :value.sync="newData.name"
                        label="文書データ名"/>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <v-file-input
                        v-model="tempDocumentFile"
                        :rules="[fileRules]"
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.html,.htm"
                        label="文書データファイルを指定"
                        show-size
                        v-bind:disabled="newRegisterLoading"
                        @change="getFile"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <p class="text-caption grey--text text--darken-2">
                      ※ PDF、Word、Excel、PowerPoint、HTMLの文書データファイルを指定してください。<br />
                      ※ 個人情報が含まれたファイル、ウイルス感染等不正なファイルをアップロードされないようご注意ください。
                    </p>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10" v-if="parentDocumentId == null">
                    <SelectField
                        :disabled="newRegisterLoading"
                        :status="rootDocumentIdAndNames"
                        :value.sync="newData.parentDocument"
                        label="親の文書データ（バージョン管理する場合に指定）"
                    />
                  </v-col>
                  <v-col cols="10" v-if="parentDocumentId != null">
                    <RequiredSelectField
                        :disabled="true"
                        :status="rootDocumentIdAndNames"
                        :value.sync="newData.parentDocument"
                        label="親の文書データ"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-n2 mb-n7" dense justify="center">
                  <v-checkbox
                      v-model="checkSynonym"
                      label="同義語候補を作成する"
                      v-bind:disabled="newRegisterLoading"
                  ></v-checkbox>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-6"
                    color="primary"
                    @click="registerDialog = false"
                >キャンセル
                </v-btn
                >
                <v-btn
                    id="button_new_register"
                    :loading="newRegisterLoading"
                    class="ma-6"
                    color="secondary"
                    @click="newRegister()"
                >OK
                </v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
          }"
          :headers="documentListHeaders"
          :items="documentList"
          :loading="searchLoading"
          :options.sync="options"
          :server-items-length="total"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          @update:items-per-page="search()"
          @update:page="search()"
      >
        <template v-slot:[`item.filename`]="{ item }">
          <!-- テーブル内の、「ファイル名」の値の部分を、ファイルダウンロードリンクに設定 -->
          <!--
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="download_button"
                  v-on="on"
                  :loading="downloadLoading[item.id]"
                  color="secondary"
                  text
                  style="text-transform:None"
                  class="text-decoration-underline"
                  @click="download(item.s3_path, item.id, false)"
              >
                {{ item.filename }}
              </v-btn>
            </template>
            <span>ダウンロード</span>
          </v-tooltip>
          -->
          <a title="ダウンロード" v-on:click.stop="download(item.s3_path, item.id, false)">
            {{ item.filename }}
          </a>
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <span v-show="item.status === '準備完了'">
            <v-tooltip color="tooltip" top>
              <template v-slot:activator="{ on }">
                <v-btn
                    id="document_edit_button"
                    v-on="on"
                    :loading="editLoading[item.id]"
                    color="secondary"
                    small
                    @click="wizardInit(item.id, item.name, item.filename, item.s3_path)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>編集</span>
            </v-tooltip>
            <NewFAQCandidateBtnForDocScreen
                :buttonClass="''"
                :isCreateFAQResultScreen="false"
                :isDocumentScreen="true"
                :isSmall="true"
                :selectDocument="item"
                @alertsInitByEmit="alertsInitByEmit"
                @search="()=>{}"
                @showError="showError"
                @showSuccess="showSuccess"
                @showWarning="showWarning"
            />
          </span>
          <span v-show="item.status !== '準備完了'">
            <InvisibleButton/>
            <InvisibleButton/>
          </span>
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="history_button"
                  v-on="on"
                  :loading="historyLoading[item.id]"
                  color="primary"
                  small
                  @click="
                    parentDocumentId = item.parent_document_id;
                    getDocumentHistory();
                  "
              >
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </template>
            <span>更新履歴</span>
          </v-tooltip>
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  color="warning"
                  small
                  @click="
                    deleteId = item.id;
                    deleteDialog = true;
                  "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>削除</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- ウィザード形式のダイアログ -->
      <v-dialog v-model="editDialog" no-click-animation persistent>
        <v-stepper v-model="step" vertical>

          <!-- ウィザード STEP1 -->
          <v-stepper-step :complete="step > 1" step="1">
            文分割テキストの編集
            <small>テキストの編集を行ってください</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-container>
              <v-textarea
                  v-model="textEditAreaValue"
                  dense
                  label="テキストファイル名"
                  name="textEditArea"
                  rows="20"
                  solo
              ></v-textarea>
            </v-container>
            <v-container>
              <v-btn class="mt-n5" color="primary" @click="editDialog = false">
                キャンセル
              </v-btn>
              <v-btn id="wizard_step_one_next"
                     :loading="step1NextLoading"
                     class="mt-n5 ml-6"
                     color="secondary"
                     @click="wizardStep1Next()">
                <v-icon>mdi-chevron-down</v-icon>
                次へ
              </v-btn>
              <v-btn id="txt_file_output"
                     class="mt-n5 ml-6"
                     color="primary"
                     @click="downloadTXT()">
                <v-icon>mdi-file-export-outline</v-icon>
                ファイル出力
              </v-btn>
              <v-btn id="full_screen_edit_button"
                     class="mt-n5 ml-6"
                     color="primary"
                     @click="fullScreenEditInit()">
                <v-icon>mdi-pencil-box</v-icon>
                全画面編集
              </v-btn>
            </v-container>
          </v-stepper-content>

          <!-- ウィザード STEP2 -->
          <v-stepper-step :complete="step > 2" step="2">
            見出し行設定
            <small>ドラッグ＆ドロップで順番を入れ替えることができます</small>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-container>
              <div v-if="headlinePatternOkLoading">
                見出し行設定データ取得中...
                <v-progress-circular
                    color="primary"
                    indeterminate
                ></v-progress-circular>
              </div>
              <v-simple-table :items="headlineSettings">
                <template v-slot:default>
                  <tbody is="draggable" v-model="headlineSettings" tag="tbody">
                  <tr v-for="headline in headlineSettings" :key="headline.title_pattern_id">
                    <td width="70">
                      <v-simple-checkbox
                          v-model="headline.isSelected"
                          :ripple="false"/>
                    </td>
                    <td>
                      <v-edit-dialog>
                        {{ headline.title_pattern_name }}

                        <!-- 見出しをクリックした際の処理 -->
                        <template v-slot:input>
                          <v-layout class="mt-3 mb-3" style="height: 40vh">
                            <v-layout style="border: solid 1px #d3d3d3">
                              <v-flex style="overflow: visible scroll">
                                <div style="white-space: pre-wrap">{{ showDataSample(headline.data_sample) }}</div>
                              </v-flex>
                            </v-layout>
                          </v-layout>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>（{{ headline.matched_row_count }}件）</td>
                    <td v-if="headline.is_custom == false" width="210">
                      <NormalInvisibleButton/>
                      <NormalInvisibleButton/>
                    </td>
                    <td v-else-if="headline.is_custom == true" width="210">
                      <v-btn id="edit_headline_pattern_dialog_button"
                             color="primary"
                             @click="editHeadlinePatternDialogButton(headline.title_pattern_id), editHeadlineTitle = headline.title_pattern_name">
                        <v-icon>mdi-pencil</v-icon>
                        編集
                      </v-btn>
                      <v-btn id="delete_headline_pattern_dialog_button" color="warning"
                             @click="editHeadlineDeleteDialogButton(headline.title_pattern_id)">
                        <v-icon>mdi-delete</v-icon>
                        削除
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
            <v-container>
              <v-btn
                  id="headline_pattern_dialog_button"
                  :disabled="headlinePatternRegisterLoading"
                  color="primary"
                  @click="headlinePatternDialogButton()">
                <v-icon>mdi-plus</v-icon>
                見出しパターン追加
              </v-btn>
              <v-dialog v-model="headlinePatternDialog" max-width="70%" no-click-animation persistent>
                <v-form ref="add_Headline_Pattern_Form">
                  <v-card>
                    <v-card-title>見出しパターン追加</v-card-title>
                    <v-container class="mt-n2 mb-n4">
                      <v-row justify="center">
                        <v-col cols="9">
                          <AlertArea :isShow.sync="dialogAlerts.warning.isShow" :message="dialogAlerts.warning.message"
                                     type="warning"/>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container>
                      <v-row justify="center">
                        <v-col cols="9">
                          <RequiredTextArea
                              :disabled="headlinePatternTestLoading || headlinePatternOkLoading"
                              :value.sync="headlineTitle"
                              label="見出しパターン名"
                          />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="9">
                          <RequiredTextArea
                              :disabled="headlinePatternTestLoading || headlinePatternOkLoading"
                              :value.sync="headlineRule"
                              label="見出しルール（正規表現）"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container>
                      <v-row justify="center">
                        <v-col cols="9">
                          <v-textarea
                              :value="headlinePatternTestResult"
                              auto-grow
                              dense
                              disabled
                              label=""
                              name="headlinePatternEdit"
                              solo
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container>
                      <v-row class="mt-n7 mb-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            id="headline_pattern_cancel_button"
                            :disabled="headlinePatternOkLoading"
                            class="ma-6"
                            color="primary"
                            @click="headlinePatternCancel()"
                        >キャンセル
                        </v-btn>
                        <v-btn
                            id="headline_pattern_test_button"
                            :disabled="headlinePatternOkLoading"
                            :loading="headlinePatternTestLoading"
                            class="ma-6"
                            color="primary"
                            @click="headlinePatternTest('new')"
                        >テスト
                        </v-btn>
                        <v-btn
                            id="headline_pattern_ok_button"
                            :disabled="headlinePatternOkButton"
                            :loading="headlinePatternOkLoading"
                            class="ma-6"
                            color="secondary"
                            @click="headlinePatternOk()"
                        >OK
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-dialog>
            </v-container>

            <v-container>
              <v-btn
                  :disabled="headlinePatternRegisterLoading"
                  color="primary"
                  @click="step = 1">戻る
              </v-btn>
              <v-btn
                  id="headline_pattern_register_button"
                  :loading="headlinePatternRegisterLoading"
                  class="ml-6"
                  color="secondary"
                  @click="wizardStep2Execute()">
                <v-icon>mdi-check</v-icon>
                実行
              </v-btn>
            </v-container>
          </v-stepper-content>

          <v-stepper-step :complete="step > 3" step="3"> 完了</v-stepper-step>
          <v-stepper-content step="3">
            <v-sheet class="px-4 pt-4 pb-3" height="90px">
              文分割テキストの編集と、見出し行設定が完了しました。
            </v-sheet>
            <v-btn
                color="primary"
                @click="
                  step = 4;
                  editDialog = false;
                "
            >
              閉じる
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-dialog>

      <!-- 文書データの更新履歴ダイアログ -->
      <v-dialog v-model="historyDialog">
        <v-card>
          <v-card-title>文書データ更新履歴</v-card-title>
          <v-container>
            <v-row>
              <!-- 文書データ 新規登録ボタン（文書データの更新履歴ダイアログからの新規登録）-->
              <v-btn
                  id="history_new_register"
                  :loading="getRootDocumentsLoading"
                  class="ma-3"
                  color="secondary"
                  @click.stop="
                    newData.parentDocument = { 'value': parentDocumentId };
                    getRootDocumentFromHistory()
                  "
              >
                <v-icon>mdi-text-box-plus-outline</v-icon>
                文書データの新規登録
              </v-btn>
              <v-spacer></v-spacer>
              <!-- 文書データ更新履歴のリストを更新するボタン -->
              <v-btn
                  id="button_get_document_data_list"
                  :loading="searchLoading"
                  class="ma-3"
                  color="primary"
                  @click="options.page = 1; search()"
              >
                <v-icon>mdi-restart</v-icon>
                更新
              </v-btn>
            </v-row>
          </v-container>
          <v-container>
              <!-- 文書データ更新履歴のリストを表示するテーブル -->
              <v-data-table
                  :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
                  }"
                  :headers="documentHistoryListHeaders"
                  :items="documentHistoryList"
                  :loading="getDocumentHistoryLoading"
                  :options.sync="historyOptions"
                  :server-items-length="historyTotal"
                  calculate-widths
                  class="elevation-1"
                  dense
                  disable-sort
                  item-key="id"
                  @update:items-per-page="getDocumentHistory()"
                  @update:page="getDocumentHistory()"
              >
                <template v-slot:[`item.filename`]="{ item }">
                  <!-- 更新履歴テーブル内の、「ファイル名」の値の部分を、ファイルダウンロードリンクに設定 -->
                  <!--
                  <v-tooltip color="tooltip" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          id="download_button"
                          v-on="on"
                          :loading="historyDownloadLoading[item.id]"
                          color="secondary"
                          text
                          style="text-transform:None"
                          class="text-decoration-underline"
                          @click="downloadFromHistory(item.s3_path, item.id, false)"
                      >
                        {{ item.filename }}
                      </v-btn>
                    </template>
                    <span>ダウンロード</span>
                  </v-tooltip>
                  -->
                  <a title="ダウンロード" v-on:click.stop="downloadFromHistory(item.s3_path, item.id, false)">
                    {{ item.filename }}
                  </a>
                </template>
                <template v-slot:[`item.operation`]="{ item }">
                  <span v-show="item.status === '準備完了'">
                    <!-- 文書データ編集ボタン（文書データ更新履歴のリストからの編集実行） -->
                    <v-tooltip color="tooltip" top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            id="document_edit_button"
                            v-on="on"
                            :loading="historyEditLoading[item.id]"
                            color="secondary"
                            small
                            @click="wizardInit(item.id, item.name, item.filename, item.s3_path)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>編集</span>
                    </v-tooltip>
                    <!-- 新規 FAQ 候補生成ボタン（文書データ更新履歴のリストからの FAQ 生成実行） -->
                    <NewFAQCandidateBtnForDocScreen
                        :buttonClass="''"
                        :isCreateFAQResultScreen="false"
                        :isDocumentScreen="true"
                        :isSmall="true"
                        :selectDocument="item"
                        @alertsInitByEmit="alertsInitByEmit"
                        @search="()=>{}"
                        @showError="showError"
                        @showSuccess="showSuccess"
                        @showWarning="showWarning"
                    />
                  </span>
                  <span v-show="item.status !== '準備完了'">
                    <InvisibleButton/>
                    <InvisibleButton/>
                  </span>
                  <!-- 削除ボタン（文書データ更新履歴のリストからの削除実行） -->
                  <v-tooltip color="tooltip" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          v-on="on"
                          color="warning"
                          small
                          @click="
                            deleteId = item.id;
                            deleteDialog = true;
                          "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>削除</span>
                  </v-tooltip>
                </template>
              </v-data-table>
          </v-container>
          <v-container>
            <v-row justify="center">
              <!-- 文書データの更新履歴ダイアログを閉じるボタン -->
              <v-card-actions class="mt-n4 mb-2">
                <v-btn class="ma-6" color="primary" @click="historyDialog = false">
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- 削除ボタン押下時のダイアログ -->
      <v-dialog v-model="deleteDialog" max-width="600">
        <v-card>
          <v-card-title>この文書データを削除した場合、このデータを元に生成された FAQ 候補も削除されます。<b/>本当に削除しますか？</v-card-title>
          <v-container>
            <v-row justify="center">
              <v-card-actions class="mt-n4 mb-2">
                <v-btn
                    class="ma-6"
                    color="primary"
                    @click="deleteDialog = false"
                >キャンセル
                </v-btn>
                <v-btn
                    id="button_delete"
                    :loading="deleteLoading"
                    class="ma-6"
                    color="warning"
                    @click="deleteDoc()"
                >
                  <v-icon>mdi-delete</v-icon>
                  削除
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- 見出しパターンの編集ボタン押下時のダイアログ -->
      <v-dialog v-model="editHeadlinePatternDialog" max-width="70%" no-click-animation persistent>
        <v-form ref="edit_Headline_Pattern_Form">
          <v-card>
            <v-card-title>見出しパターン編集</v-card-title>
            <v-container class="mt-n2 mb-n4">
              <v-row justify="center">
                <v-col cols="9">
                  <AlertArea :isShow.sync="dialogAlerts.warning.isShow" :message="dialogAlerts.warning.message"
                             type="warning"/>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row justify="center">
                <v-col cols="9">
                  <RequiredTextArea
                      :disabled="headlinePatternTestLoading || headlinePatternOkLoading"
                      :value.sync="editHeadlineTitle"
                      label="見出しパターン名"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="9">
                  <RequiredTextArea
                      :disabled="headlinePatternTestLoading || headlinePatternOkLoading"
                      :value.sync="editHeadlineRule"
                      label="見出しルール（正規表現）"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row justify="center">
                <v-col cols="9">
                  <v-textarea
                      :value="editHeadlinePatternTestResult"
                      auto-grow
                      dense
                      disabled
                      label=""
                      name="headlinePatternEdit"
                      solo
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row class="mt-n10 mb-1">
                <v-spacer></v-spacer>
                <v-btn
                    id="edit_headline_pattern_cancel_button"
                    :disabled="headlinePatternOkLoading"
                    class="ma-6"
                    color="primary"
                    @click="editHeadlinePatternCancel()"
                >キャンセル
                </v-btn>
                <v-btn
                    id="edit_headline_pattern_test_button"
                    :disabled="headlinePatternOkLoading"
                    :loading="headlinePatternTestLoading"
                    class="ma-6"
                    color="primary"
                    @click="headlinePatternTest('edit')"
                >テスト
                </v-btn>
                <v-btn
                    id="edit_headline_pattern_ok_button"
                    :disabled="editHeadlinePatternOkButton"
                    :loading="headlinePatternOkLoading"
                    class="ma-6"
                    color="secondary"
                    @click="editHeadlinePatternOk()"
                >OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- 見出しパターンの削除ボタン押下時のダイアログ -->
      <v-dialog v-model="editHeadlineDeleteDialog" max-width="600">
        <v-card>
          <v-card-title>本当に削除しますか？</v-card-title>
          <v-container>
            <v-row justify="center">
              <v-card-actions class="mt-n4 mb-2">
                <v-btn
                    class="ma-6"
                    color="primary"
                    @click="editHeadlineDeleteDialog = false"
                >キャンセル
                </v-btn>
                <v-btn
                    id="headline_button_delete"
                    :loading="headlineDeleteLoading"
                    class="ma-6"
                    color="warning"
                    @click="deleteHeadlinePattern()"
                >
                  <v-icon>mdi-delete</v-icon>
                  削除
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- 文分割テキストの全画面編集ダイアログ -->
      <v-dialog v-model="textFullScreenEditDialog" fullscreen no-click-animation persistent>
        <v-card>
          <v-card-title>{{ this.textFilename }}</v-card-title>
          <v-row class="ma-0 pa-0" dense>
            <!-- AceEditor エリアの上 -->
            <v-col cols="4">
              <v-btn id="font_size_minus_button" class="ml-4" small @click="fontSizeMinus">
                <v-icon>mdi-magnify-minus-outline</v-icon>
              </v-btn>
              <v-btn id="font_size_plus_button" small @click="fontSizePlus">
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
            </v-col>
            <!-- pdf 表示エリアの上 -->
            <template v-if="isDocPreview">
              <v-col cols="3"></v-col>
              <v-col cols="2">
                <v-slider
                    v-model="pdfWidth"
                    class="align-self-stretch"
                    max="1000"
                    min="200"
                    step="1"
                ></v-slider>
              </v-col>
              <v-col v-if="!totalPageNum" class="mb-0 pa-1" cols="3">読み込み中...</v-col>
              <v-col v-else-if="totalPageNum > 0"  cols="3">
                {{ currentPageNum }} / {{ totalPageNum }}
                <v-btn
                    id="pdf_prev_button"
                    class="ml-3"
                    small
                    @click="prevPage"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn
                    id="pdf_next_button"
                    small
                    @click="nextPage"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="1"></v-col>
              <v-col class="ma-0 pa-0 mt-1" cols="6">
                {{docPreviewErrorMessage}}
              </v-col>
            </template>
          </v-row>

          <!-- エディターとpdfプレビューを表示 -->
          <template v-if="isDocPreview">
                <v-row class="ma-5 mt-n3 mb-n2" no-gutters>
                  <v-col v-if="isDocPreview">
                    <AceEditor
                        :content="textEditAreaValue"
                        :font_size="aceEditorFontSize"
                        :watching="editorInitWatch"
                        :height_vh="80"
                        @contentChanged="onChangeText"/>
                  </v-col>
                  <v-col>
                    <v-layout style="height: 80vh; border: solid 1px #d3d3d3">
                    <v-flex style="overflow: visible scroll;">
                    <v-card v-resize="onResize" :max-width="pdfWidth">
                      <pdf
                          :page="currentPageNum"
                          :src="pdfSrc"
                          @num-pages="totalPageNum = $event"
                      ></pdf>
                    </v-card>
                    </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
          </template>

          <!-- pdf以外ファイルはエディター表示のみ -->
          <template v-else>
            <v-row class="ma-5 mb-n1" dense>
              <AceEditor
                  :content="textEditAreaValue"
                  :font_size="aceEditorFontSize"
                  :watching="editorInitWatch"
                  :height_vh="80"
                  @contentChanged="onChangeText"/>
            </v-row>
          </template>

          <v-row class="ma-0">
            <v-btn
                id="full_screen_cancel_button"
                class="ma-6"
                color="primary"
                @click="fullScreenCancelButton()"
            >キャンセル
            </v-btn>
            <v-btn
                id="full_screen_save_button"
                class="ma-6"
                color="primary"
                @click="fullScreenSaveButton()"
            >編集を反映して閉じる
            </v-btn>
          </v-row>
        </v-card>
      </v-dialog>

    </v-container>
  </v-main>
</template>

<script>
import AceEditor from "../../parts/AceEditor.vue";
import NormalTextArea from "../../parts/NormalTextArea.vue";
import NormalInvisibleButton from "../../parts/NormalInvisibleButton.vue";
import RequiredTextArea from "../../parts/RequiredTextArea.vue";
import CalendarYYYYMMDD from "../../parts/CalendarYYYYMMDD.vue";
import draggable from "vuedraggable";
import ClosedSearchFormDisp from "../../parts/ClosedSearchFormDisp.vue";
import ClosedSearchFormFromToDisp from "../../parts/ClosedSearchFormFromToDisp.vue";
import NewFAQCandidateBtnForDocScreen from "../../parts/NewFAQCandidateBtnForDocScreen";
import InvisibleButton from "../../parts/InvisibleButton";
import AlertArea from "../../parts/AlertArea.vue";
import SelectField from "../../parts/SelectField";
import RequiredSelectField from "../../parts/RequiredSelectField";
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import StringUtils from "../../js/StringUtils";
import ValidationUtils from "../../js/ValidationUtils";
import Messages from "../../js/Messages";
import FileUtils from "../../js/FileUtils";
import getPdfUrl from "../../../plugins/vue-pdf.js";

export default {
  name: "DocumentMgt",
  components: {
    AceEditor,
    AlertArea,
    NormalTextArea,
    RequiredTextArea,
    CalendarYYYYMMDD,
    draggable,
    ClosedSearchFormDisp,
    ClosedSearchFormFromToDisp,
    NewFAQCandidateBtnForDocScreen,
    InvisibleButton,
    NormalInvisibleButton,
    SelectField,
    RequiredSelectField
  },
  watch: {
    'editHeadlineRule': function () {
      this.editHeadlinePatternOkButton = true; // 見出しパターン編集/見出しルールが変更されたときOKボタン非活性化
    },
    'headlineRule': function () {
      this.headlinePatternOkButton = true; // 見出しパターン追加/見出しルールが変更されたときOKボタン非活性化
    }
  },
  methods: {

    /**
     * rules に指定する。必須項目かつ拡張子をチェックするルール。チェック違反時は入力欄を赤色で表示（メッセージは表示しない）
     */
    fileRules: (value) =>
        (!!value && /.+\.(pdf|xls|xlsx|ppt|pptx|doc|docx|html|htm)/.test(value.name.toLowerCase())) || "",

    /**
     * 選択されたファイルの情報を保存する。
     * 名称にファイル名＋現在日付を入力する。
     */
    getFile(file) {

      // バツアイコンをクリックした際、fileがnullで連携されるため、その場合はなにも処理しない
      if (file == null) {
        return;
      }
      this.newData.filename = file.name;
      this.newData.file = file;

      // 名称入力
      const date = StringUtils.getNowDate();
      const name = StringUtils.removeExtension(file.name);
      this.newData.name = name + " " + date;
    },

    /**
     * pdfの1ページを戻る。
     */
    prevPage() {
      if (this.currentPageNum <= 1) {
        return
      }
      this.currentPageNum = this.currentPageNum - 1
    },

    /**
     * pdfの1ページを進める。
     */
    nextPage() {
      if (this.currentPageNum >= this.totalPageNum) {
        return
      }
      this.currentPageNum = this.currentPageNum + 1
    },

    /**
     * 入力リセットボタン押下時の処理。
     * 検索条件をすべてnullに書き換える。
     */
    searchConditionClear() {
      this.searchCondition.name = null;
      this.searchCondition.filename = null;
      this.searchCondition.registerDateFrom = null;
      this.searchCondition.registerDateTo = null;
    },

    /**
     * 検索ボタン押下時の処理。
     * R16をリクエストして、pdfの情報を取得する。
     */
    async search() {
      this.logDebug("### search() 開始");

      // ローディング開始
      this.searchLoading = true;
      try {

        // APIリクエスト（R16）
        const response = await this.getDocuments();

        if (200 === response.status) {
          this.logDebug("#### R16 成功");
          this.documentList = response.data.data;
          this.total = response.data.counts;

          // ダウンロードアイコンをローディング中にするための前処理
          let tempArray = {};
          response.data.data.forEach(data => {
            tempArray[data.id] = false;
          })
          this.downloadLoading = tempArray;
          this.editLoading = {...tempArray};
          this.historyLoading = {...tempArray};
        } else {
          this.logDebug("#### R16 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r16.warning;
        }
      } catch (error) {
        this.logDebug("#### search() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r16.error;
      } finally {
        // ローディング終了
        this.searchLoading = false;
      }
      this.logDebug("### search() 終了");
    },

    /**
     * 文書データ更新履歴のリストを取得する
     * 文書データの一覧から、更新履歴ボタンがクリックされたタイミングなどに呼び出される
     */
    async getDocumentHistory() {
      this.logDebug("### getDocumentHistory() 開始");

      // ローディング開始
      this.getDocumentHistoryLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "limits", this.historyOptions.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.historyOptions.page);

        // APIリクエスト（同じ親を持つ文書ファイルのリストを取得）
        this.logDebug("#### 「同じ親を持つ文書ファイルのリストを取得」API リクエスト");
        const response = await ApiUtils.get(
          "/faq_from_document/document/" + this.parentDocumentId + "/history",
          qParams
        );

        if (200 === response.status) {
          this.logDebug("#### 「同じ親を持つ文書ファイルのリストを取得」API 成功");
          this.documentHistoryList = response.data.data;
          this.historyTotal = response.data.counts;

          // ダウンロードアイコンをローディング中にするための前処理
          let tempArray = {};
          response.data.data.forEach(data => {
            tempArray[data.id] = false;
          })
          this.historyDownloadLoading = tempArray;
          this.historyEditLoading = {...tempArray};

          // レコード取得成功した場合、更新履歴のダイアログを表示する
          this.historyDialog = true;

        } else {
          this.logDebug("#### 「同じ親を持つ文書ファイルのリストを取得」API 失敗 レスポンス：");
          this.logDebug(response);
          this.historyDialog = false; // 更新履歴ダイアログを閉じる
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r16.warning;
        }
      } catch (error) {
        this.logDebug("#### getDocumentHistory() 例外発生");
        this.logDebug(error);
        this.historyDialog = false; // 更新履歴ダイアログを閉じる
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r16.error;
      } finally {
        // ローディング終了
        this.getDocumentHistoryLoading = false;
      }
      this.logDebug("### getDocumentHistory() 終了");
    },

    /**
     * 文書データのうち、いちばん親（ルート）の文書（id == parent_document_id のレコード）のリストを取得する
     * そのデータは、文書データの新規登録ダイアログのドロップダウンリストに指定する
     * 文書データの新規登録ボタンがクリックされたタイミングで呼び出される
     */
    async getRootDocuments() {
      this.logDebug("### getRootDocuments() 開始");

      // ローディング開始
      this.getRootDocumentsLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "limits", 100); // 100件のみに限定
        ObjectUtils.objectPush(qParams, "page", 1);

        // APIリクエスト（ルートの文書ファイルのリストを取得）
        this.logDebug("#### 「ルートの文書ファイルのリストを取得」API リクエスト");
        const response = await ApiUtils.get(
          "/faq_from_document/document/root",
          qParams
        );

        if (200 === response.status) {
          this.logDebug("#### 「ルートの文書ファイルのリストを取得」API 成功");

          // レスポンスのデータをプルダウンにセット
          this.rootDocumentIdAndNames = [];
          // 先頭データ
          let tempObj = {};
          let tempList = [];
          tempObj["label"] = "指定なし";
          tempObj["value"] = null;
          tempList.push(tempObj);
          response.data.data.forEach(function (val) {
            tempObj = {};
            tempObj["label"] = val.name;
            tempObj["value"] = val.id;
            tempList.push(tempObj);
          });
          this.rootDocumentIdAndNames = tempList;

          // レコード取得成功した場合、文書データの新規登録ダイアログを表示する
          this.registerDialog = true;

        } else {
          this.logDebug("#### 「ルートの文書ファイルのリストを取得」API 失敗 レスポンス：");
          this.logDebug(response);
          this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r16.warning;
        }
      } catch (error) {
        this.logDebug("#### getRootDocuments() 例外発生");
        this.logDebug(error);
        this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r16.error;
      } finally {
        // ローディング終了
        this.getRootDocumentsLoading = false;
      }
      this.logDebug("### getRootDocuments() 終了");
    },

    /**
     * 文書データ更新履歴ダイアログで表示されている文書データのうち、いちばん親（ルート）の文書データを取得する
     * そのデータは、文書データの新規登録ダイアログのドロップダウンリストに指定する
     * 文書データ更新履歴ダイアログから文書データの新規登録ボタンがクリックされたタイミングで呼び出される
     */
    async getRootDocumentFromHistory() {
      this.logDebug("### getRootDocumentFromHistory() 開始");

      // ローディング開始
      this.getRootDocumentsLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "parent_document_id", this.parentDocumentId);
        ObjectUtils.objectPush(qParams, "limits", 1); // 1件のみに限定
        ObjectUtils.objectPush(qParams, "page", 1);

        // APIリクエスト（ルートの文書ファイルのリストを取得）
        this.logDebug("#### 「ルートの文書ファイルのリストを取得」API リクエスト");
        const response = await ApiUtils.get(
          "/faq_from_document/document/root",
          qParams
        );

        if (200 === response.status) {
          this.logDebug("#### 「ルートの文書ファイルのリストを取得」API 成功");

          if (response.data.counts <= 0) { // レコードが 1件も取得できなかった場合
            this.logDebug("#### 「ルートの文書ファイルのリストを取得」API 失敗 レスポンス：");
            this.logDebug(response);
            this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
            ObjectUtils.alertsInit(this.alerts);
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.r16.warning;
          }

          // レスポンスのデータをプルダウンにセット（1件だけの想定）
          this.rootDocumentIdAndNames = [];
          let tempList = [];
          response.data.data.forEach(function (val) {
            let tempObj = {};
            tempObj["label"] = val.name;
            tempObj["value"] = val.id;
            tempList.push(tempObj);
          });
          this.rootDocumentIdAndNames = tempList;

          // レコード取得成功した場合、文書データの新規登録ダイアログを表示する
          this.registerDialog = true;

        } else {
          this.logDebug("#### 「ルートの文書ファイルのリストを取得」API 失敗 レスポンス：");
          this.logDebug(response);
          this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r16.warning;
        }
      } catch (error) {
        this.logDebug("#### getRootDocumentFromHistory() 例外発生");
        this.logDebug(error);
        this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r16.error;
      } finally {
        // ローディング終了
        this.getRootDocumentsLoading = false;
      }
      this.logDebug("### getRootDocumentFromHistory() 終了");
    },

    /**
     * R16をリクエストして登録済みドキュメントPDFリストを取得。
     * 取得結果をそのまま呼び出し元に返却する。
     **/
    async getDocuments() {

      // クエリパラメータを設定
      let qParams = {};
      ObjectUtils.objectPush(qParams, "name", this.searchCondition.name);
      ObjectUtils.objectPush(qParams, "filename", this.searchCondition.filename);
      ObjectUtils.dateObjectPush(qParams, "created_at_from", this.searchCondition.registerDateFrom);
      ObjectUtils.dateObjectPush(qParams, "created_at_to", this.searchCondition.registerDateTo);
      ObjectUtils.objectPush(qParams, "limits", this.options.itemsPerPage);
      ObjectUtils.objectPush(qParams, "page", this.options.page);

      // APIリクエスト（R16）
      this.logDebug("#### R16 リクエスト");
      return await ApiUtils.get(
          "/faq_from_document/document",
          qParams
      );
    },

    /**
     * 文書ファイルをダウンロードする
     * download()、downloadFromHistory() から呼ばれる
     */
    async downloadSub(path, id, show, fromHistory) {
      this.logDebug("### download() 開始");
      try {
        if (!show) {
          if (fromHistory) { // 文書データの更新履歴ダイアログに表示されているリストから呼び出された場合
            this.historyDownloadLoading[id] = true;
          } else {
            this.downloadLoading[id] = true;
          }
        }

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "s3_path", path);

        // APIリクエスト（R2）
        this.logDebug("#### R2 リクエスト");
        const response = await ApiUtils.get("/common/download_url", qParams);

        if (200 === response.status) {
          this.logDebug("#### R2 成功");
          this.logDebug("#### R2 レスポンス.download_url：" + response.data.download_url);

          // vue-pdf の公式バグにより、実装ソースだとjestが動かない。対象機能をコメントアウトすることで対応する
          // this.pdfSrc = response.data.download_url; // 単体テスト時はこの行のコメント化を解除
          this.pdfSrc = getPdfUrl(response.data.download_url); // 単体テスト時はこの行と、getPdfUrl の import文 をコメントアウト

          // ダウンロードボタンクリック時
          if (!show) {
            window.open(response.data.download_url, '_blank')
          }
        } else {
          this.logDebug("#### R2 失敗 レスポンス：");
          this.logDebug(response);
          this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r2.warning;
          if (show) {
            this.wizardClose();
          }
        }
      } catch (error) {
        this.logDebug("#### download() 例外発生");
        this.logDebug(error);
        this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r2.error;
        if (show) {
          this.wizardClose();
        }
      } finally {
        if (fromHistory) { // 文書データの更新履歴ダイアログに表示されているリストから呼び出された場合
          this.historyDownloadLoading[id] = false;
        } else {
          this.downloadLoading[id] = false;
        }
      }
      this.logDebug("### download() 終了");
    },

    /**
     * 文書ファイルの一覧からダウンロードボタンを押下した時の処理。
     * 文書ファイルをダウンロードする。
     */
    async download(path, id, show) {
      const fromHistory = false;
      await this.downloadSub(path, id, show, fromHistory);
    },

    /**
     * 文書ファイルの更新履歴の一覧からダウンロードボタンを押下した時の処理。
     * 文書ファイルをダウンロードする。
     */
    async downloadFromHistory(path, id, show) {
      const fromHistory = true;
      await this.downloadSub(path, id, show, fromHistory);
    },

    /**
     * 削除ボタン押下時の処理。
     * R18をリクエストして選択されたドキュメント Pdf を削除する。
     */
    async deleteDoc() {
      this.logDebug("### deleteDoc() 開始");
      try {
        ObjectUtils.alertsInit(this.alerts);
        this.deleteLoading = true;

        // APIリクエスト（R18）
        this.logDebug("#### R18 リクエスト");
        const response = await ApiUtils.deleteReq(
            "/faq_from_document/document/" + this.deleteId
        );

        if (200 === response.status) {
          this.logDebug("#### R18 成功");
          this.alerts.success.isShow = true;
          this.alerts.success.message = Messages.r18.success;
          await this.search();
        } else if (406 === response.status) {
          this.logDebug("#### R18 失敗 子データを持つ親の文書データの削除失敗");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r18.warning406;
        } else {
          this.logDebug("#### R18 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r18.warning;
        }
      } catch (error) {
        this.logDebug("#### deleteDoc() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r18.error;
      } finally {
        this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
        this.deleteLoading = false;
        this.deleteDialog = false;
      }
      this.logDebug("### deleteDoc() 終了");
    },

    /**
     * 新規登録ボタンクリック時の処理。
     * PDFファイルの新規登録を行う。
     * 同義語候補がチェック有なら、同義語データを作成する。
     */
    async newRegister() {
      this.logDebug("### newRegister() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["register_form"])) {
        this.logDebug("### バリデーションNG newRegister() 終了");
        return;
      }

      // ローディング開始
      this.newRegisterLoading = true;

      // アラートエリア初期化
      ObjectUtils.alertsInit(this.alerts);
      try {
        const r1Response =
            await ApiUtils.getPath(this.alerts, "document", null, this.newData.filename);
        if (r1Response.is_success !== true) {
          this.logDebug("### アップロードURLとS3パス取得失敗 newRegister() 終了");
          return;
        }
        if (!(await ApiUtils.documentUpload(this.alerts, r1Response.upload_url, this.newData.file))) {
          this.logDebug("### PDFファイルアップロード失敗 newRegister() 終了");
          return;
        }
        const r15Response = await this.register(r1Response.s3_path); // 新規登録時、pdfのIDが返却されるので取得する
        if (r15Response.is_success !== true) {
          this.logDebug("### PDFファイル登録失敗 newRegister() 終了");
          return; // 失敗は戻る
        }
        let synonymProject = false;
        let synonymFilename = "";

        // 同義語候補処理
        if (this.checkSynonym) {
          this.logDebug("#### 同義語候補処理 開始");

          // 登録したドキュメント PDF データの ID をパラメータに渡して、同義語データ作成を実行
          const createSynonymResponse = await this.createSynonym(r15Response.id);
          if (createSynonymResponse.is_success !== true) {
            this.logDebug("#### 同義語プロジェクト作成に失敗 同義語候補処理 終了");
            return; // 失敗は戻る
          }

          // 成功時は、フラグを立てる
          synonymProject = true;
          synonymFilename = createSynonymResponse.name;
          this.logDebug("#### 同義語候補処理 終了");
        }

        // 成功時メッセージ表示
        this.alerts.success.isShow = true;
        if (synonymProject === true) {

          // 新規登録と同義語の成功メッセージ
          let reps = [
            this.newData.name,
            this.newData.filename,
            synonymFilename,
            this.newData.filename,
          ];
          this.alerts.success.message = StringUtils.messageReplace(
              Messages.documentSynonymProject.success,
              reps
          );
        } else {

          // 新規登録のみの成功メッセージ
          let reps = [this.newData.name, this.newData.filename];
          this.alerts.success.message = StringUtils.messageReplace(
              Messages.r15.success,
              reps
          );
        }

        // フォームリセットと再検索
        ValidationUtils.reset(this.$refs["register_form"]);
        this.tempDocumentFile = null;
        await this.search();
      } finally {
        this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
        this.newRegisterLoading = false;
        this.registerDialog = false;
      }
      this.logDebug("### newRegister() 終了");
    },

    /**
     * R15を使ってPDFファイルを登録する。
     */
    async register(s3_path) {
      this.logDebug("#### register() 開始");
      let ret = {
        is_success: false,
        id: null,
      };
      try {
        let body = {};
        ObjectUtils.objectPush(body, "name", this.newData.name);
        ObjectUtils.objectPush(body, "s3_path", s3_path);
        if (this.newData.parentDocument != null) {
            // 親の文書データの指定がある場合はパラメータとして指定
            ObjectUtils.objectPush(body, "parent_document_id", this.newData.parentDocument.value);
        }

        // APIリクエスト（R15）
        this.logDebug("##### R15 リクエスト");
        const response = await ApiUtils.post(
            "/faq_from_document/document",
            body
        );

        if (200 === response.status) {
          this.logDebug("##### R15 成功");
          ret = {
            is_success: true,
            id: response.data.id,
          };
        } else {
          this.logDebug("##### R15 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r15.warning;
        }
      } catch (error) {
        this.logDebug("##### register() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r15.error;
      }
      this.logDebug("##### レスポンス：" + JSON.stringify(ret));
      this.logDebug("#### register() 終了");
      return ret;
    },

    /**
     * 同義語 API（ Ten API ）のラッパーを呼び出して、
     * 同義語プロジェクト作成、単語分散表現データ作成、
     * データインポート、単語分散表現データ解析、を実行する
     *
     * 同義語プロジェクト作成、単語分散表現データ作成、までを同期的に実行した後、レスポンスを返し
     * その後、バックグラウンドで非同期的にデータインポート、単語分散表現データ解析、を実行する
     */
    async createSynonym(docId) {
      this.logDebug("#### createSynonym() 開始");
      let ret = {
        is_success: false,
        name: null,
        project_key: null,
        word_embedding_key: null
      };
      try {
        let body = {};

        // APIリクエスト（同義語データ作成）
        this.logDebug("##### 同義語データ作成 リクエスト");
        const response = await ApiUtils.post(
            "/synonym/create_synonym/document/" + String(docId),
            body
        );

        if (200 === response.status) {
          this.logDebug("##### 同義語データ作成 成功");
          ret = {
            is_success: true,
            name: response.data.name,
            project_key: response.data.project_key,
            word_embedding_key: response.data.word_embedding_key
          };
        } else {
          this.logDebug("##### 同義語データ作成 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r513.warning;
        }
      } catch (error) {
        this.logDebug("#### createSynonym() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r513.error;
      }
      this.logDebug("##### レスポンス：" + JSON.stringify(ret));
      this.logDebug("#### createSynonym() 終了");
      return ret;
    },

    /**
     * R19を使ってドキュメントPDFをテキスト化したファイルを取得する。
     */
    async getTextFile(docId) {
      this.logDebug("### getTextFile() 開始");
      let ret = {
        is_success: false,
        data: null,
      };
      try {
        let qParams = {};

        // APIリクエスト（R19）
        this.logDebug("#### R19 リクエスト");
        const response = await ApiUtils.get(
            "/faq_from_document/document/" + docId + "/text",
            qParams
        );
        if (200 === response.status) {
          this.logDebug("#### R19 成功");
          ret = {
            is_success: true,
            data: response.data.data,
          };
        } else {
          this.logDebug("#### R19 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r19.warning;
        }
      } catch (error) {
        this.logDebug("#### getTextFile() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r19.error;
      }
      this.logDebug("#### getTextFile() レスポンス：" + JSON.stringify(ret));
      this.logDebug("### getTextFile() 終了");
      return ret;
    },

    /**
     * ウィザード形式ダイアログの終了処理。
     */
    wizardClose() {
      this.editDialog = false;
      this.textFullScreenEditDialog = false;
      this.headlinePatternDialog = false;
      this.editHeadlinePatternDialog = false;
      ObjectUtils.alertsInit(this.dialogAlerts);
      this.pdfSrc = null;
      this.currentPageNum = 1;
      this.totalPageNum = 0;
      this.step = 0;
      this.step1NextLoading = false;
      this.historyDialog = false; // 更新履歴ダイアログを閉じる（もし開いていれば）
    },

    /**
     * TXTファイルを出力してダウンロードする。
     */
    downloadTXT() {
      this.logDebug("### downloadTXT() 開始");
      // TXTに出力するカラムを定義
      const filename = this.textFilename + "_文分割テキスト_" + StringUtils.getNowDatetime() + ".txt";
      FileUtils.downLoadTXT(this.textEditAreaValue, filename);
      this.logDebug("### downloadTXT() 終了");
    },

    /**
     * 編集ボタン押下時の処理。
     * ウィザード形式ダイアログを表示するための初期処理を行う。
     * wizardInit()、wizardInitFromHistory() から呼ばれる
     */
    async wizardInitSub(docId, name, filename, path, fromHistory) {
      this.logDebug("### wizardInit() 開始");

      // 文分割テキストのファイル出力用に名前を取得
      this.textFilename = name;

      // ローディング開始
      if (fromHistory) { // 文書データの更新履歴ダイアログに表示されているリストから呼び出された場合
        this.historyEditLoading[docId] = true;
      } else {
        this.editLoading[docId] = true;
      }

      // ウィザード内の他の処理でも使うためdocumentIdを保管
      this.wizardDocId = docId;

      // テキストエリアを初期化
      this.textEditAreaValue = "";
      this.fullScreenTextEditAreaValue = "";

      // R19を使ってドキュメントPDFのテキストを取得
      const response = await this.getTextFile(this.wizardDocId);
      if (response.is_success !== true) {
        if (fromHistory) { // 文書データの更新履歴ダイアログに表示されているリストから呼び出された場合
          this.historyEditLoading[docId] = false;
        } else {
          this.editLoading[docId] = false;
        }
        this.wizardClose();
        this.logDebug("### ドキュメントPDFのテキスト取得失敗 wizardInit() 終了");
        return;
      }
      this.textEditAreaValue = response.data;

      // pdf のみプレビュー表示可能
      if ((/.+\.pdf/).test(filename)) {
        this.isDocPreview = true;

        // pdfURLを取得
        this.download(path, docId, true); // ダウンロード処理は非同期で実行
      } else {
        this.isDocPreview = false;
      }

      // pdf 関係の変数を初期化
      this.totalPageNum = 0;
      this.currentPageNum = 1;

      // ローディング終了
      if (fromHistory) { // 文書データの更新履歴ダイアログに表示されているリストから呼び出された場合
        this.historyEditLoading[docId] = false;
      } else {
        this.editLoading[docId] = false;
      }

      // ウィザード開始
      this.step = 1;
      this.editDialog = true;
      this.logDebug("### wizardInit() 終了");
    },

    /**
     * 文書ファイルの一覧から編集ボタンを押下した時の処理。
     * ウィザード形式ダイアログを表示するための初期処理を行う。
     */
    async wizardInit(docId, name, filename, path) {
      const fromHistory = false;
      await this.wizardInitSub(docId, name, filename, path, fromHistory);
    },

    /**
     * 文書ファイルの更新履歴の一覧から編集ボタンを押下した時の処理。
     * ウィザード形式ダイアログを表示するための初期処理を行う。
     */
    async wizardInitFromHistory(docId, name, filename, path) {
      const fromHistory = true;
      await this.wizardInitSub(docId, name, filename, path, fromHistory);
    },

    /**
     * ウィザードStep1の「次へ」ボタン押下時の処理。
     */
    async wizardStep1Next() {
      this.logDebug("### wizardStep1Next() 開始");

      // ローディング開始
      this.step1NextLoading = true;

      // 編集済みテキストの先頭に 【--- PAGE 1 ---】 がない場合は付与
      if (!this.textEditAreaValue.startsWith("【--- PAGE 1 ---】")) {
        this.textEditAreaValue = "【--- PAGE 1 ---】\n" + this.textEditAreaValue;
      }

      // R20で編集済みのテキストをアップロード
      if (!await this.editTextUpload()) {
        this.wizardClose(); // 異常があった場合はウィザードを閉じる
        this.logDebug("### 編集済みのテキストをアップロード失敗 wizardStep1Next() 終了");
        return;
      }

      // statusが準備完了になるまで待機
      if (!await this.waitProcessing()) {
        this.wizardClose(); // 異常があった場合はウィザードを閉じる
        this.logDebug("### waitProcessing()失敗 wizardStep1Next() 終了");
        return;
      }

      // R21でドキュメントファイル（テキストファイル）の見出しを自動判定
      if (!await this.headlineJudgment()) {
        // 異常があった場合はウィザードを閉じる
        this.wizardClose();
        this.logDebug("### ドキュメントファイルの見出しを自動判定失敗 wizardStep1Next() 終了");
        return;
      }

      // ローディング終了
      this.step1NextLoading = false;

      // STEP2開始
      this.step = 2;
      this.logDebug("### wizardStep1Next() 終了");
    },

    /**
     * 対象のドキュメントの処理が進められるようになるまで待機する。
     * ドキュメントPDFリストを取得後、statusが準備完了の場合に処理が終了する。
     * 一定回数リトライしてもstatusが準備完了にならない場合はウィザードを閉じる。
     **/
    async waitProcessing() {
      this.logDebug("### waitProcessing() 開始");

      // statusが準備完了になるまで待機
      const retryLimit = 5; // およそ10分間リロード
      let retryCnt = 0;
      const wait = async (ms) => new Promise(resolve => setTimeout(resolve, ms));
      do {
        this.logDebug("#### ループ" + (retryCnt + 1) + "回目");

        // APIリクエスト（「指定の文書データファイルの詳細を取得」API）
        try {
          // クエリパラメータを設定
          let qParams = {};

          let documentDetailResponse = await ApiUtils.get(
            "/faq_from_document/document/" + this.wizardDocId + "/detail",
            qParams
          );

          // 正常以外なら異常終了する
          if (200 !== documentDetailResponse.status) {
            this.logDebug("#### 「指定の文書データファイルの詳細を取得」API 失敗 レスポンス：");
            this.logDebug(documentDetailResponse);
            ObjectUtils.alertsInit(this.alerts);
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.r16.warning;
            this.headlinePatternOkButton = true; // OKボタン非活性化
            this.editHeadlinePatternOkButton = true; // OKボタン非活性化
            this.logDebug("### 「指定の文書データファイルの詳細を取得」API 失敗 waitProcessing() 終了");
            return false;
          }

          // 処理対象のレコードを抽出する
          let doc = documentDetailResponse.data.data[0];
          this.logDebug("#### 処理対象レコード抽出：" + JSON.stringify(doc));

          // ステータスが"準備完了"の場合はループを抜けて次の処理に移る
          if ("準備完了" === doc.status) {
            this.logDebug("#### ステータス 準備完了 を検知");
            break;
          }
        } catch (error) {
          this.logDebug("#### waitProcessing() 例外発生");
          this.logDebug(error);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.error.isShow = true;
          this.alerts.error.message = Messages.r16.error;
          this.headlinePatternOkButton = true; // OKボタン非活性化
          this.editHeadlinePatternOkButton = true; // OKボタン非活性化
          this.logDebug("### waitProcessing() 終了");
          return false;
        }

        // ステータスが"準備完了"以外の場合は、一定時間待機したあと、再度ドキュメントを取得してstatusをチェックする
        await wait(6000);
        retryCnt++;
      } while (retryCnt < retryLimit);

      // リトライ回数を超えた場合は処理を終了する。
      if (retryCnt >= retryLimit) {
        this.logDebug("#### リトライ回数超過 retryCnt：" + retryCnt + ", retryLimit" + retryLimit);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.warning.isShow = true;
        this.alerts.warning.message = Messages.common.timeout;
        this.headlinePatternOkButton = true; // OKボタン非活性化
        this.editHeadlinePatternOkButton = true; // OKボタン非活性化
        this.logDebug("### waitProcessing() 終了");
        return false;
      }
      return true; // 成功
    },

    /**
     * R20を使って編集済みのテキストをアップロードする。
     * @return {Boolean} true:処理成功，false；処理失敗
     */
    async editTextUpload() {
      this.logDebug("### editTextUpload() 開始");
      let result = false;
      try {
        let body = {};
        ObjectUtils.objectPush(body, "id", this.wizardDocId);
        ObjectUtils.objectPush(body, "data", this.textEditAreaValue);

        // APIリクエスト（R20）
        this.logDebug("#### R20 リクエスト");
        const response = await ApiUtils.post(
            "/faq_from_document/document/" + this.wizardDocId + "/text",
            body
        );

        if (200 === response.status) {
          this.logDebug("#### R20 成功");
          result = true;
        } else {
          this.logDebug("#### R20 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r20.warning;
        }
      } catch (error) {
        this.logDebug("#### editTextUpload() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r20.error;
      }
      this.logDebug("### editTextUpload() 終了");
      return result;
    },

    /**
     * R21を使ってドキュメントファイル（テキストファイル）の見出しを自動判定する。
     */
    async headlineJudgment() {
      this.logDebug("### headlineJudgment() 開始");

      // データ初期化
      let result = false;
      this.headlineSettings = [];
      try {

        // APIリクエスト（R21）
        this.logDebug("#### R21 リクエスト");
        const response = await ApiUtils.get(
            "/faq_from_document/document/" + this.wizardDocId + "/title",
            ""
        );
        if (200 === response.status) {
          this.logDebug("#### R21 成功");

          // 画面表示に必要なデータを取り出す
          response.data.data.forEach(headline => {
            let temp = {};
            temp['matched_row_count'] = headline.matched_row_count;
            temp['title_pattern_id'] = headline.title_pattern_id;
            temp['title_pattern_name'] = headline.title_pattern_name;
            temp['data_sample'] = headline.data_sample;
            temp['isSelected'] = false;
            temp['is_custom'] = headline.is_custom;
            this.headlineSettings.push(temp);
          })

          // 先頭5件をチェック状態に変更
          let length = this.headlineSettings.length;
          if (length > 5) {
            length = 5;
          }
          for (let i = 0; i < length; i++) {
            this.headlineSettings[i]['isSelected'] = true;
          }
          result = true;
        } else {
          this.logDebug("#### R21 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r21.warning;
        }
      } catch (error) {
        this.logDebug("#### headlineJudgment() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r21.error;
      }
      this.logDebug("### headlineJudgment() 終了");
      return result;
    },

    /**
     * 見出しのサンプルデータを整形して表示する。
     */
    showDataSample(dataSample) {
      let result = "";
      if (dataSample == null || dataSample.length === 0) {
        return result;
      }

      let length = dataSample.length;
      // 最大10行を返却 → 全て表示するようになったためコメントアウト
      // if (length > 10) {
      //   length = 10;
      // }
      // result += "\n"; // レイアウト調整
      for (let i = 0; i < length; i++) {
        result += dataSample[i];
        result += "\n";
      }
      return result;
    },

    /**
     * R22で独自の見出しパターンによるマッチングを実施する。
     */
    async headlinePatternTest(param) {
      this.logDebug("### headlinePatternTest() 開始");

      // バリデーションNGなら処理終了
      if (param === "new" && !ValidationUtils.valid(this.$refs["add_Headline_Pattern_Form"])) {
        this.logDebug("### バリデーションNG headlinePatternTest() 終了");
        return;
      } else if (param === "edit" && !ValidationUtils.valid(this.$refs["edit_Headline_Pattern_Form"])) {
        this.logDebug("### バリデーションNG headlinePatternTest() 終了");
        return;
      }

      this.headlinePatternTestLoading = true;
      try {
        let body = {};
        ObjectUtils.objectPush(body, "id", this.wizardDocId);

        if (param === "edit") {
          ObjectUtils.objectPush(body, "rule", this.editHeadlineRule);
        } else {
          ObjectUtils.objectPush(body, "rule", this.headlineRule);
        }

        // APIリクエスト（R22）
        this.logDebug("#### R22 リクエスト");
        const response = await ApiUtils.post(
            "/faq_from_document/document/" + this.wizardDocId + "/title",
            body
        );

        // レスポンスをセット
        if (200 === response.status) {
          this.logDebug("#### R22 成功");
          let str = "";
          response.data.data_sample.forEach(sample => {
            str += sample;
            str += "\n";
          })

          // 追加または編集ダイアログのテスト結果を代入し、OKボタンを活性化する
          if (param === "edit") {
            this.editHeadlinePatternTestResult = str;
            if (!str || !(str === ("" + "\n" + ""))) {
              this.editHeadlinePatternOkButton = false; // テスト結果が空でない場合はOKボタンは活性化
            }
          } else {
            this.headlinePatternTestResult = str;
            if (!str || !(str === ("" + "\n" + ""))) {
              this.headlinePatternOkButton = false;
            }
          }
        } else if (406 === response.status) {
          // 正規表現のマッチに時間が掛かりタイムアウトエラーの場合
          this.logDebug("#### R22 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.dialogAlerts);
          this.dialogAlerts.warning.isShow = true;
          this.dialogAlerts.warning.message = Messages.r22.timeout;
        } else {
          this.logDebug("#### R22 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r22.warning;
          this.wizardClose(); // 異常があった場合はウィザードを閉じる
        }
      } catch (error) {
        this.logDebug("#### headlinePatternTest() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r22.error;
        this.wizardClose(); // 異常があった場合はウィザードを閉じる
      } finally {
        this.headlinePatternTestLoading = false;
      }
      this.logDebug("### headlinePatternTest() 終了");
    },

    /**
     * 見出しパターン追加ダイアログボタン押下時の処理。
     */
    headlinePatternDialogButton() {
      this.headlinePatternDialog = true;
      this.headlinePatternTestResult = this.headlineTestWord; // テスト欄に説明文代入
      ObjectUtils.alertsInit(this.dialogAlerts); // メッセージ初期化
    },

    /**
     * 見出しパターン追加ダイアログのOKボタン押下時の処理。
     */
    async headlinePatternOk() {
      this.logDebug("### headlinePatternOk() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["add_Headline_Pattern_Form"])) {
        this.logDebug("### バリデーションNG headlinePatternOk() 終了");
        return;
      }

      // ローディング開始
      this.headlinePatternOkLoading = true;
      try {

        // R40を使って独自の見出しパターンを登録
        if (!await this.ruleRegister()) {
          this.wizardClose();
          this.logDebug("### 独自の見出しパターン登録失敗 headlinePatternOk() 終了");
          return;
        }

        // statusが準備完了になるまで待機
        await this.waitProcessing();

        // 既存見出しパターンに独自の見出しパターンを追加登録
        if (!await this.headlinePatternRegister(false)) {
          this.wizardClose(); // 異常があった場合はウィザードを閉じる
          this.logDebug("###  R24更新した見出しパターン（登場順、レベル）の登録失敗 headlinePatternRegister() 終了");
          return;
        }

        // statusが準備完了になるまで待機
        await this.waitProcessing();

        // R21でドキュメントファイル（テキストファイル）の見出しを自動判定を再実行
        if (!await this.headlineJudgment()) {
          this.wizardClose(); // 異常があった場合はウィザードを閉じる
          this.logDebug("### 見出しの自動判定失敗 headlinePatternOk() 終了");
        }
      } finally {
        // 入力項目やテスト結果を初期化
        this.headlinePatternTestResult = this.headlineTestWord;
        ValidationUtils.reset(this.$refs["add_Headline_Pattern_Form"]);
        this.headlinePatternOkButton = true; // OKボタン非活性化
        this.headlinePatternDialog = false; // 見出しパターン追加ダイアログを閉じる
        this.headlinePatternOkLoading = false; // ローディング終了
      }
      this.logDebug("### headlinePatternOk() 終了");
    },

    /**
     * 見出しパターン追加ダイアログのキャンセルボタン押下時の処理。
     */
    headlinePatternCancel() {

      // 初期化とダイアログを閉じる
      this.headlinePatternDialog = false;
      this.headlinePatternTestResult = this.headlineTestWord;
      ValidationUtils.reset(this.$refs["add_Headline_Pattern_Form"]);
      this.headlinePatternOkButton = true;
    },

    /**
     * 見出しパターン編集ボタン押下時の処理。
     * ダイアログを開き、R39見出しパターンに登録されている正規表現を取得する。
     */
    async editHeadlinePatternDialogButton(id) {
      this.logDebug("### editHeadlinePatternDialogButton() 開始");
      this.editHeadlinePatternDialog = true;
      this.headlineId = id; // 見出しID取得
      this.editHeadlinePatternTestResult = this.headlineTestWord; // テスト欄に説明文代入
      ObjectUtils.alertsInit(this.dialogAlerts); // メッセージ初期化

      try {
        ObjectUtils.alertsInit(this.alerts);

        this.logDebug("#### R39 リクエスト");
        const response = await ApiUtils.get(
            "/faq_from_document/custom_rule/" + this.headlineId
        );

        if (200 === response.status) {
          this.logDebug("#### R39 成功");
          this.editHeadlineRule = response.data.rule;
        } else {
          this.logDebug("#### R39 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r39.warning;
          this.wizardClose(); // 異常があった場合はウィザードを閉じる
        }
      } catch (error) {
        this.logDebug("#### editHeadlinePatternDialog() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r39.error;
        this.wizardClose(); // 異常があった場合はウィザードを閉じる
      }
      this.logDebug("### editHeadlinePatternDialogButton() 終了");
    },

    /**
     * 見出しパターン編集ダイアログのOKボタン押下時の処理。
     * R41独自の見出しパターンを更新する、R24見出しID一覧更新し、R21で見出し一覧を再取得する。
     */
    async editHeadlinePatternOk() {
      this.logDebug("### editHeadlinePatternOk() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["edit_Headline_Pattern_Form"])) {
        this.logDebug("### バリデーションNG editHeadlinePatternOk() 終了");
        return;
      }
      // ローディング開始
      this.headlinePatternOkLoading = true;

      try {
        let body = {};
        ObjectUtils.objectPush(body, "name", this.editHeadlineTitle);
        ObjectUtils.objectPush(body, "rule", this.editHeadlineRule);
        this.logDebug("#### R41 リクエスト");
        const response = await ApiUtils.post(
            "/faq_from_document/custom_rule/" + this.headlineId,
            body
        );

        if (200 === response.status) {
          this.logDebug("#### R41 成功");

          // statusが準備完了になるまで待機
          await this.waitProcessing();

          // R24見出しパターンID一覧情報を更新する
          if (!await this.headlinePatternRegister(false)) {
            this.wizardClose(); // 異常があった場合はウィザードを閉じる
            this.logDebug("###  R24更新した見出しパターン（登場順、レベル）の登録失敗 headlinePatternRegister() 終了");
            return;
          }

          // statusが準備完了になるまで待機
          await this.waitProcessing();

          // R21でドキュメントファイル（テキストファイル）の見出しを自動判定を再実行
          if (!await this.headlineJudgment()) {
            this.wizardClose(); // 異常があった場合はウィザードを閉じる
            this.logDebug("### 見出しの更新失敗 editHeadlinePatternOk() 終了");
          }

        } else {
          this.logDebug("#### R41 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r41.warning;
          this.wizardClose(); // 異常があった場合はウィザードを閉じる
        }
      } catch (error) {
        this.logDebug("#### editHeadlinePatternOk() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.aerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r41.error;
        this.wizardClose(); // 異常があった場合はウィザードを閉じる
      } finally {
        // 入力項目やテスト結果を初期化
        this.editHeadlinePatternTestResult = this.headlineTestWord;
        ValidationUtils.reset(this.$refs["edit_Headline_Pattern_Form"]);
        this.editHeadlinePatternOkButton = true; // OKボタン非活性化
        this.editHeadlinePatternDialog = false; // 見出しパターン編集ダイアログを閉じる
        this.headlinePatternOkLoading = false; // ローディング終了
      }
      this.logDebug("### editHeadlinePatternOk() 終了");
    },

    /**
     * 見出しパターン編集ダイアログのキャンセルボタン押下時の処理。
     * ダイアログを初期化する。
     */
    editHeadlinePatternCancel() {
      // 初期化とダイアログを閉じる
      this.editHeadlinePatternDialog = false;
      this.editHeadlinePatternTestResult = this.headlineTestWord;
      ValidationUtils.reset(this.$refs["edit_Headline_Pattern_Form"]);
      this.editHeadlinePatternOkButton = true;
    },

    /**
     * 見出しパターン削除ボタン押下時の処理。
     * 削除対象のIDを取得する。
     */
    editHeadlineDeleteDialogButton(id) {
      this.editHeadlineDeleteDialog = true;
      this.headlineId = id;
    },

    /**
     * 見出しパターン削除の処理。
     * R42見出しパターン削除後に、R24削除したIDをID一覧から除外し一覧更新し、R21で見出し一覧を再取得する。
     */
    async deleteHeadlinePattern() {
      this.logDebug("### deleteHeadlinePattern() 開始");
      try {
        this.headlineDeleteLoading = true;


        this.logDebug("#### R42 リクエスト");
        const response = await ApiUtils.deleteReq(
            "/faq_from_document/custom_rule/" + this.headlineId
        );

        if (200 === response.status) {
          this.logDebug("#### R42 成功");

          // 削除された見出しパターンのIDをID一覧から除外する
          this.headlineSettings = this.headlineSettings.filter(item => !(item.title_pattern_id === this.headlineId));

          // statusが準備完了になるまで待機
          await this.waitProcessing();

          // R24見出しパターンID一覧情報を更新する
          if (!await this.headlinePatternRegister(false)) {
            this.wizardClose(); // 異常があった場合はウィザードを閉じる
            this.logDebug("###  R24更新した見出しパターン（登場順、レベル）の登録失敗 headlinePatternRegister() 終了");
            return;
          }

          // statusが準備完了になるまで待機
          await this.waitProcessing();

          // R21でドキュメントファイル（テキストファイル）の見出しを自動判定を再実行
          if (!await this.headlineJudgment()) {
            this.wizardClose(); // 異常があった場合はウィザードを閉じる
            this.logDebug("### R21見出しの自動判定失敗 deleteHeadlinePattern() 終了");
          }
        } else {
          this.logDebug("#### R42 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r42.warning;
          this.wizardClose(); // 異常があった場合はウィザードを閉じる
        }
      } catch (error) {
        this.logDebug("#### deleteHeadlinePattern() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r42.error;
        this.wizardClose(); // 異常があった場合はウィザードを閉じる
      } finally {
        this.headlineDeleteLoading = false;
        this.editHeadlineDeleteDialog = false;
      }
      this.logDebug("### deleteHeadlinePattern() 終了");
    },

    /**
     * R40で独自の見出しパターンを登録する。
     */
    async ruleRegister() {
      this.logDebug("### ruleRegister() 開始");
      let result = false;
      try {
        let body = {};
        ObjectUtils.objectPush(body, "name", this.headlineTitle);
        ObjectUtils.objectPush(body, "rule", this.headlineRule);

        // APIリクエスト（R40）
        this.logDebug("#### R40 リクエスト");
        const response = await ApiUtils.post(
            "/faq_from_document/custom_rule/",
            body
        );
        if (200 === response.status) {
          this.logDebug("#### R40 成功");

          // レスポンスのidをR24で登録できるように見出しパターンの変数に追加
          let temp = {};
          temp['title_pattern_id'] = response.data.id;
          temp['isSelected'] = false;
          this.headlineSettings.push(temp);
          result = true;
        } else {
          this.logDebug("#### R40 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r40.warning;
          this.headlinePatternOkButton = true; // OKボタン非活性化
          this.editHeadlinePatternOkButton = true; // OKボタン非活性化
        }
      } catch (error) {
        this.logDebug("#### ruleRegister() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r40.error;
        this.headlinePatternOkButton = true; // OKボタン非活性化
        this.editHeadlinePatternOkButton = true; // OKボタン非活性化
      }
      this.logDebug("### ruleRegister() 終了");
      return result;
    },

    /**
     * ウィザードStep2の「実行」ボタン押下時の処理。
     */
    async wizardStep2Execute() {
      this.logDebug("### wizardStep2Execute() 開始");

      // ローディング開始
      this.headlinePatternRegisterLoading = true;
      try {
        // 更新した見出しパターン（登場順、レベル）を登録
        if (!await this.headlinePatternRegister(true)) {
          this.wizardClose(); // 異常があった場合はウィザードを閉じる
          this.logDebug("###  更新した見出しパターン（登場順、レベル）の登録失敗 wizardStep2Execute() 終了");
          return;
        }

        // 画面から編集ボタンを押せないようにするため検索処理を呼び出してstatusを更新する
        await this.search();
      } finally {
        // ローディング終了
        this.headlinePatternRegisterLoading = false;
      }

      // STEP3開始
      this.step = 3;
      this.logDebug("### wizardStep2Execute() 終了");
    },

    /**
     * R24で更新した見出しパターン（登場順、レベル）を登録する。
     */
    async headlinePatternRegister(isExecuteButton) {
      this.logDebug("### headlinePatternRegister() 開始");
      let result = false;
      try {
        let body = {};
        ObjectUtils.objectPush(body, "id", this.wizardDocId);

        // title_pattern_id にセットする値を作成
        let selectedPatternId = [];

        // 実行 ボタン押下時はチェックされたパターンのみ登録
        if (isExecuteButton) {
          this.headlineSettings.forEach(headline => {
            if (headline.isSelected === true) {
              selectedPatternId.push(headline.title_pattern_id);
            }
          })
        } else {
          // OK ボタン押下時はすべてのパターンを登録
          this.headlineSettings.forEach(headline => {
            selectedPatternId.push(headline.title_pattern_id);
          })
        }
        ObjectUtils.objectPush(body, "title_pattern_id", selectedPatternId);

        // APIリクエスト（R24）
        this.logDebug("#### R24 リクエスト");
        const response = await ApiUtils.post(
            "/faq_from_document/document/" + this.wizardDocId + "/rule",
            body
        );

        // レスポンスをセット
        if (200 === response.status) {
          this.logDebug("#### R24 成功");
          result = true;
        } else {
          this.logDebug("#### R24 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r24.warning;
          this.headlinePatternOkButton = true; // OKボタン非活性化
          this.editHeadlinePatternOkButton = true; // OKボタン非活性化
        }
      } catch (error) {
        this.logDebug("#### headlinePatternRegister() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r24.error;
        this.headlinePatternOkButton = true; // OKボタン非活性化
        this.editHeadlinePatternOkButton = true; // OKボタン非活性化
      }
      this.logDebug("### headlinePatternRegister() 終了");
      return result;
    },

    /**
     * 全画面編集 画面の初期化処理。
     **/
    fullScreenEditInit() {
      this.textFullScreenEditDialog = true;
      this.fullScreenTextEditAreaValue = this.textEditAreaValue;
      this.editorInitWatch = new Date();
      this.onResize();
    },

    /**
     * 全画面編集 の画面の キャンセル ボタン押下時の処理。
     **/
    fullScreenCancelButton() {
      this.textFullScreenEditDialog = false;
    },

    /**
     * 全画面編集 の画面の 保存して閉じる ボタン押下時の処理。
     **/
    fullScreenSaveButton() {
      this.textEditAreaValue = this.fullScreenTextEditAreaValue;
      this.textFullScreenEditDialog = false;
    },

    /**
     * AceEditorのフォントサイズを小さくする。
     * 一定値以下にしない。
     **/
    fontSizeMinus() {
      if (this.aceEditorFontSize > 6) {
        this.aceEditorFontSize--;
      }
    },

    /**
     * AceEditorのフォントサイズを小さくする。
     * 一定値以上にはしない。
     **/
    fontSizePlus() {
      if (this.aceEditorFontSize < 36) {
        this.aceEditorFontSize++;
      }
    },

    /**
     * 文分割テキストの編集結果を受け取り、変数に反映するメソッド。
     * 反映した変数は「次へ」ボタンクリック時に本体の変数にコピーして使う。
     * このようにしないと、AceEditor内のカーソル位置がTOPに戻る。
     * AceEditor.vue にわたす。
     **/
    onChangeText(changeText) {
      this.fullScreenTextEditAreaValue = changeText;
    },

    /**
     * ウィンドウサイズが変更されたときに実行されるメソッド。
     * pdf の横幅を制御している。
     **/
    onResize() {
      this.pdfWidth = window.innerWidth / 1;
    },

    /**
     * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
     * @param message サクセスメッセージ
     */
    showSuccess(message) {
      this.historyDialog = false;  // 更新履歴ダイアログを閉じる（もし開いていれば）
      this.alerts.success.isShow = true;
      this.alerts.success.message = message;
    },

    /**
     * 子コンポーネントからワーニングを表示したいときに呼び出す。
     * @param message ワーニングメッセージ
     */
    showWarning(message) {
      this.historyDialog = false;  // 更新履歴ダイアログを閉じる（もし開いていれば）
      this.alerts.warning.isShow = true;
      this.alerts.warning.message = message;
    },

    /**
     * 子コンポーネントからエラーを表示したいときに呼び出す。
     * @param message エラーメッセージ
     */
    showError(message) {
      this.historyDialog = false;  // 更新履歴ダイアログを閉じる（もし開いていれば）
      this.alerts.error.isShow = true;
      this.alerts.error.message = message;
    },

    /**
     * alerts を初期化する。
     * @param alerts 初期化対象のオブジェクト
     */
    alertsInitByEmit: ObjectUtils.alertsInitByEmit,
  },
  data: () => ({
    registerDialog: false,
    editDialog: false,
    headlinePatternOkButton: true,
    editHeadlinePatternOkButton: true,
    headlinePatternDialog: false,
    editHeadlinePatternDialog: false,
    editHeadlineDeleteDialog: false,
    textFullScreenEditDialog: false,
    headlineId: "",
    headlineDeleteLoading: false,
    historyDialog: false, // 文書データファイル更新履歴の編集ダイアログ
    deleteDialog: false,
    deleteLoading: false,
    previewDialog: false,
    previewDocument: "",
    checkSynonym: false,
    parentDocumentId: null, // 文書データファイル更新履歴の表示対象の、親ドキュメント ID
    deleteId: null,
    tempDocumentFile: null,
    getRootDocumentsLoading: false, // 文書データ新規登録ダイアログの表示中であることを識別するためのフラグ
    newRegisterLoading: false,
    searchLoading: false,
    getDocumentHistoryLoading: false, // 文書データ更新履歴のリスト取得中を識別するためのフラグ
    textFilename: "",
    pdfSrc: null,
    pdfWidth: 1000,
    currentPageNum: 1,
    totalPageNum: 0,
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    dialogAlerts: {
      warning: {isShow: false, message: ""},
    },
    isDocPreview: false,
    docPreviewErrorMessage: Messages.filePreview.warning,
    step: 1,
    wizardDocId: null,
    step1NextLoading: false,
    textEditAreaValue: "", // 文分割テキストエリアの初期表示用変数
    fullScreenTextEditAreaValue: "", // フルスクリーンで編集する文分割テキスト
    editorInitWatch: null, // AceEditor を初期化したいときに更新する値
    headlinePatternTestLoading: false,
    headlinePatternTestResult: "",
    editHeadlinePatternTestResult: "",
    headlinePatternOkLoading: false,
    headlinePatternRegisterLoading: false,
    options: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ["id"],
      sortDesc: [false],
    },
    historyOptions: { // 文書データ更新履歴のリストのテーブルの、ページ番号、表示件数などの情報
      page: 1,
      itemsPerPage: 20,
      sortBy: ["id"],
      sortDesc: [false],
    },
    total: 0,
    historyTotal: 0, // 文書データ更新履歴のリストの全件数
    searchCondition: {
      name: "",
      filename: "",
      registerDateFrom: null,
      registerDateTo: null,
    },
    newData: {
      name: "",
      filename: null,
      file: null,
      parentDocument: null // 親の文書データの ID と名称（バージョン管理する場合のみ指定）
    },
    documentListHeaders: [
      {align: "start", text: "文書データ名", value: "name"},
      {align: "start", text: "ファイル名", value: "filename"},
      {align: "start", text: "状態", value: "status", width: "90"},
      {align: "start", text: "登録日時", value: "created_at", width: "10%"},
      {align: "start", text: "操作", value: "operation", width: "235"},
    ],
    documentHistoryListHeaders: [ // 文書データ更新履歴のリストのテーブルのヘッダ
      {align: "start", text: "文書データ名", value: "name"},
      {align: "start", text: "ファイル名", value: "filename"},
      {align: "start", text: "状態", value: "status", width: "90"},
      {align: "start", text: "登録日時", value: "created_at", width: "10%"},
      {align: "start", text: "操作", value: "operation", width: "195"},
    ],
    documentList: [],
    documentHistoryList: [], // 文書データ更新履歴のリスト
    rootDocumentIdAndNames: [], // 親（ルート）の文書データのリスト（文書データ新規登録ダイアログで利用）
    headlineTitle: null,
    headlineRule: "",
    editHeadlineTitle: null,
    editHeadlineRule: "",
    headlineSettings: [],
    editLoading: [],
    historyEditLoading: [], // 文書データ更新履歴のリストのテーブル上で、ファイル編集ダイアログ起動中を識別するためのフラグ
    downloadLoading: [],
    historyDownloadLoading: [], // 文書データ更新履歴のリストのテーブル上で、ファイルダウンロード中を識別するためのフラグ
    historyLoading: [], // 文書データ一覧から、更新履歴ボタンをクリックしたとき、更新履歴のポップアップページが表示されるまでの待機中であることを識別するフラグ
    headlineTestWord: "テスト結果画面：\r\n見出しルールがマッチした場合のみ、テスト結果がここに表示されます。\r\nその後、OKボタンが押せるようになります。",
    aceEditorFontSize: 14,
  }),
  mounted: function () {
    // 画面表示のタイミングで検索を実行
    this.logDebug("# DocumentMgt.vue mounted");
    this.search();

    this.onResize();
  },
};
</script>