import Vue from 'vue'
import pdf from 'vue-pdf'

Vue.component('pdf', pdf)

export default function getPdfUrl(url){
    return pdf.createLoadingTask({
        url: url,
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/cmaps/',
        cMapPacked: true,
      });
}

// export default {
//     //
// }