import Papa from 'papaparse'
import logger from "@/mixins/logger";

/**
 * CSVファイルを作成してダウンロードする。
 * (BOM付き、カンマ区切り、改行コード:"\r\n"、文字列のみダブルクォートで囲む)
 * @param fields itemsの中からCSV出力する対象を指定する。
 * @param items CSV出力データ
 * @param filename CSVファイルの名前
 */
function downLoadCSV(fields, items, filename) {
    logger.methods.logDebug("#### downLoadCSV 引数 " +
        "fields：" + JSON.stringify(fields) +
        ", items：" + JSON.stringify(items) +
        ", filename：" + JSON.stringify(filename));

    // itemsからCSV作成
    // Papa.unparse()は、データの中に改行があると自動的にダブルクォートで囲まれる
    let csv = "\ufeff";
    csv += Papa.unparse({
        delimiter: ',', // 区切り文字
        header: true, // キーをヘッダーとして扱う
        newline: '\r\n', // 改行コード
        fields: fields, // CSV出力する対象カラム
        data: items // CSV出力データ
    });
    logger.methods.logDebug("#### 出力csv：" + csv);

    // CSVをダウンロードする
    let blob = new Blob([csv], {type: "text/csv"});
    let link = document.createElement("a"); // aタグのエレメントを作成
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}


/**
 * REST API から取得したデータを元に CSV ファイルを作成してダウンロードする。
 * @param data REST API から取得したデータ
 * @param filename ダウンロード CSV ファイルの名前
 */
 function downLoadCsvFromApiResponse(data, filename) {
    logger.methods.logDebug("#### downLoadCsvFromApiResponse. filename：" + filename);

    // CSV ファイルは `UTF-8 BOM有り` で出力する。そうすることで Excel で開いたときに文字化けせずに表示できる
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    // CSV ファイルをダウンロードする
    let blob = new Blob([bom, data], {type: "text/csv"});
    let link = document.createElement("a"); // aタグのエレメントを作成
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}


/**
 * TXTファイルを作成してダウンロードする。
 * @param text TXT出力データ
 * @param filename TXTファイルの名前
 */
 function downLoadTXT(text, filename) {
    logger.methods.logDebug("#### downLoadTXT 引数 " +
        "filename：" + JSON.stringify(filename) +
        "text:" + JSON.stringify(text));

    logger.methods.logDebug("#### 出力txt：" + text);

    // TXTをダウンロードする
    let blob = new Blob([text], {type: "text/plain"});
    let link = document.createElement("a"); // aタグのエレメントを作成
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

function duplicateJsonKey(jsons, key, duplicateKey) {
    jsons.forEach(json => {
        json[duplicateKey] = json[key];
    });
}

function duplicateJsonKeyWithEscapeExcelCommand(jsons, key, duplicateKey) {
    jsons.forEach(json => {
        var escaped = json[key];
        if (escaped) {
            if (escaped.startsWith('=') || escaped.startsWith('-') || escaped.startsWith('+') || escaped.startsWith('@')) {
                // 元の文字列の先頭が =、-、+、@ の場合は先頭に「'」を付与する（ CSV を Excel で開いたときにコマンドが実行されないように）
                escaped = "'" + escaped;
            }
        }
        json[duplicateKey] = escaped;
    });
}

export default {
    downLoadCSV,
    downLoadCsvFromApiResponse,
    downLoadTXT,
    duplicateJsonKey,
    duplicateJsonKeyWithEscapeExcelCommand,
}