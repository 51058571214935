<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- 検索フォーム -->
    <v-container>
      <!-- 開閉パネル -->
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4"><h4>検索入力フォーム</h4></v-col>
              <v-col class="text--secondary" cols="8">
                <v-fade-transition leave-absolute>
                  <!-- 検索フォームを開いているときのメッセージ -->
                  <span v-if="open">検索条件を入力してください</span>
                  <!-- 検索フォームを閉じているときのメッセージ -->
                  <v-row v-else no-gutters style="width: 100%">
                    <!-- 設定された検索条件を表示 -->
                    <ClosedSearchFormDisp
                        :value="searchCondition.name"
                        label="FAQデータ名"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.filename"
                        label="ファイル名"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.dateFrom"
                        :toValue="searchCondition.dateTo"
                        label="登録日時"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.numberFrom"
                        :toValue="searchCondition.numberTo"
                        label="件数"
                    />
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-form ref="search_form">
            <v-expansion-panel-content>
              <!-- 名称-->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">FAQデータ名 :</v-col>
                <v-col cols="6">
                  <!-- value.syncで子コンポーネントに値を渡す -->
                  <NormalTextArea :value.sync="searchCondition.name" label=""/>
                </v-col>
              </v-row>

              <!-- ファイル名 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ファイル名 :</v-col>
                <v-col cols="6">
                  <NormalTextArea
                      :value.sync="searchCondition.filename"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- 登録日時 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">登録日時 :</v-col>
                <v-col class="ml-n3" cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.dateFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.dateTo"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- 件数 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">件数:</v-col>
                <v-col class="ml-n3" cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.numberFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.numberTo"
                      label=""
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-form>
        </v-expansion-panel>
      </v-expansion-panels>

      <br/>
      <v-row>
        <v-btn
            id="new_register"
            class="ma-3"
            color="secondary"
            @click="dialog = true"
        >
          <v-icon>mdi-text-box-plus-outline</v-icon>
          FAQデータの新規登録
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
            id="button_get_faq_data_list"
            :loading="searchLoading"
            class="ma-3"
            color="primary"
            @click="options.page = 1; search()"
        >
          <v-icon>mdi-magnify</v-icon>
          検索
        </v-btn>
        <v-btn
            id="button_search_condition_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>

        <v-dialog v-model="dialog" max-width="600">
          <v-card>
            <v-form ref="register_form" @submit.prevent="newRegister()">
              <v-card-title>FAQデータの新規登録</v-card-title>
              <v-container>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="newRegisterLoading"
                        :value.sync="newData.name"
                        label="FAQデータ名"
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <v-file-input
                        v-model="tempFaqFile"
                        :rules="[required]"
                        accept=".csv"
                        label="FAQ csvファイルを指定"
                        show-size
                        truncate-length="25"
                        v-bind:disabled="newRegisterLoading"
                        @change="getFile"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row class="mt-n2 mb-n7" dense justify="center">
                  <v-checkbox
                      v-model="checkSynonym"
                      label="同義語候補を作成する"
                      v-bind:disabled="newRegisterLoading"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <v-spacer/>
                  <v-col v-if="newData.loading" cols="8">
                    アップロード準備中...
                    <v-progress-circular
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                  </v-col>
                  <v-spacer/>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="ma-6" color="primary" @click="dialog = false"
                >キャンセル
                </v-btn
                >
                <v-btn
                    id="new_register_dialog"
                    :loading="newRegisterLoading"
                    class="ma-6"
                    color="secondary"
                    @click="newRegister()"
                >OK
                </v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
          :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
        }"
          :headers="faqMgtHeaders"
          :items="faqMgtDataList"
          :loading="searchLoading"
          :options.sync="options"
          :server-items-length="total"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          @update:items-per-page="search()"
          @update:page="search()"
      >
        <template v-slot:[`item.operation`]="{ item }">
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="button_faq_details"
                  v-on="on"
                  color="primary"
                  small
                  @click="
                    faqSearchDatalist = [];
                    detailTotal = 0;
                    faqDetailDialog = true;
                    dataId = item.id;
                    dtlAlerts.warning.isShow = false;
                    dtlAlerts.error.isShow = false;
                    faqSearch();
                  "
              >
                <v-icon>mdi-file-find</v-icon>
              </v-btn>
            </template>
            <span>詳細</span>
          </v-tooltip>
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="button_download"
                  v-on="on"
                  :loading="downloadLoading[item.id]"
                  color="primary"
                  small
                  @click="download(item.s3_path, item.id)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ダウンロード</span>
          </v-tooltip>
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="button_delete"
                  v-on="on"
                  color="warning"
                  small
                  @click="
                    deleteId = item.id;
                    deleteDialog = true;
                  "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>削除</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="faqDetailDialog">
        <v-card>
          <v-card-title>詳細</v-card-title>

          <!-- 検索フォーム・検索ボタン -->
          <v-container>
            <!-- アラートエリア -->
            <v-container>
              <AlertArea
                  :isShow.sync="dtlAlerts.warning.isShow"
                  :message="dtlAlerts.warning.message"
                  type="warning"
              />
              <AlertArea
                  :isShow.sync="dtlAlerts.error.isShow"
                  :message="dtlAlerts.error.message"
                  type="error"
              />
            </v-container>
            <v-container>
              <v-form @submit.prevent="faqOptions.page = 1; faqSearch()">
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                        v-model="faqSearchCondition.question"
                        clearable
                        label="質問"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                        id="button_faq_search"
                        :loading="faqSearchLoading"
                        class="mx-5 mt-2"
                        color="primary"
                        @click="faqOptions.page = 1; faqSearch()"
                    >
                      <v-icon>mdi-magnify</v-icon>
                      検索
                    </v-btn
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-form>
            </v-container>

            <!-- テーブル -->
            <v-data-table
                v-model="selected"
                :footer-props="{
                  'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
                }"
                :headers="headers"
                :items="faqSearchDatalist"
                :loading="faqSearchLoading"
                :options.sync="faqOptions"
                :server-items-length="detailTotal"
                calculate-widths
                class="elevation-1"
                dense
                disable-sort
                item-key="id"
                @update:items-per-page="faqSearch()"
                @update:page="faqSearch()"
            >
              <!-- テーブルのanswer項目をv-slotでitemとして取得 -->
              <template v-slot:[`item.answer`]="{ item }">
                <v-edit-dialog>
                  <!-- テーブル内では、文を省略する -->
                  <div @click="answerDialog = true">
                    {{ item.answer | truncate }}
                  </div>
                  <!-- ダイアログ内処理 -->
                  <template v-slot:input>
                    <v-container>
                      <v-layout style="height: 30vh">
                        <v-flex style="overflow: visible scroll;">
                          <!-- 全文表示 -->
                          <div style="white-space:pre-wrap;">{{ item.answer }}</div>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="ma-6"
                  color="primary"
                  @click="
                  faqSearchCondition.question = '';
                  faqDetailDialog = false;
                "
              >閉じる
              </v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="600">
        <v-card>
          <v-card-title>この FAQ データを削除した場合、このデータを元に生成された FAQ 候補、AI教師データも削除されます。<b/>本当に削除しますか？</v-card-title>
          <v-container>
            <v-row justify="center">
              <v-card-actions class="mt-n4 mb-2">
                <v-btn
                    class="ma-6"
                    color="primary"
                    @click="deleteDialog = false"
                >キャンセル
                </v-btn
                >
                <v-btn
                    id="button_delete_mgt"
                    :loading="deleteLoading"
                    class="ma-6"
                    color="warning"
                    @click="deleteFaq()"
                >
                  <v-icon>mdi-delete</v-icon>
                  削除
                </v-btn
                >
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import NormalTextArea from "../../parts/NormalTextArea.vue";
import RequiredTextArea from "../../parts/RequiredTextArea.vue";
import NumberTextArea from "../../parts/NumberTextArea.vue";
import CalendarYYYYMMDD from "../../parts/CalendarYYYYMMDD.vue";
import ClosedSearchFormDisp from "../../parts/ClosedSearchFormDisp.vue";
import ClosedSearchFormFromToDisp from "../../parts/ClosedSearchFormFromToDisp.vue";
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import ValidationUtils from "../../js/ValidationUtils";
import AlertArea from "../../parts/AlertArea.vue";
import Messages from "../../js/Messages";
import StringUtils from "../../js/StringUtils";

export default {
  name: "FaqMgt",
  components: {
    AlertArea,
    NormalTextArea,
    RequiredTextArea,
    NumberTextArea,
    CalendarYYYYMMDD,
    ClosedSearchFormDisp,
    ClosedSearchFormFromToDisp,
  },
  methods: {

    /**
     * 検索条件をリセットする。
     **/
    searchConditionClear() {
      this.searchCondition.name = null;
      this.searchCondition.filename = null;
      this.searchCondition.dateFrom = null;
      this.searchCondition.dateTo = null;
      this.searchCondition.numberFrom = null;
      this.searchCondition.numberTo = null;
    },

    /**
     * 必須項目が入力してあるかチェックするルール。
     **/
    required: (value) => !!value || "",

    /**
     * 選択されたファイルの情報を保存する。
     * 名称にファイル名＋現在日付を入力する。
     * 同時に同義語用のファイルも生成して保持しておく。
     */
    async getFile(file) {

      // ×アイコンを押下するとfileにnullが連携されるので、そのエラーを抑制
      if (!file) {
        return;
      }

      // ローディング開始
      this.newData.loading = true;

      // アップロード時のファイルとファイル名を保持
      this.newData.file = file;
      this.newData.filename = file.name;

      // 名称入力
      const date = StringUtils.getNowDate();
      const name = StringUtils.removeExtension(file.name);
      this.newData.name = name + " " + date;

      // ローディング終了
      this.newData.loading = false;
    },

    /**
     * FAQの詳細情報を取得する。
     */
    async faqSearch() {
      this.logDebug("### faqSearch() 開始");

      // ローディング開始
      this.faqSearchLoading = true;
      try {
        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "question", this.faqSearchCondition.question);
        ObjectUtils.objectPush(qParams, "limits", this.faqOptions.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.faqOptions.page);

        // APIリクエスト（R8）
        this.logDebug("#### R8 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/faq/" + this.dataId,
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R8 成功");
          this.faqSearchDatalist = response.data.data;
          this.detailTotal = response.data.counts;
        } else {
          this.logDebug("#### R8 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.dtlAlerts);
          this.dtlAlerts.warning.isShow = true;
          this.dtlAlerts.warning.message = Messages.r8.warning;
        }
      } catch (error) {
        this.logDebug("#### faqSearch() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.dtlAlerts);
        this.dtlAlerts.error.isShow = true;
        this.dtlAlerts.error.message = Messages.r8.error;
      } finally {
        // ローディング終了
        this.faqSearchLoading = false;
      }
      this.logDebug("### faqSearch() 終了");
    },

    /**
     * 検索ボタン押下時の処理。
     * R6をリクエストして、FAQの情報を取得する。
     */
    async search() {
      this.logDebug("### search() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["search_form"])) {
        this.logDebug("### バリデーションNG search() 終了");
        return;
      }

      // ローディング開始
      this.searchLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "name", this.searchCondition.name);
        ObjectUtils.objectPush(qParams, "filename", this.searchCondition.filename);
        ObjectUtils.dateObjectPush(qParams, "created_at_from", this.searchCondition.dateFrom);
        ObjectUtils.dateObjectPush(qParams, "created_at_to", this.searchCondition.dateTo);
        ObjectUtils.objectPush(qParams, "row_count_min", this.searchCondition.numberFrom);
        ObjectUtils.objectPush(qParams, "row_count_max", this.searchCondition.numberTo);
        ObjectUtils.objectPush(qParams, "limits", this.options.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.options.page);

        // APIリクエスト（R6）
        this.logDebug("#### R6 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/faq",
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R6 成功");
          this.faqMgtDataList = response.data.data;
          this.total = response.data.counts;

          // ダウンロードアイコンをローディング中にするための前処理
          let tempArray = {};
          response.data.data.forEach(data => {
            tempArray[data.id] = false;
          })
          this.downloadLoading = tempArray;
        } else {
          this.logDebug("#### R6 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r6.warning;
        }
      } catch (error) {
        this.logDebug("#### search() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r6.error;
      } finally {
        // ローディング終了
        this.searchLoading = false;
      }
      this.logDebug("### search() 終了");
    },

    /**
     * ダウンロードボタン押下時の処理。
     * FAQファイルをダウンロードする。
     */
    async download(path, id) {
      this.logDebug("### download() 開始");
      try {
        this.downloadLoading[id] = true;

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "s3_path", path);

        // APIリクエスト（R2）
        this.logDebug("#### R2 リクエスト");
        const response = await ApiUtils.get("/common/download_url", qParams);

        if (200 === response.status) {
          this.logDebug("#### R2 成功");
          this.logDebug("#### R2 レスポンス.download_url：" + response.data.download_url);
          window.location.href = response.data.download_url;
        } else {
          this.logDebug("#### R2 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r2.warning;
        }
      } catch (error) {
        this.logDebug("#### download() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r2.error;
      } finally {
        this.downloadLoading[id] = false;
      }
      this.logDebug("### download() 終了");
    },

    /**
     * 削除ボタン押下時の処理。
     * 該当の処理FAQレコードを削除する。
     */
    async deleteFaq() {
      this.logDebug("### deleteFaq() 開始");
      try {
        ObjectUtils.alertsInit(this.alerts);
        this.deleteLoading = true;

        // APIリクエスト（R10）
        this.logDebug("#### R10 リクエスト");
        const response = await ApiUtils.deleteReq(
            "/generate_training_data/faq/" + this.deleteId
        );

        if (200 === response.status) {
          this.logDebug("#### R10 成功");
          this.alerts.success.isShow = true;
          this.alerts.success.message = Messages.r10.success;
          await this.search();
        } else {
          this.logDebug("#### R10 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r10.warning;
        }
      } catch (error) {
        this.logDebug("#### deleteFaq() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r10.error;
      } finally {
        this.deleteLoading = false;
        this.deleteDialog = false;
      }
      this.logDebug("### deleteFaq() 終了");
    },

    /**
     * 新規登録ボタンクリック時の処理。
     * FAQのcsvファイルの新規登録を行う。
     */
    async newRegister() {
      this.logDebug("### newRegister() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["register_form"])) {
        this.logDebug("### バリデーションNG newRegister() 終了");
        return;
      }

      // 登録処理開始
      ObjectUtils.alertsInit(this.alerts);
      this.newRegisterLoading = true;
      try {
        const r1Response = await ApiUtils.getPath(
            this.alerts,
            "faq",
            null,
            this.newData.filename
        );
        if (r1Response.is_success !== true) {
          this.logDebug("### アップロードURLとS3パス取得失敗 newRegister() 終了");
          return;
        }
        if (!(await ApiUtils.csvUpload(
            this.alerts,
            r1Response.upload_url,
            this.newData.file))) {
          this.logDebug("### CSVファイルアップロード失敗 newRegister() 終了");
          return;
        }

        const r4Response = await this.register(r1Response.s3_path);
        if (r4Response.is_success !== true) {
          this.logDebug("### CSVファイル登録失敗 newRegister() 終了");
          return;
        }

        // 同義語候補処理
        let synonymProject = false;
        let synonymFilename = "";
        if (this.checkSynonym) {
          this.logDebug("#### 同義語候補処理 開始");

          // 登録した FAQ CSV データの ID をパラメータに渡して、同義語データ作成を実行
          const createSynonymResponse = await this.createSynonym(r4Response.id);
          if (createSynonymResponse.is_success !== true) {
            this.logDebug("#### 同義語プロジェクト作成に失敗 同義語候補処理 終了");
            return;
          }

          // 成功時は、フラグを立てる
          synonymProject = true;
          synonymFilename = createSynonymResponse.name;
          this.logDebug("#### 同義語候補処理 終了");
        }

        // 成功時メッセージ表示
        this.alerts.success.isShow = true;
        if (synonymProject === true) {

          // 新規登録と同義語の成功メッセージ
          let reps = [
            this.newData.name,
            this.newData.filename,
            synonymFilename,
            this.newData.filename,
          ];
          this.alerts.success.message = StringUtils.messageReplace(
              Messages.faqSynonymProject.success,
              reps
          );
        } else {

          // 新規登録のみの成功メッセージ
          let reps = [this.newData.name, this.newData.filename];
          this.alerts.success.message = StringUtils.messageReplace(
              Messages.r4.success,
              reps
          );
        }

        // フォームリセットと再検索
        ValidationUtils.reset(this.$refs["register_form"]);
        await this.search();
      } finally {
        this.newRegisterLoading = false;
        this.dialog = false;
      }
      this.logDebug("### newRegister() 終了");
    },

    /**
     * R4を使ってFAQのcsvファイルを登録する。
     */
    async register(s3_path) {
      this.logDebug("### register() 開始");
      let ret = {
        is_success: false,
        id: null
      };
      try {
        let body = {};
        ObjectUtils.objectPush(body, "name", this.newData.name);
        ObjectUtils.objectPush(body, "s3_path", s3_path);

        // APIリクエスト（R4）
        this.logDebug("#### R4 リクエスト");
        const response = await ApiUtils.post(
            "/generate_training_data/faq",
            body
        );

        if (200 === response.status) {
          this.logDebug("#### R4 成功");
          ret = {
            is_success: true,
            id: response.data.id
          };
          return ret;
        } else {
          this.logDebug("#### R4 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r4.warning;
        }
      } catch (error) {
        this.logDebug("#### register() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r4.error;
      }
      this.logDebug("### register() 終了");
      return ret;
    },

    /**
     * 同義語 API（ Ten API ）のラッパーを呼び出して、
     * 同義語プロジェクト作成、単語分散表現データ作成、
     * データインポート、単語分散表現データ解析、を実行する
     *
     * 同義語プロジェクト作成、単語分散表現データ作成、までを同期的に実行した後、レスポンスを返し
     * その後、バックグラウンドで非同期的にデータインポート、単語分散表現データ解析、を実行する
     */
    async createSynonym(faqId) {
      this.logDebug("#### createSynonym() 開始");
      let ret = {
        is_success: false,
        name: null,
        project_key: null,
        word_embedding_key: null
      };
      try {
        let body = {};

        // APIリクエスト（同義語データ作成）
        this.logDebug("##### 同義語データ作成 リクエスト");
        const response = await ApiUtils.post(
            "/synonym/create_synonym/faq/" + String(faqId),
            body
        );

        if (200 === response.status) {
          this.logDebug("##### 同義語データ作成 成功");
          ret = {
            is_success: true,
            name: response.data.name,
            project_key: response.data.project_key,
            word_embedding_key: response.data.word_embedding_key
          };
        } else {
          this.logDebug("##### 同義語データ作成 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r513.warning;
        }
      } catch (error) {
        this.logDebug("#### createSynonym() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r513.error;
      }
      this.logDebug("##### レスポンス：" + JSON.stringify(ret));
      this.logDebug("#### createSynonym() 終了");
      return ret;
    },
  },
  data: () => ({
    dataId: null,
    deleteId: null,
    tempFaqFile: null,
    newRegisterLoading: false,
    searchLoading: false,
    faqSearchLoading: false,
    dialog: false,
    answerDialog: false,
    deleteDialog: false,
    deleteLoading: false,
    faqDetailDialog: false,
    checkSynonym: false,
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    dtlAlerts: {
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    selected: [],
    total: 0,
    headers: [
      {align: "start", text: "ID", value: "faq_id"},
      {align: "start", text: "質問", value: "question", width: "50%"},
      {align: "start", text: "回答", value: "answer",},
    ],
    options: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ["id"],
      sortDesc: [false],
    },
    detailTotal: 0,
    faqOptions: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ["id"],
      sortDesc: [false],
    },
    faqSearchCondition: {
      question: "",
    },
    searchCondition: {
      name: null,
      filename: null,
      dateFrom: null,
      dateTo: null,
      numberFrom: null,
      numberTo: null,
    },
    newData: {
      name: "",
      filename: null,
      file: null,
      loading: false,
    },
    faqMgtHeaders: [
      {align: "start", text: "FAQデータ名", value: "name"},
      {align: "start", text: "ファイル名", value: "filename"},
      {align: "start", text: "登録日時", value: "created_at"},
      {align: "end", text: "件数", value: "row_count"},
      {align: "start", text: "操作", value: "operation", width: "185"},
    ],
    faqMgtDataList: [],
    faqSearchDatalist: [],
    downloadLoading: [],
  }),
  mounted: function () {
    // 画面表示のタイミングで検索を実行
    this.logDebug("# FAQMgt.vue mounted");
    this.search();
  },
};
</script>
