<template>
  <v-main>
    <!-- タイトル -->
    <Title title="文書から FAQ候補生成"/>

    <!-- タブ -->
    <DocumentToFAQTab/>

  </v-main>
</template>

<script>
import Title from '../parts/Title.vue';
import DocumentToFAQTab from '../tabs/DocumentToFAQTab.vue';

export default {
  name: 'DocumentToFAQ',
  components: {
    Title,
    DocumentToFAQTab
  },
  data: () => ({}),
};
</script>
