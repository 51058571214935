import EnvAccess from "@/components/js/EnvAccessor";

/**
 * ログレベルの定義。
 * process.env.VUE_APP_API_LOG_LEVEL はこの定義を元に数値、
 * またはログレベルを表した文字列を設定する。
 * 使える文字列はlevelSwitch()を参照。
 */
const level = {
    debug: 700, // 細かいものはすべてdebugで表示するイメージ
    info: 800, // 情報
    warning: 900, // 警告
    severe: 1000, // 重大
}

/**
 * ログレベルを取得する。
 * process.env.VUE_APP_API_LOG_LEVELにログレベルを表した文字列があれば、それを数値にする。
 * 対応する文字列が無い場合は、重大(SEVERE)のレベルを返却する。
 * 'DEBUG','INFO','WARNING','SEVERE' が使用可能。
 * 数値がセットされている場合はそれをログレベルにする。
 *
 * @return {string} 文字列に応じたレベル
 */
function getLogLevel() {

    // ログレベルに値が設定されていない場合は重大(SEVERE)を返却
    const logLevel = EnvAccess.getEnvLogLevel();
    if (logLevel == null || logLevel === "") {
        return level.severe;
    }

    // 設定された値が数値ならその値を使う
    if (!isNaN(logLevel)) {
        return logLevel;
    }

    // ログレベルに設定された文字列を数値に変換
    let ret;
    switch (logLevel) {
        case 'DEBUG':
            ret = level.debug;
            break;
        case 'INFO':
            ret = level.info;
            break;
        case 'WARNING':
            ret = level.warning;
            break;
        case 'SEVERE':
        default:
            ret = level.severe;
            break;
    }
    return ret;
}

export default {
    getLogLevel, // 本来は外から使える必要がないが、単体テストで呼び出すことができないため、export defaultに記載している

    /**
     * ■ 補足１ methods.logDebug()の呼び出し方
     * vueコンポーネントから呼び出す場合、インポート不要で以下を実行。
     *   this.logDebug("ログメッセージ")
     * jsコンポーネントから呼び出す場合、logger.jsをインポート後、
     *   logger.methods.logDebug("ログメッセージ")
     * 尚、logger.jsはmain.jsにてミックスインをしている。
     *
     * ■ 補足２ テストについて
     * vueコンポーネントで当処理を使ってログ出力している場合、テスト用のコンポーネントにもミックスインが必要となる。
     * 以下の記述が必要。
     *
     * import logger from '@/mixins/logger';
     * ..中略....
     * const localVue = createLocalVue()
     * localVue.mixin(logger)
     */
    methods: {

        /**
         * デバッグレベルのログを出力する。
         * ログレベル(process.env.VUE_APP_API_LOG_LEVEL)が
         * level.debug 以下の場合にコンソールログを出力する。
         * @param log console.log()に出力する文字列
         */
        logDebug(log) {
            if (level.debug >= getLogLevel()) {
                console.log(log);
            }
        },

        /**
         * 情報レベルのログを出力する。
         * ログレベル(process.env.VUE_APP_API_LOG_LEVEL)が
         * level.info 以下の場合にコンソールログを出力する。
         * @param log console.log()に出力する文字列
         */
        logInfo(log) {
            if (level.info >= getLogLevel()) {
                console.info(log);
            }
        },

        /**
         * 警告レベルのログを出力する。
         * ログレベル(process.env.VUE_APP_API_LOG_LEVEL)が
         * level.warning 以下の場合にコンソールログを出力する。
         * @param log console.log()に出力する文字列
         */
        logWarning(log) {
            if (level.warning >= getLogLevel()) {
                console.warn(log);
            }
        },

        /**
         * 重大レベルログを出力する。
         * ログレベル(process.env.VUE_APP_API_LOG_LEVEL)が
         * level.severe 以下の場合にコンソールログを出力する。
         * @param log console.log()に出力する文字列
         */
        logSevere(log) {
            if (level.severe >= getLogLevel()) {
                console.error(log); // 将来的にサーバーにログを残したいといった場合はこのファンクションを拡張か？
            }
        },

    },
}