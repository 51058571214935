<template>
  <v-container>
    <h3>{{ title }}</h3>
  </v-container>
</template>

<script>
export default {
  props: ['title']
};
</script>