// state: グローバルな記憶領域。ただしコンポートから直接アクセスはできない
const state = {
    username: null,
    name: null,
    token: null,
    refreshToken: null,
    tenant: null,
    isLogin: false,
    roles: null
}

// getters: 外部からstate領域のデータを取得するために使う
// this.$store.getters['user/name'];
const getters = {
    username: state => state.username,
    name: state => state.name,
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    tenant: state => state.tenant,
    isLogin: state => state.isLogin,
    roles: state => state.roles
}

// mutations: stateを書き換える処理を担当する
// 外部から呼び出しも可能 （非同期処理は定義不可）
// 使用例：this.$store.commit('user/logout');
const mutations = {
    setUsername(state, username) {
        state.username = username;
    },
    setName(state, name) {
        state.name = name;
    },
    setToken(state, token) {
        state.token = token;
    },
    setRefreshToken(state, refreshToken) {
        state.refreshToken = refreshToken;
    },
    setTenant(state, tenant) {
        state.tenant = tenant;
    },
    setIsLogin(state, isLogin) {
        state.isLogin = isLogin;
    },
    setRoles(state, roles) {
        state.roles = roles;
    },
    /**
     * ログイン時に保持した情報をすべて破棄する。
     */
    logout(state) {
        state.username = null;
        state.name = null;
        state.token = null;
        state.refreshToken = null;
        state.tenant = null;
        state.isLogin = false;
        state.roles = null;
    }
}

// actions: 外部から呼び出す処理を定義する
// （非同期処理も定義可）
// 使用例：this.$store.dispatch('user/logout');
const actions = {
    setUsername({commit}, username) {
        commit('setUsername', username)
    },
    setName({commit}, name) {
        commit('setName', name)
    },
    setToken({commit}, token) {
        commit('setToken', token)
    },
    setRefreshToken({commit}, refreshToken) {
        commit('setRefreshToken', refreshToken)
    },
    setTenant({commit}, tenant) {
        commit('setTenant', tenant)
    },
    setIsLogin({commit}, isLogin) {
        commit('setIsLogin', isLogin)
    },
    setRoles({commit}, roles) {
        commit('setRoles', roles)
    },
    logout({commit}) {
        commit('logout')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}