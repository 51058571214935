import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import user from './modules/user'
import EnvAccessor from "@/components/js/EnvAccessor";

Vue.use(Vuex)

export default new Vuex.Store({
    strict: EnvAccessor.getNodeEnv() !== 'production',
    modules: {
        user
    },
    plugins: [createPersistedState({
        key: 'KnowledgeGenerationApp',
        paths: ['user.username', 'user.name', 'user.token', 'user.refreshToken', 'user.tenant', 'user.isLogin', 'user.roles'],
        storage: window.localStorage
    })]
})