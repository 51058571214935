<template>
  <v-card>

    <!-- 閉じるボタン -->
    <v-btn id="button_close" icon
           @click="closeDialog()">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- 教師データリスト -->
    <v-container>
      <v-row>
        <v-col class="ml-6" cols="3">クラスタ番号</v-col>
        <v-col cols="8">{{ cluster_no }}</v-col>
      </v-row>

      <v-row>
        <v-col class="ml-6" cols="3">代表質問文</v-col>
        <v-col cols="8">{{ question }}</v-col>
      </v-row>

      <v-row>
        <v-col class="ml-6" cols="3">クラスタサイズ</v-col>
        <v-col cols="8">{{ cluster_size }}</v-col>
      </v-row>

      <br/>
      <v-data-table
          v-model="outputTargets"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500, 1000],
          }"
          :headers="createFAQCandidateListHeaders"
          :items="createFAQCandidateList"
          :loading="getCreateFAQCandidateListLoading"
          :options.sync="options"
          :server-items-length="total"
          calculate-widths
          class="elevation-1 row-pointer"
          dense
          disable-sort
          item-key="id"
          show-select
          @click:row="rowClickEvent"
          @update:items-per-page="getCreateFAQCandidateDetailList()"
          @update:page="getCreateFAQCandidateDetailList()">
      </v-data-table>
    </v-container>

    <v-dialog v-model="detailDialog" max-width="800">
      <v-card>
        <v-card-title>詳細</v-card-title>
        <v-container>
          <v-row>
            <v-col class="ml-6" cols="3"> 応対履歴番号:</v-col>
            <v-col cols="8">
              {{ detailDialogData.case_no }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="ml-6" cols="3"> クラスタ番号:</v-col>
            <v-col cols="8">
              {{ detailDialogData.cluster_no }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="ml-6" cols="3"> 質問文:</v-col>
            <v-col cols="8">
              {{ detailDialogData.question }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="ml-6" cols="3"> 回答文:</v-col>
            <v-col cols="8">
              {{ detailDialogData.answer }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="ml-6" cols="3"> 抽出質問文:</v-col>
            <v-col cols="8">
              {{ detailDialogData.generated_question }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-6" cols="3"> 抽出回答文:</v-col>
            <v-col cols="8">
              {{ detailDialogData.generated_answer }}
            </v-col>
          </v-row>

          <v-row class="pa-6" justify="center">
            <v-btn id="button_dialog_close" color="primary" @click="detailDialog = false">
              閉じる
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- ファイル出力ボタン -->
    <v-container class="mt-n3">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn
            id="button_download"
            class="ma-3"
            color="primary"
            @click="downloadCSV()"
        >
          <v-icon>mdi-file-export-outline</v-icon>
          ファイル出力
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

import AlertArea from "../../parts/AlertArea.vue";
import FileUtils from "../../js/FileUtils";
import ObjectUtils from "../../js/ObjectUtils";
import ApiUtils from "../../js/ApiUtils";
import Messages from "../../js/Messages";

export default {
  name: "CreateFAQCandidateResultDetail",
  components: {
    AlertArea
  },
  props: {
    jobId: Number, //ジョブの ID
    dialog: Boolean, // 大元のダイアログフラグ

    //CreateFAQCandidateResultから受け取る内容
    cluster_no: Number,
    question: String,
    cluster_size: Number,
  },

  data: () => ({
    detailDialog: false,
    getCreateFAQCandidateListLoading: false,
    detailDialogData: [],
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    createFAQCandidateListHeaders: [
      {align: "end", text: "応対履歴番号", value: "case_no"},
      {align: "start", text: "抽出質問文", value: "generated_question", width:"40%"},
      {align: "start", text: "抽出回答文", value: "generated_answer"},
    ],
    total: 0,
    options: {
      page: 1,
      itemsPerPage: 20,
    },
    outputTargets: [],
    createFAQCandidateList: []
  }),

  methods: {

    /**
     * 行をクリックした際の処理。
     * 選択された行のデータを引き継ぎ、ダイアログを表示する。
     **/
    rowClickEvent(item) {
      this.detailDialogData = item;
      this.detailDialog = true;
    },

    /**
     * FAQ候補生成の詳細リストを取得。
     **/
    async getCreateFAQCandidateDetailList() {
      this.logDebug("### getCreateFAQCandidateDetailList() 開始");

      // ローディング開始
      this.getCreateFAQCandidateListLoading = true;

      try {
        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "cluster_no", this.cluster_no);
        ObjectUtils.objectPush(qParams, "limits", this.options.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.options.page);

        // APIリクエスト（R38）
        this.logDebug("#### R38 リクエスト");
        const response = await ApiUtils.get(
            "/faq_from_case_log/job/" + this.jobId + "/detail",
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R38 成功");
          this.createFAQCandidateList = response.data.data;
          this.total = response.data.counts;
        } else {
          this.logDebug("#### R38 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r38.warning;
        }
      } catch (error) {
        this.logDebug("#### getCreateFAQCandidateDetailList() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r38.error;
      } finally {

        // ローディング終了
        this.getCreateFAQCandidateListLoading = false;
      }
      this.logDebug("### getCreateFAQCandidateDetailList() 終了");
    },

    /**
     * CSVファイルを出力してダウンロードする。
     * 出力対象はoutputTargets。
     */
    downloadCSV() {
      this.logDebug("### downloadCSV() 開始");
      if (this.outputTargets[0] == null) {
        this.logDebug("### 未選択 downloadCSV() 終了");
        alert(Messages.faqCandidateResultDetailFileOutput.error);
        return;
      }

      // FileUtils.downLoadCSV()で適切に出力されるようにデータを複製
      FileUtils.duplicateJsonKey(this.outputTargets, "case_no", "応対履歴番号");
      FileUtils.duplicateJsonKey(this.outputTargets, "cluster_no", "クラスタ番号");
      FileUtils.duplicateJsonKey(this.outputTargets, "question", "質問文");
      FileUtils.duplicateJsonKey(this.outputTargets, "answer", "回答文");
      FileUtils.duplicateJsonKey(this.outputTargets, "generated_question", "抽出質問文");
      FileUtils.duplicateJsonKey(this.outputTargets, "generated_answer", "抽出回答文");

      // CSVに出力するカラムを定義
      const fields = ["応対履歴番号", "クラスタ番号", "質問文", "回答文", "抽出質問文", "抽出回答文"];
      const filename = "FAQ候補生成結果明細.csv";
      FileUtils.downLoadCSV(fields, this.outputTargets, filename);
      this.logDebug("### downloadCSV() 終了");
    },

    /**
     * 親にダイアログをクローズしてもらう。
     */
    closeDialog() {
      this.$emit("update:dialog", false);
    },

    /**
     * 画面を描画する際の初期処理。
     * （初回描画、groupIdが切り替わったタイミングなど）
     */
    async init() {
      this.logDebug("## init() 開始");

      // 変数初期化
      this.outputTargets = [];
      this.createFAQCandidateList = [];
      this.options.itemsPerPage = 20;
      this.options.page = 1;

      // ローディング開始
      this.getCreateFAQCandidateListLoading = true;

      // FAQ候補生成詳細を取得
      await this.getCreateFAQCandidateDetailList();

      //ローディング終了
      this.getCreateFAQCandidateListLoading = false;
      this.logDebug("## init() 終了");
    }
  },
  mounted: function () {
    this.logDebug("# CreateFAQCandidateResultDetail.vue mounted");
    this.init();
  },
  watch: {
    cluster_no: function () {
      this.logDebug("# watch cluster_no：" + this.cluster_no);
      this.init();
      this.alerts.info.isShow = false;
      this.alerts.success.isShow = false;
      this.alerts.warning.isShow = false;
      this.alerts.error.isShow = false;
    }
  },

};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>