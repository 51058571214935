<template>
  <div v-if="isShow"
       style="white-space:pre-wrap;
       word-wrap:break-word;">
    <v-alert
        v-if="type==='info'"
        dense
        dismissible
        text
        type="info"
        @input="close()"
    >
      {{ message }}
    </v-alert>
    <v-alert
        v-else-if="type==='success'"
        dense
        dismissible
        text
        type="success"
        @input="close()"
    >
      {{ message }}
    </v-alert>
    <v-alert
        v-else-if="type==='warning'"
        color="orange"
        dense
        dismissible
        text
        type="warning"
        @input="close()"
    >
      {{ message }}
    </v-alert>
    <v-alert
        v-else-if="type==='error'"
        dense
        dismissible
        text
        type="error"
        @input="close()"
    >
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'AlertArea',
  props: ['type', 'message', 'isShow'],
  methods: {
    close(val) {
      this.$emit('update:isShow', val)
    },
  },
};
</script>