<template>
  <v-form>

    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="uncompletedAlerts.warning.isShow" :message="uncompletedAlerts.warning.message"
                 type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>
    <v-form ref="register_form">
      <v-container>
        <v-row class="mt-n7" dense>
          <v-col cols="5">
            <v-file-input
                v-model="files"
                :rules="[required]"
                accept=".csv"
                label="応対履歴csvファイルを複数指定"
                multiple
                truncate-length="25"
                v-bind:disabled="addFilesLoading"
                @change="getFiles"
            >
              <template v-slot:selection="{ text }">
                <v-chip color="primary" label small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="3">
            <v-row justify="end">
              <v-col cols="9">
                <v-checkbox
                    v-model="checkSynonym"
                    label="同義語候補も追加する"
                    v-bind:disabled="addFilesLoading"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-btn
                id="button_add_files"
                :loading="addFilesLoading"
                class="ma-3"
                color="secondary"
                @click="addFiles()"
            >
              <v-icon>mdi-file-plus</v-icon>
              追加
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-n7 ml-8 mb-7" dense>
          <v-col cols="5">
            <small class="text--disabled"
            >複数の応対履歴ファイルが指定可能です。</small
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-container>
      <v-data-table
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
          }"
          :headers="headers"
          :items="items"
          :loading="getDatasetDetailsLoading"
          :options.sync="options"
          :server-items-length="total"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          @update:items-per-page="getDatasetDetails()"
          @update:page="getDatasetDetails()"
      >
        <template v-slot:[`item.operation`]="{ item }">
          <v-btn
              id="button_detail"
              class="primary"
              small
              :loading="previewLoading[item.id]"
              @click="getPreviewData(item.id)"
          >
            <v-icon>mdi-file-find</v-icon>
            詳細
          </v-btn>
          <v-btn
              id="button_download"
              :loading="downloadLoading[item.id]"
              class="primary"
              small
              @click="download(item.s3_path, item.id)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn
              id="button_delete"
              color="warning"
              small
              @click="
              deleteId = item.id;
              erasure = true;
            "
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>

    <!--プレビューダイアログを表示-->
    <v-dialog v-model="previewDialog" max-width="80%">
      <v-card>
        <v-container>
          <v-layout class="mt-3" style="height: 40vh">
            <v-layout style="border: solid 1px #d3d3d3">
              <v-flex style="overflow: visible scroll">
                <div style="white-space: pre-wrap">{{ dataSample }}</div>
              </v-flex>
            </v-layout>
          </v-layout>
          <br/>
          <v-row class="mt-n3 mb-1" justify="center">
            <v-btn class="ma-6" color="primary" @click="previewDialog = false"
            >閉じる
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="erasure" max-width="600">
      <v-card>
        <v-card-title>この応対履歴データセットを元に FAQ 候補、AI教師データを生成中の場合、生成結果に不整合が発生する可能性があります。<b/>本当に削除しますか？</v-card-title>
        <v-container>
          <v-row justify="center">
            <v-card-actions class="mt-n4 mb-2">
              <v-btn class="ma-6" color="primary" @click="erasure = false"
              >キャンセル
              </v-btn>
              <v-btn
                  id="button_delete_dialog"
                  class="ma-6"
                  color="warning"
                  :loading="deleteLoading"
                  @click="deleteDoc()"
              >
                <v-icon>mdi-delete</v-icon>
                削除
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

  </v-form>
</template>
<script>
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import AlertArea from "../../parts/AlertArea.vue";
import Messages from "../../js/Messages";
import ValidationUtils from "../../js/ValidationUtils";
import StringUtils from "@/components/js/StringUtils";

export default {
  props: {
    datasetGroupId: Number,
  },
  components: {
    AlertArea,
  },
  methods: {

    /**
     * rules に指定する。必須項目となるルール。
     */
    required: (value) => !!value || "",

    /**
     * 応対履歴のプレビューデータを取得する。
     */
    async getPreviewData(id) {
      this.logDebug("### getPreviewData() 開始");
      this.logDebug("#### 引数 id：" + id);
      try {
        this.previewLoading[id] = true;

        // APIリクエスト（R7）
        this.logDebug("#### R7 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/case_log/" + id + "/preview",
            ""
        );

        if (200 === response.status) {
          this.logDebug("#### R7 成功");
          this.dataSample = response.data.data_sample;
          this.previewDialog = true;
        } else {
          this.logDebug("#### R7 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.uncompletedAlerts.warning.isShow = false;
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r7.warning;
          this.previewDialog = false;
        }
      } catch (error) {
        this.logDebug("#### getPreviewData() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.uncompletedAlerts.warning.isShow = false;
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r7.error;
        this.previewDialog = false;
      } finally {
        this.previewLoading[id] = false;
      }
      this.logDebug("### getPreviewData() 終了");
    },

    /**
     * ダウンロードボタン押下時の処理。
     * 応対履歴ファイルをダウンロードする。
     */
    async download(path, id) {
      this.logDebug("### download() 開始");
      this.logDebug("#### 引数 path：" + path + ", id：" + id);
      try {
        this.downloadLoading[id] = true;

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "s3_path", path);

        // APIリクエスト（R2）
        this.logDebug("#### R2 リクエスト");
        const response = await ApiUtils.get("/common/download_url", qParams);

        if (200 === response.status) {
          this.logDebug("#### R2 成功");
          this.logDebug("##### response.data.download_url：" + response.data.download_url);
          window.location.href = response.data.download_url;
        } else {
          this.logDebug("#### R2 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.uncompletedAlerts.warning.isShow = false;
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r2.warning;
        }
      } catch (error) {
        this.logDebug("#### download() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.uncompletedAlerts.warning.isShow = false;
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r2.error;
      } finally {
        this.downloadLoading[id] = false;
      }
      this.logDebug("### download() 終了");
    },

    /**
     * 応対履歴データセットを削除する。
     */
    async deleteDoc() {
      this.logDebug("### deleteDoc() 開始");
      try {

        // アラートエリア初期化
        ObjectUtils.alertsInit(this.alerts);
        this.uncompletedAlerts.warning.isShow = false;
        this.deleteLoading = true;

        // APIリクエスト（R33）
        this.logDebug("#### R33 リクエスト");
        const response = await ApiUtils.deleteReq(
            "/generate_training_data/case_log_dataset/" +
            this.datasetGroupId +
            "/file/" +
            this.deleteId
        );

        // 正常終了したら再度検索。検索後にメッセージ表示
        if (200 === response.status) {
          this.logDebug("#### R33 成功");
          await this.getDatasetDetails();
          this.alerts.success.isShow = true;
          this.alerts.success.message = Messages.r33.success;
        } else if (406 === response.status) {
          this.logDebug("#### R33 失敗 最後の 1ファイルの削除失敗：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r33.warning406;
        } else {
          this.logDebug("#### R33 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r33.warning;
        }
      } catch (error) {
        this.logDebug("#### deleteDoc() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r33.error;
      } finally {
        this.deleteLoading = false;
        this.erasure = false;
      }
      this.logDebug("### deleteDoc() 終了");
    },

    /**
     * 画面表示時の検索処理。
     */
    async getDatasetDetails() {
      this.logDebug("### getDatasetDetails() 開始");

      // ローディング開始
      this.getDatasetDetailsLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "id", this.datasetGroupId);
        ObjectUtils.objectPush(qParams, "limits", this.options.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.options.page);

        // APIリクエスト（R32）
        this.logDebug("#### R32 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/case_log_dataset/" + this.datasetGroupId,
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R32 成功");
          this.items = response.data.data;
          this.total = response.data.counts;

          // ダウンロードアイコンをローディング中にするための前処理
          let tempArray = {};
          response.data.data.forEach(data => {
            tempArray[data.id] = false;
          })
          this.downloadLoading = tempArray;
          this.previewLoading = {...tempArray};
        } else {
          this.logDebug("#### R32 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.uncompletedAlerts.warning.isShow = false;
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r32.warning;
        }
      } catch (error) {
        this.logDebug("#### getDatasetDetails() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.uncompletedAlerts.warning.isShow = false;
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r32.error;
      } finally {
        // ローディング終了
        this.getDatasetDetailsLoading = false;
      }
      this.logDebug("### getDatasetDetails() 終了");
    },

    /**
     * 追加ボタンクリック時の処理。
     * 応対履歴の追加を行う。
     */
    async addFiles() {
      this.logDebug("### addFiles() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["register_form"])) {
        this.logDebug("### バリデーションNG addFiles() 終了");
        return;
      }

      // ファイル追加中の場合は処理終了
      if (this.addFilesLoading) {
        this.logDebug("### ファイル追加中 addFiles() 終了");
        return;
      }

      // アラートエリア初期化
      ObjectUtils.alertsInit(this.alerts);
      this.uncompletedAlerts.warning.isShow = false;

      // ファイル追加処理開始
      this.addFilesLoading = true;
      try {

        // 登録成功時のメッセージを保持する配列。
        let successMessages = [];
        let uploadedFileIdList = []; // アップロード成功した応対履歴 CSV ファイルの ID（同義語データ追加インポート時に利用する）
        const id = this.datasetGroupId;
        this.logDebug("#### 追加ファイル件数：" + this.newData.files.length);
        let i = 0; // どこまで処理したか覚えておくため変数は外に出しておく
        for (; i < this.newData.files.length; i++) {
          this.logDebug("##### ファイル：" + this.newData.files[i].filename + " 処理開始");

          // R1 アップロードURLとS3パス取得
          const r1Response = await ApiUtils.getPath(
              this.alerts, "case_log", id, this.newData.files[i].filename);
          if (r1Response.is_success !== true) {
            this.logDebug("### アップロードURLとS3パス取得に失敗 addFiles() 終了");
            break;
          }

          // ファイルアップロード
          if (!(await ApiUtils.csvUpload(
              this.alerts, r1Response.upload_url, this.newData.files[i].file))) {
            this.logDebug("### ファイルアップロード失敗 addFiles() 終了");
            break;
          }

          // R30 応対履歴ファイル登録
          const r30Response = await this.registerR30(r1Response.s3_path, id, this.newData.files[i].filename);
          if (!r30Response.is_success) {
            this.logDebug("### ファイル登録に失敗 addFiles() 終了");
            break;
          }

          // アップロード成功した応対履歴 CSV ファイルの ID を保存（同義語データ追加インポート時に利用する）
          uploadedFileIdList.push(r30Response.id);
          successMessages.push("・[ファイル名]：" + this.newData.files[i].filename + "\n");
          this.logDebug("##### ファイル：" + this.newData.files[i].filename + " 処理終了");
        }

        // 未処理のファイルがあれば画面にファイル名を保持しておく
        let uncompletedMessages = [];
        for (i += 1; i < this.newData.files.length; i++) {
          uncompletedMessages.push("・[ファイル名]：" + this.newData.files[i].filename + "\n");
        }

        // 「同義語候補も追加する」のチェックがついている場合かつ、ファイルの登録が1件以上成功している場合に同義語処理をする
        let isSynonymSuccess = false;
        if (this.checkSynonym && successMessages.length > 0) {
          this.logDebug("#### 同義語処理開始");

          // この応対履歴データセットと紐つく同義語プロジェクトの情報を取得する
          const synonymAndData = await this.getSynonymAndData(id);
          if (synonymAndData && synonymAndData.is_success && synonymAndData.found) {

            // 紐つく同義語プロジェクトが存在する場合、追加した応対履歴 CSV ファイルのデータを、追加インポートする
            const addSynonymDataResponse = await this.addSynonymData(
                synonymAndData.project_key,
                synonymAndData.word_embedding_key,
                id,
                uploadedFileIdList
            );

            if (!addSynonymDataResponse.is_success) {
              this.logDebug("#### 同義語追加に失敗");
            } else {
              isSynonymSuccess = true; // 同義語処理成功
            }
          } else if (synonymAndData && synonymAndData.is_success && !synonymAndData.found) {

            // 紐つく同義語プロジェクトが存在しない場合は、新規に登録する
            const createSynonymResponse = await this.createSynonym(id);
            if (!createSynonymResponse.is_success) {
              this.logDebug("#### 同義語新規登録に失敗");
            } else {
              isSynonymSuccess = true; // 同義語処理成功
            }
          } else {
            this.logDebug("#### 同義語プロジェクトの情報取得に失敗");
          }
          this.logDebug("#### 同義語処理終了");
        }

        // ファイル追加成功している場合は成功メッセージを表示
        if (successMessages.length > 0) {
          let message = Messages.r30.addSuccess + "\n";
          if (isSynonymSuccess) {

            // 同義語処理が成功している場合も追加する」のチェックがついている場合
            message += Messages.addSynonymData.success + "\n"; // 同義語データ登録完了のメッセージを追加
          }
          for (let i = 0; i < successMessages.length; i++) {
            message += successMessages[i];
          }

          // 最後の改行削除
          message = message.slice(0, -1);
          this.alerts.success.message = message;
          this.alerts.success.isShow = true;
        }

        // 未処理ファイルがあれば未処理を伝えるメッセージを表示
        if (uncompletedMessages.length > 0) {
          this.uncompletedAlerts.warning.message = Messages.r30.uncompleted + "\n";
          uncompletedMessages.forEach(str => {
            this.uncompletedAlerts.warning.message += str;
          });
          this.uncompletedAlerts.warning.message = this.uncompletedAlerts.warning.message.slice(0, -1);
          this.uncompletedAlerts.warning.isShow = true;
        }

        // 画面表示時の再検索処理
        this.options.page = 1;
        await this.getDatasetDetails();

        // フォームのリセット
        ValidationUtils.reset(this.$refs["register_form"]);
        this.files = null;
      } finally {
        this.addFilesLoading = false;
        this.logDebug("### addFiles() 終了");
      }
    },

    /**
     * 選択されたファイルの情報を保存する。
     */
    async getFiles(files) {
      this.logDebug("### getFiles() 開始");

      // ファイルが指定されていない場合は処理終了
      if (files == null || files.length === 0) {
        this.logDebug("### ファイルが指定されていない getFiles() 終了");
        return;
      }

      // ローディング開始
      this.newData.loading = true;
      let tmpFiles = [];
      for (let i = 0; i < files.length; i++) {
        let tmpFile = {};
        tmpFile["filename"] = files[i].name;
        tmpFile["file"] = files[i];
        tmpFiles.push(tmpFile);
      }
      this.newData.files = tmpFiles;
      this.newData.loading = false;
      this.logDebug("### getFiles() 終了");
    },

    /**
     * R30 データセットと応対履歴ファイルの紐付け情報を登録 API を呼び出す
     *
     * R30 API のレスポンスから id 項目を取得するため、ApiUtils.registerR30 function ではなく、
     * ここで独自に定義した function を利用する
     */
    async registerR30(s3_path, datasetId, filename) {
      this.logDebug("#### registerR30. s3_path: " + s3_path + ", datasetId: " + datasetId + ", filename:" + filename);

      try {
        let body = {};
        ObjectUtils.objectPush(body, "s3_path", s3_path);

        // APIリクエスト（R30）
        this.logDebug("#### R30 リクエスト");
        const response = await ApiUtils.post(
            "/generate_training_data/case_log_dataset/" +
            String(datasetId) +
            "/file",
            body
        );
        if (200 === response.status) {
          this.logDebug("#### R30 成功 登録したID：" + response.data.id);
          return {is_success: true, id: response.data.id}; // 成功（登録したレコードの ID も返す）
        } else {
          this.logDebug("#### registerR30. Bad response:");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = StringUtils.messageReplace(Messages.r30.warning, [filename])
        }
      } catch (error) {
        this.logDebug("#### registerR30. Error:");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = StringUtils.messageReplace(Messages.r30.error, [filename])
      }
      return {is_success: false}; // エラー時は is_success: false を返す
    },

    /**
     * 応対履歴データセットと紐つく同義語プロジェクト情報を取得する
     */
    async getSynonymAndData(datasetId) {
      this.logDebug("#### getSynonymAndData. datasetId: " + datasetId);

      try {
        // APIリクエスト（同義語プロジェクト情報取得）
        this.logDebug("#### 同義語プロジェクト情報取得 リクエスト");
        const response = await ApiUtils.get(
            "/synonym/get_synonym_and_data/case_log/" + String(datasetId),
            {}
        );

        if (200 === response.status) {
          this.logDebug("#### 同義語プロジェクト情報取得 成功");
          return {
            is_success: true,
            found: true,
            project_key: response.data.project_key,
            word_embedding_key: response.data.word_embedding_key,
          }; // 対象の同義語プロジェクトが見つかった
        } else if (422 === response.status) {
          this.logDebug("#### 同義語プロジェクト情報取得 失敗");
          this.logDebug("#### getSynonymAndData. Bad response:");
          this.logDebug(response);

          return {is_success: true, found: false}; // レスポンスを受け取れたが、対象の同義語プロジェクトが見つからなかった
        } else {
          this.logDebug("#### getSynonymAndData. Bad response:");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r512.warning;

          return {is_success: false}; // その他エラー
        }
      } catch (error) {
        this.logDebug("#### getSynonymAndData. Error:");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r512.error;

        return {is_success: false}; // API 呼び出しに失敗した
      }
    },

    /**
     * 同義語 API（ Ten API ）のラッパーを呼び出して、
     * 同義語プロジェクト作成、単語分散表現データ作成、データインポート、単語分散表現データ解析、を実行する
     *
     * 同義語プロジェクト作成、単語分散表現データ作成、までを同期的に実行した後、レスポンスを返し
     * その後、バックグラウンドで非同期的にデータインポート、単語分散表現データ解析、を実行する
     *
     * 「同義語候補も追加する」をチェックしたが、まだ同義語プロジェクトを作成していなかった場合、当 function が呼ばれる
     */
    async createSynonym(datasetId) {
      this.logDebug("#### createSynonym. datasetId: " + datasetId);

      try {
        let body = {};

        // APIリクエスト（同義語データ作成）
        const response = await ApiUtils.post(
            "/synonym/create_synonym/case_log/" + String(datasetId),
            body
        );

        if (200 === response.status) {
          return {
            is_success: true,
            name: response.data.name,
            project_key: response.data.project_key,
            word_embedding_key: response.data.word_embedding_key,
          }; // 成功
        } else {
          this.logDebug("#### createSynonym. Bad response:");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r513.warning;
        }
      } catch (error) {
        this.logDebug("#### createSynonym. Error:");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r513.error;
      }

      return {is_success: false}; // エラー時は is_success: false を返す
    },

    /**
     * 同義語 API（ Ten API ）のラッパーを呼び出して、
     * 作成済みの同義語プロジェクト、単語分散表現データに対して、追加する応対履歴 CSV ファイルのデータを元に、
     * 追加のデータインポートと、それを元にした単語分散表現データ解析を実行する
     *
     * データインポート、単語分散表現データ解析の開始成功の時点で、レスポンスを返し
     * その後、バックグラウンドで非同期的にデータインポート、単語分散表現データ解析、を実行する
     *
     * 「同義語候補も追加する」をチェックして、かつ、同義語プロジェクト作成済みであった場合、当 function が呼ばれる
     */
    async addSynonymData(projectKey, wordEmbeddingKey, datasetId, fileIdList) {
      this.logDebug(
          "#### addSynonymData. projectKey: " +
          projectKey +
          ", wordEmbeddingKey: " +
          wordEmbeddingKey +
          ", datasetId: " +
          datasetId +
          ", fileIdList: "
      );
      this.logDebug(fileIdList);

      try {
        let body = {};
        ObjectUtils.objectPush(body, "data_type", "case_log");
        ObjectUtils.objectPush(body, "project_key", projectKey);
        ObjectUtils.objectPush(body, "word_embedding_key", wordEmbeddingKey);
        ObjectUtils.objectPush(body, "data_id", datasetId);
        ObjectUtils.objectPush(body, "file_id_list", fileIdList);

        // APIリクエスト（同義語データ追加）
        this.logDebug("#### 同義語データ追加 リクエスト");
        const response = await ApiUtils.post("/synonym/add_synonym_data", body);

        if (200 === response.status) {
          this.logDebug("#### 同義語データ追加 成功");
          return {is_success: true}; // 成功
        } else {
          this.logDebug("#### 同義語データ追加 失敗");
          this.logDebug("#### addSynonymData. Bad response:");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r514.warning;
        }
      } catch (error) {
        this.logDebug("#### addSynonymData. Error:");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r514.error;
      }

      return {is_success: false}; // エラー時は is_success: false を返す
    },
  },
  watch: {
    datasetGroupId: function () {
      this.logDebug("# watch datasetGroupId：" + this.datasetGroupId);

      // datasetGroupIdを監視。datasetGroupIdに変更があったら再度取得処理を呼び出す
      // 画面で使う変数を初期化
      this.items = [];
      this.total = 0;
      this.options.page = 1;
      this.options.itemsPerPage = 20;
      this.downloadLoading = [];
      this.previewLoading = [];
      ValidationUtils.reset(this.$refs["register_form"]);
      this.files = null;
      ObjectUtils.alertsInit(this.alerts);
      this.uncompletedAlerts.warning.isShow = false;
      this.getDatasetDetails();
    },
  },
  mounted: function () {
    this.logDebug("# DataSetEdit.vue mounted");
    this.getDatasetDetails();
  },

  data() {
    return {
      previewDialog: false,
      previewLoading: [],
      erasure: false,
      deleteLoading: false,
      total: 0,
      deleteId: null,
      files: null,
      addFilesLoading: false,
      checkSynonym: false,
      getDatasetDetailsLoading: false,
      alerts: {
        info: {isShow: false, message: ""},
        success: {isShow: false, message: ""},
        warning: {isShow: false, message: ""},
        error: {isShow: false, message: ""},
      },
      uncompletedAlerts: {
        warning: {isShow: false, message: ""},
      },
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ["id"],
        sortDesc: [false],
      },
      headers: [
        {align: "start", text: "ファイル名", value: "filename"},
        {align: "start", text: "登録日時", value: "created_at"},
        {align: "start", text: "対象期間開始日", value: "start_date"},
        {align: "start", text: "対象期間終了日", value: "end_date"},
        {align: "end", text: "件数", value: "row_count"},
        {align: "start", text: "操作", value: "operation", width: "210"},
      ],
      items: [],
      newData: {
        loading: false,
        filename: null,
        uploadFiles: null,
      },
      dataSample: null,
      downloadLoading: [],
    };
  },
};
</script>