<template>
  <v-app-bar app color="indigo darken-2" dark dense>
    <template v-if="isLogin">
      <v-app-bar-nav-icon
          v-show="this.$vuetify.breakpoint.width < 1000"
          @click="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-navigation-drawer
          v-show="this.$vuetify.breakpoint.width < 1000"
          v-model="drawer"
          absolute
          app
          color="white"
          hide-overlay
          overlay-opacity="0"
          temporary
          width="216"
      >
        <v-list>
          <v-list-item-group v-model="group">
            <v-app-bar-nav-icon class="mt-n2 ml-1" color="black" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <div style="margin-left:1.9em;font-size:1.2em;color:#111111;">{{ getName }}</div>
            <div style="margin-left:2.6em;font-size:0.9em;color:#555555;">{{ getTenant }}</div>
            <v-divider color="#303F9F"></v-divider>
            <v-list-item>
              <v-btn :ripple="false" color="#333333" large text to="/">TOP</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn :ripple="false" color="#333333" large text to="/doc-faq">文書から FAQ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn :ripple="false" color="#333333" large text to="/log-faq">応対履歴から FAQ</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn :ripple="false" color="#333333" large text to="/ai">AI 教師データ生成</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn :ripple="false" color="#333333" large text to="/synonym">同義語生成</v-btn>
            </v-list-item>
            <v-list-item v-if="isManager">
              <v-btn :ripple="false" color="#333333" large text to="/users">ユーザ管理</v-btn>
            </v-list-item>
            <v-divider color="#303F9F"></v-divider>
            <v-list-item>
              <v-btn :ripple="false" color="#333333" large text to="/password">パスワード変更</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn :ripple="false" color="#333333" large text @click="logout()">
                <v-icon>mdi-logout</v-icon>
                ログアウト
              </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>

    <v-toolbar-title>
      <v-btn text to="/" x-large>知識生成サービス</v-btn>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <template v-if="isLogin && this.$vuetify.breakpoint.width >= 1000">
      <v-toolbar-items v-on="drawer = false" class="hidden-sm-and-down">
        <!--      <v-btn text to="/">TOP</v-btn>-->
        <v-btn text to="/doc-faq">文書から FAQ</v-btn>
        <v-btn text to="/log-faq">応対履歴から FAQ</v-btn>
        <v-btn text to="/ai">AI 教師データ生成</v-btn>
        <v-btn text to="/synonym">同義語生成</v-btn>
        <!--      &lt;!&ndash; <v-btn text to="/login">ログイン</v-btn> &ndash;&gt;-->
        <v-btn v-if="isManager" text to="/users">ユーザ管理</v-btn>
        <div style="width:40px"></div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" small text v-bind="attrs" v-on="on">
              {{ getName }}&nbsp;({{ getTenant }})&nbsp;{{ arrowSign }}
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><span style="font-size:1.2em;">{{ getName }}</span></v-list-item-title>
                  <v-list-item-subtitle>{{ getTenant }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider color="#303F9F"></v-divider>
            <v-list>
              <v-list-item>
                <v-btn :ripple="false" color="#333333" text to="/password">パスワード変更</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn :ripple="false" color="#333333" text @click="logout()">
                  <v-icon>mdi-logout</v-icon>
                  ログアウト
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    /**
     * ログアウト処理。
     * Vuexに保持しているログイン情報を破棄する。
     */
    logout() {
      // ログアウト
      this.$cognito.logout();
      // Vuexで管理しているログイン情報を破棄
      this.$store.dispatch("user/logout");

      // ログイン画面でログイン画面に遷移すると警告が出力されるため抑制している
      this.$router.push({name: "login"}).catch(() => {
      });
    },
  },
  computed: {
    /**
     * Vuexで管理しているuser.jsのログイン状態を表すisLoginを取得する。
     */
    isLogin() {
      return this.$store.getters['user/isLogin']
    },
    /**
     * ログインユーザの名前を取得する。
     */
    getName() {
      return this.$store.getters['user/name']
    },
    /**
     * ログインユーザのテナントIDを取得する。
     */
    getTenant() {
      return this.$store.getters['user/tenant']
    },
    /**
     * ログインユーザが管理者ユーザかどうかを判定する。
     */
    isManager() {
      const roles_string = this.$store.getters['user/roles']
      const roles = JSON.parse(roles_string)
      const tenant = this.$store.getters['user/tenant']
      return (roles[tenant] === 'manager')
    },
    /**
     * マイアカウントメニューボタンの矢印マークを描画する
     */
    arrowSign() {
      return '▼'
    }
  },
};
</script>
