<template>
  <v-main>
    <v-container fill-height>
      <v-flex>
        <LoginTitle title="知識生成サービス"/>
        <br/>
        <br/>
        <v-row justify="center">
          <v-col cols="6">
            <AlertArea
                :isShow.sync="alerts.error.isShow"
                :message="alerts.error.message"
                type="error"
            />
          </v-col>
        </v-row>

        <!-- 入力フォーム -->
        <v-form @submit.prevent="login()">
          <!-- ユーザID -->
          <LoginTextArea :value.sync="id" label="ユーザID"/>
          <!-- パスワード -->
          <v-row justify="center">
            <v-col cols="4">
              <LoginPasswordTextField :value.sync="password" label="パスワード"/>
            </v-col>
          </v-row>
          <br/>
          <br/>
          <v-container>
            <v-row justify="center">
              <v-btn :loading="loading" color="secondary" large type="submit">ログイン</v-btn>
            </v-row>
          </v-container>
        </v-form>
        <br/>

        <!-- 「パスワードを忘れた場合」の機能は無効とする
        <!- パスワードを忘れた場合のリンク ->
        <v-container>
          <v-row justify="center">
            <a @click.stop="dialog = true">パスワードを忘れた場合はこちら</a>
          </v-row>
          <!- パスワードを忘れた場合のダイアログ ->
          <v-dialog v-model="dialog" no-click-animation persistent width="600">
            <v-card>
              <v-container>
                <v-card-title>パスワードを忘れた場合</v-card-title>
                <v-container>
                  <v-row class="mt-n6" justify="center">
                    <v-col cols="9">
                      <v-chip v-if="chips[0].visible" class="ma-2" close color="red" label outlined
                              @click:close="chips[0].visible = false">
                        {{ chips[0].message }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n6" justify="center">
                    <v-col cols="9">
                      <v-form ref="forgot_password_form" @submit.prevent="forgotPassword()">
                        <RequiredTextArea
                            :disabled=false
                            :value.sync="mail"
                            label="メールアドレスを入力してください。"
                        />
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n9 ml-6" dense justify="center">
                    <v-col cols="10">
                      <small class="text--disabled">
                        パスワードをリセットするための情報をお送りします。
                      </small>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-card-actions class="mt-n7">
                      <v-btn class="ma-6" color="primary" @click="dialog = false">キャンセル</v-btn>
                      <v-btn
                          id="button_forgot_password"
                          :loading="forgotPasswordLoading" class="ma-6"
                          color="secondary"
                          @click="forgotPassword()">送信
                      </v-btn>

                      <!- パスワードリセットダイアログ ->
                      <v-dialog v-model="passwordDialog" no-click-animation persistent width="600">
                        <v-card>
                          <v-container>
                            <v-card-title>パスワードリセット</v-card-title>
                            <v-container>
                              <v-row class="mt-n3 mb-n5" dense justify="center">
                                <v-col cols="9">
                                  <p class="caption text--disabled">
                                    登録されているメールアドレスに、<br/>
                                    パスワードリセットのためのコードをお送りしました。<br/>
                                    以下に、そのコードと、新しいパスワードを入力してください。<br/>
                                    パスワードは、英大・子文字、数字、記号を含め8文字以上、かつ、ユーザIDとは異なる値をご指定下さい。
                                  </p>
                                </v-col>
                              </v-row>
                            </v-container>
                            <v-form ref="confirm_forgot_password_form" @submit.prevent="confirmForgotPassword()">
                              <v-container>
                                <v-row class="mt-n6" justify="center">
                                  <v-col cols="9">
                                    <v-chip v-if="chips[1].visible" class="ma-2" close color="red" label outlined
                                            @click:close="chips[1].visible = false">
                                      {{ chips[1].message }}
                                    </v-chip>
                                  </v-col>
                                </v-row>
                                <v-row class="mt-n6" justify="center">
                                  <v-col cols="9">
                                    <RequiredTextArea
                                        :disabled=false
                                        :value.sync="code"
                                        label="コード"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row class="mt-n6" justify="center">
                                  <v-col cols="9">
                                    <PasswordTextField
                                        :value.sync="newPassword"
                                        label="新しいパスワード"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row class="mt-n6" justify="center">
                                  <v-col cols="9">
                                    <PasswordTextField
                                        :value.sync="newPasswordConfirm"
                                        label="新しいパスワード（再確認）"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row justify="center">
                                  <v-card-actions class="mt-n6">
                                    <v-btn class="ma-6" color="primary" @click="passwordDialog = false">キャンセル</v-btn>
                                    <v-btn id="button_confirm_forgot_password" :loading="confirmForgotPasswordLoading" class="ma-6"
                                           color="secondary"
                                           @click="confirmForgotPassword()">OK
                                    </v-btn>
                                  </v-card-actions>
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-container>
                        </v-card>
                      </v-dialog>
                    </v-card-actions>
                  </v-row>
                </v-container>
              </v-container>
            </v-card>
          </v-dialog>
        </v-container>
        -->

        <!-- 初期パスワード更新ダイアログエリア -->
        <v-container>
          <v-row justify="center">
            <v-card-actions class="mt-n7">
              <!-- 初期パスワード更新ダイアログ -->
              <v-dialog v-model="initialPasswordDialog" width="600">
                <v-card>
                  <v-container>
                    <v-card-title>初期パスワードの変更</v-card-title>
                    <v-container>
                      <v-row class="mt-n3 mb-n5" dense justify="center">
                        <v-col cols="9">
                          <p class="caption text--disabled">
                            パスワードは、英大・子文字、数字、記号を含め8文字以上、かつ、ユーザIDとは異なる値をご指定下さい。
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container>
                      <v-row class="mt-n6" justify="center">
                        <v-col cols="9">
                          <v-chip v-if="chips[2].visible" class="ma-2" close color="red" label outlined
                                  @click:close="chips[2].visible = false">
                            {{ chips[2].message }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n6" justify="center">
                        <v-col cols="9">
                          <PasswordTextField
                              :value.sync="initialNewPassword"
                              label="新しいパスワード"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mt-n6" justify="center">
                        <v-col cols="9">
                          <PasswordTextField
                              :value.sync="initialNewPasswordConfirm"
                              label="新しいパスワード（再確認）"
                          />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-card-actions class="mt-n6">
                          <v-btn
                              id="button_update_new_password"
                              :loading="updateNewPasswordLoading"
                              color="secondary"
                              large
                              @click="updateNewPassword()">
                            パスワード変更
                          </v-btn>
                        </v-card-actions>
                      </v-row>
                    </v-container>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-row>
        </v-container>
        <!-- テナント選択ダイアログエリア -->
        <v-container>
          <v-row justify="center">
            <v-card-actions class="mt-n7">
              <!-- テナント選択ダイアログ -->
              <v-dialog v-model="tenantDialog" no-click-animation persistent width="600">
                <v-card>
                  <v-container>
                    <v-card-title>テナントの選択</v-card-title>
                    <v-flex>
                      <v-row justify="center"> テナントを選択してください。</v-row>
                      <br/>
                      <br/>
                      <v-row justify="center">
                        <v-data-table
                            :headers="tenantItemHeaders"
                            :items="tenantItems"
                            calculate-widths
                            class="elevation-1"
                            disable-sort
                            hide-default-footer
                            hide-default-header
                            item-key="id"
                        >
                          <template v-slot:[`item.operation`]="{ item }">
                            <template v-if="item.operation === 'tenant'">
                              <v-btn id="button_select_tenant" color="secondary" small @click="tenantSelected(item.id)">
                                選択
                              </v-btn>
                            </template>
                          </template>
                        </v-data-table>
                      </v-row>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </v-flex>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-row>
        </v-container>
        <br/>
        <br/>
        <br/>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import Messages from "../js/Messages";
import LoginTitle from "../parts/LoginTitle";
import LoginTextArea from "../parts/LoginTextArea";
import PasswordTextField from "../parts/PasswordTextField";
import LoginPasswordTextField from "../parts/LoginPasswordTextField";
//import RequiredTextArea from "../parts/RequiredTextArea";
import AlertArea from "../parts/AlertArea.vue";
import ValidationUtils from "../js/ValidationUtils";

export default {
  name: "Login",
  components: {
    LoginTitle,
    LoginTextArea,
    PasswordTextField,
    LoginPasswordTextField,
    //RequiredTextArea,
    AlertArea,
  },
  data: () => ({
    dialog: false,
    passwordDialog: false,
    initialPasswordDialog: false, // 初期パスワード更新ダイアログの表示を識別するフラグ
    tenantDialog: false, // テナント選択ダイアログの表示を識別するフラグ
    id: null,
    password: null,
    mail: null, // mail という名称だが、実際には「パスワードを忘れた場合」のユーザIDが設定される
    code: null,
    loading: false,
    forgotPasswordUserId: null, // 「パスワードを忘れた」ユーザのIDを一時保管する
    newPassword: null,
    newPasswordConfirm: null,
    initialNewPassword: null, // 更新した初期パスワード
    initialNewPasswordConfirm: null, // 更新した初期パスワード（確認用）
    updateNewPasswordLoading: false,
    alerts: {error: {isShow: false, message: ""}},
    chips: [ // ダイアログ上のエラーメッセージとその表示領域の表示要否フラグ
      {"visible": false, "message": "",},
      {"visible": false, "message": "",},
      {"visible": false, "message": "",}
    ],
    forgotPasswordLoading: false,
    confirmForgotPasswordLoading: false,
    tenantItemHeaders: [ // テナント選択ダイアログ内のテーブルのヘッダ
      {align: "start", text: "ユーザID", value: "id"},
      {align: "start", text: "操作", value: "operation", width: "100"},
    ],
    tenantItems: null, // テナント選択ダイアログ内のテーブルに設定するデータのリスト
  }),
  methods: {
    // ログイン
    login() {
      if (!this.id || !this.password) { // 入力されていない項目がある場合
        // エラーメッセージを、ログイン画面上に表示
        this.alerts.error.isShow = true
        this.alerts.error.message = Messages.login.error_validation
        return
      }

      this.loading = true;
      this.$cognito.login(this.id, this.password)
          .then(result => {
            const status = result.status
            if (status === 'FORCE_CHANGE_PASSWORD') { // ログイン成功したが、初期パスワードの更新が必要
              this.logDebug('#### login completed(new password required)')
              this.logDebug(result)

              this.initialPasswordDialog = true // 初期パスワード更新用のダイアログを表示

            } else { // ログイン成功
              this.logDebug('#### login completed')
              this.logDebug(result)

              this.setUser(result) // ユーザ情報を保存

              const tenants = this.getTenants(result)
              if (tenants && tenants.length > 1) {
                this.tenantItems = this.getTenantItems(tenants) // テナント選択ダイアログに表示するリストを作成
                this.tenantDialog = true // 複数のテナントに所属している場合は、その選択ダイアログを表示
              } else {
                this.$router.replace('/') // お知らせ画面に遷移
              }
            }
          }, err => { // ログイン失敗
            this.logDebug('#### login failed')
            this.logDebug(err)

            // ログイン失敗メッセージを、ログイン画面上に表示
            this.alerts.error.isShow = true
            this.alerts.error.message = Messages.login.error
          })
          .finally(() => {
            this.loading = false;
          })
    },
    // パスワードリセットをリクエスト
    forgotPassword() {
      this.chips[0].visible = false // エラーメッセージを非表示

      if (!this.mail) { // 入力されていない項目がある場合
        // エラーメッセージを、「パスワードを忘れた場合のダイアログ」上に表示
        this.chips[0].visible = true
        this.chips[0].message = Messages.forgotPassword.error_validation
        return
      }

      this.forgotPasswordLoading = true
      this.$cognito.forgotPassword(this.mail)
          .then(result => { // forgotPassword 成功
            this.logDebug('#### forgotPassword completed')
            this.logDebug(result)

            this.forgotPasswordUserId = this.mail // パスワードを忘れたユーザの ID を保管
            ValidationUtils.reset(this.$refs["forgot_password_form"]) // 入力内容をクリア
            this.chips[0].visible = false // エラーメッセージを非表示
            this.dialog = false // パスワードリセットリクエスト用のダイアログを非表示
            this.forgotPasswordLoading = false
            this.passwordDialog = true // パスワードリセット用のダイアログを表示

          }, err => { // forgotPassword 失敗
            this.logDebug('#### forgotPassword failed')
            this.logDebug(err)
            this.forgotPasswordLoading = false

            // ユーザ ID 不正のメッセージを、「パスワードを忘れた場合のダイアログ」上に表示
            this.chips[0].visible = true
            this.chips[0].message = Messages.forgotPassword.error
          })
    },
    // パスワードリセット用の確認コードとあわせて、パスワードをリセット（変更）する
    confirmForgotPassword() {
      this.chips[1].visible = false // エラーメッセージを非表示

      if (!this.newPassword || !this.newPasswordConfirm || !this.code) { // 入力されていない項目がある場合
        // エラーメッセージを、「パスワードリセットダイアログ」上に表示
        this.chips[1].visible = true
        this.chips[1].message = Messages.confirmForgotPassword.error_validation_not_set
        return
      }

      if (this.newPassword !== this.newPasswordConfirm) { // 入力パスワードのチェック
        this.logDebug('#### confirmForgotPassword validation error')

        // パスワード不正のメッセージを、「パスワードリセットダイアログ」上に表示
        this.chips[1].visible = true
        this.chips[1].message = Messages.confirmForgotPassword.error_validation_not_correct
        return
      }

      this.confirmForgotPasswordLoading = true
      this.$cognito.confirmForgotPassword(this.forgotPasswordUserId, this.newPassword, this.code)
          .then(result => { // confirmForgotPassword 成功
            this.logDebug('#### confirmForgotPassword completed')
            this.logDebug(result)

            this.forgotPasswordUserId = null
            ValidationUtils.reset(this.$refs["confirm_forgot_password_form"]) // 入力内容をクリア
            this.confirmForgotPasswordLoading = false
            this.chips[1].visible = false // エラーメッセージを非表示
            this.passwordDialog = false // パスワードリセット用のダイアログを非表示（ログイン入力欄が再表示される）

          }, err => { // confirmForgotPassword 失敗
            this.logDebug('#### confirmForgotPassword failed')
            this.logDebug(err)
            this.confirmForgotPasswordLoading = false

            // 確認コード、またはパスワード不正のメッセージを、「パスワードリセットダイアログ」上に表示
            this.chips[1].visible = true
            this.chips[1].message = Messages.confirmForgotPassword.error
          })
    },
    // 初期パスワードを更新する
    updateNewPassword() {
      if (!this.initialNewPassword || !this.initialNewPasswordConfirm) { // 入力されていない項目がある場合
        // エラーメッセージを、「パスワードリセットダイアログ」上に表示
        this.chips[2].visible = true
        this.chips[2].message = Messages.updateNewPassword.error_validation_not_set
        return
      }

      if (this.initialNewPassword !== this.initialNewPasswordConfirm) { // 入力パスワードのチェック
        this.logDebug('#### updateNewPassword validation error')

        // パスワード不正のメッセージを、「初期パスワードの変更」ダイアログ上に表示
        this.chips[2].visible = true
        this.chips[2].message = Messages.updateNewPassword.error_validation_not_correct

        return
      }

      this.updateNewPasswordLoading = true;
      this.$cognito.updateNewPassword(this.initialNewPassword)
          .then(result => { // updateNewPassword 成功
            this.logDebug('#### updateNewPassword completed')
            this.logDebug(result)

            this.initialPasswordDialog = false // 初期パスワード更新用のダイアログを非表示
            this.updateNewPasswordLoading = false

            this.setUser(result) // ユーザ情報を保存

            const tenants = this.getTenants(result)
            if (tenants && tenants.length > 1) {
              this.tenantItems = this.getTenantItems(tenants) // テナント選択ダイアログに表示するリストを作成
              this.tenantDialog = true // 複数のテナントに所属している場合は、その選択ダイアログを表示
            } else {
              this.$router.replace('/') // お知らせ画面に遷移
            }

          }, err => { // updateNewPassword 失敗
            this.logDebug('#### updateNewPassword failed')
            this.logDebug(err)

            if (err && (
                (err.message && err.message.includes('User is not authenticated'))
                ||
                (err.code && err.code === 'NotAuthorizedException')
            )) {
              // 認証エラー ログイン画面にリダイレクト
              this.logDebug('#### ログイン期限切れ');
              this.$store.dispatch("user/logout");
              this.$router.go({path: this.$router.currentRoute.path, force: true});
            } else {
              // パスワード不正のメッセージを、「初期パスワードの変更」ダイアログ上に表示
              this.chips[2].visible = true
              this.chips[2].message = Messages.updateNewPassword.error
              this.updateNewPasswordLoading = false
            }
          })
    },
    // テナント情報を取得する
    getTenants(result) {
      var tenants = result.idToken.payload['cognito:groups']
      if (typeof tenants == 'string') {
        tenants = JSON.parse(tenants)
      }

      return tenants
    },
    // ユーザ情報を保存する
    setUser(result) {
      this.logDebug('#### setUser username: ' + result.idToken.payload['cognito:username'] + ', name: ' + result.idToken.payload.name)
      this.$store.dispatch('user/setUsername', result.idToken.payload['cognito:username']) // ユーザ ID
      this.$store.dispatch('user/setName', result.idToken.payload.name) // ユーザ表示名

      this.logDebug('#### setUser token: ' + result.idToken.jwtToken)
      this.$store.dispatch('user/setToken', result.idToken.jwtToken) // IDトークン
      this.$store.dispatch('user/setRefreshToken', result.refreshToken.token) // リフレッシュトークン

      const tenants = this.getTenants(result)
      this.logDebug('#### setUser tenants: ')
      this.logDebug(tenants)
      if (tenants && tenants.length === 1) {
        this.$store.dispatch('user/setTenant', tenants[0]) // テナント
      }

      this.$store.dispatch('user/setIsLogin', true) // ログイン済みかどうか

      // ロールの値はそのまま格納
      // { tenant1: "manager", tenant2: "user" } のように値が格納されているため、
      // 参照する場合は、roles[{テナントID}] のようにする
      this.logDebug('#### setUser role: ' + result.idToken.payload['custom:role'])
      this.$store.dispatch('user/setRoles', result.idToken.payload['custom:role']) // ロール
    },
    // テナント選択用にリストを作成
    getTenantItems(tenants) {
      var i = 0
      var tenantItems = []
      for (i = 0; i < tenants.length; i++) {
        const item = {id: tenants[i], operation: "tenant"}
        tenantItems.push(item)
      }

      return tenantItems
    },
    /**
     * テナント選択ボタン押下時の処理。
     * 選択されたテナントIDをVuexにセットし、
     * TOP画面にリダイレクトする。
     */
    tenantSelected(tenantId) {
      this.logDebug('#### tenantSelected tenant: ' + tenantId)

      this.tenantDialog = false // テナント選択ダイアログを非表示
      this.$store.dispatch('user/setTenant', tenantId)
      this.$router.replace('/') // お知らせ画面に遷移
    }
  }
};
</script>
