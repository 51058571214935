<template>
  <v-container>
    <v-sheet class="px-4 pt-4 pb-3" height=90px outlined>
      {{ notice }}
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  props: ['notice']
};
</script>