<template>
  <v-btn
      id="password_generation_button"
      class="ml-3 mt-4"
      color="primary"
      small
      @click="passwordGenerate()"
  >
    <v-icon>mdi-autorenew</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'PasswordGenerateButton',
  props: ["password"],
  methods: {

    /**
     * パスワードを生成する。
     * 生成したパスワードを親に渡す。
     **/
    passwordGenerate() {

      // 使用文字の定義
      const lower = "abcdefghijklmnopqrstuvwxyz";
      const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const number = "0123456789";
      const symbol = "^$*.[]{}()?!@#%&/,><:;|_~`";
      const all = lower + upper + number + symbol;

      // 桁数の定義
      const password_length = 12;

      // 最低限必要な文字列をセット
      let new_password_array = [];
      new_password_array.push(lower.charAt(Math.floor(Math.random() * lower.length)));
      new_password_array.push(upper.charAt(Math.floor(Math.random() * upper.length)));
      new_password_array.push(number.charAt(Math.floor(Math.random() * number.length)));
      new_password_array.push(symbol.charAt(Math.floor(Math.random() * symbol.length)));

      // 設定桁数までランダムな文字列を追加
      for (; new_password_array.length < password_length;) {
        new_password_array.push(all.charAt(Math.floor(Math.random() * all.length)));
      }

      // 並び順をシャッフルする
      for (let i = 0; i < new_password_array.length; i++) {

        // 乱数を作成
        const rnd = Math.floor(Math.random() * new_password_array.length);

        //配列の各要素を入れ替える
        const password1 = new_password_array[0];
        const password2 = new_password_array[rnd];
        new_password_array[rnd] = password1;
        new_password_array[0] = password2;
      }
      this.$emit("update:password", new_password_array.join(""));
    }
  }
}
</script>