<template>
  <v-card>
    <!-- 閉じるボタン -->
    <v-btn id="button_close" icon @click="closeDialog()">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <!-- アラートエリア -->
    <v-container class="mt-n4 mb-n5">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- タイトル -->
    <v-container class="mt-n5 mb-n4">
      <v-row>
        <v-card-title>名称：{{ createFaqName }}</v-card-title>
      </v-row>
      <!-- 応対履歴データセットダウンロードダイアログボタン -->
      <v-row class="ml-1" dense style="color:rgba(0, 0, 0, 0.6);">
        ・応対履歴データ：{{ caseLogName }}
        <v-tooltip color="tooltip" top>
          <template v-slot:activator="{ on }">
            <v-btn
                id="case_log_dialog_button"
                v-on="on"
                class="mt-n1 ma-3"
                color="primary"
                small
                @click="caseLogDialog=true"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>ダウンロード</span>
        </v-tooltip>
        <v-card-actions class="mt-n7">
          <!-- 応対履歴選択ダウンロードダイアログ -->
          <v-dialog v-model="caseLogDialog" width="700">
            <v-card>
              <v-container>
                <v-card-title>応対履歴データの選択</v-card-title>
                <v-flex>
                  <DownloadResponseHistory :datasetGroupId="datasetGroupId"/>
                  <v-row class="mt-7" justify="center">
                    <v-btn
                        id="case_log_dialog_ok_button"
                        class="ma-3"
                        color="primary"
                        @click="caseLogDialog=false"
                    >閉じる
                    </v-btn>
                  </v-row>
                  <br/>
                </v-flex>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-row>
      <!-- FAQダウンロードボタン -->
      <v-row class="ml-1" dense style="color:rgba(0, 0, 0, 0.6);">
        ・FAQデータ：{{ faqName }}
        <v-tooltip color="tooltip" top>
          <template v-slot:activator="{ on }">
            <v-btn
                id="faq_download_button"
                v-on="on"
                :loading="downloadLoading"
                class="mt-n1 ma-3"
                color="primary"
                small
                @click="download()"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>ダウンロード</span>
        </v-tooltip>
      </v-row>
    </v-container>

    <v-container>
      <v-container>
        <v-row>
          <v-col cols="9">
            <v-textarea
                :value="selectQuestion"
                auto-grow
                class="ml-n3"
                label="質問文"
                outlined
                readonly
                rows="1"
            ></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
              id="faq_select_button"
              :loading="getMatchingFaqListLoading || getAiTeacherDetailListLoading"
              class="mt-7"
              color="primary"
              @click="faqSelectButton();"
          >
            <v-icon>mdi-magnify</v-icon>
            FAQを選択する
          </v-btn>
        </v-row>
        <v-spacer></v-spacer>
      </v-container>

      <!-- FAQ選択ボタン -->
      <v-container>
        <v-row>
          <v-dialog v-model="faqSearchDialog">
            <v-card>
              <v-card-title>FAQ選択</v-card-title>
              <v-container>
                <!-- 検索フォーム・検索ボタン -->
                <v-container>
                  <v-form @submit.prevent="faqOptions.page = 1; getFaqDetailList()">
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                            v-model="faqDetailSearchCondition.question"
                            clearable
                            label="質問"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                            id="faq_detail_search_button"
                            :loading="getMatchingFaqListLoading"
                            class="mt-2"
                            color="primary"
                            @click="faqOptions.page = 1; getFaqDetailList()"
                        >
                          <v-icon>mdi-magnify</v-icon>
                          検索
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-data-table
                      v-model="faqDetailSelected"
                      :footer-props="{
                        'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500, 1000],
                      }"
                      :headers="faqDetailListHeaders"
                      :items="faqDetailList"
                      :loading="getMatchingFaqListLoading"
                      :options.sync="faqOptions"
                      calculate-widths
                      class="elevation-1"
                      dense
                      disable-sort
                      item-key="faq_id"
                      show-select
                      single-select
                  >
                    <!-- テーブルのanswer項目をv-slotでitemとして取得 -->
                    <template v-slot:[`item.answer`]="{ item }">
                      <v-edit-dialog>
                        <!-- テーブル内では、文を省略する -->
                        <div @click="answerDialog = true">
                          {{ item.answer | truncate }}
                        </div>
                        <!-- ダイアログ内処理 -->
                        <template v-slot:input>
                          <v-container>
                            <v-layout style="height: 10vh">
                              <v-flex style="overflow: visible scroll;">
                                <!-- 全文表示 -->
                                <div style="white-space:pre-wrap;">{{ item.answer }}</div>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>
                </v-container>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="ma-6"
                      color="primary"
                      @click="faqSearchDialog = false"
                  >キャンセル
                  </v-btn
                  >
                  <v-btn
                      id="faq_search_dialog_ok_button"
                      :loading="getAiTeacherDetailListLoading"
                      class="ma-6"
                      color="primary"
                      @click="faqSearchDialogOK()"
                  >OK
                  </v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-container>

    <!-- 教師データリスト -->
    <v-container class="mt-n12">
      <v-data-table
          v-model="outputTargets"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
          }"
          :headers="aiTeacherDetailListHeaders"
          :items="aiTeacherDetailList"
          :loading="getAiTeacherDetailListLoading"
          :options.sync="aiOptions"
          :server-items-length="aiTotal"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          show-select
          @update:items-per-page="getAiTeacherDetailList()"
          @update:page="getAiTeacherDetailList()">
        >
      </v-data-table>
    </v-container>

    <!-- ファイル出力ボタン -->
    <v-container class="mt-n3">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn
            id="button_download_csv"
            class="ma-3"
            color="primary"
            @click="downloadCSV()"
        >
          <v-icon>mdi-file-export-outline</v-icon>
          ファイル出力
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import FileUtils from "../../js/FileUtils";
import StringUtils from "../../js/StringUtils";
import AlertArea from "../../parts/AlertArea.vue";
import DownloadResponseHistory from "./DownloadResponseHistory.vue";
import Messages from "../../js/Messages";

export default {
  name: "AiResult",
  components: {
    AlertArea,
    DownloadResponseHistory,
  },
  props: {
    groupId: Number, // AI 教師データ生成ジョブの ID
    datasetGroupId: Number, // 応対履歴データセットの ID
    faqGroupId: Number, // FAQ ファイルの ID
    dialog: Boolean, // 大元のダイアログフラグ
    caseLogName: String, // 応対履歴の名称
    faqName: String, // FAQの名称
    createFaqName: String, // FAQ候補の名称
  },
  data: () => ({
    searchLoading: false,
    downloadLoading: false,
    caseLogDialog: false,
    selectQuestion: "",
    faqSearchDialog: false,
    answerDialog: false,
    getAiTeacherDetailListLoading: false,
    getMatchingFaqListLoading: false,
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    aiTotal: 0,
    aiOptions: {
      page: 1,
      itemsPerPage: 20,
    },
    faqOptions: {
      page: 1,
      itemsPerPage: 20,
    },
    faqDetailSelected: [],
    faqDetailSearchCondition: {
      question: null,
    },
    faqDetailListHeaders: [
      {align: "start", text: "ID", value: "faq_id",},
      {align: "start", text: "質問", value: "question",},
      {align: "start", text: "回答", value: "answer"},
    ],
    faqDetailList: [],
    outputTargets: [],
    aiTeacherDetailListHeaders: [
      {align: "start", text: "教師データ候補", value: "question",},
      {align: "end", text: "スコア", value: "score_for_view",},
    ],
    aiTeacherDetailList: [],
    slider: {
      value: 0,
    }
  }),
  methods: {

    /**
     * 親にダイアログをクローズしてもらう。
     */
    closeDialog() {
      this.$emit("update:dialog", false);
    },

    /**
     * Ai 教師データとマッチした Faq を登場回数の多い順に取得する。
     **/
    async getMatchingFaqList() {
      this.logDebug("### getMatchingFaqList() 開始");

      // ローディング開始
      this.getMatchingFaqListLoading = true;
      let result = {
        is_success: false,
        data: [],
      };
      try {

        // APIリクエスト（R34）
        let qParams = {};
        ObjectUtils.objectPush(qParams, "faq_group_id", this.faqGroupId);
        ObjectUtils.objectPush(qParams, "question", this.faqDetailSearchCondition.question);
        ObjectUtils.objectPush(qParams, "limits", 1000);
        ObjectUtils.objectPush(qParams, "page", 1);
        this.logDebug("#### R34 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/training_data/" + this.groupId + "/summary",
            qParams
        );
        if (200 === response.status) {
          this.logDebug("#### R34 成功");

          // ステータス200なら処理成功
          result.is_success = true;

          // 何も取得できない場合は処理成功で返却
          if (response.data.data[0] == null) {
            this.logDebug("#### R34 response.data.data[0] == null");
            return result;
          }
          result.data = response.data.data;
        } else {
          this.logDebug("#### R34 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r34.warning;
        }
      } catch (error) {
        this.logDebug("#### getMatchingFaqList() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r34.error;
      } finally {

        // ローディング終了
        this.getMatchingFaqListLoading = false;
      }
      this.logDebug("### getMatchingFaqList() 終了");
      return result;
    },

    /**
     * Ai教師データの詳細リストを取得。
     **/
    async getAiTeacherDetailList() {
      this.logDebug("### getAiTeacherDetailList() 開始");

      // FAQが何も選択されていない場合は何も処理しない
      if (this.faqDetailSelected[0] == null) {
        this.logDebug("### FAQが選択されていないため処理しない。getAiTeacherDetailList() 終了");
        return;
      }

      // ローディング開始
      this.getAiTeacherDetailListLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(
            qParams,
            "faq_id",
            this.faqDetailSelected[0].faq_id
        );
        ObjectUtils.objectPush(qParams, "limits", this.aiOptions.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.aiOptions.page);

        // APIリクエスト（R14）
        this.logDebug("#### R14 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/training_data/" + this.groupId,
            qParams
        );
        if (200 === response.status) {
          this.logDebug("#### R14 成功");

          // データが取得できないが、
          // 前に選択したデータがfaqDetailSelectedに残っていると質問文だけ表示されるというケースの対策
          if (response.data.data.length > 0) {
            this.selectQuestion = this.faqDetailSelected[0].question;
          }
          let trainingDataList = response.data.data;
          for (let i = 0; i < trainingDataList.length; i++) {

            // スコアの値は、表示用に小数点以下桁数を揃えて、文字列として持たせる
            trainingDataList[i].score_for_view = StringUtils.formatNumber(trainingDataList[i].score)
          }
          this.aiTeacherDetailList = trainingDataList;
          this.aiTotal = response.data.counts;
        } else {
          this.logDebug("#### R14 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r14.warning;
        }
      } catch (error) {
        this.logDebug("#### getAiTeacherDetailList() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r14.error;
      } finally {

        // ローディング終了
        this.getAiTeacherDetailListLoading = false;
      }
      this.logDebug("### getAiTeacherDetailList() 終了");
    },

    /**
     * FAQを選択するボタン押下時の処理。
     * データの詳細リストを取得後にダイアログを表示する。
     **/
    async faqSelectButton() {
      this.logDebug("## faqSelectButton() 開始");
      const response = await this.getMatchingFaqList();
      this.faqDetailList = response.data;
      this.faqSearchDialog = response.is_success;
      this.logDebug("## faqSelectButton() 数量");
    },

    /**
     * FAQデータの詳細リストを取得。
     **/
    async getFaqDetailList() {
      this.logDebug("## getFaqDetailList() 開始");
      this.searchLoading = true;
      const response = await this.getMatchingFaqList();
      this.faqDetailList = response.data;
      this.searchLoading = false;
      this.logDebug("## getFaqDetailList() 終了");
    },

    /**
     * FAQを選択するボタン押下で表示されたダイアログのOKボタンが押されたときの処理。
     * Ai教師データの詳細リストを取得したあと、検索条件をリセットする。
     **/
    async faqSearchDialogOK() {
      this.logDebug("## faqSearchDialogOK() 開始");

      // 検索処理前に必要な変数を初期化
      this.aiOptions.page = 1;
      this.outputTargets = [];
      await this.getAiTeacherDetailList();
      this.faqDetailSearchCondition.question = null;
      this.faqSearchDialog = false;
      this.logDebug("## faqSearchDialogOK() 終了");
    },

    /**
     * CSVファイルを出力してダウンロードする。
     * 出力対象はoutputTargets。
     */
    downloadCSV() {
      this.logDebug("### downloadCSV() 開始");
      if (this.outputTargets[0] == null) {
        this.logDebug("### 未選択 downloadCSV() 終了");
        alert(Messages.aiResultFileOutput.error);
        return;
      }

      // FileUtils.downLoadCSV()で適切に出力されるようにデータを複製
      FileUtils.duplicateJsonKey(this.outputTargets, "question", "教師データ候補");
      FileUtils.duplicateJsonKey(this.outputTargets, "score", "スコア");

      // CSVに出力するカラムを定義
      const fields = ["教師データ候補", "スコア"];
      const filename = "AI教師データ.csv";
      FileUtils.downLoadCSV(fields, this.outputTargets, filename);
      this.logDebug("### downloadCSV() 終了");
    },

    /**
     * 画面を描画する際の初期処理。
     * （初回描画、groupIdが切り替わったタイミングなど）
     */
    async init() {
      this.logDebug("## init() 開始");

      // ローディング開始
      this.getAiTeacherDetailListLoading = true;

      // 質問文、ファイル出力対象、テーブルをリセット
      this.selectQuestion = "";
      this.outputTargets = [];
      this.aiTeacherDetailList = [];
      this.aiOptions.itemsPerPage = 20;
      this.aiOptions.page = 1;
      this.aiTotal = 0;
      ObjectUtils.alertsInit(this.alerts);

      // 異常時の処理はgetMatchingFaqList()内部で行っている
      const response = await this.getMatchingFaqList();
      const faqs = response.data;
      if (faqs.length === 0) {
        this.getAiTeacherDetailListLoading = false;
        this.logDebug("## faq取得件数0件のためinit() 終了");
        return;
      }

      // FAQ選択画面で選択された状態にするため、スコアが一番高いFAQをセット
      this.faqDetailSelected = [
        {faq_id: faqs[0].faq_id, question: faqs[0].question},
      ];
      this.logDebug("### スコアが一番高いFAQをセット：" + JSON.stringify(this.faqDetailSelected));

      // AI教師データ詳細を取得
      await this.getAiTeacherDetailList();

      // ローディング終了
      this.getAiTeacherDetailListLoading = false;
      this.logDebug("## init() 終了");
    },

    /**
     * FAQダウンロードボタン押下時の処理。
     * 生成結果の元ファイルをダウンロードする。
     * 最初に一度検索してAPIリクエスト（R6）からダウンロードパス（s3_path）を取得している。
     */
    async download() {
      this.logDebug("### download() 開始");
      try {
        this.downloadLoading = true;

        // APIリクエスト（R6）
        const searchResponse = await this.getFaq();
        if (!searchResponse.is_success) {
          return
        }

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "s3_path", searchResponse.path);
        // APIリクエスト（R2）
        this.logDebug("#### R2 リクエスト");
        const response = await ApiUtils.get("/common/download_url", qParams);

        if (200 === response.status) {
          window.location.href = response.data.download_url;
        } else {
          this.logDebug("#### R2 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r2.warning;
        }
      } catch (error) {
        this.logDebug("#### download() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r2.error;
      } finally {
        this.downloadLoading = false;
        this.logDebug("### download() 終了");
      }
    },

    /**
     * R6をリクエストして、FAQの情報を取得する。
     * 取得結果をそのまま呼び出し元に返却する。
     **/
    async getFaq() {
      this.logDebug("### getFaq() 開始");
      let ret = {
        is_success: false,
        path: null,
      };

      // クエリパラメータを設定
      let qParams = {};
      ObjectUtils.objectPush(qParams, "name", this.faqName);

      try {
        // APIリクエスト（R6）
        this.logDebug("#### R6 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/faq",
            qParams
        );
        if (200 === response.status) {
          const data = response.data.data.find(data => data.id === this.faqGroupId);
          if (!data) {
            this.logDebug("#### getFaq():元ファイル削除済み");
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.download.warning;
            return ret;
          }
          ret = {
            is_success: true,
            path: data.s3_path,
          }
          return ret;
        } else {
          this.logDebug("#### R6 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r6.warning;
          return ret;
        }
      } catch (error) {
        this.logDebug("#### getFaq() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r6.error;
        return ret;
      } finally {
        this.logDebug("### getFaq() 終了");
      }
    },
  },
  mounted: function () {
    this.logDebug("# AiResult.vue mounted");
    this.init();
  },
  watch: {
    groupId: function () {
      this.logDebug("# watch groupId：" + this.groupId);

      // groupIdを監視。groupIdに変更があったら初期処理を呼び出す
      this.init();
    }
  },
};
</script>