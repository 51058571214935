<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- 検索フォーム -->
    <v-container>
      <!-- 開閉パネル -->
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4">
                <h4>検索入力フォーム</h4>
              </v-col>
              <v-col class="text--secondary" cols="8">
                <v-fade-transition leave-absolute>
                  <!-- 検索フォームを開いているときのメッセージ -->
                  <span v-if="open">検索条件を入力してください</span>
                  <!-- 検索フォームを閉じているときのメッセージ -->
                  <v-row v-else no-gutters style="width: 100%">
                    <!-- 設定された検索条件を表示 -->
                    <ClosedSearchFormDisp
                        :value="searchCondition.name"
                        label="名称"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.caseLogName"
                        label="応対履歴データ名"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.faqName"
                        label="FAQデータ名"
                    />
                    <ClosedSearchFormDisp
                        :value="searchCondition.selectedStatus"
                        label="状態"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.startDatetimeFrom"
                        :toValue="searchCondition.startDatetimeTo"
                        label="ジョブ開始日時"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.endDatetimeFrom"
                        :toValue="searchCondition.endDatetimeTo"
                        label="ジョブ終了日時"
                    />
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- 名称 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">名称 :</v-col>
              <v-col cols="6">
                <NormalTextArea
                    :value.sync="searchCondition.name"
                    label=""
                />
              </v-col>
            </v-row>

            <!-- 応対履歴 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">応対履歴データ名 :</v-col>
              <v-col cols="6">
                <NormalTextArea
                    :value.sync="searchCondition.caseLogName"
                    label=""
                />
              </v-col>
            </v-row>

            <!-- FAQ -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">FAQデータ名 :</v-col>
              <v-col cols="6">
                <NormalTextArea
                    :value.sync="searchCondition.faqName"
                    label=""
                />
              </v-col>
            </v-row>

            <!-- 状態 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">状態 :</v-col>
              <v-col cols="6">
                <SelectField
                    :status="processingSelects"
                    :value.sync="searchCondition.selectedStatus"
                    label=""
                />
              </v-col>
            </v-row>

            <!-- ジョブ開始日時 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ジョブ開始日時 :</v-col>
              <v-col cols="3">
                <CalendarYYYYMMDD
                    v-model="searchCondition.startDatetimeFrom"
                    label=""
                />
              </v-col>
              <div class="mt-6">～</div>
              <v-col cols="3">
                <CalendarYYYYMMDD
                    v-model="searchCondition.startDatetimeTo"
                    label=""
                />
              </v-col>
            </v-row>

            <!-- ジョブ終了日時 -->
            <v-row dense>
              <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ジョブ終了日時 :</v-col>
              <v-col cols="3">
                <CalendarYYYYMMDD
                    v-model="searchCondition.endDatetimeFrom"
                    label=""
                />
              </v-col>
              <div class="mt-6">～</div>
              <v-col cols="3">
                <CalendarYYYYMMDD
                    v-model="searchCondition.endDatetimeTo"
                    label=""
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ボタン２つ -->
      <br/>
      <v-row>
        <NewAiTeacherGenerateButton
            :buttonClass="'ma-3'"
            :isAiMgtScreen="true"
            :isResHisScreen="false"
            :isSmall="false"
            :selectResHis="null"
            @search="search"
            @showError="showError"
            @showSuccess="showSuccess"
            @showWarning="showWarning"
            @alertsInitByEmit="alertsInitByEmit"
            @uncompletedAlertsInitByEmit="() => {}"
        />
        <v-spacer></v-spacer>
        <v-btn
            :loading="aiTeacherDataListLoading"
            id="button_get_ai_teacher_data_list"
            class="ma-3"
            color="primary"
            @click="
              aiTeacherDataListOptions.page = 1;
              search();
            "
        >
          <v-icon>mdi-magnify</v-icon>
          検索
        </v-btn>
        <v-btn
            id="button_search_condition_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>
      </v-row>
    </v-container>

    <!-- テーブル内 -->
    <v-container>
      <v-data-table
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
          }"
          :headers="aiTeacherDataHeaders"
          :items="aiTeacherDataList"
          :loading="aiTeacherDataListLoading"
          :options.sync="aiTeacherDataListOptions"
          :server-items-length="total"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          @update:items-per-page="search()"
          @update:page="search()"
      >
        <template v-slot:[`item.start_datetime`]="{ item }">
          {{ dateReplace(item.start_datetime) }}
        </template>
        <template v-slot:[`item.end_datetime`]="{ item }">
          {{ dateReplace(item.end_datetime) }}
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <template v-if="item.status === '処理完了'">
            <v-tooltip color="tooltip" top>
              <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    color="primary"
                    small
                    @click="
                    createFaqName = item.name;
                    caseLogName = item.case_log_dataset_group_name;
                    faqName = item.faq_group_name;
                    groupId = item.id;
                    datasetGroupId = item.case_log_dataset_group_id;
                    faqGroupId = item.faq_group_id;
                    aiResultDialog = true;
                  "
                >
                  <v-icon>mdi-file-find</v-icon>
                </v-btn>
              </template>
              <span>結果確認</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
      <v-dialog v-model="aiResultDialog">
        <AiResult
            :caseLogName="caseLogName"
            :faqName="faqName"
            :dialog.sync="aiResultDialog"
            :datasetGroupId="datasetGroupId"
            :faqGroupId="faqGroupId"
            :groupId="groupId"
            :createFaqName="createFaqName"
        />
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import NormalTextArea from "../../parts/NormalTextArea.vue";
import CalendarYYYYMMDD from "../../parts/CalendarYYYYMMDD.vue";
import SelectField from "../../parts/SelectField.vue";
import NewAiTeacherGenerateButton from "../../parts/NewAiTeacherGenerateButton.vue";
import ClosedSearchFormDisp from "../../parts/ClosedSearchFormDisp.vue";
import ClosedSearchFormFromToDisp from "../../parts/ClosedSearchFormFromToDisp.vue";
import AiResult from "./AiResult.vue";
import ApiUtils from "../../js/ApiUtils";
import StringUtils from "../../js/StringUtils";
import ObjectUtils from "../../js/ObjectUtils";
import AlertArea from "../../parts/AlertArea.vue";
import Messages from "../../js/Messages";

export default {
  name: "AiTeacherData",
  components: {
    AlertArea,
    NormalTextArea,
    CalendarYYYYMMDD,
    SelectField,
    ClosedSearchFormDisp,
    ClosedSearchFormFromToDisp,
    NewAiTeacherGenerateButton,
    AiResult,
  },
  data: () => ({
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    aiResultDialog: false,
    aiTeacherDataListLoading: false,
    aiTeacherDataListOptions: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ["id"],
      sortDesc: [false],
    },
    total: 0,
    processingSelects: ["未処理", "処理中", "処理完了"],
    searchCondition: {
      name: null,
      caseLogName: null,
      faqName: null,
      selectedStatus: null,
      startDatetimeFrom: null,
      startDatetimeTo: null,
      endDatetimeFrom: null,
      endDatetimeTo: null,
    },
    groupId: null,
    datasetGroupId: null,
    faqGroupId: null,
    faqName: null,
    caseLogName: null,
    createFaqName: null,
    aiTeacherDataHeaders: [
      {align: "start", text: "名称", value: "name"},
      {align: "start", text: "応対履歴データ名", value: "case_log_dataset_group_name"},
      {align: "start", text: "FAQデータ名", value: "faq_group_name"},
      {align: "start", text: "状態", value: "status"},
      {align: "start", text: "ジョブ開始日時", value: "start_datetime"},
      {align: "start", text: "ジョブ終了日時", value: "end_datetime"},
      {align: "start", text: "操作", value: "operation", width: "80"},
    ],
    aiTeacherDataList: [],
  }),
  methods: {
    /**
     * 日付書式を変更する。
     */
    dateReplace: function (item) {
      return StringUtils.dateReplace(item);
    },

    /**
     * 検索フォームをすべてリセットする。
     */
    searchConditionClear() {
      this.searchCondition.name = null;
      this.searchCondition.caseLogName = null;
      this.searchCondition.faqName = null;
      this.searchCondition.selectedStatus = null;
      this.searchCondition.startDatetimeFrom = null;
      this.searchCondition.startDatetimeTo = null;
      this.searchCondition.endDatetimeFrom = null;
      this.searchCondition.endDatetimeTo = null;
    },

    /**
     * Ai教師データのリスト（学習・推論中のものも含む）を取得。
     **/
    async search() {
      this.logDebug("### search() 開始");
      try {

        // ローディング開始
        this.aiTeacherDataListLoading = true;

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "name", this.searchCondition.name);
        ObjectUtils.objectPush(qParams, "case_log_dataset_name", this.searchCondition.caseLogName);
        ObjectUtils.objectPush(qParams, "faq_name", this.searchCondition.faqName);
        ObjectUtils.objectPush(qParams, "status", this.searchCondition.selectedStatus);
        ObjectUtils.dateObjectPush(qParams, "start_datetime_from", this.searchCondition.startDatetimeFrom);
        ObjectUtils.dateObjectPush(qParams, "start_datetime_to", this.searchCondition.startDatetimeTo);
        ObjectUtils.dateObjectPush(qParams, "end_datetime_from", this.searchCondition.endDatetimeFrom);
        ObjectUtils.dateObjectPush(qParams, "end_datetime_to", this.searchCondition.endDatetimeTo);
        ObjectUtils.objectPush(qParams, "limits", parseInt(this.aiTeacherDataListOptions.itemsPerPage));
        ObjectUtils.objectPush(qParams, "page", parseInt(this.aiTeacherDataListOptions.page));

        // APIリクエスト（R13）
        this.logDebug("#### R13 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/training_data",
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R13 成功");
          this.aiTeacherDataList = response.data.data;
          this.total = response.data.counts;
        } else {
          this.logDebug("#### R13 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r13.warning;
        }
      } catch (error) {
        this.logDebug("#### search() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r13.error;
      } finally {
        this.aiTeacherDataListLoading = false;
      }
      this.logDebug("### search() 終了");
    },

    /**
     * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
     * @param message サクセスメッセージ
     */
    showSuccess: ObjectUtils.showSuccess,

    /**
     * 子コンポーネントからワーニングを表示したいときに呼び出す。
     * @param message ワーニングメッセージ
     */
    showWarning: ObjectUtils.showWarning,

    /**
     * 子コンポーネントからエラーを表示したいときに呼び出す。
     * @param message エラーメッセージ
     */
    showError: ObjectUtils.showError,

    /**
     * alerts を初期化する。
     */
    alertsInitByEmit: ObjectUtils.alertsInitByEmit,
  },
  mounted: function () {
    // 画面表示のタイミングで検索を実行
    this.logDebug("# AiTeacherDataMgt.vue mounted");
    this.search();
  },
};
</script>
