<template>
  <v-main>
    <!-- タイトル -->
    <Title title="お知らせ"/>

    <!-- お知らせの内容 -->
    <Notice notice="ヘッダーから各画面にアクセスしてください。"/>

  </v-main>
</template>

<script>
import Title from '../parts/Title';
import Notice from '../parts/Notice';

export default {
  name: 'Top',
  components: {
    Title,
    Notice
  },
  data: () => ({}),
};
</script>
