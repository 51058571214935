<template>
  <v-row
      v-if="value !== null && value !== ''"
      no-gutters
      style="width: 100%"
  >
    <v-col cols="12">
      <div v-if="value !== null && value !== ''">
        {{ label }}: {{ value }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['value', 'label']
};
</script>