<template>
  <v-app>

    <!-- ヘッダー -->
    <Header/>

    <!-- コンテンツ -->
    <router-view/>

    <!-- フッター -->
    <Footer/>

  </v-app>
</template>

<script>
import Header from './components/globals/Header';
import Footer from './components/globals/Footer';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data: () => ({}),
};
</script>
