<template>
  <!-- アラートエリア -->
  <v-container class="mt-n4">
    <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
    <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
    <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
    <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    <v-row justify="center" class="mt-1"> ダウンロードする応対履歴データを選択してください。</v-row>
    <br/>
    <br/>
    <v-row justify="center">
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="listLoading"
          calculate-widths
          class="elevation-1"
          disable-sort
          hide-default-footer
          hide-default-header
          item-key="name"
      >
        <template v-slot:[`item.filename`]="{ item }">
          {{ item.filename }}
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="case_log_download_button"
                  v-on="on"
                  :loading="downloadLoading[item.id]"
                  color="primary"
                  small
                  @click="download(item.s3_path, item.id)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ダウンロード</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>
<script>
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import AlertArea from "../../parts/AlertArea.vue";
import Messages from "../../js/Messages";

export default {
  props: {
    datasetGroupId: Number, // 応対履歴データセットの ID
  },
  components: {
    AlertArea,
  },
  methods: {
    /**
     * 応対履歴ダウンロードボタン押下時の処理。
     * 生成結果の元ファイルをダウンロードする。
     */
    async download(param, id) {
      this.logDebug("### download() 開始");
      try {
        this.downloadLoading[id] = true;

        let qParams = {};
        // 応対履歴の場合は param に s3_path が指定されている
        // クエリパラメータを設定
        ObjectUtils.objectPush(qParams, "s3_path", param);

        // APIリクエスト（R2）
        this.logDebug("#### R2 リクエスト");
        const response = await ApiUtils.get("/common/download_url", qParams);

        if (200 === response.status) {
          this.logDebug("#### R2 成功");
          window.location.href = response.data.download_url;
        } else {
          this.logDebug("#### R2 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r2.warning;
        }
      } catch (error) {
        this.logDebug("#### download() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r2.error;
      }
      this.downloadLoading[id] = false;
      this.logDebug("### download() 終了");
    },

    /**
     * 応対履歴データセットの応対履歴を展開するダイアログ。
     * APIリクエスト（R32）は ID をクエリ指定で情報を取得できる。
     **/
    async init() {
      this.logDebug("### init() 開始");
      this.listLoading = true;
      try {
        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "id", this.datasetGroupId);

        // APIリクエスト（R32）
        this.logDebug("#### R32 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/case_log_dataset/" + this.datasetGroupId,
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R32 成功");
          this.items = response.data.data;
          // ダウンロードアイコンをローディング中にするための前処理
          let tempArray = {};
          response.data.data.forEach(data => {
            tempArray[data.id] = false;
          })
          this.downloadLoading = tempArray;

          if (!this.items[0]) {
            this.logDebug("#### init():元ファイル削除済み");
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.download.warning;
          }
        } else {
          this.logDebug("#### R32 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r32.warning;
        }
      } catch (error) {
        this.logDebug("#### init() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r32.error;
      } finally {
        this.caseLogDialog = true;
        this.listLoading = false;
        this.logDebug("### init() 終了");
      }
    },
  },
  watch: {
    datasetGroupId: function () {
      this.logDebug("# watch datasetGroupId：" + this.datasetGroupId);

      // datasetGroupIdを監視。datasetGroupIdに変更があったら再度取得処理を呼び出す
      // 画面で使う変数を初期化
      this.items = [];
      this.downloadLoading = [];
      this.alerts.info.isShow = false;
      this.alerts.success.isShow = false;
      this.alerts.warning.isShow = false;
      this.alerts.error.isShow = false;
      this.init();
    },
  },
  mounted: function () {
    this.logDebug("# DownloadResponseHistory.vue mounted");
    this.init();
  },
  data() {
    return {
      downloadLoading: [],
      listLoading: false,
      caseLogDialog: false,
      headers: [
        {align: "start", text: "応対履歴データ", value: "filename"},
        {align: "start", text: "操作", value: "operation", width: "100"},
      ],
      items: [],
      alerts: {
        info: {isShow: false, message: ""},
        success: {isShow: false, message: ""},
        warning: {isShow: false, message: ""},
        error: {isShow: false, message: ""},
      },
    };
  },
};
</script>