<template>
  <v-card>

    <v-tabs centered color="secondary" fixed-tabs>
      <v-tab href="#tab-1">応対履歴データから同義語生成</v-tab>
      <v-tab href="#tab-2">FAQ データから同義語生成</v-tab>
      <v-tab href="#tab-3">文書データから同義語生成</v-tab>

      <v-tab-item value="tab-1">
        <SynonymMgt :items="responseHistorySynonymItems"
                    :loading="loading"
                    :tabAlerts="tabAlerts"
                    @getAllProject="getAllProject"
                    @tabAlertsInitByEmit="tabAlertsInitByEmit"/>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <SynonymMgt :items="faqSynonymItems"
                    :loading="loading"
                    :tabAlerts="tabAlerts"
                    @getAllProject="getAllProject"
                    @tabAlertsInitByEmit="tabAlertsInitByEmit"/>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <SynonymMgt :items="docSynonymItems"
                    :loading="loading"
                    :tabAlerts="tabAlerts"
                    @getAllProject="getAllProject"
                    @tabAlertsInitByEmit="tabAlertsInitByEmit"/>
      </v-tab-item>
    </v-tabs>

  </v-card>
</template>

<script>
import SynonymMgt from './views/SynonymMgt.vue';
import ApiUtils from "@/components/js/ApiUtils";
import ObjectUtils from "@/components/js/ObjectUtils";
import Messages from "@/components/js/Messages";

export default {
  name: 'CreateSynonymTab',
  components: {
    SynonymMgt,
  },
  methods: {
    /**
     * 作成されているプロジェクトの一覧を取得する。
     **/
    async getAllProject() {
      this.logDebug("### getAllProject() 開始");

      // ローディング開始
      this.loading = true;
      try {

        // APIリクエスト（R511）
        this.logDebug("#### R511 リクエスト");
        let r511Response = await ApiUtils.get(
            "/synonym/projects",
            null,
        );

        // APIリクエスト（R551）
        this.logDebug("#### R551 リクエスト");
        let r551Response = await ApiUtils.get(
            "/synonym/word_embeddings",
            null,
        );

        // 正常時のみ処理
        if (200 === r511Response.status && 200 === r551Response.status) {
          this.logDebug("#### R511 R551 成功");

          // 初期化
          this.responseHistorySynonymItems = [];
          this.faqSynonymItems = [];
          this.docSynonymItems = [];

          // プロジェクトが取得できない場合は処理を終了する
          if (r511Response.counts === 0) {
            this.logDebug("#### R511 counts 0件 getAllProject()終了");
            return;
          }

          // プロジェクトのnameのprefixに応じて応対履歴、FAQ、ドキュメントのプロジェクトか判定して振り分ける
          // プロジェクトと単語表現分散データの紐付けも行う
          for (let i = 0; i < r511Response.data.data.length; i++) {
            let project = r511Response.data.data[i];

            // プロジェクトキーと単語表現分散データのnameが一致しているものをプロジェクトに追加
            project["word_embedding"] =
                r551Response.data.data.find(function (wordembedding) {
                  return wordembedding.name === project.project_key;
                });

            // 振り分け
            const name = project.name;
            if (name.startsWith(ApiUtils.PREFIX_RES_HIS)) {
              this.responseHistorySynonymItems.push(project);
            } else if (name.startsWith(ApiUtils.PREFIX_FAQ)) {
              this.faqSynonymItems.push(project);
            } else if (name.startsWith(ApiUtils.PREFIX_DOC)) {
              this.docSynonymItems.push(project);
            }
          }
        } else {
          this.logDebug("#### R511またはR551 失敗 R511レスポンス：");
          this.logDebug(r511Response);
          this.logDebug("#### R551レスポンス：");
          this.logDebug(r551Response);

          // 子コンポーネントに渡すアラート情報をセット
          ObjectUtils.alertsInit(this.tabAlerts);
          this.tabAlerts.warning.isShow = true;
          this.tabAlerts.warning.message = Messages.r511.warning;
        }
      } catch (error) {
        this.logDebug("#### getAllProject() 例外発生");
        this.logDebug(error);

        // 子コンポーネントに渡すアラート情報をセット
        ObjectUtils.alertsInit(this.tabAlerts);
        this.tabAlerts.error.isShow = true;
        this.tabAlerts.error.message = Messages.r511.error;
      } finally {

        // ローディング終了
        this.loading = false;
      }
      this.logDebug("### getAllProject() 終了");
    },

    /**
     * tabAlerts を初期化する。
     */
    tabAlertsInitByEmit: function () {
      this.tabAlerts = {
        warning: {isShow: false, message: ""},
        error: {isShow: false, message: ""},
      }
    },
  },
  data: () => ({
    loading: false,
    responseHistorySynonymItems: [],
    faqSynonymItems: [],
    docSynonymItems: [],
    tabAlerts: {
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
  }),

  mounted() {
    this.getAllProject();
  }
}
</script>