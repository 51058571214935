<template>
  <v-menu
      id="menu"
      v-model="menu"
      :close-on-content-click="false"
      min-width="290px"
      offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-bind="attrs"
          v-on="on"
          :label="label"
          :value="value"
          clearable
          prepend-icon="mdi-calendar"
          readonly
          @click:clear="inputData = null"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="inputData"
        type="month"
        locale="ja-JP"
        no-title
        scrollable
        @click="menu = false"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'CalendarYYYYMM',
  data: () => ({
    menu: false
  }),
  props: ['value', 'label'],
  // 変更を検知したら親に値の更新を依頼する
  computed: {
    inputData: {
      get() {
        return this.value
      },
      set(val) {
        this.menu = false
        this.$emit("input", val)
      }
    },
  }
};
</script>