<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>
    <!-- 検索フォーム -->
    <v-container>
      <!-- 開閉パネル -->
      <v-sheet class="px-6 pa-4" elevation="2">
        <v-row no-gutters>
          <v-col cols="4"><h4>検索入力フォーム</h4></v-col>
          <v-col cols="8"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-form ref="search_form">
              <!-- 集計対象の年月 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">集計対象の年月 :</v-col>
                <v-col cols="3">
                  <CalendarYYYYMM
                      v-model="searchCondition.startYearAndMonthLocalFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">&nbsp;～&nbsp;</div>
                <v-col cols="3">
                  <CalendarYYYYMM
                      v-model="searchCondition.startYearAndMonthLocalTo"
                      label=""
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-sheet>

      <br/>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
            :loading="loadingSummaryTitle"
            id="button_get_title"
            class="ma-3"
            color="secondary"
            @click="getTitle()"
        >
          <v-icon>mdi-file-export-outline</v-icon>
          月ごとの FAQ 候補数集計一覧を CSV 出力
        </v-btn>
        <v-btn
            :loading="loadingSummaryDetail"
            id="button_get_detail"
            class="ma-3"
            color="secondary"
            @click="getDetail()"
        >
          <v-icon>mdi-file-export-outline</v-icon>
          FAQ 候補数明細を CSV 出力
        </v-btn>
        <v-btn
            id="button_search_condition_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import AlertArea from "../../parts/AlertArea.vue";
import CalendarYYYYMM from "../../parts/CalendarYYYYMM.vue";
import ApiUtils from "../../js/ApiUtils";
import FileUtils from "../../js/FileUtils";
import ObjectUtils from "../../js/ObjectUtils";
import Messages from "../../js/Messages";

// 文書から FAQ: FAQ 候補数集計画面
export default {
  name: "FAQFromDocumentSummary",
  components: {
    AlertArea,
    CalendarYYYYMM
  },
  methods: {

    /**
     * 検索フォームをすべてリセットする。
     */
    searchConditionClear() {
      // アラートメッセージクリア
      ObjectUtils.alertsInit(this.alerts);

      this.searchCondition.startYearAndMonthLocalFrom = null;
      this.searchCondition.startYearAndMonthLocalTo = null;
    },

    /**
     * 月ごとの FAQ 候補数集計一覧を CSV 出力
     */
    async getTitle() {
      this.logDebug("### getTitle()　開始");

      // アラートメッセージクリア
      ObjectUtils.alertsInit(this.alerts);

      // ローディング開始
      this.loadingSummaryTitle = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.dateObjectPush(qParams, "start_year_and_month_local_from", this.searchCondition.startYearAndMonthLocalFrom);
        ObjectUtils.dateObjectPush(qParams, "start_year_and_month_local_to", this.searchCondition.startYearAndMonthLocalTo);

        // APIリクエスト（指定期間の生成 FAQ 候補数の集計結果の、年月毎のサマリーを返す）
        this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/title");
        const response = await ApiUtils.get("/faq_from_document/faq/summary/title", qParams);

        if (200 === response.status) {
          this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/title　成功");
          if (!response.data) {
            this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/title　データなし");
            this.logDebug(response);
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.faqFromDocumentSummaryTitle.empty;
          } else {
            // API から受け取ったデータを CSV ファイルとしてダウンロードする
            FileUtils.downLoadCsvFromApiResponse(response.data, "faq_from_document_summary_monthly.csv");
          }

        } else {
          this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/title　失敗");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.faqFromDocumentSummaryTitle.warning;
        }
      } catch (error) {
        this.logDebug("#### getTitle() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.faqFromDocumentSummaryTitle.error;
      } finally {
        // ローディング終了
        this.loadingSummaryTitle = false;
      }

      this.logDebug("### getTitle() 終了");
    },

    /**
     * FAQ 候補数明細を CSV 出力
     */
    async getDetail() {
      this.logDebug("### getDetail()　開始");

      // アラートメッセージクリア
      ObjectUtils.alertsInit(this.alerts);

      // ローディング開始
      this.loadingSummaryDetail = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.dateObjectPush(qParams, "start_year_and_month_local_from", this.searchCondition.startYearAndMonthLocalFrom);
        ObjectUtils.dateObjectPush(qParams, "start_year_and_month_local_to", this.searchCondition.startYearAndMonthLocalTo);

        // APIリクエスト（指定期間の、生成 FAQ 候補数を集計し、その詳細を返す）
        this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/detail");
        const response = await ApiUtils.get("/faq_from_document/faq/summary/detail", qParams);

        if (200 === response.status) {
          this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/detail　成功");
          if (!response.data) {
            this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/detail　データなし");
            this.logDebug(response);
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.faqFromDocumentSummaryDetail.empty;
          } else {
            // API から受け取ったデータを CSV ファイルとしてダウンロードする
            FileUtils.downLoadCsvFromApiResponse(response.data, "faq_from_document_summary_detail.csv");
          }

        } else {
          this.logDebug("#### API リクエスト. /faq_from_document/faq/summary/detail　失敗");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.faqFromDocumentSummaryDetail.warning;
        }
      } catch (error) {
        this.logDebug("#### getTitle() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.faqFromDocumentSummaryDetail.error;
      } finally {
        // ローディング終了
        this.loadingSummaryDetail = false;
      }

      this.logDebug("### getDetail() 終了");
    },

    /**
     * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
     * @param message サクセスメッセージ
     */
    showSuccess: ObjectUtils.showSuccess,

    /**
     * 子コンポーネントからワーニングを表示したいときに呼び出す。
     * @param message ワーニングメッセージ
     */
    showWarning: ObjectUtils.showWarning,

    /**
     * 子コンポーネントからエラーを表示したいときに呼び出す。
     * @param message エラーメッセージ
     */
    showError: ObjectUtils.showError,

    /**
     * alerts を初期化する。
     */
    alertsInitByEmit: ObjectUtils.alertsInitByEmit,
  },
  data: () => ({
    loadingSummaryTitle: false,
    loadingSummaryDetail: false,
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    searchCondition: {
      startYearAndMonthLocalFrom: null,
      startYearAndMonthLocalTo: null,
    },
  }),
  mounted: function () {
  },
};
</script>
