<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="uncompletedAlerts.warning.isShow" :message="uncompletedAlerts.warning.message"
                 type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>
    <v-container>
      <!-- 開閉パネル -->
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4"><h4>検索入力フォーム</h4></v-col>
              <v-col class="text--secondary" cols="8">
                <v-fade-transition leave-absolute>
                  <!-- 検索フォームを開いているときのメッセージ -->

                  <span v-if="open">検索条件を入力してください</span>

                  <!-- 検索フォームを閉じているときのメッセージ -->
                  <v-row v-else no-gutters style="width: 100%">
                    <!-- 設定された検索条件を表示 -->
                    <ClosedSearchFormDisp
                        :value="searchCondition.name"
                        label="データセット名"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.RegisterDateFrom"
                        :toValue="searchCondition.RegisterDateTo"
                        label="登録日時"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.jobStartDateFrom"
                        :toValue="searchCondition.jobStartDateTo"
                        label="対象期間開始日"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.jobEndDateFrom"
                        :toValue="searchCondition.jobEndDateTo"
                        label="対象期間終了日"
                    />
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchCondition.numberFrom"
                        :toValue="searchCondition.numberTo"
                        label="件数"
                    />
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-form ref="search_faq_form">
            <!-- 開閉パネルコンテンツ -->
            <v-expansion-panel-content>
              <!-- データセット名 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">データセット名 :</v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="searchCondition.name"
                      clearable
                      label=""
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- 登録日時 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">登録日時 :</v-col>
                <v-col class="ml-n3" cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.RegisterDateFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.RegisterDateTo"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- 対象期間開始日 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);"> 対象期間開始日:</v-col>
                <v-col class="ml-n3" cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobStartDateFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobStartDateTo"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- 対象期間終了日 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);"> 対象期間終了日:</v-col>
                <v-col class="ml-n3" cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobEndDateFrom"
                      label=""
                  />
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <CalendarYYYYMMDD
                      v-model="searchCondition.jobEndDateTo"
                      label=""
                  />
                </v-col>
              </v-row>

              <!-- 件数 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">件数:</v-col>
                <v-col class="ml-n3" cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.numberFrom"
                      label=""
                  ></NumberTextArea>
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchCondition.numberTo"
                      label=""
                  ></NumberTextArea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-form>
        </v-expansion-panel>
      </v-expansion-panels>

      <br/>
      <v-row>
        <v-btn class="ma-3" color="secondary" @click.stop="dialog = true">
          <v-icon>mdi-text-box-plus-outline</v-icon>
          応対履歴データセットの新規登録
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            :loading="searchLoading"
            id="button_get_select_fields"
            class="ma-3"
            color="primary"
            @click="options.page = 1; search()"
        >
          <v-icon>mdi-magnify</v-icon>
          検索
        </v-btn>
        <v-btn
            id="button_search_condition_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>
        <v-dialog v-model="dialog" max-width="600">
          <v-card>
            <v-form ref="create_form" @submit.prevent="newRegister()">
              <v-card-title>応対履歴データセットの新規登録</v-card-title>
              <v-container>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="newRegisterLoading"
                        :value.sync="newData.name"
                        label="データセット名"
                    />
                  </v-col>
                </v-row>

                <v-row dense justify="center">
                  <v-col cols="10">
                    <v-file-input
                        v-model="files"
                        accept=".csv"
                        label="応対履歴csvファイルを複数指定"
                        multiple
                        truncate-length="25"
                        :rules="[required]"
                        v-bind:disabled="newRegisterLoading"
                        @change="getFiles"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip color="primary" label small>
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row class="mt-n7 ml-8" dense justify="center">
                  <v-col cols="10">
                    <small class="text--disabled">複数の応対履歴ファイルが指定可能です。</small>
                  </v-col>
                </v-row>
                <v-row class="mt-n2 mb-n4" dense justify="center">
                  <v-checkbox
                      v-model="checkSynonym"
                      label="同義語候補を作成する"
                      v-bind:disabled="newRegisterLoading"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <v-spacer/>
                  <v-col v-if="newData.loading" cols="8">
                    アップロード準備中...
                    <v-progress-circular
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                  </v-col>
                  <v-spacer/>
                </v-row>
              </v-container>
            </v-form>
            <v-card-actions class="mt-n4">
              <v-spacer></v-spacer>
              <v-btn class="ma-6" color="primary" @click="dialog = false"
              >キャンセル
              </v-btn>
              <v-btn
                  id="button_new_register"
                  :loading="newRegisterLoading"
                  class="ma-6"
                  color="secondary"
                  @click="newRegister()"
              >OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <v-container>
      <v-data-table
          v-model="selected"
          :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
        }"
          :headers="headers"
          :items="items"
          :loading="searchLoading"
          :options.sync="options"
          :server-items-length="total"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="id"
          @update:items-per-page="search()"
          @update:page="search()"
      >
        <template v-slot:[`item.operation`]="{ item }">
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="button_edit"
                  v-on="on"
                  class="secondary"
                  small
                  @click="
                    groupId = item.id;
                    editDialog = true;
                  "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>編集</span>
          </v-tooltip>
          <template v-if="isAiScreen">
            <NewAiTeacherGenerateButton
                :buttonClass="''"
                :isAiMgtScreen="false"
                :isResHisScreen="true"
                :isSmall="true"
                :selectResHis="item"
                @search="()=>{}"
                @showError="showError"
                @showSuccess="showSuccess"
                @showWarning="showWarning"
                @alertsInitByEmit="alertsInitByEmit"
                @uncompletedAlertsInitByEmit="uncompletedAlertsInitByEmit"
            />
          </template>
          <template v-if="isFAQCandidateScreen">
            <NewFAQCandidateBtnForResHisScreen
                :buttonClass="''"
                :isFAQCandidateScreen="false"
                :isResHisScreen="true"
                :isSmall="true"
                :selectResHis="item"
                @search="()=>{}"
                @showError="showError"
                @showSuccess="showSuccess"
                @showWarning="showWarning"
                @alertsInitByEmit="alertsInitByEmit"
                @uncompletedAlertsInitByEmit="uncompletedAlertsInitByEmit"
            />
          </template>
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="button_delete_dialog"
                  v-on="on"
                  color="warning"
                  small
                  @click="
                    deleteId = item.id;
                    deleteDialog = true;
                  "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>削除</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- データセット編集ダイアログ-->
      <v-dialog v-model="editDialog">
        <v-card>
          <v-card-title>データセット編集</v-card-title>
          <v-container>
            <DataSetEdit :datasetGroupId="groupId"/>
            <br/>
            <v-row justify="center">
              <v-card-actions class="mt-n4 mb-2">
                <v-btn class="ma-6" color="primary" @click="editDialog = false"
                >閉じる
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- 削除ダイアログ -->
      <v-dialog v-model="deleteDialog" max-width="600">
        <v-card>
          <v-card-title>この応対履歴データセットを削除した場合、このデータを元に生成された FAQ 候補、AI教師データも削除されます。<b/>本当に削除しますか？</v-card-title>
          <v-container>
            <v-row justify="center">
              <v-card-actions class="mt-n4 mb-2">
                <v-btn
                    class="ma-6"
                    color="primary"
                    @click="deleteDialog = false"
                >キャンセル
                </v-btn>
                <v-btn
                    id="button_delete"
                    class="ma-6"
                    color="warning"
                    :loading="deleteLoading"
                    @click="deleteDoc()"
                >
                  <v-icon>mdi-delete</v-icon>
                  削除
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>
<script>
import AlertArea from "../../parts/AlertArea.vue";
import CalendarYYYYMMDD from "../../parts/CalendarYYYYMMDD.vue";
import ClosedSearchFormDisp from "../../parts/ClosedSearchFormDisp.vue";
import ClosedSearchFormFromToDisp from "../../parts/ClosedSearchFormFromToDisp.vue";
import RequiredTextArea from "../../parts/RequiredTextArea.vue";
import NumberTextArea from "../../parts/NumberTextArea.vue";
import NewAiTeacherGenerateButton from "../../parts/NewAiTeacherGenerateButton.vue";
import NewFAQCandidateBtnForResHisScreen from "@/components/parts/NewFAQCandidateBtnForResHisScreen";
import DataSetEdit from "./DataSetEdit.vue";
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import Messages from "../../js/Messages";
import StringUtils from "../../js/StringUtils";
import ValidationUtils from "../../js/ValidationUtils";

export default {
  components: {
    AlertArea,
    RequiredTextArea,
    CalendarYYYYMMDD,
    NumberTextArea,
    ClosedSearchFormDisp,
    ClosedSearchFormFromToDisp,
    DataSetEdit,
    NewAiTeacherGenerateButton,
    NewFAQCandidateBtnForResHisScreen,
  },
  props: [
    'isAiScreen', // このコンポーネントを表示する画面がAI教師データ生成画面ならtrue
    'isFAQCandidateScreen', // このコンポーネントを表示する画面が応対履歴からFAQ画面ならtrue
  ],
  methods: {

    /**
     * 必須チェック。
     * このInputエリアは複数ファイルの指定を可能としているため、
     * 一度ファイルを指定したあとバツアイコンでファイルを除去すると空配列がセットされるので
     * lengthもチェックしている。
     **/
    required: value => !!value && value.length > 0 || "",

    /**
     * 入力リセットボタン押下時の処理。
     * 検索条件をすべてリセットする。
     **/
    searchConditionClear() {
      this.searchCondition.name = null;
      this.searchCondition.RegisterDateFrom = null;
      this.searchCondition.RegisterDateTo = null;
      this.searchCondition.jobStartDateFrom = null;
      this.searchCondition.jobStartDateTo = null;
      this.searchCondition.jobEndDateFrom = null;
      this.searchCondition.jobEndDateTo = null;
      this.searchCondition.numberFrom = null;
      this.searchCondition.numberTo = null;
    },

    /**
     * 検索ボタン押下時の処理。
     * 検索条件を指定して応対履歴データセットをする。
     */
    async search() {
      this.logDebug("### search() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["search_faq_form"])) {
        this.logDebug("### バリデーションNG search() 終了");
        return;
      }

      // ローディング開始
      this.searchLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "name", this.searchCondition.name);
        ObjectUtils.dateObjectPush(qParams, "created_at_from", this.searchCondition.RegisterDateFrom);
        ObjectUtils.dateObjectPush(qParams, "created_at_to", this.searchCondition.RegisterDateTo);
        ObjectUtils.dateObjectPush(qParams, "start_date_from", this.searchCondition.jobStartDateFrom);
        ObjectUtils.dateObjectPush(qParams, "start_date_to", this.searchCondition.jobStartDateTo);
        ObjectUtils.dateObjectPush(qParams, "end_date_from", this.searchCondition.jobEndDateFrom);
        ObjectUtils.dateObjectPush(qParams, "end_date_to", this.searchCondition.jobEndDateTo);
        ObjectUtils.objectPush(qParams, "row_count_min", this.searchCondition.numberFrom);
        ObjectUtils.objectPush(qParams, "row_count_max", this.searchCondition.numberTo);
        ObjectUtils.objectPush(qParams, "limits", this.options.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.options.page);

        // APIリクエスト（R31）
        this.logDebug("#### R31 リクエスト");
        const response = await ApiUtils.get(
            "/generate_training_data/case_log_dataset",
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R31 成功");
          this.items = response.data.data;
          this.total = response.data.counts;
        } else {
          this.logDebug("#### R31 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.uncompletedAlerts.warning.isShow = false;
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r31.warning;
        }
      } catch (error) {
        this.logDebug("#### search() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.uncompletedAlerts.warning.isShow = false;
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r31.error;
      } finally {
        this.searchLoading = false;
      }
      this.logDebug("### search() 終了");
    },

    /**
     * 選択されたファイルの情報を保存する。
     * 名称にファイル名＋現在日付を入力する。
     */
    async getFiles(files) {

      // ×アイコンを押下するとfilesにnullが連携されるので、そのエラーを抑制
      if (files == null || files.length === 0) {
        return;
      }

      // ローディング開始
      this.newData.loading = true;
      let tmpFiles = [];
      for (let i = 0; i < files.length; i++) {
        let tmpFile = {};
        tmpFile["filename"] = files[i].name;
        tmpFile["file"] = files[i];
        tmpFiles.push(tmpFile);
      }
      this.newData.files = tmpFiles;
      this.newData.loading = false;

      // 最初のファイル名を名称に入力
      const date = StringUtils.getNowDate();
      const name = StringUtils.removeExtension(tmpFiles[0].filename);
      this.newData.name = name + " " + date;
    },

    /**
     * 新規登録ボタンクリック時の処理。
     * 応対履歴データセット新規登録を行う。
     */
    async newRegister() {
      this.logDebug("### newRegister() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["create_form"])) {
        this.logDebug("### バリデーションNG newRegister() 終了");
        return; // 失敗は戻る
      }

      // アラートエリア初期化
      ObjectUtils.alertsInit(this.alerts);
      this.uncompletedAlerts.warning.isShow = false;
      try {
        this.newRegisterLoading = true;

        // 登録成功時のメッセージを保持する配列。
        let successMessages = [];

        // R29 応対履歴データセット情報（データセット名）を登録
        const getIdResponse = await this.getId();
        if (getIdResponse.is_success !== true) {
          this.logDebug("### 応対履歴データセットID取得失敗 newRegister() 終了");
          return; // 失敗は戻る
        }

        this.logDebug("#### ファイル件数：" + this.newData.files.length);
        let i = 0; // どこまで処理したか覚えておくため変数は外に出しておく
        for (; i < this.newData.files.length; i++) {

          // R1 アップロードURLとS3パス取得
          const r1Response = await ApiUtils.getPath(
              this.alerts, "case_log", getIdResponse.id, this.newData.files[i].filename);
          if (r1Response.is_success !== true) {
            this.logDebug("### アップロードURLとS3パス取得失敗 newRegister() 終了");
            break;
          }
          if (!(await ApiUtils.csvUpload(this.alerts, r1Response.upload_url, this.newData.files[i].file))) {
            this.logDebug("### CSVファイルアップロード失敗 newRegister() 終了");
            break;
          }
          if (!(await ApiUtils.registerR30(this.alerts, r1Response.s3_path, getIdResponse.id, this.newData.files[i].filename))) {
            this.logDebug("### 応対履歴ファイルの紐付け情報登録失敗 newRegister() 終了");
            break;
          }
          successMessages.push("・[ファイル名]：" + this.newData.files[i].filename + "\n");
        }

        // 未処理のファイルがあれば画面にファイル名を保持しておく
        let uncompletedMessages = [];
        for (i += 1; i < this.newData.files.length; i++) {
          uncompletedMessages.push("・[ファイル名]：" + this.newData.files[i].filename + "\n");
        }

        // 同義語候補処理
        let synonymProject = false;
        let synonymProjectName = "";

        // 同義語候補を作成するにチェックが入っており、ファイルの登録が1件以上成功している場合に同義語処理をする
        if (this.checkSynonym && successMessages.length > 0) {

          // 登録した応対履歴データセットの ID をパラメータに渡して、同義語データ作成を実行
          const createSynonymResponse = await this.createSynonym(
              getIdResponse.id
          );
          if (createSynonymResponse.is_success !== true) {
            this.logDebug("#### 同義語作成失敗");
          } else {

            // 成功したら、フラグを立てる
            synonymProject = true;
            synonymProjectName = createSynonymResponse.name;
          }
        }

        // ファイル登録成功している場合は成功メッセージを表示
        if (successMessages.length > 0) {

          let message = StringUtils.messageReplace(Messages.r30.success, [this.newData.name]) + "\n";
          successMessages.forEach(str => {
            message += str
          });
          message = message.slice(0, -1); // 最後の改行削除

          // 同義語処理成功メッセージ処理
          if (synonymProject === true) {
            message += "\n";
            let reps = [synonymProjectName];
            message += StringUtils.messageReplace(Messages.responseHistorySynonymProject.success, reps);
            message += "\n";
            successMessages.forEach(str => {
              message += str
            });
            message = message.slice(0, -1); // 最後の改行削除
          }

          // 成功メッセージ表示
          this.alerts.success.message = message;
          this.alerts.success.isShow = true;
        }

        // 未処理ファイルがあれば未処理を伝えるメッセージを表示
        if (uncompletedMessages.length > 0) {
          this.uncompletedAlerts.warning.message = Messages.r30.uncompleted + "\n";
          uncompletedMessages.forEach(str => {
            this.uncompletedAlerts.warning.message += str;
          });
          this.uncompletedAlerts.warning.message = this.uncompletedAlerts.warning.message.slice(0, -1);
          this.uncompletedAlerts.warning.isShow = true;
        }

        // 検索を実行しデータを再取得
        await this.search();

        // 入力を初期化
        ValidationUtils.reset(this.$refs["create_form"]);
      } finally {
        this.dialog = false;
        this.newRegisterLoading = false;
        this.logDebug("### newRegister() 終了");
      }
    },

    /**
     * 応対履歴データセット情報（データセット名）を登録する。
     * レスポンスのidが応対履歴データセットのIDとなる。
     */
    async getId() {
      this.logDebug("#### getId() 開始");
      let ret = {
        is_success: false,
        id: null,
      };
      try {
        let body = {};
        ObjectUtils.objectPush(body, "name", this.newData.name);

        // APIリクエスト（R29）
        this.logDebug("##### R29 リクエスト");
        const response = await ApiUtils.post(
            "/generate_training_data/case_log_dataset",
            body
        );
        if (200 === response.status) {
          this.logDebug("##### R29 成功");
          ret = {
            is_success: true,
            id: parseInt(response.data.id),
          };
        } else {
          this.logDebug("##### R29 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r29.warning;
        }
      } catch (error) {
        this.logDebug("##### getId() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r29.error;
      }
      this.logDebug("#### getId() 終了");
      return ret;
    },

    /**
     * 同義語 API（ Ten API ）のラッパーを呼び出して、
     * 同義語プロジェクト作成、単語分散表現データ作成、
     * データインポート、単語分散表現データ解析、を実行する
     *
     * 同義語プロジェクト作成、単語分散表現データ作成、までを同期的に実行した後、レスポンスを返し
     * その後、バックグラウンドで非同期的にデータインポート、単語分散表現データ解析、を実行する
     */
    async createSynonym(datasetId) {
      this.logDebug("#### createSynonym() 開始");
      let ret = {
        is_success: false,
        name: null,
        project_key: null,
        word_embedding_key: null,
      };
      try {
        let body = {};

        // APIリクエスト（同義語データ作成）
        this.logDebug("##### 同義語データ作成 リクエスト");
        const response = await ApiUtils.post(
            "/synonym/create_synonym/case_log/" + String(datasetId),
            body
        );

        if (200 === response.status) {
          this.logDebug("##### 同義語データ作成 成功");
          ret = {
            is_success: true,
            name: response.data.name,
            project_key: response.data.project_key,
            word_embedding_key: response.data.word_embedding_key,
          };
        } else {
          this.logDebug("##### 同義語データ作成 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r513.warning;
        }
      } catch (error) {
        this.logDebug("#### createSynonym() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r513.error;
      }
      this.logDebug("##### レスポンス：" + JSON.stringify(ret));
      this.logDebug("#### createSynonym() 終了");
      return ret;
    },

    /**
     * 登録済み応対履歴データセットを削除する。
     */
    async deleteDoc() {
      this.logDebug("### deleteDoc() 開始");
      try {

        // アラートエリア初期化
        ObjectUtils.alertsInit(this.alerts);
        this.uncompletedAlerts.warning.isShow = false;
        this.deleteLoading = true;

        // APIリクエスト（R9）
        this.logDebug("#### R9 リクエスト");
        const response = await ApiUtils.deleteReq(
            "/generate_training_data/case_log/" + this.deleteId
        );

        if (200 === response.status) {
          this.logDebug("#### R9  成功");
          this.alerts.success.isShow = true;
          this.alerts.success.message = Messages.r9.success;
          await this.search();
        } else {
          this.logDebug("#### R9 失敗 レスポンス：");
          this.logDebug(response);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r9.warning;
        }
      } catch (error) {
        this.logDebug("#### deleteDoc() 例外発生");
        this.logDebug(error);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r9.error;
      } finally {
        this.deleteLoading = false;
        this.deleteDialog = false;
      }
      this.logDebug("### deleteDoc() 終了");
    },

    /**
     * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
     * @param message サクセスメッセージ
     */
    showSuccess: ObjectUtils.showSuccess,

    /**
     * 子コンポーネントからワーニングを表示したいときに呼び出す。
     * @param message ワーニングメッセージ
     */
    showWarning: ObjectUtils.showWarning,

    /**
     * 子コンポーネントからエラーを表示したいときに呼び出す。
     * @param message エラーメッセージ
     */
    showError: ObjectUtils.showError,

    /**
     * alerts を初期化する。
     */
    alertsInitByEmit: ObjectUtils.alertsInitByEmit,

    /**
     * uncompletedAlerts を初期化する。
     */
    uncompletedAlertsInitByEmit: function () {
      this.uncompletedAlerts = {warning: {isShow: false, message: ""}}
    },
  },
  mounted: function () {
    // 画面表示のタイミングで検索を実行
    this.logDebug("# ResponseHistoryMgt.vue mounted");
    this.search();
  },
  data() {
    return {
      dialog: false,
      details: false,
      editDialog: false,
      deleteDialog: false,
      deleteLoading: false,
      groupId: null,
      deleteId: null,
      files: null,
      newRegisterLoading: false,
      checkSynonym: false,
      searchLoading: false,
      alerts: {
        info: {isShow: false, message: ""},
        success: {isShow: false, message: ""},
        warning: {isShow: false, message: ""},
        error: {isShow: false, message: ""},
      },
      uncompletedAlerts: {
        warning: {isShow: false, message: ""},
      },
      searchCondition: {
        name: null,
        RegisterDateFrom: null,
        RegisterDateTo: null,
        jobStartDateFrom: null,
        jobStartDateTo: null,
        jobEndDateFrom: null,
        jobEndDateTo: null,
        numberFrom: null,
        numberTo: null,
      },
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ["id"],
        sortDesc: [false],
      },
      total: 0,
      newData: {
        name: "",
        loading: false,
        files: [
          {
            filename: null,
            file: null,
          },
        ],
      },
      singleSelect: true,
      selected: [],
      headers: [
        {align: "start", text: "データセット名", value: "name"},
        {align: "start", text: "登録日時", value: "created_at"},
        {align: "start", text: "対象期間開始日", value: "start_date"},
        {align: "start", text: "対象期間終了日", value: "end_date"},
        {align: "end", text: "件数", value: "row_count"},
        {align: "start", text: "操作", value: "operation", width: "185"},
      ],
      items: [],
    };
  },
};
</script>