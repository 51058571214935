<template>
  <v-main>
    <!-- タイトル -->
    <Title title="同義語生成"/>

    <!-- タブ -->
    <CreateSynonymTab/>

  </v-main>
</template>

<script>
import Title from '../parts/Title.vue';
import CreateSynonymTab from '../tabs/CreateSynonymTab.vue';

export default {
  name: 'CreateSynonym',
  components: {
    Title,
    CreateSynonymTab
  },
  data: () => ({}),
};
</script>
