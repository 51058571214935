<template>
  <v-card>

    <v-tabs centered color="secondary" fixed-tabs>
      <v-tab href="#tab-1">応対履歴データ登録</v-tab>
      <v-tab href="#tab-2">FAQ データ登録</v-tab>
      <v-tab href="#tab-3">AI 教師データ生成</v-tab>

      <v-tab-item value="tab-1">
        <ResponseHistory :isAiScreen="true" :isFAQCandidateScreen="false"/>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <FAQMgt/>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <AiTeacherData/>
      </v-tab-item>
    </v-tabs>

  </v-card>
</template>

<script>
import ResponseHistory from './views/ResponseHistoryMgt.vue';
import FAQMgt from './views/FAQMgt.vue';
import AiTeacherData from './views/AiTeacherDataMgt.vue';

export default {
  name: 'CreateAiTeacherDataTab',
  components: {
    ResponseHistory,
    FAQMgt,
    AiTeacherData
  },
  data() {
    return {}
  },
}
</script>