import moment from "moment"

/**
 * messageに記載された[0]や[1]をreplacesで置換した文字列を返却する。
 * 引数が不正な場合は空文字を返却する。
 * @param {string} message 置換対象の文字列
 * @param {array} replaces 置換に使う文字の配列
 * @return {string} messageを置換した文字列。値が不正な場合は空文字を返却
 **/
function messageReplace(message, replaces) {
    if (message == null || replaces == null || replaces.length === 0) {
        return "";
    }
    let result = message;
    replaces.forEach((elem, index) => {
        result = result.replace(`[${`${index}`}]`, `${elem}`);
    });
    return result;
}

/**
 * 日付の書式を変換した文字列を返却する。
 * 日付が不正な書式の場合は、そのまま返却する。
 * @param {string} date 置換対象の日付文字列
 * @return {string} dateを置換した日付文字列。不正な書式の場合はそのまま返却
 **/
function dateReplace(date) {

    // 不正チェック
    if (!date && !moment(date).isValid()) {
        return date;
    }

    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * 指定の数値を、小数点以下桁数を揃えて、文字列として返す
 * @param {number} value フォーマット対象の数値
 * @param {number} digits 小数点以下桁数（デフォルトは7）
 */
function formatNumber(value, digits = 7) {
    if (typeof value !== 'number') {
        return '';
    }
    if (typeof digits !== 'number') {
        return ''
    }
    if (digits < 0) {
        return ''
    }

    const formatted = new Intl.NumberFormat('ja-JP', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    }).format(value);
    return formatted;
}

/**
 * 現在の日付を返却する。
 * @return {string} dateを置換した日付文字列。
 **/
function getNowDate() {
    const date = new Date();

    // [2019-03-02T00:42:16.000Z]をTで分割し末尾の文字列を削除することで[2019-03-02]を取得する
    const result = date.toISOString().split('T').slice(0, -1);
    return result;
}

/**
 * 現在の日時を返却する。
 * @return {string} dateを置換した日時文字列。
 **/
function getNowDatetime() {
    const date = new Date();

    // [2021/9/7 16:03:35]を[/]から[-]に変換して[2021-9-7 16:03:35]を取得する
    const result = date.toLocaleString("ja").replace(/\//g, '-');
    return result;
}

/**
 * ファイル名から拡張子を取り除く。
 * @return {string} ファイル名。
 **/
function removeExtension (filename) {
    return filename.split('.').slice(0, -1).join('.');
}

export default {
    messageReplace,
    dateReplace,
    formatNumber,
    getNowDate,
    getNowDatetime,
    removeExtension
}