<template>
  <v-text-field
      v-model="inputData"
      :label="label"
      :rules="[required, number]"
      :value="value"
      auto-grow
      clearable
      row-height="15"
      rows="1"
      v-bind:disabled="disabled"
  ></v-text-field>
</template>

<script>
export default {
  name: "RequiredNumberArea",
  props: ["value", "label", 'disabled'],
  methods: {
    required: value => !!value || "",
    number: v =>
        v == null ||
        (v.search(/[.]/g) === -1 && Number(v) >= 0 && Math.sign(v) >= 0) ||
        `正数を半角数字で入力してください`,
  },
  computed: {
    inputData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
};
</script>