import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        light: true,
        themes: {
            light: {
                primary: '#78909C', // 基本ボタンカラー（グレー）
                secondary: '#3F51B5', // 新規登録・編集・OKボタンカラー（ブルー）
                warning: '#b71c1c', // 削除ボタンカラー（レッド）
                tooltip: '#303030', // ツールチップの背景色（ブラック）
            },
        },
    },
});
