import axios from "axios";
import route from '../../route';
import Messages from "./Messages";
import ObjectUtils from "./ObjectUtils";
import store from '../../store/store.js';
import logger from '@/mixins/logger';
import EnvAccessor from "@/components/js/EnvAccessor";
import StringUtils from "@/components/js/StringUtils";

const PREFIX_RES_HIS = "[応対履歴データ]";
const PREFIX_FAQ = "[FAQデータ]";
const PREFIX_DOC = "[文書データ]";

/**
 * 知識生成サービスAPIで使うヘッダーを取得する。
 * @returns {any}
 */
function getAiHeaders() {
    return {
        Authorization: store.getters['user/token'],
        Tenant: store.getters['user/tenant'],
    }
}

/**
 * axios.get()を実行する。
 * 例外が発生しないようにvalidateStatusを指定している。
 * (デフォルトだと200以外のステータスが返却されると例外がスローされる)
 **/
function get(url, qParams) {
    logger.methods.logDebug('#### ApiUtils.get(url:' + url + ' , qParams:' + JSON.stringify(qParams) + ')実行結果');
    const response = axios.get(
        EnvAccessor.getApiBaseUrl() + url,
        {
            params: qParams,
            headers: getAiHeaders(),
            validateStatus: validateStatus
        }
    );
    logger.methods.logDebug(response);
    auth(response);
    return response;
}

/**
 * axios.post()を実行する。
 * 例外が発生しないようにvalidateStatusを指定している。
 * (デフォルトだと200以外のステータスが返却されると例外がスローされる)
 **/
function post(url, body) {
    logger.methods.logDebug('#### ApiUtils.post(url:' + url + ' , body:' + JSON.stringify(body) + ')実行結果');
    const response = axios.post(
        EnvAccessor.getApiBaseUrl() + url,
        body,
        {
            headers: getAiHeaders(),
            validateStatus: validateStatus
        },
    );
    logger.methods.logDebug(response);
    auth(response);
    return response;
}

/**
 * axios.delete()を実行する。
 * function名がdelete ではないのは、deleteは予約後で使えないため。
 * 例外が発生しないようにvalidateStatusを指定している。
 * (デフォルトだと200以外のステータスが返却されると例外がスローされる)
 **/
function deleteReq(url) {
    logger.methods.logDebug('#### ApiUtils.deleteReq(url:' + url + ')実行結果');
    const response = axios.delete(
        EnvAccessor.getApiBaseUrl() + url,
        {
            headers: getAiHeaders(),
            validateStatus: validateStatus
        }
    );
    logger.methods.logDebug(response);
    auth(response);
    return response;
}

/**
 * 例外が発生しないようにvalidateStatusを指定する。
 * (デフォルトだと200以外のステータスが返却されると例外がスローされる)
 * （returns `true` (or is set to `null` or `undefined`), the promise will be resolved.）
 **/
function validateStatus(status) {
    return status < 500;
}

/**
 * ログイン期限が切れているかチェックする。
 * （401をログイン期間切れと判定）
 * 切れている場合はログイン画面にリダイレクトする。
 **/
function auth(response) {
    response.then((result) => {
        if (401 === result.status) {
            logger.methods.logDebug('#### ログイン期限切れ');
            store.dispatch("user/logout");
            route.push({name: "login"});
        }
    });
}

/**
 * ログイン期限が切れているかチェックする。
 * (async await内で使う用)
 **/
function authAsyncAwait(response) {
    if (401 === response.status) {
        logger.methods.logDebug('#### ログイン期限切れ');
        store.dispatch("user/logout");
        route.push({name: "login"});
    }
}

/**
 * 新規登録に必要なアップロードURLとS3パスを取得する。
 * @param {Object} alerts - アラートエリアのオブジェクト
 * @param {string} type - ファイルのタイプ（応対履歴の場合、"case_log"、FAQ の場合、"faq"、マニュアルなどの文書の場合、"document"）
 * @param {Object} id - 応対履歴のID
 * @param {string} filename - アップロードするファイルの名前
 * @returns アップロードURL(s3_path), S3パス(s3_path)
 */
async function getPath(alerts, type, id, filename) {
    logger.methods.logDebug('#### ApiUtils.getPath(alerts:' + alerts
        + ', type:' + type + ', id:' + id + ', filename:' + filename + ') 実行');
    let ret = {
        'is_success': false,
        's3_path': '',
        'upload_url': ''
    }
    try {
        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "type", type);
        ObjectUtils.objectPush(qParams, "filename", filename);
        ObjectUtils.objectPush(qParams, "id", id);

        // APIリクエスト（R1）
        logger.methods.logDebug("#### R1 リクエスト");
        const response = await this.get(
            '/common/upload_url_put',
            qParams
        );

        if (200 === response.status) {
            logger.methods.logDebug("#### R1 成功");
            ret = {
                'is_success': true,
                's3_path': response.data.s3_path,
                'upload_url': response.data.upload_url
            }
        } else {
            logger.methods.logDebug("#### R1 失敗 レスポンス：");
            logger.methods.logDebug(response);
            alerts.warning.isShow = true
            alerts.warning.message = StringUtils.messageReplace(Messages.r1.warning, [filename])
        }
    } catch (error) {
        logger.methods.logDebug("#### getPath() 例外発生");
        logger.methods.logDebug(error);
        alerts.error.isShow = true
        alerts.error.message = StringUtils.messageReplace(Messages.r1.error, [filename])
    }
    logger.methods.logDebug("#### getPath() レスポンス：" + JSON.stringify(ret));
    return ret;
}

/**
 * axios.put()を使ってファイルをアップロードする。
 * 例外が発生しないようにvalidateStatusを指定している。
 * (デフォルトだと200以外のステータスが返却されると例外がスローされる)
 **/
async function fileUpload(alerts, url, file, contentType) {
    logger.methods.logDebug('#### ApiUtils.fileUpload(alerts:' + JSON.stringify(alerts)
        + ', url:' + url + ', contentType:' + contentType + ') 実行');
    try {

        // headersに日本語を使用するとエラーになるのでエンコード
        // Content-disposition に attachment を付与することでファイルがダウンロードされる
        const contentDisposition = "attachment; filename=" + encodeURIComponent(file.name);
        const response = await axios.put(
            url,
            file,
            {
                validateStatus: validateStatus,
                headers: {
                    'Content-Type': contentType,
                    'Content-disposition': contentDisposition,
                },
            }
        );
        logger.methods.logDebug(response);
        authAsyncAwait(response);
        if (200 === response.status) {
            logger.methods.logDebug("#### fileUpload() 成功");
            return true;
        } else {
            throw (''); // catchで処理する
        }
    } catch (error) {
        logger.methods.logDebug("#### fileUpload() 例外発生");
        logger.methods.logDebug(error);
        alerts.error.isShow = true
        alerts.error.message = StringUtils.messageReplace(Messages.fileupload.error, [file.name])
    }
    return false;
}

/**
 * 署名付きURL(uploadUrl)にCSVファイル(file)をアップロードする。
 */
function csvUpload(alerts, uploadUrl, file) {
    logger.methods.logDebug('#### ApiUtils.csvUpload(alerts:' + JSON.stringify(alerts)
        + ', uploadUrl:' + uploadUrl + ', file:' + file + ') 実行');
    return fileUpload(
        alerts,
        uploadUrl,
        file,
        "text/csv"
    );
}

/**
 * 署名付きURL(uploadUrl)に文書データファイル(file)をアップロードする。
 */
function documentUpload(alerts, uploadUrl, file) {
    logger.methods.logDebug('#### ApiUtils.documentUpload(alerts:' + alerts
        + ', uploadUrl:' + uploadUrl + ', file:' + file + ') 実行');

    // ファイルから拡張子を取得（比較のため小文字に変換）
    const ext = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();

    // 拡張子からcontentTypeを設定
    // switchに記載した拡張子以外はバリデーションエラーになるため、これ以外の拡張子はこの処理に到達しない
    let contentType;
    switch (ext) {
        case "pdf":
            contentType = "application/pdf";
            break;
        case "xls":
            contentType = "application/vnd.ms-excel";
            break;
        case "xlsx":
            contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            break;
        case "ppt":
            contentType = "application/vnd.ms-powerpoint";
            break;
        case "pptx":
            contentType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            break;
        case "doc":
            contentType = "application/msword";
            break;
        case "docx":
            contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            break;
        case "html":
        case "htm":
            contentType = "text/html";
            break;
    }

    return fileUpload(
        alerts,
        uploadUrl,
        file,
        contentType
    );
}

/**
 * データセットと応対履歴ファイルの紐付け情報を登録する。
 */
async function registerR30(alerts, s3_path, id, filename) {
    logger.methods.logDebug('#### ApiUtils.registerR30(alerts:' + alerts
        + ', s3_path:' + s3_path + ', id:' + id + ', filename:' + filename + ') 実行');
    try {
        let body = {};
        ObjectUtils.objectPush(body, "s3_path", s3_path);

        // APIリクエスト（R30）
        logger.methods.logDebug("#### R30 リクエスト");
        const response = await this.post(
            '/generate_training_data/case_log_dataset/' + id + '/file',
            body,
        );

        if (200 === response.status) {
            logger.methods.logDebug("#### R30 成功");
            return true;
        } else {
            logger.methods.logDebug("#### R30 失敗 レスポンス：");
            logger.methods.logDebug(response);
            alerts.warning.isShow = true
            alerts.warning.message = StringUtils.messageReplace(Messages.r30.warning, [filename])
            return false;
        }
    } catch (error) {
        logger.methods.logDebug("#### registerR30() 例外発生");
        logger.methods.logDebug(error);
        alerts.error.isShow = true
        alerts.error.message = StringUtils.messageReplace(Messages.r30.error, [filename])
        return false;
    }
}

export default {
    get,
    post,
    deleteReq,
    getPath,
    csvUpload,
    documentUpload,
    registerR30,
    authAsyncAwait,
    validateStatus,
    PREFIX_RES_HIS,
    PREFIX_FAQ,
    PREFIX_DOC,
}