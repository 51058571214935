var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-btn',{attrs:{"id":"button_close","icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-container',{staticClass:"mt-n4 mb-n5"},[_c('AlertArea',{attrs:{"isShow":_vm.alerts.info.isShow,"message":_vm.alerts.info.message,"type":"info"},on:{"update:isShow":function($event){return _vm.$set(_vm.alerts.info, "isShow", $event)},"update:is-show":function($event){return _vm.$set(_vm.alerts.info, "isShow", $event)}}}),_c('AlertArea',{attrs:{"isShow":_vm.alerts.success.isShow,"message":_vm.alerts.success.message,"type":"success"},on:{"update:isShow":function($event){return _vm.$set(_vm.alerts.success, "isShow", $event)},"update:is-show":function($event){return _vm.$set(_vm.alerts.success, "isShow", $event)}}}),_c('AlertArea',{attrs:{"isShow":_vm.alerts.warning.isShow,"message":_vm.alerts.warning.message,"type":"warning"},on:{"update:isShow":function($event){return _vm.$set(_vm.alerts.warning, "isShow", $event)},"update:is-show":function($event){return _vm.$set(_vm.alerts.warning, "isShow", $event)}}}),_c('AlertArea',{attrs:{"isShow":_vm.alerts.error.isShow,"message":_vm.alerts.error.message,"type":"error"},on:{"update:isShow":function($event){return _vm.$set(_vm.alerts.error, "isShow", $event)},"update:is-show":function($event){return _vm.$set(_vm.alerts.error, "isShow", $event)}}})],1),_c('v-container',{staticClass:"mt-n5 mb-n4"},[_c('v-row',[_c('v-card-title',[_vm._v("名称："+_vm._s(_vm.createFaqName))])],1),_c('v-row',{staticClass:"ml-1",staticStyle:{"color":"rgba(0, 0, 0, 0.6)"},attrs:{"dense":""}},[_vm._v(" ・応対履歴データ："+_vm._s(_vm.caseLogName)+" "),_c('v-tooltip',{attrs:{"color":"tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-n1 ma-3",attrs:{"id":"case_log_dialog_button","color":"primary","small":""},on:{"click":function($event){_vm.caseLogDialog=true}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("ダウンロード")])]),_c('v-card-actions',{staticClass:"mt-n7"},[_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.caseLogDialog),callback:function ($$v) {_vm.caseLogDialog=$$v},expression:"caseLogDialog"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_vm._v("応対履歴データの選択")]),_c('v-flex',[_c('DownloadResponseHistory',{attrs:{"datasetGroupId":_vm.datasetGroupId}}),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"ma-3",attrs:{"id":"case_log_dialog_ok_button","color":"primary"},on:{"click":function($event){_vm.caseLogDialog=false}}},[_vm._v("閉じる ")])],1),_c('br')],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"ml-1",staticStyle:{"color":"rgba(0, 0, 0, 0.6)"},attrs:{"dense":""}},[_vm._v(" ・FAQデータ："+_vm._s(_vm.faqName)+" "),_c('v-tooltip',{attrs:{"color":"tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-n1 ma-3",attrs:{"id":"faq_download_button","loading":_vm.downloadLoading,"color":"primary","small":""},on:{"click":function($event){return _vm.download()}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("ダウンロード")])])],1)],1),_c('v-container',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-textarea',{staticClass:"ml-n3",attrs:{"value":_vm.selectQuestion,"auto-grow":"","label":"質問文","outlined":"","readonly":"","rows":"1"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-7",attrs:{"id":"faq_select_button","loading":_vm.getMatchingFaqListLoading || _vm.getAiTeacherDetailListLoading,"color":"primary"},on:{"click":function($event){return _vm.faqSelectButton();}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" FAQを選択する ")],1)],1),_c('v-spacer')],1),_c('v-container',[_c('v-row',[_c('v-dialog',{model:{value:(_vm.faqSearchDialog),callback:function ($$v) {_vm.faqSearchDialog=$$v},expression:"faqSearchDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("FAQ選択")]),_c('v-container',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.faqOptions.page = 1; _vm.getFaqDetailList()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"clearable":"","label":"質問"},model:{value:(_vm.faqDetailSearchCondition.question),callback:function ($$v) {_vm.$set(_vm.faqDetailSearchCondition, "question", $$v)},expression:"faqDetailSearchCondition.question"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"id":"faq_detail_search_button","loading":_vm.getMatchingFaqListLoading,"color":"primary"},on:{"click":function($event){_vm.faqOptions.page = 1; _vm.getFaqDetailList()}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" 検索 ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
                      'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500, 1000],
                    },"headers":_vm.faqDetailListHeaders,"items":_vm.faqDetailList,"loading":_vm.getMatchingFaqListLoading,"options":_vm.faqOptions,"calculate-widths":"","dense":"","disable-sort":"","item-key":"faq_id","show-select":"","single-select":""},on:{"update:options":function($event){_vm.faqOptions=$event}},scopedSlots:_vm._u([{key:"item.answer",fn:function(ref){
                    var item = ref.item;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-container',[_c('v-layout',{staticStyle:{"height":"10vh"}},[_c('v-flex',{staticStyle:{"overflow":"visible scroll"}},[_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(item.answer))])])],1)],1)]},proxy:true}],null,true)},[_c('div',{on:{"click":function($event){_vm.answerDialog = true}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.answer))+" ")])])]}}],null,true),model:{value:(_vm.faqDetailSelected),callback:function ($$v) {_vm.faqDetailSelected=$$v},expression:"faqDetailSelected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-6",attrs:{"color":"primary"},on:{"click":function($event){_vm.faqSearchDialog = false}}},[_vm._v("キャンセル ")]),_c('v-btn',{staticClass:"ma-6",attrs:{"id":"faq_search_dialog_ok_button","loading":_vm.getAiTeacherDetailListLoading,"color":"primary"},on:{"click":function($event){return _vm.faqSearchDialogOK()}}},[_vm._v("OK ")]),_c('v-spacer')],1)],1)],1)],1)],1)],1)],1),_c('v-container',{staticClass:"mt-n12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
          'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
        },"headers":_vm.aiTeacherDetailListHeaders,"items":_vm.aiTeacherDetailList,"loading":_vm.getAiTeacherDetailListLoading,"options":_vm.aiOptions,"server-items-length":_vm.aiTotal,"calculate-widths":"","dense":"","disable-sort":"","item-key":"id","show-select":""},on:{"update:options":function($event){_vm.aiOptions=$event},"update:items-per-page":function($event){return _vm.getAiTeacherDetailList()},"update:page":function($event){return _vm.getAiTeacherDetailList()}},model:{value:(_vm.outputTargets),callback:function ($$v) {_vm.outputTargets=$$v},expression:"outputTargets"}},[_vm._v(" > ")])],1),_c('v-container',{staticClass:"mt-n3"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-3",attrs:{"id":"button_download_csv","color":"primary"},on:{"click":function($event){return _vm.downloadCSV()}}},[_c('v-icon',[_vm._v("mdi-file-export-outline")]),_vm._v(" ファイル出力 ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }