<template>
  <v-main>
    <!-- タイトル -->
    <Title title="ユーザ管理"/>

    <v-container>
      <v-row>
        <v-btn
            class="ma-3"
            color="secondary"
            @click.stop="showAccountAddDialog()"
        >
          <v-icon>mdi-account-plus</v-icon>
          ユーザ追加
        </v-btn
        >
        <v-dialog v-model="accountAddDialog" max-width="600">
          <v-form ref="add_user_form" @submit.prevent="createUser()">
            <v-card>
              <v-card-title>ユーザ追加</v-card-title>
              <v-container>
                <v-row justify="center">
                  <v-col cols="10">
                    <div class="text--disabled">
                      初期パスワードは、英大・子文字、数字、記号を含め8文字以上、かつ、ユーザIDとは異なる値をご指定下さい。
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-n6" justify="center">
                  <v-col cols="10">
                    <v-chip
                        v-if="chips[0].visible"
                        class="ma-2"
                        close
                        color="red"
                        label
                        outlined
                        @click:close="chips[0].visible = false"
                    >
                      {{ chips[0].message }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="false"
                        :value.sync="accountCondition.username"
                        label="ユーザID（例： nomurataro）"
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="false"
                        :value.sync="accountCondition.name"
                        label="ユーザ名（例： 野村 太郎）"
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="false"
                        :value.sync="accountCondition.email"
                        label="メールアドレス"
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <v-radio-group
                        v-model="accountCondition.role"
                        :rules="[required]"
                        mandatory
                        row
                    >
                      <v-radio
                          label="管理者"
                          value="manager"
                      ></v-radio>
                      <v-radio
                          label="一般ユーザ"
                          value="user"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="8">
                    <PasswordTextField
                        :value.sync="accountCondition.temporary_password"
                        label="初期パスワード"
                    />
                  </v-col>
                  <v-col cols="2">
                    <PasswordGenerateButton
                        ref="account_condition_temporary_password"
                        :password.sync="accountCondition.temporary_password"/>
                  </v-col>
                </v-row>
                <v-card-actions class="mt-n4">
                  <v-spacer></v-spacer>
                  <v-btn
                      id="hide_add_user_dialog"
                      class="ma-6"
                      color="primary"
                      @click="hideAddUserDialog()"
                  >キャンセル
                  </v-btn
                  >
                  <v-btn id="create_user" :loading="createUserLoading" class="ma-6"
                         color="secondary" @click="createUser()"
                  >OK
                  </v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-dialog v-model="editDialog" max-width="600">
          <v-form ref="add_user_form" @submit.prevent="updateUser()">
            <v-card>
              <v-card-title>ユーザ編集</v-card-title>
              <v-container>
                <v-row class="mt-n6" justify="center">
                  <v-col cols="10">
                    <v-chip
                        v-if="chips[1].visible"
                        class="ma-2"
                        close
                        color="red"
                        label
                        outlined
                        @click:close="chips[1].visible = false"
                    >
                      {{ chips[1].message }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <ReadOnlyNormalTextArea
                        :value.sync="accountForEdit.username"
                        label="ユーザID"
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="false"
                        :value.sync="accountForEdit.name"
                        label="ユーザ名"
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <RequiredTextArea
                        :disabled="false"
                        :value.sync="accountForEdit.email"
                        label="メールアドレス"
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <v-radio-group
                        v-model="accountForEdit.role"
                        :disabled="isMyRecord"
                        mandatory
                        row
                    >
                      <v-radio
                          label="管理者"
                          value="manager"
                      ></v-radio>
                      <v-radio
                          label="一般ユーザ"
                          value="user"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col cols="10">
                    <p class="text-body2 grey--text text--darken-2">※ ユーザがログイン中の場合は、最大1時間は変更前の権限で利用可能になる場合があります。</p>
                  </v-col>
                </v-row>
                <v-card-actions class="mt-n4">
                  <v-spacer></v-spacer>
                  <v-btn class="ma-6" color="primary" @click="editDialog = false"
                  >キャンセル
                  </v-btn
                  >
                  <v-btn
                      id="update_user"
                      :loading="updateUserLoading"
                      class="ma-6"
                      color="secondary"
                      @click="updateUser()"
                  >OK
                  </v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-dialog v-model="resetPasswordDialog" max-width="600">
          <v-card>
            <v-card-title>パスワードリセット</v-card-title>
            <v-container>
              <v-row justify="center">
                <v-col cols="10">
                  <div class="text--disabled">
                    仮パスワードは、英大・子文字、数字、記号を含め8文字以上、かつ、ユーザIDとは異なる値をご指定下さい。<br />
                    ここで設定する仮パスワードを書き留めていただき、対象のユーザに直接ご案内ください。
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-n6" justify="center">
                <v-col cols="10">
                  <v-chip
                      v-if="chips[2].visible"
                      class="ma-2"
                      close
                      color="red"
                      label
                      outlined
                      @click:close="chips[2].visible = false"
                  >
                    {{ chips[2].message }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col cols="10">
                  <ReadOnlyNormalTextArea
                      :value.sync="accountForResetPassword.username"
                      label="ユーザID"
                  />
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col cols="10">
                  <ReadOnlyNormalTextArea
                      :value.sync="accountForResetPassword.name"
                      label="ユーザ名"
                  />
                </v-col>
              </v-row>
              <v-form ref="reset_password_form" @submit.prevent="resetPassword()">
                <v-row dense justify="center">
                  <v-col cols="7">
                    <PasswordTextField
                        :value.sync="accountForResetPassword.temporary_password"
                        label="仮パスワード"
                    />
                  </v-col>
                  <v-col cols="3">
                      <PasswordGenerateButton
                          ref="account_for_reset_password_temporary_password"
                          :password.sync="accountForResetPassword.temporary_password"/>
                      <v-tooltip color="tooltip" top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            id="copy_password"
                            v-on="on"
                            class="ml-3 mt-4"
                            color="primary"
                            small
                            @click="copyToClipboard()">
                              <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>クリップボードにコピー</span>
                      </v-tooltip>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions class="mt-n4">
                <v-spacer></v-spacer>
                <v-btn class="ma-6" color="primary" @click="hideResetPasswordDialog()"
                >キャンセル
                </v-btn>
                <v-btn
                    id="reset_password"
                    :loading="passwordLoading"
                    class="ma-6"
                    color="secondary"
                    @click="resetPassword()"
                >OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              <v-snackbar
                v-model="snackbar"
              >
                パスワードをクリップボードにコピーしました。
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="secondary"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-dialog v-model="deleteDialog" max-width="600">
          <v-card>
            <v-card-title
            >{{
                accountForDelete.name
              }}
              さんのユーザ情報を削除します。<br/>よろしいですか？
            </v-card-title
            >
            <v-container>
              <v-row justify="center">
                <v-card-actions class="mt-n4 mb-2">
                  <v-btn
                      class="ma-6"
                      color="primary"
                      @click="deleteDialog = false"
                  >キャンセル
                  </v-btn
                  >
                  <v-btn
                      id="delete_user"
                      :loading="deleteLoading"
                      class="ma-6"
                      color="warning"
                      @click="deleteUser()"
                  >
                    <v-icon>mdi-delete</v-icon>
                    削除
                  </v-btn
                  >
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <v-container class="mt-n15">
      <v-row justify="center">
        <v-col cols="12">
          <AlertArea
              :isShow.sync="alerts.error.isShow"
              :message="alerts.error.message"
              type="error"
          />
          <AlertArea
              :isShow.sync="alerts.success.isShow"
              :message="alerts.success.message"
              type="success"
          />
          <AlertArea
              :isShow.sync="alerts.warning.isShow"
              :message="alerts.warning.message"
              type="warning"
          />
        </v-col>
      </v-row>
      <v-data-table
          v-model="selected"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
          }"
          :headers="headers"
          :items="items"
          :loading="userLoading"
          :options.sync="options"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="username"
      >
        <template v-slot:[`item.operation`]="{ item }">
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="edit_user"
                  v-on="on"
                  class="secondary"
                  small
                  @click="
                  showEditUserDialog(item.username, item.name, item.email, item.role)
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>編集</span>
          </v-tooltip>
          <v-tooltip color="tooltip" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  id="reset_password_dialog"
                  v-on="on"
                  class="secondary"
                  small
                  @click="
                  showResetPasswordDialog(item.username, item.name)
                "
              >
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            <span>パスワードリセット</span>
          </v-tooltip>
          <template v-if="item.role !== 'manager'">
            <v-tooltip color="tooltip" top>
              <template v-slot:activator="{ on }">
                <v-btn
                    id="delete_user_dialog"
                    v-on="on"
                    color="warning"
                    small
                    @click="showDeleteUserDialog(item.username, item.name)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>削除</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
import ApiUtils from "../js/ApiUtils";
import ObjectUtils from "../js/ObjectUtils";
import StringUtils from "../js/StringUtils";
import ValidationUtils from "../js/ValidationUtils";
import Messages from "../js/Messages";
import Title from "../parts/Title";
import RequiredTextArea from "../parts/RequiredTextArea";
import PasswordTextField from "../parts/PasswordTextField";
import ReadOnlyNormalTextArea from "../parts/ReadOnlyNormalTextArea";
import AlertArea from "../parts/AlertArea.vue";
import PasswordGenerateButton from "../parts/PasswordGenerateButton";

export default {
  name: "Users",
  components: {
    Title,
    RequiredTextArea,
    PasswordTextField,
    ReadOnlyNormalTextArea,
    AlertArea,
    PasswordGenerateButton,
  },
  data: () => ({
    accountAddDialog: false,
    editDialog: false,
    resetPasswordDialog: false,
    deleteDialog: false,
    deleteLoading: false,
    userLoading: false,
    updateUserLoading: false,
    passwordLoading: false,
    createUserLoading: false,
    options: {
      page: 1,
      itemsPerPage: 20,
    },
    accountCondition: {
      username: "",
      name: "",
      email: "",
      role: "",
      temporary_password: "",
    },
    accountForEdit: {
      username: "",
      name: "",
      email: "",
      role: "",
    },
    accountForResetPassword: {
      username: "",
      name: "",
      temporary_password: "",
    },
    accountForDelete: {
      username: "",
      name: "",
    },
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    chips: [
      // ダイアログ上のエラーメッセージとその表示領域の表示要否フラグ
      {visible: false, message: ""},
      {visible: false, message: ""},
      {visible: false, message: ""},
    ],
    selected: [],
    headers: [
      {align: "start", text: "ユーザID", value: "username"},
      {align: "start", text: "ユーザ名", value: "name"},
      {align: "start", text: "メールアドレス", value: "email"},
      {align: "start", text: "役割", value: "role_for_view", width: "120"},
      {align: "start", text: "操作", value: "operation", width: "184"},
    ],
    items: [],
    snackbar: false,
  }),
  methods: {

    /**
     * rules に指定する。必須項目となるルール。
     */
    required: value => !!value || "",

    /**
     * 所属テナントのユーザ一覧を取得する API を呼び出す
     **/
    async callListUsers() {
      this.logDebug("#### callListUsers");
      this.userLoading = true;

      let result = null;
      try {
        // APIリクエスト
        let qParams = {};
        const response = await ApiUtils.get("/common/users", qParams);

        if (200 === response.status) {
          result = response.data.data;
          this.logDebug("#### callListUsers. result: ");
          this.logDebug(result);

          return result;
        } else {
          this.logDebug("#### callListUsers. bad response: ");
          this.logDebug(response);

          // メッセージ表示
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.callListUsers.warning;
          return null;
        }
      } catch (error) {
        this.logDebug("#### callListUsers. error: ");
        this.logDebug(error);

        // メッセージ表示
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.callListUsers.error;
        return null;
      } finally {
        this.userLoading = false;
      }
    },
    /**
     * 所属テナントのユーザ一覧を取得する
     **/
    async listUsers() {
      let users = await this.callListUsers();

      if (users) {
        // "役割" の日本語表記をリストに追加格納する
        for (let i = 0; i < users.length; i++) {
          if (users[i].role === 'manager') {
            users[i].role_for_view = '管理者';
          } else if (users[i].role === 'user') {
            users[i].role_for_view = '一般ユーザ';
          } else {
            users[i].role_for_view = '';
          }
        }
      }

      this.items = users;
    },
    /**
     * ユーザ追加ダイアログを表示する
     */
    showAccountAddDialog() {
      this.accountAddDialog = true;
      this.chips[0].visible = false;

      // 権限の初期値を 一般ユーザ に設定
      this.accountCondition.role = "user";

      // ダイアログ表示時に仮パスワードをセット
      // DOM の生成をまってから refs で参照したいため nextTick を使っている
      this.$nextTick(function () {
        this.$refs["account_condition_temporary_password"].passwordGenerate();
      });
    },
    /**
     * ユーザ追加ダイアログを非表示にする
     */
    hideAddUserDialog() {
      // フォームリセット
      ValidationUtils.reset(this.$refs["add_user_form"]);

      // ユーザ追加ダイアログを非表示
      this.accountAddDialog = false;
    },
    /**
     * ユーザを新規追加する
     */
    async createUser() {
      this.logDebug("#### createUser");
      this.createUserLoading = true;
      this.chips[0].visible = false;

      if (!ValidationUtils.valid(this.$refs["add_user_form"])) {
        this.logDebug("#### createUser. Valid NG");

        // いずれかの項目が入力されていない
        // エラーメッセージを、「ユーザ追加ダイアログ」上に表示
        this.chips[0].visible = true;
        this.chips[0].message = Messages.createUser.entered;
        this.createUserLoading = false;
        return;
      }

      try {
        // bodyを設定
        let body = {};
        ObjectUtils.objectPush(
            body,
            "username",
            this.accountCondition.username
        );
        ObjectUtils.objectPush(body, "name", this.accountCondition.name);
        ObjectUtils.objectPush(body, "email", this.accountCondition.email);
        ObjectUtils.objectPush(body, "role", this.accountCondition.role);
        ObjectUtils.objectPush(
            body,
            "temporary_password",
            this.accountCondition.temporary_password
        );

        // APIリクエスト
        const response = await ApiUtils.post("/common/user", body);

        if (200 === response.status) {
          const result = response.data.username;
          this.logDebug("#### createUser. result: ");
          this.logDebug(result);

          document.activeElement.blur(); // フォーカスを外してからフォームリセットすることで、バリデーションエラーを回避
          this.hideAddUserDialog(); // フォームリセットしてダイアログを閉じる
          await this.listUsers(); // ユーザのリストを再取得して表示する

          // メッセージ表示
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.success.isShow = true;
          this.alerts.success.message = Messages.createUser.success;

          return result;
        } else {
          this.logDebug("#### createUser. bad response: ");
          this.logDebug(response);

          // エラー表示
          this.chips[0].visible = true;
          this.chips[0].message = Messages.createUser.warning;
          return null;
        }
      } catch (error) {
        this.logDebug("#### createUser. error: ");
        this.logDebug(error);

        // エラー表示
        this.chips[0].visible = true;
        this.chips[0].message = Messages.createUser.error;
        return null;
      } finally {
        this.createUserLoading = false;
      }
    },
    /**
     * ユーザ編集ダイアログを表示する
     */
    showEditUserDialog(username, name, email, role) {
      // 選択行のユーザ情報を格納
      this.accountForEdit.username = username;
      this.accountForEdit.name = name;
      this.accountForEdit.email = email;
      this.accountForEdit.role = role;

      // 編集ダイアログを表示
      this.editDialog = true;
      this.chips[1].visible = false;
    },
    /**
     * パスワードリセットダイアログを表示する
     */
    showResetPasswordDialog(username, name) {
      // 選択行のユーザ情報を格納
      this.accountForResetPassword.username = username;
      this.accountForResetPassword.name = name;

      // ダイアログ表示時に仮パスワードをセット
      // DOM の生成をまってから refs で参照したいため nextTick を使っている
      this.$nextTick(function () {
        this.$refs["account_for_reset_password_temporary_password"].passwordGenerate();
      });

      // パスワードリセットダイアログを表示
      this.resetPasswordDialog = true;
      this.chips[2].visible = false;
    },
    /**
     * パスワードリセットダイアログを非表示にする
     */
    hideResetPasswordDialog() {
      // フォームリセット
      ValidationUtils.reset(this.$refs["reset_password_form"]);
      // パスワードリセットを非表示
      this.resetPasswordDialog = false;
    },
    /**
     * ユーザ削除ダイアログを表示する
     */
    showDeleteUserDialog(username, name) {
      // 選択行のユーザ情報を格納
      this.accountForDelete.username = username;
      this.accountForDelete.name = name;
      // 削除ダイアログを表示
      this.deleteDialog = true;
    },
    /**
     * ユーザ属性を編集する
     */
    async updateUser() {
      this.logDebug("#### updateUser");
      this.updateUserLoading = true;

      this.chips[1].visible = false;

      if (
          !this.accountForEdit.name ||
          !this.accountForEdit.email ||
          !this.accountForEdit.role
      ) {
        // ユーザ名、または役割が入力されていない場合
        this.logDebug("#### updateUser. name or role is empty.");
        this.chips[1].visible = true;
        this.chips[1].message = Messages.updateUser.entered;
        this.updateUserLoading = false;
        return;
      }

      try {
        // bodyを設定
        let body = {};
        ObjectUtils.objectPush(body, "username", this.accountForEdit.username);
        ObjectUtils.objectPush(body, "name", this.accountForEdit.name);
        ObjectUtils.objectPush(body, "email", this.accountForEdit.email);
        ObjectUtils.objectPush(body, "role", this.accountForEdit.role);

        // APIリクエスト
        const response = await ApiUtils.post(
            "/common/user/update",
            body
        );

        if (200 === response.status) {
          const result = response.data.count;
          this.logDebug("#### updateUser. result: ");
          this.logDebug(result);

          this.editDialog = false; // 成功時はダイアログを閉じる
          await this.listUsers(); // ユーザのリストを再取得して表示する

          // メッセージ表示
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.success.isShow = true;
          this.alerts.success.message = Messages.updateUser.success;
          return result;
        } else {
          this.logDebug("#### updateUser. bad response: ");
          this.logDebug(response);

          // エラー表示
          this.chips[1].visible = true;
          this.chips[1].message = Messages.updateUser.warning;
          return null;
        }
      } catch (error) {
        this.logDebug("#### updateUser. error: ");
        this.logDebug(error);

        // エラー表示
        this.chips[1].visible = true;
        this.chips[1].message = Messages.updateUser.error;
        return null;
      } finally {
        this.updateUserLoading = false;
      }
    },
    /**
     * パスワードをリセットする
     */
    async resetPassword() {
      this.logDebug("#### resetPassword");
      this.passwordLoading = true;

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["reset_password_form"])) {
        this.logDebug("### バリデーションNG resetPassword() 終了");
        // バリデーションエラーメッセージを表示
        this.chips[2].visible = true;
        this.chips[2].message = Messages.resetPassword.error_validation_not_set
        this.passwordLoading = false;
        return;
      }

      try {
        // bodyを設定
        let body = {};
        ObjectUtils.objectPush(body, "username", this.accountForResetPassword.username);
        ObjectUtils.objectPush(body, "temporary_password", this.accountForResetPassword.temporary_password);
        // APIリクエスト
        const response = await ApiUtils.post(
            "/common/user/resetpassword",
            body
        );

        if (200 === response.status) {
          this.logDebug("#### resetPassword. success.");

          document.activeElement.blur(); // フォーカスを外してからフォームリセットすることで、バリデーションエラーを回避
          this.resetPasswordDialog = false; // ダイアログを閉じる
          ValidationUtils.reset(this.$refs["reset_password_form"]) // 入力をクリア

          // メッセージ表示
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.success.isShow = true;
          this.alerts.success.message = Messages.resetPassword.success;

          return null;
        } else {
          this.logDebug("#### resetPassword. bad response: ");
          this.logDebug(response);

          // メッセージ表示
          this.chips[2].visible = true;
          this.chips[2].message = Messages.resetPassword.warning
          return null;
        }
      } catch (error) {
        this.logDebug("#### resetPassword. error: ");
        this.logDebug(error);

        // メッセージ表示
        this.chips[2].visible = true;
        this.chips[2].message = Messages.resetPassword.error
        return null;
      } finally {
        this.passwordLoading = false;
      }
    },
    /**
     * ユーザを削除する
     */
    async deleteUser() {
      this.logDebug("#### deleteUser");
      try {
        this.deleteLoading = true;

        let body = {};
        ObjectUtils.objectPush(body, "username", this.accountForDelete.username);

        // APIリクエスト（ POST リクエスト）
        const response = await ApiUtils.post(
            "/common/user/delete",
            body
        );

        this.deleteDialog = false; // ダイアログを閉じる

        if (200 === response.status) {
          const result = response.data.count; // レスポンスボディには削除件数のみ格納されている
          this.logDebug("#### deleteUser. result: ");
          this.logDebug(result);

          // メッセージ表示
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.success.isShow = true;
          let reps = [result];
          this.alerts.success.message = StringUtils.messageReplace(
              Messages.deleteUser.success,
              reps
          );
          await this.listUsers(); // ユーザのリストを再取得して表示する

          return result; // 削除件数
        } else {
          this.logDebug("#### deleteUser. bad response: ");
          this.logDebug(response);

          // メッセージ表示
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.deleteUser.warning;
          return null;
        }
      } catch (error) {
        this.logDebug("#### deleteUser. error: ");
        this.logDebug(error);

        this.deleteDialog = false; // ダイアログを閉じる

        // メッセージ表示
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.deleteUser.error;
        return null;
      } finally {
        this.deleteLoading = false;
      }
    },
    /**
     * パスワードの値をクリップボードにコピーする
     */
    copyToClipboard() {
      this.accountForResetPassword.temporary_password
      if (!this.accountForResetPassword.temporary_password) {
        return;
      }

      navigator.clipboard.writeText(this.accountForResetPassword.temporary_password)
          .then(() => {
            this.snackbar = true; // メッセージを Toast 表示
          })
          .catch(e => {
            console.error(e)
          })
    },
  },
  computed: {
    // データ編集時に、自分自身のレコードかどうかを判定する
    // 自身のユーザレコードの場合、役割のデータを変更できないようにするため
    isMyRecord() {
      return (this.$store.getters['user/username'] === this.accountForEdit.username)
    },
  },
  mounted: function () {
    // users ページの mount 時
    this.listUsers();
  },
};
</script>
