<template>
  <v-select
      v-model="selectedData"
      :items="status"
      :label="label"
      :value="value"
      clearable
      item-text="label"
      item-value="value"
      return-object
  ></v-select>
</template>

<script>
export default {
  name: 'SelectField',
  props: ['status', 'value', 'label'],
  // 変更を検知したら親に値の更新を依頼する
  computed: {
    selectedData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      }
    },
  }
};
</script>