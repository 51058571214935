<template>
  <v-text-field
      v-model="inputData"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :label="label"
      :rules="[required]"
      :type="show ? 'text' : 'password'"
      :value="value"
      auto-grow
      clearable
      row-height="15"
      rows="1"
      @click:append="show = !show"
  ></v-text-field>
</template>

<script>
export default {
  name: "PasswordTextField",
  props: ["value", "label"],
  methods: {
    required: value => !!value || ""
  },
  // 変更を検知したら親に値の更新を依頼する
  computed: {
    inputData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>