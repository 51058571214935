<template>
  <v-card>

    <v-tabs centered color="secondary" fixed-tabs>
      <v-tab href="#tab-1">文書データ登録</v-tab>
      <v-tab href="#tab-2">FAQ 候補生成</v-tab>
      <v-tab href="#tab-3">FAQ 候補数集計</v-tab>

      <v-tab-item value="tab-1">
        <DocumentMgt/>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <FAQCreateResultMgt/>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <FAQFromDucumentSummary/>
      </v-tab-item>

    </v-tabs>

  </v-card>
</template>

<script>
import DocumentMgt from './views/DocumentMgt.vue';
import FAQCreateResultMgt from './views/FAQCreateResultMgt.vue';
import FAQFromDucumentSummary from './views/FAQFromDucumentSummary.vue';

export default {
  name: 'CreateAiTeacherDataTab',
  components: {
    DocumentMgt,
    FAQCreateResultMgt,
    FAQFromDucumentSummary
  },
  data() {
    return {}
  },
}
</script>