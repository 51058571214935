<template>
  <span>
    <v-tooltip :disabled="isFAQCandidateScreen" color="tooltip" top>
      <template v-slot:activator="{ on }">
        <v-btn
            id="button_get_select_fields"
            v-on="on"
            :class="buttonClass"
            :loading="selectFieldsLoading"
            :small="isSmall"
            color="secondary"
            @click="getSelectFields()"
        >
          <v-icon>mdi-chart-box-plus-outline</v-icon>
          <div v-if="isFAQCandidateScreen">
            新規FAQ候補生成
          </div>
        </v-btn>
        </template>
      <span>新規FAQ候補生成</span>
    </v-tooltip>

    <!-- 新規FAQ候補生成ダイアログ -->
    <v-dialog v-model="createFAQCandidateDialog" max-width="600">
      <v-card>
        <v-form ref="create_ai_form" @submit.prevent="createFAQCandidateDataRequest()">
          <v-card-title>新規FAQ候補生成</v-card-title>
          <v-container>
            <v-row justify="center">
              <v-col cols="10">
                <v-chip v-if="chip.visible" class="ma-2" close color="red" label outlined
                        @click:close="chip.visible = false">
                  {{ chip.message }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredTextArea
                    :disabled="createFAQCandidateLoading"
                    :value.sync="createFAQCandidate.name"
                    label="名称"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredSelectField
                    :disabled="isResHisScreen || createFAQCandidateLoading"
                    :status="responseHistorySelects"
                    :value.sync="createFAQCandidate.caseLogName"
                    label="応対履歴データ"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredSelectField
                    :disabled="createFAQCandidateLoading"
                    :status="faqSelects"
                    :value.sync="createFAQCandidate.faqName"
                    label="FAQデータ"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredNumberArea
                    :disabled="createFAQCandidateLoading"
                    :value.sync="createFAQCandidate.clusterCount"
                    label="クラスタ数"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-6" color="primary" @click="createFAQCandidateDialog = false"
            >キャンセル
            </v-btn>
            <v-btn
                id="button_create_create_faq_candidate_request"
                :loading="createFAQCandidateLoading"
                class="ma-6"
                color="secondary"
                @click="createFAQCandidateDataRequest()"
            >OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import ObjectUtils from "@/components/js/ObjectUtils";
import ApiUtils from "@/components/js/ApiUtils";
import Messages from "@/components/js/Messages";
import RequiredTextArea from "@/components/parts/RequiredTextArea.vue";
import RequiredSelectField from "@/components/parts/RequiredSelectField.vue";
import RequiredNumberArea from "@/components/parts/RequiredNumberArea.vue";
import ValidationUtils from "@/components/js/ValidationUtils";
import StringUtils from "@/components/js/StringUtils";

export default {
  name: 'NewFAQCandidateBtnForResHisScreen',
  props: [
    'selectResHis', // 選択された応対履歴のデータ
    'isResHisScreen', // このコンポーネントを表示する画面が応対履歴画面ならtrue
    'isFAQCandidateScreen', // このコンポーネントを表示する画面がFAQ候補生成画面ならtrue
    'isSmall', // ボタンのサイズを小さくする場合はtrue
    'buttonClass', // ボタンのclass
  ],
  components: {
    RequiredTextArea,
    RequiredSelectField,
    RequiredNumberArea,
  },
  methods: {

    /**
     * プルダウン項目を取得するファンクション。
     * FAQ候補生成画面で表示する場合、登録済み応対履歴および、登録済みFaqのリストはAPIから取得する。
     * 応答履歴画面で表示する場合、応対履歴はpropsの値を使い、登録済みFaqのリストはAPIから取得する。
     */
    async getSelectFields() {
      this.logDebug("### getSelectFields() 開始");
      try {
        this.selectFieldsLoading = true;
        let qParams = {};

        // 名称を入力
        const date = StringUtils.getNowDate();
        this.createFAQCandidate.name = "FAQ候補 " + date;

        // 応答履歴画面で表示する場合は、応対履歴プルダウンにpropsの値をセット
        if (this.isResHisScreen) {

          // 選択された値としてセット
          let tempObj = {};
          tempObj["label"] = this.selectResHis.name;
          tempObj["value"] = this.selectResHis.id;
          tempObj["rowCount"] = this.selectResHis.row_count; // 応対履歴データセットの件数
          this.createFAQCandidate.caseLogName = tempObj;

          // プルダウン項目をセット
          let tempCaseSelects = [];
          tempCaseSelects.push(tempObj);
          this.responseHistorySelects = tempCaseSelects;
        }

        // FAQ候補生成画面で表示する場合はAPIで応対履歴プルダウンにの値をAPIから取得する
        if (this.isFAQCandidateScreen) {

          // APIを呼び出して応対履歴プルダウンをセット
          // クエリパラメータを設定
          ObjectUtils.objectPush(qParams, "limits", 10000);
          ObjectUtils.objectPush(qParams, "page", 1);

          // 登録済み応対履歴取得APIをリクエスト（R31）
          // プルダウンに表示するために取得するため絞り込み条件なし
          this.logDebug("#### R31 リクエスト");
          const resResponseHistory = await ApiUtils.get(
              "/generate_training_data/case_log_dataset",
              qParams
          );

          // レスポンス処理
          if (200 === resResponseHistory.status) {
            this.logDebug("#### R31 成功");

            // レスポンスのデータを応対履歴プルダウンにセット
            let tempCaseSelects = [];
            resResponseHistory.data.data.forEach(function (val) {
              let tempObj = {};
              tempObj["label"] = val.name;
              tempObj["value"] = val.id;
              tempObj["rowCount"] = val.row_count; // 応対履歴データセットの件数
              tempCaseSelects.push(tempObj);
            });
            this.responseHistorySelects = tempCaseSelects;
          } else {
            this.logDebug("#### R31 失敗 レスポンス：");
            this.logDebug(resResponseHistory);
            this.$emit("alertsInitByEmit");
            this.$emit("uncompletedAlertsInitByEmit");
            this.$emit("showWarning", Messages.r31.warning);
            this.createFAQCandidateDialog = false;
            return;
          }
        }
        this.logDebug("#### 応対履歴プルダウン：" + JSON.stringify(this.responseHistorySelects));

        // 登録済みFaqのリスト取得APIをリクエスト（R6）
        // プルダウンに表示するために取得するため絞り込み条件なし
        this.logDebug("#### R6 リクエスト");
        const resFaq = await ApiUtils.get(
            "/generate_training_data/faq",
            qParams
        );

        // レスポンス処理
        if (200 === resFaq.status) {
          this.logDebug("#### R6 成功");

          // プルダウン初期化
          let tempFaqSelects = [];

          // レスポンスのデータをFAQプルダウンにセット
          resFaq.data.data.forEach(function (val) {
            let tempObj = {};
            tempObj["label"] = val.name;
            tempObj["value"] = val.id;
            tempFaqSelects.push(tempObj);
          });
          this.faqSelects = tempFaqSelects;

          // 処理が正常終了したらダイアログを表示
          this.createFAQCandidateDialog = true;
        } else {
          this.logDebug("#### R6 失敗 レスポンス：");
          this.logDebug(resFaq);
          this.$emit("alertsInitByEmit");
          this.$emit("uncompletedAlertsInitByEmit");
          this.$emit("showWarning", Messages.r6.warning);
          this.createFAQCandidateDialog = false;
        }
      } catch (error) {
        this.logDebug("#### getSelectFields() 例外発生");
        this.logDebug(error);
        this.$emit("alertsInitByEmit");
        this.$emit("uncompletedAlertsInitByEmit");
        this.$emit("showError", Messages.r31r6.error);
        this.createFAQCandidateDialog = false;
      } finally {
        this.selectFieldsLoading = false;
      }
      this.logDebug("### getSelectFields() 終了");
    },

    /**
     * 学習・推論ジョブ情報（選択した応対履歴、Faq 情報）を
     * 登録、ジョブ開始依頼するAPIをリクエストする。
     **/
    async createFAQCandidateDataRequest() {
      this.logDebug("### createFAQCandidateDataRequest() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["create_ai_form"])) {
        this.logDebug("### バリデーションNG。createFAQCandidateDataRequest() 終了");
        return;
      }

      // FAQ生成ジョブ新規登録で入力されたクラスタ数が、適切な値かどうかを判定
      if (!this.isClusterCountAvailable()) {
        this.logDebug("### クラスタ数NG。createFAQCandidateDataRequest() 終了");
        this.chip.visible = true;
        this.chip.message = Messages.clusterCount.error;
        return;
      }

      this.$emit("alertsInitByEmit");
      this.$emit("uncompletedAlertsInitByEmit");
      this.createFAQCandidateLoading = true;
      try {

        // bodyを設定
        let body = {};
        ObjectUtils.objectPush(body, "name", this.createFAQCandidate.name);
        ObjectUtils.objectPush(body, "case_log_dataset_group_id", this.createFAQCandidate.caseLogName.value);
        ObjectUtils.objectPush(body, "faq_group_id", this.createFAQCandidate.faqName.value);
        ObjectUtils.objectPush(body, "cluster_count", this.createFAQCandidate.clusterCount);

        // APIリクエスト（R35）
        this.logDebug("#### R35 リクエスト");
        const response = await ApiUtils.post(
            "/faq_from_case_log/start_job",
            body,
        );
        if (200 === response.status) {
          this.logDebug("#### R35 成功");

          // 成功メッセージを表示
          let reps = [this.createFAQCandidate.name,
            this.createFAQCandidate.caseLogName.label,
            this.createFAQCandidate.faqName.label,
            this.createFAQCandidate.clusterCount];
          this.$emit("showSuccess", StringUtils.messageReplace(Messages.r35.success, reps));

          // 入力フォームを初期状態にリセット
          ValidationUtils.reset(this.$refs["create_ai_form"])
          this.chip = {"visible": false, "message": ""};
          this.createFAQCandidate.clusterCount = "100";

          // 検索処理を呼び出し
          this.$emit("search");
        } else {
          this.logDebug("#### R35 失敗 レスポンス：");
          this.logDebug(response);
          this.$emit("showWarning", Messages.r35.warning);
        }
      } catch (error) {
        this.logDebug("#### createFAQCandidateDataRequest() 例外発生");
        this.logDebug(error);
        this.$emit("showError", Messages.r35.error);
      } finally {
        this.createFAQCandidateDialog = false;
        this.createFAQCandidateLoading = false;
      }
      this.logDebug("### createFAQCandidateDataRequest() 終了");
    },

    /**
     * 応対履歴データセットの件数を元に、クラスタ数の初期値を設定する
     */
    defaultClusterCount(caselogDatasetRowCount) {
      if (caselogDatasetRowCount <= 0) {
        return 0;
      } else {

        // 応対履歴データセットの件数を 10 で割って、その整数部分を取得
        let defaultValue = Math.floor(caselogDatasetRowCount / 10);
        if (defaultValue <= 0) {
          defaultValue = 1; // 求めた値が 0 以下となる場合は、1 に補正
        }
        return defaultValue;
      }
    },

    /**
     * FAQ生成ジョブ新規登録時の、応対履歴データセットの選択状況にあわせて、クラスター数の初期値を調整して設定する
     */
    resetClusterCount() {
      if (!this.createFAQCandidate.caseLogName) {
        this.logDebug('#### resetClusterCount. case log dataset is not set.')
        return; // 応対履歴データセットが選択されていない
      }

      // FAQ生成ジョブ新規登録のダイアログで選択された応対履歴データセットの件数を取得
      const caselogDatasetRowCount = this.createFAQCandidate.caseLogName.rowCount
      if (caselogDatasetRowCount) {

        // 該当する応対履歴データセットの件数が設定されている場合は、その値を元に、クラスタ数項目の初期値を算出して設定
        const defaultValue = this.defaultClusterCount(caselogDatasetRowCount)
        this.logDebug('#### resetClusterCount. rowCount: ' + caselogDatasetRowCount + ', default clusterCount: ' + defaultValue)
        this.createFAQCandidate.clusterCount = String(defaultValue)
      } else {
        this.logDebug('#### resetClusterCount. case log dataset row count is not found.')

        // 該当する応対履歴データセットの件数が設定されていない場合は、クラスタ数項目の初期値に 0 を設定
        this.createFAQCandidate.clusterCount = String(0)
      }
    },

    /**
     * FAQ生成ジョブ新規登録で入力されたクラスタ数が、適切な値かどうかを判定する
     */
    isClusterCountAvailable() {
      if (!this.createFAQCandidate.caseLogName) {
        this.logDebug('#### isClusterCountAvailable. case log dataset is not set.')
        return false; // 応対履歴データセットが選択されていない
      }

      // FAQ生成ジョブ新規登録のダイアログで選択された応対履歴データセットの件数を取得
      const caselogDatasetRowCount = this.createFAQCandidate.caseLogName.rowCount
      if (caselogDatasetRowCount) {

        // 該当する応対履歴データセットの件数が設定されている場合
        // 0 より多い、かつ、該当する応対履歴データセットの件数以下の場合、適切な値であると判断する
        const available = (0 < this.createFAQCandidate.clusterCount && this.createFAQCandidate.clusterCount <= caselogDatasetRowCount)
        this.logDebug('#### isClusterCountAvailable: ' + available)
        return available
      } else {
        this.logDebug('#### isClusterCountAvailable. case log dataset row count is not found.')
        return false // 当する応対履歴データセットの件数が設定されていない場合
      }
    }
  },
  data: () => ({
    createFAQCandidateDialog: false,
    selectFieldsLoading: false,
    createFAQCandidate: {
      name: null,
      caseLogName: null,
      faqName: null,
      clusterCount: "100",
    },
    responseHistorySelects: [],
    faqSelects: [],
    createFAQCandidateLoading: false,
    chip: {"visible": false, "message": "",} // ダイアログ上のエラーメッセージとその表示領域の表示要否フラグ
  }),
  watch: {
    'createFAQCandidate.caseLogName': function () {
      // 応対履歴データセットのドロップダウンボックスの選択内容が変わった場合、クラスタ数の初期値を再設定する
      this.resetClusterCount()
    }
  },

};
</script>