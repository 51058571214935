import cognito from '@/cognito';
import logger from '@/mixins/logger';
import store from '@/store/store';

//const intervalMilliSec = 60 * 1000 // 実行間隔。1分間
const intervalMilliSec = 60 * 60 * 1000 // 実行間隔。1時間

export default {
    methods: {
        // ID トークンをリフレッシュトークンで更新する
        refreshSession() {
            const id = setInterval(() => {
                const username = store.getters['user/username']
                const refreshToken = store.getters['user/refreshToken']

                logger.methods.logDebug('#### polling refreshSession username: ' + username)
                if (!username || !refreshToken) {
                    logger.methods.logDebug('#### polling refreshSession skip.')
                    return
                }
                // congito の refreshSession メソッドを呼び出す
                cognito.refreshSession(username, refreshToken)
                    .then(result => { // refreshSession 成功
                        logger.methods.logDebug('#### refreshSession completed')
                        logger.methods.logDebug(result)

                        // 更新した ID トークン、リフレッシュトークンを保存
                        store.dispatch('user/setToken', result.idToken)
                        store.dispatch('user/setRefreshToken', result.refreshToken)
                
                  }, err => { // refreshSession 失敗
                        logger.methods.logDebug('#### refreshSession failed')
                        logger.methods.logDebug(err)

                        // クライアント側でもログアウト状態にする（トークンをクリアする）
                        store.dispatch('user/setToken', null)
                        store.dispatch('user/setRefreshToken', null)
                  })
            }, intervalMilliSec)
            return id
        }
    }
}