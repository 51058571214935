<template>
  <v-card>
    <v-card-title>削除しますか？</v-card-title>
    <v-container>
      <v-row justify="center">
        <v-card-actions class="mt-n4 mb-2">
          <v-btn
              id="synonym_delete_dialog_button_cancel"
              class="ma-6"
              color="primary"
              @click="closeDialog()"
          >
            キャンセル
          </v-btn>
          <v-btn
              id="synonym_delete_dialog_button_delete"
              class="ma-6"
              color="warning"
              :loading="deleteLoading"
              @click="projectDelete()"
          >
            <v-icon>mdi-delete</v-icon>
            削除
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ApiUtils from "../../js/ApiUtils";
import Messages from "../../js/Messages";

export default {
  name: "SynonymDelete",
  props: {
    project: Object, // プロジェクトデータに単語分散表現データを紐付けたオブジェクト
    dialog: Boolean, // 親のダイアログフラグ
    alerts: Object, // 親のアラートエリア制御オブジェクト
  },
  methods: {

    /**
     * 親にダイアログをクローズしてもらう。
     */
    closeDialog() {
      this.$emit("update:dialog", false);
    },

    /**
     * 単語分散表現データ削除後、同義語プロジェクトも削除する。
     **/
    async projectDelete() {
      this.logDebug("### projectDelete() 開始");
      try {
        this.$emit("alertsInitByEmit");
        this.$emit("tabAlertsInitByEmit");
        this.deleteLoading = true;

        // 単語分散表現データを削除
        if (!await this.wordDelete()) {
          this.logDebug("### 単語分散表現データ削除失敗 projectDelete() 終了");
          return;
        }

        // 同義語プロジェクトを削除
        if (!await this.synonymProjectDelete()) {
          this.logDebug("### 同義語プロジェクト削除失敗 projectDelete() 終了");
          return;
        }

        // 削除を画面に反映するため再度プロジェクト一覧を取得
        this.$emit("getAllProject");

        // 削除完了のメッセージを表示
        this.$emit("showSuccess", Messages.synonymProjectDelete.success);
      } finally {
        this.deleteLoading = false;

        // 削除ダイアログを閉じる
        this.closeDialog();
      }
      this.logDebug("### projectDelete() 終了");
    },

    /**
     * 単語分散表現データを削除する。
     * @returns {Promise<boolean>} true:処理成功，false:処理失敗
     */
    async wordDelete() {
      this.logDebug("#### wordDelete() 開始");

      // 単語分散表現データが無い場合は何もしない
      if (this.project.word_embedding == null) {
        return true;
      }

      let result = false;
      try {

        // APIリクエスト（R555）単語分散表現データ削除
        this.logDebug("##### R555 リクエスト");
        const response = await ApiUtils.deleteReq(
            "/synonym/word_embeddings/" + this.project.word_embedding.word_embedding_key
        );

        // 処理が失敗した場合はエラーメッセージを表示
        if (200 === response.status) {
          this.logDebug("##### R555 成功");
          result = true;
        } else {
          this.logDebug("##### R555 失敗 レスポンス：");
          this.logDebug(response);
          this.$emit("showWarning", Messages.r555.warning);
        }
      } catch (error) {
        this.logDebug("#### wordDelete() 例外発生");
        this.logDebug(error);
        this.$emit("showError", Messages.r555.error);
      }
      this.logDebug("#### wordDelete() 終了");
      return result;
    },

    /**
     * 同義語プロジェクトを削除する。
     * @returns {Promise<boolean>} true:処理成功，false:処理失敗
     */
    async synonymProjectDelete() {
      this.logDebug("#### synonymProjectDelete() 開始");
      let result = false;
      try {

        // APIリクエスト（R516）同義語プロジェクト削除
        this.logDebug("##### R516 リクエスト");
        const response = await ApiUtils.deleteReq(
            "/synonym/projects/" + this.project.project_key,
        );

        // 処理が失敗した場合はエラーメッセージを表示
        if (200 === response.status) {
          this.logDebug("##### R516 成功");
          result = true;
        } else {
          this.logDebug("##### R516 失敗 レスポンス：");
          this.logDebug(response);
          this.$emit("showWarning", Messages.r516.warning);
        }
      } catch (error) {
        this.logDebug("#### synonymProjectDelete() 例外発生");
        this.logDebug(error);
        this.$emit("showError", Messages.r516.error);
      }
      this.logDebug("#### synonymProjectDelete() 失敗");
      return result;
    },
  },
  data: () => ({
    deleteLoading: false,
  }),
};
</script>