<template>
  <v-main>
    <v-container fill-height>
      <v-flex>
        <LoginTitle title="パスワード変更"/>
        <br/>
        <br/>
        <v-row justify="center">
          <v-col cols="4">
            <div class="text--disabled">
              パスワードは、英大・子文字、数字、記号を含め8文字以上、かつ、ユーザIDとは異なる値をご指定下さい。
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6">
            <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
            <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
            <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
          </v-col>
        </v-row>

        <!-- 入力フォーム -->
        <v-form ref="change_password_form" @submit.prevent="changePassword()">
          <v-row justify="center">
            <v-col cols="4">
              <PasswordTextField
                  :value.sync="oldPassword"
                  label="現在のパスワード"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="4">
              <PasswordTextField
                  :value.sync="newPassword"
                  label="新しいパスワード"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="4">
              <PasswordTextField
                  :value.sync="newPasswordConfirm"
                  label="新しいパスワード（再確認）"
              />
            </v-col>
          </v-row>
          <br/>
          <br/>
          <v-container>
            <v-row justify="center">
              <v-btn :loading="loading" color="secondary" large type="submit">パスワード変更</v-btn>
            </v-row>
          </v-container>
        </v-form>
        <br/>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import Messages from "../js/Messages";
import LoginTitle from "../parts/LoginTitle";
import PasswordTextField from "../parts/PasswordTextField";
import AlertArea from "../parts/AlertArea.vue";
import ObjectUtils from "@/components/js/ObjectUtils";
import ValidationUtils from "../js/ValidationUtils";

export default {
  name: "Login",
  components: {
    LoginTitle,
    PasswordTextField,
    AlertArea,
  },
  data: () => ({
    oldPassword: null,
    newPassword: null,
    newPasswordConfirm: null,
    alerts: {
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    loading: false
  }),
  methods: {

    // パスワードを変更する
    changePassword() {

      // メッセージをいったんすべて非表示に
      ObjectUtils.alertsInit(this.alerts);

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["change_password_form"])) {
        this.logDebug("### バリデーションNG changePassword() 終了");

        // バリデーションエラーメッセージを表示
        this.alerts.warning.isShow = true
        this.alerts.warning.message = Messages.changePassword.error_validation_not_set
        return;
      }

      if (this.newPassword !== this.newPasswordConfirm) { // 新しいパスワードの入力値が一致しない場合
        this.logDebug('#### changePassword validation error')
        this.alerts.warning.isShow = true
        this.alerts.warning.message = Messages.changePassword.error_validation_not_correct
        return
      }

      this.loading = true

      this.$cognito.changePassword(this.oldPassword, this.newPassword)
          .then(result => { // changePassword 成功
            this.logDebug('#### changePassword completed')
            this.logDebug(result)

            this.loading = false
            // 入力内容をクリア
            ValidationUtils.reset(this.$refs["change_password_form"])

            // パスワード変更成功のメッセージを表示
            this.alerts.success.isShow = true
            this.alerts.success.message = Messages.changePassword.success

          }, err => { // changePassword 失敗
            this.logDebug('#### changePassword failed')
            this.logDebug(err)

            this.loading = false

            if (err && err.message && err.message.includes('User is not authenticated')) {
              // 認証エラー ログイン画面にリダイレクト
              this.logDebug('#### ログイン期限切れ');
              this.$store.dispatch("user/logout");
              this.$router.push({name: "login"});
            } else {
              // パスワード変更失敗のメッセージを表示
              this.alerts.error.isShow = true
              this.alerts.error.message = Messages.changePassword.error
            }
          })
    },
  }
};
</script>
