<template>
  <span>
    <v-tooltip :disabled="isAiMgtScreen" color="tooltip" top>
      <template v-slot:activator="{ on }">
        <v-btn
            id="button_get_select_fields"
            v-on="on"
            :class="buttonClass"
            :loading="selectFieldsLoading"
            :small="isSmall"
            color="secondary"
            @click="getSelectFields()"
        >
          <v-icon>mdi-chart-box-plus-outline</v-icon>
          <div v-if="isAiMgtScreen">
            新規AI教師データ生成
          </div>
        </v-btn>
        </template>
      <span>新規AI教師データ生成</span>
    </v-tooltip>

    <v-dialog v-model="createAiDataDialog" max-width="600">
      <v-card>
        <v-form ref="create_ai_form" @submit.prevent="createAiTeacherDataRequest()">
          <v-card-title>新規AI教師データ生成</v-card-title>
          <v-container>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredTextArea
                    :disabled="createAiTeacherLoading"
                    :value.sync="createAiData.name"
                    label="名称"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredSelectField
                    :disabled="isResHisScreen || createAiTeacherLoading"
                    :status="responseHistorySelects"
                    :value.sync="createAiData.caseLogName"
                    label="応対履歴データ"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10">
                <RequiredSelectField
                    :disabled="createAiTeacherLoading"
                    :status="faqSelects"
                    :value.sync="createAiData.faqName"
                    label="FAQデータ"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-6" color="primary" @click="createAiDataDialog = false"
            >キャンセル
            </v-btn
            >
            <v-btn
                id="button_create_ai_teacher_data_request"
                :loading="createAiTeacherLoading"
                class="ma-6"
                color="secondary"
                @click="createAiTeacherDataRequest()"
            >OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import ObjectUtils from "@/components/js/ObjectUtils";
import ApiUtils from "@/components/js/ApiUtils";
import Messages from "@/components/js/Messages";
import RequiredTextArea from "@/components/parts/RequiredTextArea.vue";
import RequiredSelectField from "@/components/parts/RequiredSelectField.vue";
import ValidationUtils from "@/components/js/ValidationUtils";
import StringUtils from "@/components/js/StringUtils";

export default {
  name: 'NewAiTeacherGenerateButton',
  props: [
    'selectResHis', // 選択された応対履歴のデータ
    'isResHisScreen', // このコンポーネントを表示する画面が応対履歴画面ならtrue
    'isAiMgtScreen', // このコンポーネントを表示する画面がAI教師データ画面ならtrue
    'isSmall', // ボタンのサイズを小さくする場合はtrue
    'buttonClass', // ボタンのclass
  ],
  components: {
    RequiredTextArea,
    RequiredSelectField,
  },
  methods: {

    /**
     * プルダウン項目を取得するファンクション。
     * AI教師データ画面で表示する場合、登録済み応対履歴および、登録済みFaqのリストはAPIから取得する。
     * 応答履歴画面で表示する場合、応対履歴はpropsの値を使い、登録済みFaqのリストはAPIから取得する。
     */
    async getSelectFields() {
      this.logDebug("### getSelectFields() 開始");
      try {
        this.selectFieldsLoading = true;
        let qParams = {};

        // 名称を入力
        const date = StringUtils.getNowDate();
        this.createAiData.name = "AI教師データ " + date;

        // 応答履歴画面で表示する場合は、応対履歴プルダウンにpropsの値をセット
        if (this.isResHisScreen) {

          // 選択された値としてセット
          let tempObj = {};
          tempObj["label"] = this.selectResHis.name;
          tempObj["value"] = this.selectResHis.id;
          this.createAiData.caseLogName = tempObj;

          // プルダウン項目をセット
          let tempCaseSelects = [];
          tempCaseSelects.push(tempObj);
          this.responseHistorySelects = tempCaseSelects;
        }

        // AI教師データ画面で表示する場合はAPIで応対履歴プルダウンにpropsの値を取得する
        if (this.isAiMgtScreen) {

          // APIを呼び出して応対履歴プルダウンをセット
          // クエリパラメータを設定
          ObjectUtils.objectPush(qParams, "limits", 10000);
          ObjectUtils.objectPush(qParams, "page", 1);

          // 登録済み応対履歴取得APIをリクエスト（R31）
          // プルダウンに表示するために取得するため絞り込み条件なし
          this.logDebug("#### R31 リクエスト");
          const resResponseHistory = await ApiUtils.get(
              "/generate_training_data/case_log_dataset",
              qParams
          );

          // レスポンス処理
          if (200 === resResponseHistory.status) {
            this.logDebug("#### R31 成功");

            // レスポンスのデータを応対履歴プルダウンにセット
            let tempCaseSelects = [];
            resResponseHistory.data.data.forEach(function (val) {
              let tempObj = {};
              tempObj["label"] = val.name;
              tempObj["value"] = val.id;
              tempCaseSelects.push(tempObj);
            });
            this.responseHistorySelects = tempCaseSelects;
          } else {
            this.logDebug("#### R31 失敗 レスポンス：");
            this.logDebug(resResponseHistory);
            this.$emit("alertsInitByEmit");
            this.$emit("uncompletedAlertsInitByEmit");
            this.$emit("showWarning", Messages.r31.warning);
            this.createAiDataDialog = false;
            return;
          }
        }
        this.logDebug("#### 応対履歴プルダウン：" + JSON.stringify(this.responseHistorySelects))

        // 登録済みFaqのリスト取得APIをリクエスト（R6）
        // プルダウンに表示するために取得するため絞り込み条件なし
        this.logDebug("#### R6 リクエスト");
        const resFaq = await ApiUtils.get(
            "/generate_training_data/faq",
            qParams
        );

        // レスポンス処理
        if (200 === resFaq.status) {
          this.logDebug("#### R6 成功");

          // プルダウン初期化
          let tempFaqSelects = [];

          // レスポンスのデータをFAQプルダウンにセット
          resFaq.data.data.forEach(function (val) {
            let tempObj = {};
            tempObj["label"] = val.name;
            tempObj["value"] = val.id;
            tempFaqSelects.push(tempObj);
          });
          this.faqSelects = tempFaqSelects;
          this.logDebug("#### FAQプルダウン：" + JSON.stringify(this.faqSelects));

          // 処理が正常終了したらダイアログを表示
          this.createAiDataDialog = true;
        } else {
          this.logDebug("#### R6 失敗 レスポンス：");
          this.logDebug(resFaq);
          this.$emit("alertsInitByEmit");
          this.$emit("uncompletedAlertsInitByEmit");
          this.$emit("showWarning", Messages.r6.warning);
          this.createAiDataDialog = false;
        }
      } catch (error) {
        this.logDebug("#### getSelectFields() 例外発生");
        this.logDebug(error);
        this.$emit("alertsInitByEmit");
        this.$emit("uncompletedAlertsInitByEmit");
        this.$emit("showError", Messages.r31r6.error);
        this.createAiDataDialog = false;
      } finally {
        this.selectFieldsLoading = false;
      }
      this.logDebug("### getSelectFields() 終了");
    },

    /**
     * 学習・推論ジョブ情報（選択した応対履歴、Faq 情報）を
     * 登録、ジョブ開始依頼するAPIをリクエストする。
     **/
    async createAiTeacherDataRequest() {
      this.logDebug("### createAiTeacherDataRequest() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["create_ai_form"])) {
        this.logDebug("### バリデーションNG createAiTeacherDataRequest() 終了");
        return;
      }
      this.$emit("alertsInitByEmit");
      this.$emit("uncompletedAlertsInitByEmit");
      this.createAiTeacherLoading = true;
      try {

        // bodyを設定
        let body = {};
        ObjectUtils.objectPush(body, "name", this.createAiData.name);
        ObjectUtils.objectPush(body, "case_log_dataset_group_id", this.createAiData.caseLogName.value);
        ObjectUtils.objectPush(body, "faq_group_id", this.createAiData.faqName.value);

        // APIリクエスト（R11）
        this.logDebug("#### R11 リクエスト");
        const response = await ApiUtils.post(
            "/generate_training_data/start_job",
            body,
        );
        if (200 === response.status) {
          this.logDebug("#### R11 成功");

          // 成功メッセージを表示
          let reps = [this.createAiData.name,
            this.createAiData.caseLogName.label,
            this.createAiData.faqName.label];
          this.$emit("showSuccess", StringUtils.messageReplace(Messages.r11.success, reps));

          // 入力フォームをリセット
          ValidationUtils.reset(this.$refs["create_ai_form"])

          // 検索処理を呼び出し
          this.$emit("search")
        } else {
          this.logDebug("#### R11 失敗 レスポンス：");
          this.logDebug(response);
          this.$emit("showWarning", Messages.r11.warning);
        }
      } catch (error) {
        this.logDebug("#### createAiTeacherDataRequest() 例外発生");
        this.logDebug(error);
        this.$emit("showError", Messages.r11.error);
      } finally {
        this.createAiDataDialog = false;
        this.createAiTeacherLoading = false;
      }
      this.logDebug("### createAiTeacherDataRequest() 終了");
    },
  },
  data: () => ({
    createAiDataDialog: false,
    selectFieldsLoading: false,
    createAiData: {
      name: null,
      caseLogName: null,
      faqName: null,
    },
    responseHistorySelects: [],
    faqSelects: [],
    createAiTeacherLoading: false,
  }),
};
</script>