<template>
  <v-main class="mt-n15">
    <!-- アラートエリア -->
    <v-container id="dummy" class="mt-6 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="tabAlerts.warning.isShow" :message="tabAlerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
      <AlertArea :isShow.sync="tabAlerts.error.isShow" :message="tabAlerts.error.message" type="error"/>
    </v-container>

    <v-container>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
            id="button_update"
            :loading="loading"
            class="ma-3"
            color="primary"
            @click="$emit('getAllProject')"
        >
          <v-icon>mdi-restart</v-icon>
          更新
        </v-btn>
      </v-row>
      <br/>
      <v-data-table
          :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500],
        }"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          calculate-widths
          class="elevation-1"
          dense
          disable-sort
          item-key="project_key"
      >
        <template v-slot:[`item.status`]="{ item }">
          <div id="table_text_status">{{ showStatus(item) }}</div>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ dateReplace(item.created) }}
        </template>
        <template v-slot:[`item.operation`]="{ item }">
          <template
              v-if="
              item.word_embedding != null &&
              item.word_embedding.status === 'normal'
            "
          >
            <v-tooltip color="tooltip" top>
              <template v-slot:activator="{ on }">
                <v-btn
                    id="button_edit_start"
                    v-on="on"
                    class="secondary"
                    small
                    @click="
                    editProject = item;
                    editDialog = true;
                  "
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>同義語生成</span>
            </v-tooltip>
          </template>
          <template v-else>
            <InvisibleButton/>
          </template>
          <template
              v-if="
              item.word_embedding == null ||
              item.word_embedding.status === 'normal' ||
              item.word_embedding.status === 'error'
            "
          >
            <v-tooltip color="tooltip" top>
              <template v-slot:activator="{ on }">
                <v-btn
                    id="button_delete_start"
                    v-on="on"
                    color="warning"
                    small
                    @click="
                    deleteProject = item;
                    deleteDialog = true;
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>削除</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>

      <!-- 同義語生成ダイアログ -->
      <v-dialog v-model="editDialog">
        <SynonymEdit :dialog.sync="editDialog" :project="editProject"/>
      </v-dialog>

      <!-- 削除ダイアログ -->
      <v-dialog v-model="deleteDialog" max-width="600">
        <SynonymDelete
            :dialog.sync="deleteDialog"
            :project="deleteProject"
            @getAllProject="$emit('getAllProject')"
            @showError="showError"
            @showSuccess="showSuccess"
            @showWarning="showWarning"
            @alertsInitByEmit="alertsInitByEmit"
            @tabAlertsInitByEmit="$emit('tabAlertsInitByEmit')"
        />
      </v-dialog>
    </v-container>
  </v-main>
</template>
<script>
import AlertArea from "../../parts/AlertArea.vue";
import InvisibleButton from "../../parts/InvisibleButton.vue";
import SynonymEdit from "./SynonymEdit.vue";
import SynonymDelete from "./SynonymDelete.vue";
import StringUtils from "@/components/js/StringUtils";
import ObjectUtils from "@/components/js/ObjectUtils";

export default {
  name: "SynonymMgt",
  components: {
    AlertArea,
    InvisibleButton,
    SynonymEdit,
    SynonymDelete,
  },
  props: {
    loading: Boolean, // メインテーブルのローディング
    items: Array, // メインテーブルに表示するレコード
    tabAlerts: Object, // 親からもらうアラートエリアの情報
  },
  methods: {

    /**
     * item.createdの日付書式を変更する。
     */
    dateReplace: function (item) {
      return StringUtils.dateReplace(item);
    },

    /**
     * item.word_embedding.statusの値に応じた文字列を返却する。
     */
    showStatus: function (item) {
      let result = "エラー";

      // 存在しない場合はエラーを返却
      if (item == null || item.word_embedding == null) {
        return result;
      }

      // ステータスに応じて文字列を返却
      switch (item.word_embedding.status) {
        case "normal":
          result = "通常";
          break;
        case "analyzing":
          result = "解析中";
          break;
        default:
          break;
      }
      return result;
    },

    /**
     * 子コンポーネントからサクセスメッセージを表示したいときに呼び出す。
     * @param message サクセスメッセージ
     */
    showSuccess: ObjectUtils.showSuccess,

    /**
     * 子コンポーネントからワーニングを表示したいときに呼び出す。
     * @param message ワーニングメッセージ
     */
    showWarning: ObjectUtils.showWarning,

    /**
     * 子コンポーネントからエラーを表示したいときに呼び出す。
     * @param message エラーメッセージ
     */
    showError: ObjectUtils.showError,

    /**
     * alerts を初期化する。
     */
    alertsInitByEmit: ObjectUtils.alertsInitByEmit,
  },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      alerts: {
        info: {isShow: false, message: ""},
        success: {isShow: false, message: ""},
        warning: {isShow: false, message: ""},
        error: {isShow: false, message: ""},
      },
      editProject: null,
      deleteProject: null,
      headers: [
        {align: "start", text: "名称", value: "name"},
        {align: "start", text: "登録日時", value: "created"},
        {align: "end", text: "件数", value: "count"},
        {align: "start", text: "状態", value: "status"},
        {align: "start", text: "操作", value: "operation", width: "135"},
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>