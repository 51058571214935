<template>
  <v-btn
      elevation="0"
      plain
      disabled
  >
  </v-btn>
</template>

<script>
export default {
  name: 'NormalInvisibleButton'
}
</script>