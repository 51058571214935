<template>
  <div class="ace-parent" :style="parent_style">
    <div ref="ace" class="ace"></div>
  </div>
</template>

<script>
import ace from 'ace-builds'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-clouds'

export default {
  name: 'AceEditor',
  props: {
    content: String, // 編集するテキスト
    font_size: Number, // フォントサイズ
    watching: Date, // 親から初期化処理をコントロールできるようにする
    height_vh: Number, // 親からheightを受け取る
  },
  computed: {

    // CSS変数を定義
    parent_style() {
      return {
        '--height_vh' : this.height_vh + 'vh',
      }
    },
  },
  methods: {
    editor_init() {
      this.editor = ace.edit(this.$refs["ace"]); // AceEditorオブジェクト取得
      this.editor.session.setMode("ace/mode/text"); // モード設定
      this.editor.setTheme("ace/theme/clouds"); // テーマ設定
      this.editor.setFontSize(this.font_size); // フォントサイズ
      this.editor.session.setValue(this.content); // テキストをセット
      this.editor.getSession().setUseWrapMode(true) // 文章の自動折り返しON
      this.editor.setShowInvisibles(true); // タブ、空白、改行を可視化
      this.editor.setShowPrintMargin(false); // 何文字目かを示す縦線を非表示

      // 2バイト文字がきれいなフォントは機種依存文字があるとカーソルがずれるので使えない
      // 機種依存文字を表示では２バイト、AceEditorでは1バイトと判定しているのが問題と思われるため対応できない
      //const fontFamily = ["sans-serif"];

      // フォント設定
      const fontFamily = ["Consolas", "source-code-pro", "monospace"];
      this.editor.setOptions({
        fontFamily: fontFamily,
      });

      // テキストが編集された際に、親に編集後のテキストを送る
      this.editor.on('change', () => {
        this.$emit('contentChanged', this.editor.getValue())
      })
      this.editor.resize(); // AceEditor のエリアを画面表示に合わせて設定
    }
  },
  data() {
    return {
      editor: Object,
    };
  },
  mounted() {
    this.editor_init();
  },
  watch: {
    watching: function () {
      this.editor.destroy(); // インスタンスを破棄してから生成する
      this.editor_init();
    },
    font_size: function () {
      this.editor.setFontSize(this.font_size);
    }
  },
};
</script>

<style scoped>
.ace-parent {
  width: 100%;
  --height_vh: 100vh;
  height: var(--height_vh);
  display: inline-block;
  position: relative;
}

.ace {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>