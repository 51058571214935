<template>
  <v-card>
    <!-- 閉じるボタン -->
    <v-btn id="button_close" icon @click="closeDialog()">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <!-- アラートエリア -->
    <v-container class="mt-n5 mb-n4">
      <AlertArea :isShow.sync="alerts.info.isShow" :message="alerts.info.message" type="info"/>
      <AlertArea :isShow.sync="alerts.success.isShow" :message="alerts.success.message" type="success"/>
      <AlertArea :isShow.sync="alerts.warning.isShow" :message="alerts.warning.message" type="warning"/>
      <AlertArea :isShow.sync="alerts.error.isShow" :message="alerts.error.message" type="error"/>
    </v-container>

    <!-- タイトル -->
    <v-container class="mt-n5 mb-n4">
      <v-row>
        <v-card-title>名称：{{ createFaqName }}</v-card-title>
      </v-row>
      <!-- 文書データダウンロードボタン -->
      <v-row class="ml-1" dense style="color:rgba(0, 0, 0, 0.7);">
        ・文書データ：{{ documentName }}
        <v-tooltip color="tooltip" top>
          <template v-slot:activator="{ on }">
            <v-btn
                id="download_button"
                v-on="on"
                :loading="downloadLoading"
                class="mt-n1 ma-3"
                color="primary"
                small
                @click="download()"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>ダウンロード</span>
        </v-tooltip>
      </v-row>
    </v-container>

    <v-container>
      <!-- 開閉パネル -->
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }">
            <v-row no-gutters>
              <v-col cols="4">
                <h4>検索入力フォーム</h4>
              </v-col>
              <v-col class="text--secondary" cols="8">
                <v-fade-transition leave-absolute>
                  <!-- 検索フォームを開いているときのメッセージ -->

                  <span v-if="open">検索条件を入力してください</span>

                  <!-- 検索フォームを閉じているときのメッセージ -->
                  <v-row v-else no-gutters style="width: 100%">
                    <!-- 設定された検索条件を表示 -->
                    <ClosedSearchFormFromToDisp
                        :fromValue="searchConditions.pageNumberMin"
                        :toValue="searchConditions.pageNumberMax"
                        label="ページ番号"
                    />
                    <ClosedSearchFormDisp
                        :value="searchConditions.originalText"
                        label="原文"
                    />
                    <ClosedSearchFormDisp
                        :value="searchConditions.question"
                        label="生成質問文"
                    />
                    <ClosedSearchFormDisp
                        :value="searchConditions.answer"
                        label="生成回答文"
                    />
                    <ClosedSearchFormDisp
                        :value="searchConditions.questionType"
                        label="質問種別"
                    />
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-form ref="search_form">
            <v-expansion-panel-content>
              <!-- ページ番号 -->
              <v-row>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">ページ番号 :</v-col>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchConditions.pageNumberMin"
                      label=""
                  ></NumberTextArea>
                </v-col>
                <div class="mt-6">～</div>
                <v-col cols="3">
                  <NumberTextArea
                      :value.sync="searchConditions.pageNumberMax"
                      label=""
                  ></NumberTextArea>
                </v-col>
              </v-row>

              <!-- 原文 -->
              <v-row>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">原文 :</v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="searchConditions.originalText"
                      clearable
                      label=""
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- 生成質問文 -->
              <v-row>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">生成質問文 :</v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="searchConditions.question"
                      clearable
                      label=""
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- 生成回答文 -->
              <v-row>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">生成回答文 :</v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="searchConditions.answer"
                      clearable
                      label=""
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- 質問種別 -->
              <v-row dense>
                <v-col class="mt-5 ml-4" cols="2" style="color:rgba(0, 0, 0, 0.6);">質問種別 :</v-col>
                <v-col cols="6">
                  <SelectField
                      :status="processingSelects"
                      :value.sync="searchConditions.questionType"
                      label=""
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-form>
        </v-expansion-panel>
      </v-expansion-panels>

      <br/>
      <v-row>
        <v-btn
            id="button_all_download_csv"
            class="ma-3"
            color="primary"
            :loading="downloadAllCSVLoading"
            @click="downloadAllCSV()"
        >
          <v-icon>mdi-file-export-outline</v-icon>
          全件ファイル出力
        </v-btn>
        <v-checkbox
            v-model="checkSkipBlankFaq"
            label="FAQ候補が生成された行のみ"
            v-bind:disabled="downloadAllCSVLoading">
        </v-checkbox>
        <v-spacer/>
        <v-btn
            :loading="getGeneratedFaqDetailListLoading"
            id="button_get_generated_faq_detail_list"
            class="ma-3"
            color="primary"
            @click="getGeneratedFaqDetailList()"
        >
          <v-icon>mdi-magnify</v-icon>
          検索
        </v-btn>
        <v-btn
            id="button_search_conditions_clear"
            class="ma-3"
            color="primary"
            @click="searchConditionsClear()"
        >
          <v-icon>mdi-eraser</v-icon>
          入力リセット
        </v-btn>
      </v-row>
    </v-container>

    <v-container>
      <v-data-table
          v-model="outputTargets"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 200, 300, 400, 500, 1000],
          }"
          :headers="headers"
          :items="generatedFaqDetailList"
          :loading="getGeneratedFaqDetailListLoading"
          :options.sync="options"
          :server-items-length="total"
          calculate-widths
          class="elevation-1 row-pointer mb-5"
          dense
          disable-sort
          item-key="id"
          show-select
          @click:row="rowClickEvent"
          @update:items-per-page="getGeneratedFaqDetailList()"
          @update:page="getGeneratedFaqDetailList()"
      >
        <template v-slot:[`item.original_text`]="{ item }">
          {{ item.original_text | truncate }}
        </template>
        <template v-slot:[`item.question`]="{ item }">
          {{ item.question | truncate }}
        </template>
        <template v-slot:[`item.answer`]="{ item }">
          {{ item.answer | truncate }}
        </template>
      </v-data-table>
      <v-dialog v-model="detailDialog" max-width="800">
        <v-card>
          <v-card-title>詳細</v-card-title>
          <v-container>
            <v-row>
              <v-col class="ml-6" cols="3"> 通し番号:</v-col>
              <v-col cols="8">
                {{ detailDialogData.no }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="ml-6" cols="3"> ページ番号:</v-col>
              <v-col cols="8">
                {{ detailDialogData.page }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="ml-6" cols="3"> 原文:</v-col>
              <v-col cols="8">
                {{ detailDialogData.original_text }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="ml-6" cols="3"> 生成質問文:</v-col>
              <v-col cols="8">
                {{ detailDialogData.question }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="ml-6" cols="3"> 生成回答文:</v-col>
              <v-col cols="8">
                {{ detailDialogData.answer }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ml-6" cols="3"> 質問種別:</v-col>
              <v-col cols="8">
                {{ detailDialogData.question_type }}
              </v-col>
            </v-row>

            <v-row class="pa-6" justify="center">
              <v-btn color="primary" @click="detailDialog = false"
              >閉じる
              </v-btn
              >
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- ファイル出力ボタン -->
    <v-container class="mt-n9">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn
            id="button_download_csv"
            class="ma-3"
            color="primary"
            @click="downloadCSV()"
        >
          <v-icon>mdi-file-export-outline</v-icon>
          ファイル出力
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import AlertArea from "../../parts/AlertArea.vue";
import NumberTextArea from "../../parts/NumberTextArea.vue";
import SelectField from "../../parts/SelectField.vue";
import ClosedSearchFormDisp from "../../parts/ClosedSearchFormDisp.vue";
import ClosedSearchFormFromToDisp from "../../parts/ClosedSearchFormFromToDisp.vue";
import ApiUtils from "../../js/ApiUtils";
import ObjectUtils from "../../js/ObjectUtils";
import Messages from "../../js/Messages";
import FileUtils from "@/components/js/FileUtils";
import ValidationUtils from "../../js/ValidationUtils";

export default {
  name: "FAQResult",
  props: {
    groupId: Number, // AI 教師データ生成ジョブの ID
    dialog: Boolean, // 大元のダイアログフラグ
    documentName: String, // 生成結果元の名称
    documentId: Number, // ドキュメントID
    createFaqName: String, // FAQ候補の名称
  },
  components: {
    AlertArea,
    NumberTextArea,
    SelectField,
    ClosedSearchFormDisp,
    ClosedSearchFormFromToDisp,
  },
  methods: {

    /**
     * 行をクリックした際の処理。
     * 選択された行のデータを引き継ぎ、ダイアログを表示する。
     **/
    rowClickEvent(item) {
      this.detailDialogData = item;
      this.detailDialog = true;
    },

    /**
     * 親にダイアログを閉じるように依頼する。
     **/
    closeDialog() {
      this.$emit("update:dialog", false);
    },

    /**
     * 検索フォームをすべてリセットする。
     */
    searchConditionsClear() {
      this.searchConditions.pageNumberMin = null;
      this.searchConditions.pageNumberMax = null;
      this.searchConditions.originalText = null;
      this.searchConditions.question = null;
      this.searchConditions.answer = null;
      this.searchConditions.questionType = null;
    },

    /**
     * 生成されたFAQデータの詳細リストを取得。
     **/
    async getGeneratedFaqDetailList() {
      this.logDebug("### getGeneratedFaqDetailList() 開始");

      // バリデーションNGなら処理終了
      if (!ValidationUtils.valid(this.$refs["search_form"])) {
        this.logDebug("### バリデーションNG getGeneratedFaqDetailList() 終了");
        return;
      }

      // チェックボックス初期化
      this.checkSkipBlankFaq = false;
      // ローディング開始
      this.getGeneratedFaqDetailListLoading = true;
      try {

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "page_from", this.searchConditions.pageNumberMin);
        ObjectUtils.objectPush(qParams, "page_to", this.searchConditions.pageNumberMax);
        ObjectUtils.objectPush(qParams, "original_text", this.searchConditions.originalText);
        ObjectUtils.objectPush(qParams, "question", this.searchConditions.question);
        ObjectUtils.objectPush(qParams, "answer", this.searchConditions.answer);
        ObjectUtils.objectPush(qParams, "question_type", this.searchConditions.questionType);
        ObjectUtils.objectPush(qParams, "limits", this.options.itemsPerPage);
        ObjectUtils.objectPush(qParams, "page", this.options.page);

        // APIリクエスト（R28）
        this.logDebug("#### R28 リクエスト");
        const response = await ApiUtils.get(
            "/faq_from_document/document/" + this.groupId + "/faq",
            qParams
        );

        if (200 === response.status) {
          this.logDebug("#### R28 成功");
          this.generatedFaqDetailList = response.data.data;
          this.total = response.data.counts;
        } else {
          this.logDebug("#### R28 失敗 レスポンス：");
          this.logDebug(response);
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r28.warning;
        }
      } catch (error) {
        this.logDebug("#### getGeneratedFaqDetailList() 例外発生");
        this.logDebug(error);
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r28.error;
      } finally {
        // ローディング終了
        this.getGeneratedFaqDetailListLoading = false;
      }
      this.logDebug("### getGeneratedFaqDetailList() 終了");
    },

    /**
     * 全件ファイル出力ボタン押下時の処理。
     * 生成結果全件が出力されたcsvファイルをダウンロードする。
     */
    async downloadAllCSV() {
      this.logDebug("### downloadAllCSV() 開始");
      try {
        this.downloadAllCSVLoading = true;

        let skip_blank_faq = (this.checkSkipBlankFaq ? "1" : "0")
        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "skip_blank_faq", skip_blank_faq);

        // APIリクエスト（R43）
        this.logDebug("#### R43 リクエスト（FAQ候補が生成された行のみ：" + skip_blank_faq + "）");
        const response = await ApiUtils.get("/faq_from_document/document/" + this.groupId + "/faq_csv", qParams);

        if (200 === response.status) {
          window.location.href = response.data.download_url;
        } else {
          this.logDebug("#### R43 失敗 レスポンス：");
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r43.warning;
        }
      } catch (error) {
        this.logDebug("#### downloadAllCSV() 例外発生");
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r43.error;
      } finally {
        this.downloadAllCSVLoading = false;
      }
      this.logDebug("### downloadAllCSV() 終了");
    },

    /**
     * CSVファイルを出力してダウンロードする。
     * CSVはファイルの先頭にBOMとしてを'\ufeff'を追加している。
     * 出力対象はoutputTargetsとしている。
     */
    downloadCSV() {
      this.logDebug("### downloadCSV() 開始");
      if (this.outputTargets[0] == null) {
        this.logDebug("### 未選択 downloadCSV() 終了");
        alert(Messages.faqResultFileOutput.error);
        return;
      }

      // FileUtils.downLoadCSV()で適切に出力されるようにデータを複製
      FileUtils.duplicateJsonKey(this.outputTargets, "no", "通し番号");
      FileUtils.duplicateJsonKey(this.outputTargets, "page", "ページ番号");
      FileUtils.duplicateJsonKey(this.outputTargets, "categories", "カテゴリ");
      FileUtils.duplicateJsonKeyWithEscapeExcelCommand(this.outputTargets, "original_text", "原文");
      FileUtils.duplicateJsonKeyWithEscapeExcelCommand(this.outputTargets, "question", "生成質問文");
      FileUtils.duplicateJsonKeyWithEscapeExcelCommand(this.outputTargets, "answer", "生成回答文");
      FileUtils.duplicateJsonKey(this.outputTargets, "question_type", "質問種別");

      // CSVに出力するカラムを定義
      const fields = [
        "通し番号",
        "ページ番号",
        "カテゴリ",
        "原文",
        "生成質問文",
        "生成回答文",
        "質問種別",
      ];
      const filename = "FAQデータ.csv";
      FileUtils.downLoadCSV(fields, this.outputTargets, filename);
      this.logDebug("### downloadCSV() 終了");
    },

    /**
     * ダウンロードボタン押下時の処理。
     * 生成結果の元ファイルをダウンロードする。
     * 一度検索してレスポンスからs3_pathを取得し、
     */
    async download() {
      this.logDebug("### download() 開始");
      try {
        this.downloadLoading = true;

        const searchResponse = await this.search();
        if (!searchResponse.is_success) {
          return
        }

        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "s3_path", searchResponse.path);
        // APIリクエスト（R2）
        this.logDebug("#### R2 リクエスト");
        const response = await ApiUtils.get("/common/download_url", qParams);

        if (200 === response.status) {
          window.open(response.data.download_url, '_blank')
        } else {
          this.logDebug("#### R2 失敗 レスポンス：");
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r2.warning;
        }
      } catch (error) {
        this.logDebug("#### download() 例外発生");
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r2.error;
      } finally {
        this.downloadLoading = false;
        this.logDebug("### download() 終了");
      }
    },

    /**
     * R16をリクエストして、ドキュメントpdfの情報を取得する。
     */
    async search() {
      this.logDebug("### search() 開始");
      let ret = {
        is_success: false,
        path: null,
      };
      try {
        // クエリパラメータを設定
        let qParams = {};
        ObjectUtils.objectPush(qParams, "name", this.documentName);

        // APIリクエスト（R16）
        this.logDebug("#### R16 リクエスト");
        const response = await ApiUtils.get("/faq_from_document/document", qParams);

        if (200 === response.status) {
          const documentData = response.data.data.find(data => data.id === this.documentId);
          if (!documentData) {
            this.logDebug("#### search():元ファイル削除済み");
            this.alerts.warning.isShow = true;
            this.alerts.warning.message = Messages.download.warning;
            return ret;
          }
          ret = {
            is_success: true,
            path: documentData.s3_path,
          }
          return ret;
        } else {
          this.logDebug("#### R16 失敗 レスポンス：");
          ObjectUtils.alertsInit(this.alerts);
          this.alerts.warning.isShow = true;
          this.alerts.warning.message = Messages.r16.warning;
          return ret;
        }
      } catch (error) {
        this.logDebug("#### search() 例外発生");
        ObjectUtils.alertsInit(this.alerts);
        this.alerts.error.isShow = true;
        this.alerts.error.message = Messages.r16.error;
        return ret;
      } finally {
        this.logDebug("### search() 終了");
      }
    },
  },
  data: () => ({
    downloadLoading: false,
    downloadAllCSVLoading: false,
    detailDialog: false,
    detailDialogData: {
      no: "",
      page: "",
      original_text: "",
      question: "",
      answer: "",
      question_type: "",
    },
    getGeneratedFaqDetailListLoading: false,
    alerts: {
      info: {isShow: false, message: ""},
      success: {isShow: false, message: ""},
      warning: {isShow: false, message: ""},
      error: {isShow: false, message: ""},
    },
    processingSelects: ["What", "Yes/No", "見出し"],
    outputTargets: [],
    headers: [
      {align: "end", text: "通し番号", value: "no"},
      {align: "end", text: "ページ番号", value: "page"},
      {align: "start", text: "カテゴリ", value: "categories"},
      {align: "start", text: "原文", value: "original_text"},
      {align: "start", text: "生成質問文", value: "question"},
      {align: "start", text: "生成回答文", value: "answer"},
      {align: "start", text: "質問種別", value: "question_type"},
    ],
    total: 0,
    options: {
      page: 1,
      itemsPerPage: 20,
    },
    generatedFaqDetailList: [],
    searchConditions: {
      pageNumberMin: null,
      pageNumberMax: null,
      originalText: null,
      question: null,
      answer: null,
      questionType: null,
    },
    checkSkipBlankFaq: false,
  }),
  watch: {
    groupId: function () {
      // groupIdを監視。groupIdに変更があったら再度取得処理を呼び出す
      // データリセット
      this.outputTargets = [];
      this.generatedFaqDetailList = [];
      this.options.itemsPerPage = 20;
      this.options.page = 1;
      this.total = 0;
      ObjectUtils.alertsInit(this.alerts);
      this.getGeneratedFaqDetailList();
    },
  },
  mounted: function () {
    // 画面表示のタイミングでデータ詳細を取得
    this.getGeneratedFaqDetailList();
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>