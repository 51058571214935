// --------------------------------------------------------------------
// 補足：このファイルに記載したファンクションはすべてのコンポーネントで使用可能になる
// --------------------------------------------------------------------
/**
 * 文章の文字数を18文字分だけ取得、その後ろに三点リーダーつけて省略した文章を返す。
 * @param {string} value 変換対象の文字列
 * @returns {string} 変換後の文字列
 */
export function truncate(value) {
    const length = 18;
    const omission = "...";
    if (value == null || value.length <= length) {
        return value;
    }
    return value.substring(0, length) + omission;
}

export default {
    truncate
}