<template>
  <v-main>
    <!-- タイトル -->
    <Title title="応対履歴から FAQ候補生成"/>

    <!-- タブ -->
    <ResponseHistoryToFAQTab/>

  </v-main>
</template>

<script>
import Title from '../parts/Title.vue';
import ResponseHistoryToFAQTab from '../tabs/ResponseHistoryToFAQTab.vue';

export default {
  name: 'ResponseHistoryToFAQ',
  components: {
    Title,
    ResponseHistoryToFAQTab
  },
  data: () => ({}),
};
</script>

