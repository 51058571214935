<template>
  <v-text-field
      v-model="inputData"
      :label="label"
      :value="value"
      auto-grow
      disabled
      row-height="15"
      rows="1"
  ></v-text-field>
</template>

<script>
// 読み取り専用の NormalTextArea コンポーネント
export default {
  name: 'ReadOnlyNormalTextArea',
  props: ['value', 'label'],
  // 変更を検知したら親に値の更新を依頼する
  computed: {
    inputData: {
      get() {
        return this.value
      }
    },
  }
};
</script>