<template>
  <v-row justify="center">
    <v-col cols="4">
      <v-text-field
          v-model="inputData"
          :label="label"
          :rules="[required]"
          :value="value"
          auto-grow
          no-resize
          row-height="15"
          rows="1"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LoginTextArea",
  props: ["value", "label"],
  methods: {
    required: value => !!value || ""
  },
  // 変更を検知したら親に値の更新を依頼する
  computed: {
    inputData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  data() {
    return {};
  },
};
</script>