export default Object.freeze({
    r1: {
        warning: "[ファイル名]：[0] のアップロードに必要な情報の取得に失敗しました。時間をおいてやり直してください。",
        error: "[ファイル名]：[0] のアップロードに必要な情報の取得に失敗しました。管理者にお問い合わせください。",
    },
    r2: {
        warning: "ダウンロードに失敗しました。時間をおいてやり直してください。",
        error: "ダウンロードに失敗しました。管理者にお問い合わせください。",
    },
    r4: {
        success: "FAQデータの新規登録が完了しました。\n・[FAQデータ名]：[0]\n・[ファイル名]：[1]",
        warning: "FAQデータの新規登録処理が失敗しました。ファイルの内容を確認してください。",
        error: "FAQデータの新規登録処理が失敗しました。管理者にお問い合わせください。",
    },

    r6: {
        warning: "登録済みのFAQデータが取得できませんでした。時間をおいてやり直してください。",
        error: "登録済みのFAQデータが取得できませんでした。管理者にお問い合わせください。",
    },
    r31r6: {
        error: "登録済みの応対履歴データおよびFAQデータが取得できませんでした。管理者にお問い合わせください。",
    },
    r7: {
        warning: "応対履歴データを取得できませんでした。時間をおいてやり直してください。",
        error: "応対履歴データを取得できませんでした。管理者にお問い合わせください。",
    },
    r8: {
        warning: "FAQ詳細データを取得できませんでした。時間をおいてやり直してください。",
        error: "FAQ詳細データを取得できませんでした。管理者にお問い合わせください。",
    },
    r9: {
        success: "データセットの削除が完了しました。",
        warning: "データセットの削除に失敗しました。時間をおいてやり直してください。",
        error: "データセットの削除に失敗しました。管理者にお問い合わせください。",
    },
    r10: {
        success: "FAQファイルの削除が完了しました。",
        warning: "FAQファイルの削除に失敗しました。時間をおいてやり直してください。",
        error: "FAQファイルの削除に失敗しました。管理者にお問い合わせください。",
    },
    r11: {
        success: "学習・推論ジョブ情報の登録が完了しました。AI教師データ生成処理が完了するまでしばらくお待ち下さい。\n・[名称]：[0]\n・[応対履歴データ]：[1]\n・[FAQデータ]：[2]",
        warning: "学習・推論ジョブ情報の登録が失敗しました。時間をおいてやり直してください。",
        error: "学習・推論ジョブ情報の登録が失敗しました。管理者にお問い合わせください。",
    },
    r13: {
        warning: "AI教師データが取得できませんでした。時間をおいてやり直してください。",
        error: "AI教師データが取得できませんでした。管理者にお問い合わせください。",
    },
    r14: {
        warning: "AI教師データを取得できませんでした。時間をおいてやり直してください。",
        error: "AI教師データを取得できませんでした。管理者にお問い合わせください。",
    },
    r15: {
        success: "文書データの新規登録が完了しました。\n・[文書データ名]：[0]\n・[ファイル名]：[1]",
        warning: "新規登録処理が失敗しました。時間をおいてやり直してください。",
        error: "新規登録処理が失敗しました。管理者にお問い合わせください。",
    },
    r16: {
        warning: "登録済み文書データを取得できませんでした。時間をおいてやり直してください。",
        error: "登録済み文書データを取得できませんでした。管理者にお問い合わせください。",
    },
    r18: {
        success: "文書データの削除が完了しました。",
        warning: "文書データの削除に失敗しました。時間をおいてやり直してください。",
        warning406: "文書データの削除に失敗しました。子データを1つ以上持つ文書データは削除できません。",
        error: "文書データの削除に失敗しました。管理者にお問い合わせください。",
    },
    r19: {
        warning: "テキスト化した文書データを取得できませんでした。時間をおいてやり直してください。",
        error: "テキスト化した文書データを取得できませんでした。管理者にお問い合わせください。",
    },
    r20: {
        warning: "編集済みテキストのアップロードに失敗しました。時間をおいてやり直してください。",
        error: "編集済みテキストのアップロードに失敗しました。管理者にお問い合わせください。",
    },
    r21: {
        warning: "見出しの自動判定に失敗しました。時間をおいてやり直してください。",
        error: "見出しの自動判定に失敗しました。管理者にお問い合わせください。",
    },
    r22: {
        timeout:"見出しルールの処理時間が一定を超えたためテストに失敗しました。見出しルールを変更してください。" ,
        warning: "見出しパターンのテストに失敗しました。時間をおいてやり直してください。",
        error: "見出しパターンのテストに失敗しました。管理者にお問い合わせください。",
    },
    r24: {
        warning: "編集した見出しパターンの登録に失敗しました。時間をおいてやり直してください。",
        error: "編集した見出しパターンの登録に失敗しました。管理者にお問い合わせください。",
    },
    r25: {
        success: "解析ジョブ情報の登録が完了しました。新規FAQ候補生成処理が完了するまでしばらくお待ち下さい。\n・[名称]：[0]\n・[文書データ]：[1]",
        warning: "解析ジョブ情報の登録が失敗しました。時間をおいてやり直してください。",
        error: "解析ジョブ情報の登録が失敗しました。管理者にお問い合わせください。",
    },
    r27: {
        warning: "情報を取得できませんでした。時間をおいてやり直してください。",
        error: "情報を取得できませんでした。管理者にお問い合わせください。",
    },
    r28: {
        warning: "FAQ詳細データを取得できませんでした。時間をおいてやり直してください。",
        error: "FAQ詳細データを取得できませんでした。管理者にお問い合わせください。",
    },
    r29: {
        warning: "応対履歴データセット情報が登録できませんでした。時間をおいてやり直してください。",
        error: "応対履歴データセット情報が登録できませんでした。管理者にお問い合わせください。",
    },
    r30: {
        success: "データセットと応対履歴ファイルの紐付けが完了しました。\n・[データセット名]：[0]",
        addSuccess: "データセットと応対履歴ファイルの紐付けが完了しました。",
        warning: "データセットと応対履歴ファイルの紐付けが失敗しました。ファイルの内容を確認してください。\n・[ファイル名]：[0] ",
        error: "データセットと応対履歴ファイルの紐付けが失敗しました。管理者にお問い合わせください。\n・[ファイル名]：[0] ",
        uncompleted: "処理中に異常を検知したため、以下のファイルは処理されておりません。再度登録してください。",
    },
    r31: {
        warning: "応対履歴データセットが取得できませんでした。時間をおいてやり直してください。",
        error: "応対履歴データセットが取得できませんでした。管理者にお問い合わせください。",
    },
    r32: {
        warning: "応対履歴データセットの詳細が取得できませんでした。時間をおいてやり直してください。",
        error: "応対履歴データセットの詳細が取得できませんでした。管理者にお問い合わせください。",
    },
    r33: {
        success: "データセットと応対履歴ファイルの紐付け情報の削除が完了しました。",
        warning: "データセットと応対履歴ファイルの紐付け情報の削除に失敗しました。時間をおいてやり直してください。",
        warning406: "ファイルが1件だけの場合、削除することはできません。完全に削除する場合は、データセット自体の削除をご検討ください。",
        error: "データセットと応対履歴ファイルの紐付け情報の削除に失敗しました。管理者にお問い合わせください。",
    },
    r34: {
        warning: "AI教師データとマッチするFAQが取得できませんでした。時間をおいてやり直してください。",
        error: "AI教師データとマッチするFAQが取得できませんでした。管理者にお問い合わせください。",
    },
    r35: {
        success: "学習・推論ジョブ情報の登録が完了しました。新規FAQ候補生成処理が完了するまでしばらくお待ち下さい。\n・[名称]：[0]\n・[応対履歴データ]：[1]\n・[FAQデータ]：[2]\n・[クラスタ数]：[3]",
        warning: "学習・推論ジョブ情報の登録が失敗しました。時間をおいてやり直してください。",
        error: "学習・推論ジョブ情報の登録が失敗しました。管理者にお問い合わせください。",
    },
    r36: {
        warning: "FAQ候補生成結果を取得できませんでした。時間をおいてやり直してください。",
        error: "FAQ候補生成結果を取得できませんでした。管理者にお問い合わせください。",
    },
    r37: {
        warning: "FAQ候補生成結果を取得できませんでした。時間をおいてやり直してください。",
        error: "FAQ候補生成結果を取得できませんでした。管理者にお問い合わせください。",
    },
    r38: {
        warning: "FAQ候補生成結果の明細を取得できませんでした。時間をおいてやり直してください。",
        error: "FAQ候補生成結果の明細を取得できませんでした。管理者にお問い合わせください。",
    },
    r39:{
        warning: "見出しパターンの取得に失敗しました。時間をおいてやり直してください。",
        error: "見出しパターンの取得に失敗しました。管理者にお問い合わせください。",
    },
    r40: {
        warning: "見出しパターンの登録に失敗しました。時間をおいてやり直してください。",
        error: "見出しパターンの登録に失敗しました。管理者にお問い合わせください。",
    },
    r41:{
        warning: "見出しパターンの更新に失敗しました。時間をおいてやり直してください。",
        error: "見出しパターンの更新に失敗しました。管理者にお問い合わせください。",
    },
    r42:{
        success: "見出しパターンの削除が完了しました。",
        warning: "見出しパターンの削除に失敗しました。時間をおいてやり直してください。",
        error: "見出しパターンの削除に失敗しました。管理者にお問い合わせください。",
    },
    r43: {
        warning: "全件ファイル出力に失敗しました。時間をおいてやり直してください。",
        error: "全件ファイル出力に失敗しました。管理者にお問い合わせください。",
    },
    r511: {
        warning: "同義語プロジェクトの一覧が取得できませんでした。時間をおいてやり直してください。",
        error: "同義語プロジェクトの一覧が取得できませんでした。管理者にお問い合わせください。",
    },
    r512: {
        warning: "同義語プロジェクトが取得できませんでした。時間をおいてやり直してください。",
        error: "同義語プロジェクトが取得できなかったか、同義語プロジェクトでエラーが発生しております。管理者にお問い合わせください。",
    },
    r513: {
        warning: "同義語プロジェクトの作成に失敗しました。時間をおいてやり直してください。",
        error: "同義語プロジェクトの作成に失敗しました。管理者にお問い合わせください。",
    },
    r514: {
        warning: "同義語データインポートに失敗しました。時間をおいてやり直してください。",
        error: "同義語データインポートに失敗しました。管理者にお問い合わせください。",
    },
    r516: {
        warning: "同義語プロジェクトの削除に失敗しました。時間をおいてやり直してください。",
        error: "同義語プロジェクトの削除に失敗しました。管理者にお問い合わせください。",
    },
    r541: {
        warning: "単語ランキング分析結果の取得に失敗しました。時間をおいてやり直してください。",
        error: "単語ランキング分析結果の取得に失敗しました。管理者にお問い合わせください。",
    },
    r553: {
        warning: "単語分散表現データ作成に失敗しました。時間をおいてやり直してください。",
        error: "単語分散表現データ作成に失敗しました。管理者にお問い合わせください。",
    },
    r554: {
        success: "同義語プロジェクトの登録が完了しました。\n・[名称]：[0]\n・[ファイル名]：[1]",
        warning: "単語分散表現データ解析に失敗しました。時間をおいてやり直してください。",
        error: "単語分散表現データ解析に失敗しました。管理者にお問い合わせください。",
    },
    r555: {
        warning: "単語分散表現データの削除に失敗しました。時間をおいてやり直してください。",
        error: "単語分散表現データの削除に失敗しました。管理者にお問い合わせください。",
    },
    r556: {
        warning: "類似単語の取得に失敗しました。時間をおいてやり直してください。",
        error: "類似単語の取得に失敗しました。管理者にお問い合わせください。",
    },
    callListUsers: {
        success: "ユーザリストの取得に成功しました。",
        warning: "ユーザリストの取得に失敗しました。",
        error: "ユーザリストの取得でエラーが発生しました。しばらくたってから、再度お試しください。",
    },
    createUser: {
        success: "ユーザの新規追加に成功しました。",
        warning: "入力された値が正しくないか、または重複しています。",
        error: "エラーが発生しました。しばらくたってから再度お試しください。",
        entered: "すべての項目を入力してください。"
    },
    updateUser: {
        success: "ユーザ情報の更新に成功しました。",
        warning: "入力された値が正しくないか、または重複しています。",
        error: "エラーが発生しました。しばらくたってから再度お試しください。",
        entered: "すべての項目を入力してください。"
    },
    deleteUser: {
        success: "ユーザの削除に[0]件成功しました。",
        warning: "ユーザの削除に失敗しました。",
        error: "ユーザ削除でエラーが発生しました。しばらくたってから、再度お試しください。",
    },
    synonymProjectDelete: {
        success: "同義語プロジェクトの削除が完了しました。",
    },
    responseHistorySynonymProject: {
        success: "同義語プロジェクトの登録が完了しました。\n・[名称]：[0]",
    },
    addSynonymData: {
        success: "同義語プロジェクトの登録が完了しました。",
    },
    faqSynonymProject: {
        success: "FAQデータの新規登録が完了しました。\n・[FAQデータ名]：[0]\n・[ファイル名]：[1]\n同義語プロジェクトの登録が完了しました。\n・[名称]：[2]\n・[ファイル名]：[3]",
    },
    documentSynonymProject: {
        success: "文書データの新規登録が完了しました。\n・[文書データ名]：[0]\n・[ファイル名]：[1]\n同義語プロジェクトの登録が完了しました。\n・[名称]：[2]\n・[ファイル名]：[3]",
    },
    fileupload: {
        error: "[ファイル名]：[0] のアップロードに失敗しました。管理者にお問い合わせください。",
    },
    aiResultFileOutput: {
        error: "ファイル出力するAI教師データを１つ以上選んでください。",
    },
    faqResultFileOutput: {
        error: "ファイル出力するFAQデータを１つ以上選んでください。",
    },
    synonymFileOutput: {
        error: "ファイル出力する単語を１つ以上選んでください。",
    },
    faqCandidateResultFileOutput: {
        error: "ファイル出力するFAQ候補データを１つ以上選んでください。",
    },
    faqCandidateResultDetailFileOutput: {
        error: "ファイル出力するFAQ候補生成結果明細を１つ以上選んでください。",
    },

    login: {
        error_validation: "ユーザID 、またはパスワードを入力してください。",
        error: "ユーザID 、またはパスワードが正しくありません。",
    },
    forgotPassword: {
        error_validation: "ユーザID を入力してください。",
        error: "ユーザID が正しくありません。",
    },
    confirmForgotPassword: {
        error_validation_not_set: "確認コード、またはパスワードを入力してください。",
        error_validation_not_correct: "入力されたパスワードが正しくありません。",
        error: "確認コード、またはパスワードが正しくありません。",
    },
    updateNewPassword: {
        error_validation_not_set: "パスワードを入力してください。",
        error_validation_not_correct: "入力されたパスワードが正しくありません。",
        error: "パスワードが正しくありません。",
    },
    clusterCount: {
        error: "クラスタ数は1以上、かつ応対履歴の件数以下にしてください。",
    },
    common: {
        timeout: "API通信でタイムアウトが発生しました。時間をおいてやり直してください。",
    },
    download: {
        warning: "ファイルが削除されています。",
    },
    changePassword: {
        success: "パスワードの変更が完了しました。",
        error_validation_not_set: "すべての項目を入力してください。",
        error_validation_not_correct: "入力されたパスワードが正しくありません。",
        error: "パスワードの変更に失敗しました。",
    },
    resetPassword: {
        success: "パスワードのリセットが完了しました。設定した仮パスワードを、対象のユーザにご案内ください。",
        error_validation_not_set: "すべての項目を入力してください。",
        warning: "入力されたパスワードが正しくありません。",
        error: "パスワードのリセットに失敗しました。時間をおいてやり直してください。",
    },
    filePreview: {
        warning:"pdf以外のファイルはプレビュー表示されません。"
    },
    faqFromDocumentSummaryTitle: {
        empty: "該当するデータがありません。",
        warning: "入力された年月の値が正しくありません。",
        error: "FAQ 候補数集計結果の取得に失敗しました。",
    },
    faqFromDocumentSummaryDetail: {
        empty: "該当するデータがありません。",
        warning: "入力された年月の値が正しくありません。",
        error: "FAQ 候補数明細の取得に失敗しました。",
    },
});
