import Vue from 'vue'
import Router from "vue-router"
import TopContents from "./components/pages/Top"
import CreateAiTeacherData from "./components/pages/CreateAiTeacherData"
import DocumentToFAQ from "./components/pages/DocumentToFAQ"
import ResponseHistoryToFAQ from "./components/pages/ResponseHistoryToFAQ"
import CreateSynonym from "./components/pages/CreateSynonym"
import Login from "./components/pages/Login"
import Password from "./components/pages/Password"
import Users from "./components/pages/Users"
import store from './store/store'

Vue.use(Router)

const router = new Router({
        mode: 'history',
        routes: [
            {
                path: '/',
                name: 'top',
                component: TopContents
            },
            {
                path: '/ai',
                name: 'createAiTeacherData',
                component: CreateAiTeacherData
            },
            {
                path: '/doc-faq',
                name: 'DocumentToFAQ',
                component: DocumentToFAQ
            },
            {
                path: '/log-faq',
                name: 'responseHistoryToFAQ',
                component: ResponseHistoryToFAQ
            },
            {
                path: '/synonym',
                name: 'createSynonym',
                component: CreateSynonym
            },
            {
                path: '/login',
                name: 'login',
                component: Login
            },
            {
                path: '/users',
                name: 'users',
                component: Users
            },
            {
                path: '/password',
                name: 'password',
                component: Password
            }
        ]
    }
)

// 各ページに遷移する前に、認証状態をチェックする
router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        // 次の遷移先が login の場合は、（ログイン情報があれば破棄した後で）そのまま遷移
        store.dispatch("user/logout"); // Vuexで管理しているログイン情報を破棄
        next() 

    } else {
        // isLogin フラグ値が true で、かつ、テナント情報が格納されているかどうかをチェック
        // 認証の有効期限チェックについては、REST API のレスポンス受け取りのタイミングで実施されることになる
        if (store.getters['user/isLogin'] && store.getters['user/tenant']) {

            if (to.name === 'users') { // ユーザ管理画面への遷移の場合、ロールの値をチェック
                const roles_string = store.getters['user/roles']
                const roles = JSON.parse(roles_string)
                const tenant = store.getters['user/tenant']
                if (roles[tenant] === 'manager') {
                    next() // manager 権限を持っているユーザであれば、ユーザ管理画面への遷移を許可
                } else {
                    next({name: 'top'}) // manager 権限を持っていない場合、トップ画面に遷移
                }
            } else { // ユーザ管理画面以外への遷移の場合
                next() // 認証済みの状態（ isLogin、tenant の値を保持している）の場合、そのまま遷移
            }

        } else {
            store.dispatch("user/logout"); // Vuexで管理しているログイン情報を破棄
            next({name: 'login'}) // 非認証状態の場合、login ページに遷移
        }
    }
})

export default router